import { useCallback, useEffect, useState } from 'react';
import * as Server from '../functions/data/Server';

const count = 50;

/**
 * メッセージ情報の取得Hook
 * @returns
 */
export default function useMessage() {
  const [messages, setMessages] = useState([]);
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState();

  const next = () => {
    if (!totalCount) {
      return;
    }
    let newOffset = offset + count;
    if (newOffset >= totalCount - count) {
      newOffset = totalCount - count;
      if (newOffset < 0) newOffset = 0;
    }
    setOffset(newOffset);
  };

  const before = () => {
    if (!totalCount) {
      return;
    }
    let newOffset = offset - count;
    if (newOffset < 0) {
      newOffset = 0;
    }
    setOffset(newOffset);
  };

  useEffect(() => {
    (async () => {
      const totalCount = await Server.getMessageTotalCount();
      setTotalCount(totalCount);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const serverResult = await Server.getMessage(offset, count);
      const newMessages = messages.concat(serverResult).sort((a, b) => a.regDate > b.regDate);
      setMessages(newMessages);
    })();
  }, [offset]);

  return [messages, next, before];
}
