import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputSuggest from '../../common/components/InputSuggest';
import TitledComponent from './TitledComponent';
import TitledSelect from './TitledSelect';
import { TextInput } from '@react-native-material/core';
import TitledInput from './TitledInput';
import * as TypeUtil from '../../common/util/TypeUtil';
import { View } from 'react-native';

/**
 * 選択に対して１つの自由入力欄がある
 * 選択itemsはパフォーマンスや、すでに入力された値の除外などを意識して、propsでもらう
 * @param onChange このコンポーネントで修正された値を上位に渡す
 * @returns
 */
export default function TitledInputSuggestAddition(props) {
  const {
    onChange, value, items, componentKey, label, isEnableCustomValue, multiple, additionItems,
    placeholder, dir, icon, title, iconColor, iconSize, help, modalHelp, subIcons, componentStyle, style,
    error, errorBgColor, itemBgColor, itemTextColor, itemStyle, itemsFunc, appendNode, vertical,
    customValueMaxLength, additionPlaceholder,
  } = props;

  const separateValueForState = (v) => {
    if (TypeUtil.isEmpty(v)) {
      return null;
    }
    if (multiple) {
      return {
        selectedItems: TypeUtil.optional(v?.selectedItems)?.map((e) => ({
          no: e?.no,
          label: e?.label,
          isCustom: e?.isCustom,
          isSelected: e?.isSelected,
          }
        )) || [],
        addition: v?.addition,
      };
    }
    return !TypeUtil.isEmpty(v)
      ? {
        no: v?.no,
        label: v?.label,
        isCustom: v?.isCustom,
        isSelected: true,
        addition: v?.addition,
      }
      : null;
  };

  const separatedValue = separateValueForState(value);

  const [selectedItems, setSelectedItems] = useState(multiple ? separatedValue?.selectedItems : separatedValue);
  const [addition, setAddition] = useState(separatedValue?.addition);

  useEffect(() => {
    if (!TypeUtil.isEmpty(selectedItems)) {
      if (multiple) {
        onChange({
          selectedItems,
          addition,
        });
        return;
      }
      onChange({
        no: selectedItems?.no,
        label: selectedItems?.label,
        isCustom: selectedItems?.isCustom,
        isSelected: selectedItems?.isSelected,
        addition,
      });
    }
  }, [selectedItems, addition]);

  return (
    <TitledComponent
      componentKey={`titledComponent_${componentKey}`}
      dir={dir}
      icon={icon}
      iconColor={iconColor}
      iconSize={iconSize}
      componentStyle={[
        {
          flexDirection: vertical ? 'column' : 'row',
          flexWrap: 'wrap',
          marginBottom: !!error ? 0 : 8,
          marginTop: 8,
          paddingLeft: 16,
          alignItems: 'flex-start',
        },
        componentStyle,
      ]}
      style={style}
      title={title}
      help={help}
      subIcons={subIcons}
      error={error}
      errorBgColor={errorBgColor}
      appendNode={appendNode}
    >
      <View
        style={{
          flex: 1,
          minWidth: 200,
        }}
      >
        <InputSuggest
          componentKey={`inputSuggest_${componentKey}`}
          onChange={(v) => setSelectedItems(v)}
          label={label}
          help={modalHelp}
          value={selectedItems}
          items={items}
          multiple={multiple}
          itemBgColor={itemBgColor}
          itemTextColor={itemTextColor}
          itemStyle={itemStyle}
          isEnableCustomValue={isEnableCustomValue}
          customValueMaxLength={customValueMaxLength}
          itemsFunc={itemsFunc}
        />
      </View>
      <View
        style={{
          flex: 1,
          marginTop: 8,
        }}
      >
        { !TypeUtil.isEmpty(additionItems)
        ? (
          <TitledSelect
            placeholder={!!additionPlaceholder ? additionPlaceholder : `${label ? `${label}-` : ''}選択`}
            items={additionItems}
            defaultValue={value?.addition}
            onChange={(v) => {
              setAddition(v);
            }}
            componentKey={`additionSelect-${componentKey}`}
          />
        ) : (
          <TitledInput
            key={`textInput_${componentKey}`}
            placeholder={!!additionPlaceholder ? additionPlaceholder : placeholder}
            onChangeText={(v) => setAddition(v)}
            value={addition || ''}
            style={{flex: 1}}
          />
        )}
      </View>
    </TitledComponent>
  );
}

export const oneValueType = PropTypes.shape({
  no: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  addition: PropTypes.any,
  isCustom: PropTypes.bool,
  isSelected: PropTypes.bool,
});

export const multipleValueType = PropTypes.shape({
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      no: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      isCustom: PropTypes.bool,
      isSelected: PropTypes.bool,
    })
  ),
  addition: PropTypes.any,
});

export const valueType = PropTypes.oneOfType([
  oneValueType,
  multipleValueType,
]);

TitledInputSuggestAddition.propTypes = {
  dir: TitledComponent.propTypes.dir,
  icon: TitledComponent.propTypes.icon,
  iconColor: TitledComponent.propTypes.iconColor,
  iconSize: TitledComponent.propTypes.iconSize,
  title: TitledComponent.propTypes.title,
  onChange: PropTypes.func.isRequired,
  value: valueType,
  items: InputSuggest.propTypes.items,
  multiple: InputSuggest.propTypes.multiple,
  additionItems: TitledSelect.propTypes.items,
  componentKey: InputSuggest.propTypes.componentKey,
  label: PropTypes.string,
  isEnableCustomValue: InputSuggest.propTypes.isEnableCustomValue,
  customValueMaxLength: InputSuggest.propTypes.customValueMaxLength,
  placeholder: PropTypes.string,
  help: TitledComponent.propTypes.help,
  modalHelp: InputSuggest.propTypes.help,
  subIcons: TitledComponent.propTypes.subIcons,
  error: TitledComponent.propTypes.error,
  errorBgColor: TitledComponent.propTypes.errorBgColor,
  itemBgColor: InputSuggest.propTypes.itemBgColor,
  itemTextColor: InputSuggest.propTypes.itemTextColor,
  itemStyle: InputSuggest.propTypes.itemStyle,
  itemsFunc: InputSuggest.propTypes.itemsFunc,
  appendNode: TitledComponent.propTypes.appendNode,
  vertical: PropTypes.bool,
  additionPlaceholder: TitledSelect.propTypes.placeholder,
};

TitledInputSuggestAddition.defaultProps = {
  dir: TitledComponent.defaultProps.dir,
  icon: TitledComponent.defaultProps.icon,
  iconColor: TitledComponent.defaultProps.iconColor,
  iconSize: TitledComponent.defaultProps.iconSize,
  title: TitledComponent.defaultProps.title,
  value: null,
  multiple: InputSuggest.multiple,
  additionItems: null,
  label: '',
  isEnableCustomValue: InputSuggest.defaultProps.isEnableCustomValue,
  customValueMaxLength: InputSuggest.defaultProps.customValueMaxLength,
  help: '',
  modalHelp: '',
  placeholder: '入力',
  error: TitledComponent.defaultProps.error,
  errorBgColor: TitledComponent.defaultProps.errorBgColor,
  itemBgColor: InputSuggest.defaultProps.itemBgColor,
  itemTextColor: InputSuggest.defaultProps.itemTextColor,
  itemStyle: InputSuggest.defaultProps.itemStyle,
  itemsFunc: InputSuggest.defaultProps.itemsFunc,
  appendNode: null,
  vertical: false,
  additionPlaceholder: TitledSelect.defaultProps.placeholder,
};
