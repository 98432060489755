import React from 'react';
import TitledInputSuggest from './TitledInputSuggest';
import * as Server from '../functions/data/Server';


/**
 * 企業登録
 * @returns
 */
export default function TitledInputSuggestCompany(props) {
  const {
    onChange, label, value, componentKey, subIcons,
  } = props;

  return (
    <TitledInputSuggest
      componentKey={componentKey}
      label={label}
      onChange={onChange}
      value={value}
      itemsFunc={async (v) => {
        if (!!v) {
          const result = await Server.searchGovHoujin(v);
          return result?.filter((e) => !!e).map((e) => ({
            no: parseInt(e.companyNo, 10),
            label: `${e.name} (${e.prefName}${e.cityName})`,
            object: e,
          }));
        }
        return null;
      }}
      help="法人名で検索して法人番号を特定します"
      modalHelp="法人名で検索して法人番号を特定します"
      subIcons={subIcons}
    />
  );
};

TitledInputSuggestCompany.propTypes = {
  componentKey: TitledInputSuggest.propTypes.componentKey,
  label: TitledInputSuggest.propTypes.label,
  onChange: TitledInputSuggest.propTypes.onChange,
  value: TitledInputSuggest.propTypes.value,
  isEnableCustomValue: TitledInputSuggest.propTypes.isEnableCustomValue,
  subIcons: TitledInputSuggest.propTypes.subIcons,
};

TitledInputSuggestCompany.defaultProps = {
  componentKey: TitledInputSuggest.defaultProps.componentKey,
  label: TitledInputSuggest.defaultProps.label,
  onChange: TitledInputSuggest.defaultProps.onChange,
  value: TitledInputSuggest.defaultProps.value,
  isEnableCustomValue: TitledInputSuggest.defaultProps.isEnableCustomValue,
  subIcons: TitledInputSuggest.defaultProps.subIcons,
};
