import React from 'react';
import { ScrollView, View, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import Styles from '../../src/Styles';
import IconText from './IconText';
import T from '../../src/components/T';

const BORDER_RADIUS = 8;

export default function TabBox(props) {
  const { children, style, containerStyle, title, tabHeight, help, viewTabKey, setViewTabKey } = props;

  const activeChildren = children?.filter((e) => !!e);

  if (!viewTabKey) {
    return <></>;
  }

  return (
    <View
      style={[
        {
          flex: 1,
          flexDirection: 'column',
          alignSelf: 'stretch',
          padding: 0,
          backgroundColor: '#fff',
          borderRadius: BORDER_RADIUS,
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.2,
          shadowRadius: 4,
          elevation: 1,
        },
        style,
      ]}
    >
      <View
        style={{
          flexDirection: 'row',
          height: tabHeight,
        }}
      >
        { !!title && (
          <View
            style={{
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
            key="titleTab"
          >
            <IconText help={help}>
              {title.indexOf(' ') > 0
                ? title.split(' ').join("\n")
                : title}
            </IconText>
          </View>
        )}
        { activeChildren.filter((e) => !!e).map((e, index) => {
          const caption = e.props.caption.indexOf(' ') > 0
            ? e.props.caption.split(' ').join("\n")
            : e.props.caption;
          return e.key === viewTabKey
          ? (
            <View
              style={{
                backgroundColor: Styles.COLOR_MAIN_ACCENT_BG,
                flexGrow: 1,
                alignContent: 'center',
                justifyContent: 'center',
                borderTopRightRadius: activeChildren.length - 1 === index ? BORDER_RADIUS : 0,
              }}
              key={`currentTop_${e.key}`}
            >
              <TouchableOpacity
                style={{
                  alignItems: 'center',
                }}
                key={e.key}
              >
                <T
                  style={{
                    color: '#fff',
                  }}
                >
                  {caption}
                </T>
              </TouchableOpacity>
              { /* center position for arrow */}
              <View
                style={{
                  alignSelf: 'center',
                  width: 0,
                  height: 0,
                }}
              >
                { /* arrow */}
                <View
                  style={{
                    position: 'absolute',
                    width: 0,
                    height: 0,
                    zIndex: 100,
                    top: 0,
                    left: tabHeight * -0.25,
                    borderLeftColor: 'transparent',
                    borderLeftWidth: tabHeight * 0.25,
                    borderTopWidth: tabHeight * 0.5,
                    borderTopColor: Styles.COLOR_MAIN_ACCENT_BG,
                    borderRightWidth: tabHeight * 0.25,
                    borderRightColor: 'transparent',
                  }}
                ></View>
              </View>
            </View>
          ) : (
            <TouchableOpacity
              style={{
                flexGrow: 1,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#ccc',
                borderLeftWidth: 1,
                borderLeftColor: '#888',
                borderTopRightRadius: activeChildren.length - 1 === index ? BORDER_RADIUS : 0,
              }}
              key={e.key}
              onPress={() => setViewTabKey(e.key)}
            >
              <T>{caption}</T>
            </TouchableOpacity>
          );
        })}
      </View>
      <ScrollView
        style={[
          {
            flexDirection: 'column',
            scrollbarColor: 'rebeccapurple green',
            scrollbarWidth: 'thin',
          },
          containerStyle,
        ]}
      >
        {activeChildren.find((e) => e.key === viewTabKey).props.children}
      </ScrollView>
    </View>
  )
}

TabBox.propTypes = {
  style: PropTypes.any, //View.PropTypes.style,
  containerStyle: PropTypes.any, //View.PropTypes.style,
  title: PropTypes.string,
  tabHeight: PropTypes.number,
  help: IconText.propTypes.help,
  viewTabKey: PropTypes.string,
  setViewTabKey: PropTypes.func,
};

TabBox.defaultProps = {
  style: null,
  containerStyle: null,
  title: null,
  tabHeight: 30,
  help: null,
  viewTabKey: null,
  setViewTabKey: null,
};

export function TabBoxItem(props) {
  const { children, caption, key } = props;

  return (
    <></>
  );
}

TabBoxItem.propTypes = {
  children: PropTypes.node.isRequired,
  caption: PropTypes.string.isRequired,
};

TabBoxItem.defaultProps = {
};
