import {
  string,
  number,
  bool,
  any,
  exact,
  shape,
  arrayOf,
  oneOf,
  oneOfType,
} from 'prop-types';

// String or Number
export const STR_OR_NUM = oneOfType([string, number]);

export const DEF_CATEGORY = {
  FIELDS: 'fields',
  ENGINEER_CATEGORIES: 'engineerCategories',
  PROCESSES: 'processes',
  SKILLS: 'skills',
  EXAMINATIONS: 'examinations',
  OTHER_SKILLS: 'otherSkills',
  AREAS: 'areas',
  REQUESTS: 'requests',
  WORK_STATUSES: 'workStatuses',
  EVALUATION_SETTINGS: 'evaluationSettings',
  SCHOOLS: 'schools',
  MONETIZE: 'monetize',
  CONNECTIONS: 'connections',
  CONNECTION_COMS: 'connectionComs',
  CLIENT_CATEGORIES: 'clientCategories',
};

/**
 * label value 型JSONから value を探して label を返す
 * @param {json} enumJson
 * @param {string | number} value
 * @returns string
 */
export const label = (enumJson, value) => {
  return Object.values(enumJson).find((e) => e.value == value)?.label;
};

/** 選択項目 */
export const Defs = {
  propTypes: shape({
    no: number.isRequired,
    label: string.isRequired,
  }),
};

/** 選択項目に補足入力値があるもの */
export const AdditionDefs = {
  propTypes: shape({
    no: number.isRequired,
    label: string.isRequired,
    addition: string,
  }),
  defaultProps: {
    addition: '',
  },
};

export const PublishLevel = {
  private: {
    icon: 'signal-off',
    label: '非公開',
    value: 0,
  },
  friendOnly: {
    icon: 'signal-cellular-1',
    label: 'フレンドのみ表示',
    value: 1,
  },
  engineerOnly: {
    icon: 'signal-cellular-2',
    label: 'エンジニアのみ表示',
    value: 2,
  },
  public: {
    icon: 'signal-cellular-3',
    label: '無制限公開',
    value: 3,
  },
};

export const Monetize = {
  free: {
    label: '無償提供(有償化予定)',
    value: 0,
  },
  paid: {
    label: '有償提供',
    value: 1,
  },
};

export const CareerType = {
  ses: {
    label: 'エージェント仲介SES',
    value: 0,
  },
  work: {
    label: '直請け',
    value: 1,
  },
  business: {
    label: '自主製作・事業化',
    value: 2,
  },
};

export const RecruitType = {
  ses: {
    label: 'エージェント仲介SES',
    value: 0,
  },
  work: {
    label: '直請け',
    value: 1,
  },
  cooperation: {
    label: '共同製作・協力依頼',
    value: 2,
  },
};

export const CompanyEmp = {
  propTypes: shape({
    no: number,
    name: string.isRequired, //128
    kana: string.isRequired, //128
    iconUrl: exact({
      uri: string.isRequired,
    }), //URI
    companyNo: number,
    tel: string, //11
  }),
  defaultProps: {
    accountNo: number,
    name: string,
    kana: string,
    iconUrl: exact({
      uri: string.isRequired,
    }), //URI
  },
};

export const Company = {
  propTypes: shape({
    no: number,
    name: string.isRequired,
    kana: string,
    iconUrl: exact({
      uri: string.isRequired,
    }), //URI
    name: string, //128
    kana: string, //128
    govCompanyNo: number,
    prefCd: string, //2
    cityCd: string, //5
    town: string, //64
    build: string, //64
    tel: string, //11
    emps: arrayOf(CompanyEmp.propTypes),
  }),
  defaultProps: {

  },
};

export const CompanySelect = {
  propTypes: shape({
    no: number.isRequired,
    label: string.isRequired,
    object: shape({
      companyNo: string.isRequired,
      name: string.isRequired,
      prefCd: string.isRequired,
      prefName: string.isRequired,
      cityCd: string.isRequired,
      cityName: string.isRequired,
      town: string,
    }).isRequired,
  }),
  defaultProps: null,
}

export const EngineerCareer = {
  propTypes: shape({
    no: number,
    accountNo: number,
    sortNo: number,
    careerType: oneOf(Object.values(CareerType).map((e) => e.value)),
    projectName: string,
    termSt: string,
    termEd: string,
    /** 付属情報 */
    fields: arrayOf(Defs.propTypes),
    engineerCategories: arrayOf(Defs.propTypes),
    processes: arrayOf(Defs.propTypes),
    skills: arrayOf(Defs.propTypes),
    companyMain: CompanySelect.propTypes,
    agent: CompanySelect.propTypes,
    subContractor: CompanySelect.propTypes,
    numPersonOfTeam: number,
    rewardMonth: number,
    hoursForReward: number,
    rewardHour: number,
    monetize: oneOf(Object.values(Monetize).map((e) => e.value)),
    productUrl: string,
    pr: string,
  }),
  defaultProps: {
    no: null,
    accountNo: null,
    sortNo: null,
    careerType: CareerType.ses.value,
    projectName: '',
    termSt: '',
    termEd: '',
    /** 付属情報 */
    fields: [],
    engineerCategories: [],
    processes: [],
    skills: [],
    companyMain: null,
    agent: null,
    subContractor: null,
    numPersonOfTeam: null,
    rewardMonth: null,
    hoursForReward: null,
    rewardHour: null,
    monetize: null,
    productUrl: null,
    pr: null,
  },
};

export const Engineer = {
  propTypes: shape({
    accountNo: number,
    nickName: string.isRequired, //24
    uniqueName: string.isRequired, //24
    iconUrl: string,
    name: string, //128
    kana: string, //128
    birthday: string, // YYYYMMDD DATE
    prefCd: STR_OR_NUM, //2
    cityCd: STR_OR_NUM, //5
    town: string, //64
    build: string, //64
    tel: string, //11
    stationGCd: STR_OR_NUM, //10
    /* TRUE:全公開 FALSE:履歴は何者からも閲覧されない */
    settingIsHistoryPublic: bool, // BOOLEAN NOT NULL DEFAULT FALSE ,
    /* TRUE:検索される FALSE:企業アカウントから検索されない */
    settingIsTargetByCompany: bool, // BOOLEAN NOT NULL DEFAULT FALSE ,
    /* 0:非公開 1:フレンドのみ 2:エンジニアのみ 3:全公開 　経歴書を送った先は個人情報含み全公開 */
    settingCareerPublishLevel: oneOf(Object.values(PublishLevel).map((v) => v.value)),
    /* 0:非公開 1:フレンドのみ 2:エンジニアのみ 3:全公開 　経歴書を送った先は個人情報含み全公開 */
    settingAreaPublishLevel: oneOf(Object.values(PublishLevel).map((v) => v.value)),
    /* 0:非公開 1:フレンドのみ 2:エンジニアのみ 3:全公開 　経歴に登録された企業名 */
    settingCareerCompanyPublishLevel: oneOf(Object.values(PublishLevel).map((v) => v.value)),
    /* 0:非公開 1:フレンドのみ 2:エンジニアのみ 3:全公開 　経歴に登録された報酬額 */
    settingCareerRewardPublishLevel: oneOf(Object.values(PublishLevel).map((v) => v.value)),
    /* TRUE:マッチング支援パートナー FALSE:通常 */
    settingIsMatchingPartner: bool, // BOOLEAN NOT NULL DEFAULT FALSE ,
    /* Git Info */
    gitInfo: shape({
      userName: string,
      repoLangList: arrayOf(
        shape({
          repoName: string,
          langs: any,
        })
      ),
      languages: any,
    }),

    /** 付属情報 */
    engineerCategories: arrayOf(Defs.propTypes),
    examinations: arrayOf(Defs.propTypes),
    otherSkills: arrayOf(AdditionDefs.propTypes),
    areas: arrayOf(Defs.propTypes),
    schools: Defs.propTypes,
    requests: arrayOf(Defs.propTypes),
    workStatuses: arrayOf(Defs.propTypes),
    engineerCareers: arrayOf(EngineerCareer.propTypes),
  }),
  defaultProps: {
    accountNo: null,
    iconUrl: null,
    name: null,
    kana: null,
    birthday: null,
    prefCd: null,
    cityCd: null,
    town: null,
    build: null,
    tel: null,
    stationGCd: null,
    /* TRUE:全公開 FALSE:履歴は何者からも閲覧されない */
    settingIsHistoryPublic: true, // BOOLEAN NOT NULL DEFAULT FALSE ,
    /* TRUE:検索される FALSE:企業アカウントから検索されない */
    settingIsTargetByCompany: false, // BOOLEAN NOT NULL DEFAULT FALSE ,
    /* 0:非公開 1:フレンドのみ 2:エンジニアのみ 3:全公開 　経歴書を送った先は個人情報含み全公開 */
    settingCareerPublishLevel: PublishLevel.public.value,
    /* 0:非公開 1:フレンドのみ 2:エンジニアのみ 3:全公開 　経歴書を送った先は個人情報含み全公開 */
    settingAreaPublishLevel: PublishLevel.friendOnly.value,
    /* 0:非公開 1:フレンドのみ 2:エンジニアのみ 3:全公開 　経歴書を送った先は個人情報含み全公開 */
    settingCareerCompanyPublishLevel: PublishLevel.friendOnly.value,
    /* 0:非公開 1:フレンドのみ 2:エンジニアのみ 3:全公開 　経歴書を送った先は個人情報含み全公開 */
    settingCareerRewardPublishLevel: PublishLevel.friendOnly.value,
    /* TRUE:マッチング支援パートナー FALSE:通常 */
    settingIsMatchingPartner: false,

    /** 付属情報 */
    engineerCategories: [],
    examinations: [],
    otherSkills: [],
    areas: [],
    schools: null,
    requests: [],
    workStatuses: [],
    evaluationSettings: [],
    engineerCareers: [],
  }
};

export const FriendConnection = {
  net: {
    label: 'ネット友',
    value: 1,
  },
  project: {
    label: '同じ現場',
    value: 2,
  },
  product: {
    label: '共同製作',
    value: 3,
  },
  off: {
    label: 'オフ会',
    value: 4,
  },
  real: {
    label: 'リアル友',
    value: 5,
  },
};

export const Evaluation = {
  none: {
    label: '評価未実施',
    description: 'まだ評価していません',
    value: 0,
  },
  paisen: {
    label: 'パイセン',
    description: '自分は相手にかなわない、目標となるなど、自分よりも高いレベルのエンジニアだと思う',
    value: 1,
  },
  vote: {
    label: '投票依頼',
    description: '自分と同等レベル、相手と自分がどちらがレベルが高いと評価されるか、他のユーザに投票してほしい',
    value: 2,
  },
  yell: {
    label: 'がんばれ',
    description: '相手からパイセン評価をされた場合のみ、自分の方が高いレベルと認め、エールを送ります',
    value: 3,
  },
};

/**
 * 自エンジニア情報と、相手エンジニア情報を与えて、フレンド状態を割り出す
 * @param {Engineer} engineerMe
 * @param {Engineer} engineerTarget
 */
export const getFriendStatus = (engineerMe, engineerTarget) => {
  // if (isEmpty(engineerMe?.friends)) {
  //   throw `自分のfriends情報が取得出来てない`;
  // }
  // if (isEmpty(engineerTarget?.friends)) {
  //   throw `相手のfriends情報が取得出来てない`;
  // }
  // フレンド
  const isFriendRequested = engineerMe?.friends?.request?.find((f) =>
    f.targetAccountNo === engineerTarget.accountNo
    && f.accountNo === engineerMe?.accountNo
    && !f.isRejection
  );
  const isFriendRejected = engineerMe?.friends?.request?.find((f) =>
    f.targetAccountNo === engineerTarget.accountNo
    && f.accountNo === engineerMe?.accountNo
    && f.isRejection
  );
  const isFriendReceived = engineerMe?.friends?.received?.find((f) =>
    f.targetAccountNo === engineerMe?.accountNo && f.accountNo === engineerTarget.accountNo
  );
  const isBothFriend =
        engineerTarget.friends?.both?.find((f) => f.targetAccountNo === engineerMe?.accountNo)
    &&  engineerMe?.friends?.both?.find((f) => f.targetAccountNo === engineerTarget.accountNo);
  return {
    isFriendRequested, isFriendReceived, isBothFriend, isFriendRejected,
  }
};


export const Recruit = {
  propTypes: shape({
    no: number,
    accountNo: number,
    recruitType: oneOf(Object.values(RecruitType).map((e) => e.value)),
    title: string,
    endCompany: CompanySelect.propTypes,
    endName: string,
    endPrefCd: STR_OR_NUM,
    endPrefName: string,
    endCityCd: STR_OR_NUM,
    endCityName: string,
    endTown: string,
    endBuild: string,
    endStationGCd: STR_OR_NUM,
    endStationLineName: string,
    endStationName: string,
    numPersonOfTeam: number,
    rewardMonthMin: number,
    rewardMonthMax: number,
    hoursForReward: number,
    rewardHour: number,
    description: string,
    contract: string,
    fields: arrayOf(Defs.propTypes),
    engineerCategories: arrayOf(Defs.propTypes),
    processes: arrayOf(Defs.propTypes),
    skills: arrayOf(Defs.propTypes),
    examinations: arrayOf(Defs.propTypes),
    requests: arrayOf(Defs.propTypes),
    secretInfo: shape({
      contract: string,
    }),
  }),
  defaultProps: {
    no: null,
    accountNo: null,
    recruitType: RecruitType.work.value,
    title: null,
    endCompany: null,
    endName: null,
    endPrefCd: null,
    endCityCd: null,
    endTown: null,
    endBuild: null,
    endStationGCd: null,
    numPersonOfTeam: null,
    rewardMonthMin: null,
    rewardMonthMax: null,
    hoursForReward: null,
    rewardHour: null,
    description: null,
    contract: null,
    fields: [],
    engineerCategories: [],
    processes: [],
    skills: [],
    examinations: [],
    requests: [],
  },
};

export const Client = {
  propTypes: shape({
    accountNo: number,
    empIconUrl: string,
    empName: string.isRequired, //64
    empKana: string, //64
    empTel: string, //11
    compName: string.isRequired, //64
    compKana: string, //64
    compIconUrl: string,
    compTel: string, //11
    compPrefCd: STR_OR_NUM, //2
    compCityCd: STR_OR_NUM, //5
    compTown: string, //32
    compBuild: string, //32
    compStationGCd:  STR_OR_NUM, //10
    govCompanyNo: number, //32
    company: CompanySelect.propTypes,
    companySiteUrl: string, //256
    compTypes: arrayOf(Defs.propTypes),
  }),
  defaultProps: {
    accountNo: null,
    empIconUrl: null,
    empKana: null, //64
    empTel: null, //11
    compKana: null, //64
    compIconUrl: null,
    compTel: null, //11
    compPrefCd: null, //2
    compCityCd: null, //5
    compTown: null, //32
    compBuild: null, //32
    compStationGCd: null, //10
    govCompanyNo: null, //32
    company: null,
    companySiteUrl: null, //256
    compTypes: [],
  },
};
