import Axios from 'axios';
import { getSession, saveData, saveSession } from '../../../common/functions/LocalStorage';
import { debug, error, stop } from '../../../common/util/Log';
import { isEmpty } from '../../../common/util/TypeUtil';
import {API_PATH_HEADER, isDev} from '../../../env';
import uuid from '../../../common/util/Uuid';
import LocationLink from '../../../common/functions/LocationLink';

export const LOCAL_KEY_USERINFO = 'userInfo';
export const LOCAL_KEY_FAILSAVE = 'failSave';

export const UNAUTH = 401;

export function gitOauthStart() {
  LocationLink(
    `${API_PATH_HEADER}gitApi/authStart?nonce=${uuid()}`,
    false);
}

/** つかってない
export async function checkAuth(token) {
  const result = await send(
    !!token ? await checkAndGetToken() : null,
    'account/checkToken',
    {},
  );
  return result;
}*/

export async function loginByLongToken(longToken) {
  try {
    const result = await send(
      null,
      'account/loginByLongToken',
      { longToken },
    );
    return result;
  }catch(err){
    error(err);
  }
}

export async function send(token, path, json) {
  const header = {
    withCredentials: true,
  };
  if (token) {
    header.headers = {
      authorization: token,
    };
  }
  try {
    const result = await Axios.post(
      `${API_PATH_HEADER}${path}`,
      json,
      header,
    );
    return result;
  } catch (err) {
    if (err.response.status === UNAUTH) {
      saveSession(LOCAL_KEY_USERINFO, null);
      saveSession(LOCAL_KEY_FAILSAVE, null);
      throw UNAUTH;
    }
    throw err;
  }
}

export async function get(token, path, params) {
  const param = {};
  if (token) {
    param.headers = {
      authorization: token, // 無条件に最新を取ろうとしたが、結果的に token更新直前のものを取ってきていた await checkAndGetToken(),
    };
  }
  if (params) {
    param.params = params;
  }
  try {
    const result = await Axios.get(
      `${API_PATH_HEADER}${path}`,
      param,
    );
    return result;
  } catch (err) {
    if (err.response.status === UNAUTH) {
      debug('clear session in 401');
      saveSession(LOCAL_KEY_USERINFO, null);
      saveSession(LOCAL_KEY_FAILSAVE, null);
      throw {
        ...UNAUTH,
        errorMessage: err.errorMessage,
      };
    }
    throw err;
  }
}

export async function authSendWhenFailSave(path, json) {
  try {
    const result = await authSend(path, json);
    // ローカル保存情報を削除
    saveData(`${LOCAL_KEY_FAILSAVE}_${path}`, null);
    return result;
  } catch (err) {
    saveData(`${LOCAL_KEY_FAILSAVE}_${path}`, JSON.stringify({
      path,
      json,
    }));
    throw err;
  }
}

export async function authSend(path, json) {
  debug(`call getToken in authSend`);
  const token = await getToken();
  const result = await Axios.post(
    `${API_PATH_HEADER}${path}`,
    json,
    {
      headers: {
        authorization: token,
      },
    },
  );
  return result;
}

export async function authGet(path) {
  try {
    debug(`call getToken in authGet`);
    const token = await getToken();
    const result = await Axios.get(
      `${API_PATH_HEADER}${path}`,
      {
        headers: {
          authorization: token,
        },
      },
    );
    return result;
  } catch (err) {
    throw err;
  }
}

export async function getUserInfo(isCheck) {
  debug(`getUserInfo called isCheck:${isCheck}`);
  let sessionUserInfo = await getSessionUserInfo();
  if (!isCheck || !sessionUserInfo?.token) {
    return sessionUserInfo;
  }

  const {data} = await get(sessionUserInfo.token, 'self/get',);
  debug('call self get with longToken when expired');
  if (!!data?.engineer) sessionUserInfo.engineer = data?.engineer;
  if (!!data?.client) sessionUserInfo.client = data?.client;
  saveSession(LOCAL_KEY_USERINFO, JSON.stringify(sessionUserInfo));
  return sessionUserInfo;
}

export async function logout() {
  try {
    const userInfo = JSON.parse(getSession('userInfo'));
    await send(
      userInfo?.token,
      'account/logout',
    );
  } catch(err) {
    ;
  } finally {
    debug('clearSession call in logout')
    clearSession();
  }
}

export async function getSessionUserInfo() {
  const sessionStr = getSession(LOCAL_KEY_USERINFO);
  let sessionUserInfo = JSON.parse(sessionStr);
  if (!sessionUserInfo?.expire) {
    return sessionUserInfo;
  }

  // 既存セッションが期限きれてる
  debug(`check Expire ${sessionUserInfo.expire} < ${Date.now()} ${sessionUserInfo.expire < Date.now() ? 'reget longToken' : 'alive'}`);
  if (sessionUserInfo.expire < Date.now()) {
    if (!sessionUserInfo?.longToken) {
      error('getSessionUserInfo longToken is null')
      throw 'token expired';
    }

    debug(`getSessionUserInfo loginByLongToken`);
    const resultLongTokenLogin = await loginByLongToken(sessionUserInfo?.longToken);
    sessionUserInfo = resultLongTokenLogin?.data;
    if (!sessionUserInfo) {
      clearSession();
      throw `loginByLongToken result is null`;
    }
    const {data} = await get(sessionUserInfo.token, 'self/get',);
    if (!!data?.engineer) sessionUserInfo.engineer = data?.engineer;
    if (!!data?.client) sessionUserInfo.client = data?.client;
    saveSession(LOCAL_KEY_USERINFO, JSON.stringify(sessionUserInfo));
    debug('getSessionUserInfo longToken success')
  }
  return sessionUserInfo;
}

export function clearSession() {
  debug('clearSession called')
  saveSession(LOCAL_KEY_USERINFO, null);
  saveSession(LOCAL_KEY_FAILSAVE, null);
}

export async function getToken() {
  debug(`getToken`);
  const sessionUserInfo = await getSessionUserInfo();
  if (!sessionUserInfo?.token) {
    debug('unauth in getToken')
    throw UNAUTH;
  }
  return sessionUserInfo.token;
}

export async function requestCsrfKey() {
  const token = await getToken();
  const {data} = await get(
    token,
    'checkCsrf/request',
  );
  return data;
}

export async function getEngineerByUniqueName(uniqueName) {
  const token = await getToken();
  const {data} = await get(
    token,
    'engineer/getByUniqueName',
    {uniqueName},
  );
  return data;
}

export async function getRegistableName(nickName, uniqueName) {
  const token = await getToken();
  const {data} = await get(
    token,
    'engineer/registableName',
    {nickName, uniqueName},
  );
  return {
    isRegistableNickName: data.isRegistableNickName,
    isRegistableUniqueName: data.isRegistableUniqueName,
  };
}

/**
 * 都道府県リストを得る
 * @returns
 */
export async function getPrefList() {
  const token = await getToken();
  const result = await send(
    token,
    'address/',
    {},
  );
  if (Array.isArray(result?.data) && result?.data?.length > 0) {
    return result.data;
  }
  return null;
}

/**
 * 路線リストを得る
 * @param {String} prefCd 都道府県絞り込み
 * @param {String} cityCd 市区町村絞り込み
 * @returns
 */
export async function getStationLineList(prefCd, cityCd) {
  const token = await getToken();
  const result = await send(
    token,
    'station/line',
    {
      prefCd,
      cityCd,
    },
  );
  if (Array.isArray(result?.data) && result?.data?.length > 0) {
    return result.data;
  }
  return null;
}

/**
 * 駅リストを得る
 * @param {String} prefCd 都道府県絞り込み
 * @param {String} cityCd 市区町村絞り込み
 * @returns
 */
export async function getStationList(prefCd, cityCd) {
  const token = await getToken();
  const result = await send(
    token,
    'station/station',
    {
      prefCd,
      cityCd,
    },
  );
  if (Array.isArray(result?.data) && result?.data?.length > 0) {
    return result.data;
  }
  return null;
}

/**
 * 定義語リストを得る
 * @param {String} category
 * @returns
 */
export async function getDefinitions(category) {
  const token = await getToken();
  const result = await Axios.get(
    `${API_PATH_HEADER}appDefinitions/withCount`,
    {
      params: {
        category,
      },
      headers: {
        authorization: token,
      },
    },
  );
  if (Array.isArray(result?.data) && result?.data?.length > 0) {
    return result.data;
  }
  return null;
}

/**
 * Google の OAuth で得られる token をサーバに送信して、サーバのユーザ情報を得る
 * 2023/3 までの gsi 用
 * @param {String} googleToken
 * @returns
 */
export async function getUserInfoByGoogleTokenGsi(googleToken) {
  const result = await send(
    null,
    'oauth/googleToken',
    {
      token: googleToken,
    },
  );
  if (result?.data) {
    return result.data;
  }
  return null;
}

/**
 * Google の OAuth で得られる credential をサーバに送信して、サーバのユーザ情報を得る
 * 2022年版 JWT で返却されるやつ
 * @param {String} googleToken
 * @returns
 */
export async function getUserInfoByGoogleCredentialResponse(response) {
  const result = await send(
    null,
    'oauth/googleCredential',
    {
      googleCredentialResponse: response,
    },
  );
  if (result?.data) {
    return result.data;
  }
  return null;
}

export async function searchGovHoujin(name) {
  const token = await getToken();
  const result = await get(token, 'govHoujin', {name});
  /*
    corporation: {
      name: govOptional(e?.name),
      kana: govOptional(e?.furigana),
      companyNo: govOptional(e?.corporateNumber),
      prefCd: govOptional(e?.prefectureCode),
      prefName: govOptional(e?.prefectureName),
      cityCd: govOptional(e?.cityCode),
      cityName: govOptional(e?.cityName),
      town: govOptional(e?.streetNumber),
      kind: govOptional(e?.kind),
      hide: govOptional(e?.hihyoji),
      enName: e?.enName,
      enPrefectureName: e?.enPrefectureName,
      enCityName: e?.enCityName,
      enAddressOutside: e?.enAddressOutside,
      postCode: govOptional(e?.postCode),
      updateDate: govOptional(e?.updateDate),
      assignmentDate: govOptional(e?.assignmentDate),
      addressImageId: govOptional(e?.addressImageId),
      addressOutside: govOptional(e?.addressOutside),
      addressOutsideImageId: govOptional(e?.addressOutsideImageId),
      nameImageId: govOptional(e?.nameImageId),
      successorCorporateNumber: govOptional(e?.successorCorporateNumber),
    });  */
  if (Array.isArray(result?.data?.corporation) && result?.data?.corporation?.length > 0) {
    return result.data.corporation;
  }
  return null;
}

/** インスタントにGit情報を得る、保存はされない */
export async function getGitInfo(gitInfoNonce) {
  const token = await getToken();
  const result = await get(token, 'gitApi/getUserReposSkillSummary', {
    gitInfoNonce
  });
  /*
  {
    userName: "technokuro",
    repoLangList: [
      {
        repoName: "checkAccessLogAndSettingDrop",
        languages: [
          {
            language: "Perl",
            count: 1354,
          },
        ],
      },
    ],
    usedLanguages: [
      {
        language: "Perl",
        count: 1354,
        ratio: 0.2374,
      },
    ],
  }
  */
  return result?.data;
}

/** Git情報を得て、情報を保存する */
export async function saveGitInfo() {
  const token = await getToken();
  const result = await send(token, 'gitApi/saveUserReposSkillSummary');
  return result?.data;
}

/** 自分のGit情報を削除する */
export async function removeGitInfo() {
  const token = await getToken();
  const result = await send(token, 'gitApi/removeUserReposSkillSummary');
  return result?.data;
}

export async function getHistory(offset, count) {
  const token = await getToken();
  const result = await get(token, 'history/', {offset, count});
  if (isEmpty(result?.data)) {
    return null;
  }
  // 日付型の調整
  result.data.forEach((d) => {
    d.regDate = !!d.regDate ? new Date(d.regDate) : null;
  });
  return result.data;
}

export async function getHistoryTotalCount() {
  const token = await getToken();
  const result = await get(token, 'history/totalCount');
  if (isEmpty(result?.data)) {
    return null;
  }
  return result?.data;
}

export async function getMessage(offset, count) {
  const token = await getToken();
  const result = await get(token, 'message/', {offset, count});
  if (isEmpty(result?.data)) {
    return null;
  }
  // 日付型の調整
  result.data.forEach((d) => {
    d.regDate = !!d.regDate ? new Date(d.regDate) : null;
  });
  return result.data;
}

export async function getMessageTotalCount() {
  const token = await getToken();
  const result = await get(token, 'message/totalCount');
  if (isEmpty(result?.data)) {
    return null;
  }
  return result?.data;
}

/**
 * フレンド登録
 * @returns
 */
export async function registerFriend(targetAccountNo, friendConnection) {
  const token = await getToken();
  const serverResult = await send(
    token,
    `friend/register`,
    {
      targetAccountNo,
      friendConnection,
      isRejection: false,
    },
  );
  const { result } = serverResult?.data;
  if (result !== 'OK') {
    throw serverResult?.data;
  }
  return true;
}

/**
 * フレンド拒否
 * @returns
 */
export async function rejectionFriend(targetAccountNo) {
  const token = await getToken();
  const serverResult = await send(
    token,
    `friend/registerRejection`,
    {
      targetAccountNo,
    },
  );
  const { result } = serverResult?.data;
  if (result !== 'OK') {
    throw serverResult?.data;
  }
  return true;
}

/**
 * フレンドキャンセル
 * @returns
 */
export async function cancelFriend(targetAccountNo) {
  const token = await getToken();
  const serverResult = await send(
    token,
    `friend/cancel`,
    {
      targetAccountNo,
    },
  );
  const { result } = serverResult?.data;
  if (result !== 'OK') {
    throw serverResult?.data;
  }
  return true;
}

/**
 * 評価登録
 * すでに登録済みなら解除
 * @param {*} targetAccountNo
 * @param {*} defNo
 * @param {*} star
 * @param {*} description
 */
export async function registerEvaluationToggle(targetAccountNo, defNo, star, description) {
  const token = await getToken();
  const serverResult = await send(
    token,
    `evaluation/registerToggle`,
    {
      targetAccountNo,
      defNo,
      star,
      description
    },
  );
  const { result } = serverResult?.data;
  if (result !== 'OK') {
    throw serverResult?.data;
  }
  return true;
}

/**
 * 自分が過去に行った評価の取得
 * @param {*} targetAccountNo
 */
export async function getMyEvaluationActivity(targetAccountNo) {
  const token = await getToken();
  const serverResult = await get(
    token,
    `evaluation/getMyActivity`,
   {targetAccountNo}
  );
  return serverResult?.data;
}

export async function getClientByAccountNo(clientAccountNo) {
  const token = await getToken();
  const {data} = await get(
    token,
    'client/getByAccountNo',
    {clientAccountNo},
  );
  return data;
}

export async function getRecruitByNo(recruitNo) {
  const token = await getToken();
  const {data} = await get(
    token,
    'recruit/get',
    {no: recruitNo},
  );
  return data;
}

/**
 * stationGCd リストから line + stationName のリストを返す
 * @param {Array} stationGCdList
 * @returns
 */
export async function getStationByStationGCdList(stationGCdList) {
  const token = await getToken();
  const {data} = await get(
    token,
    'station/getStationByStationGCdList',
    {stationGCdList},
  );
  return data;
}

export async function getSelfSecretInfo() {
  const token = await getToken();
  const {data} = await get(
    token,
    'self/getSecret',
  );
  return data;
}

/**
 *
 * nickName string
 * examinations array of Defs
 * otherSkills array of Defs
 * areas array of Defs
 * skillRanges array of Defs addition Range
 * fieldsRanges array of Defs addition Range
 * endineerCategoriesRanges array of Defs addition Range
 * processesRanges array of Defs addition Range
 * @param {*} json
 */
export async function searchEngineer(json) {
  const token = await getToken();
  const {data} = await get(
    token,
    'engineer/search',
    json,
  );
  return data;
}
