import { setString } from 'expo-clipboard';
import { Platform } from 'react-native';

/**
 * クリップボードへ文字列コピー
 * @param {String} contents
 */
export default function ClipCopyText(contents) {
  if (Platform.OS === 'web') {
    navigator.clipboard.writeText(contents);
    // prompt('以下文言をコピー(Ctrl+C や右クリック-コピー)してください', contents);
  } else {
    setString(contents);
  }
}
