import React, { useState, useMemo, useContext, useEffect } from 'react';
import {
  StyleSheet, View, Text,
} from 'react-native';
import PropTypes from 'prop-types';
import { AppContext } from '../context/AppContext';
import { TITLE_ICON } from '../Definition';
import TitledInput from '../components/TitledInput';
import TitledSelect from '../components/TitledSelect';
import Validator from '../components/Validator';
import TitledInputSuggest from '../components/TitledInputSuggest';
import useDefinition from '../hooks/useDefinition';
import { CareerType, DEF_CATEGORY, RecruitType } from '../types/Types';
import { ifPresent } from '../../common/util/TypeUtil';
import TitledComponent from '../components/TitledComponent';
import uuid from '../../common/util/Uuid';
import * as Server from '../functions/data/Server';
import TitledInputSuggestCompany from '../components/TitledInputSuggestCompany';
import Styles from '../Styles';
import PreviewAccountEngineer from './component/PreviewAccountEngineer';
import T from '../components/T';
import ViewClient from './component/ViewClient';
import Box from '../../common/components/Box';
import TitledStationSelect from '../components/TitledStationSelect';
import TitledAddressInput from '../components/TitledAddressInput';
import Button from '../../common/components/Button';
import { authSendWhenFailSave } from '../functions/data/Server';
import { toastSorry } from '../functions/Toast';
import { getData } from '../../common/functions/LocalStorage';
import Waiting from '../components/Waiting';
import { LinkUrl } from '../LinkUrl';
import LocationLink from '../../common/functions/LocationLink';

// Recruit で扱う DEFS
export const RECRUIT_DEFS_CATEGORIES = [
  DEF_CATEGORY.FIELDS,
  DEF_CATEGORY.PROCESSES,
  DEF_CATEGORY.SKILLS,
  DEF_CATEGORY.ENGINEER_CATEGORIES,
  DEF_CATEGORY.EXAMINATIONS,
  DEF_CATEGORY.REQUESTS,
];

const SERVER_PATH = 'recruit/register';
const FAIL_SAVE_KEY = `${Server.LOCAL_KEY_FAILSAVE}_${SERVER_PATH}`;

// 現在日時
const NOW = new Date();

const DEFAULT_YM = '000000';

// 現在年月
const NOW_Y = NOW.getFullYear();
const NOW_M = NOW.getMonth() + 1;

const makeYM = (initY, initM) => {
  const ret = [];
  const nInitY = Number(initY);
  for (let y = nInitY; y <= nInitY + 10; y++) {
    const startM = Number(y == initY ? initM : 1);
    for (let m = startM; m <= 12; m++) {
      ret.push({
        value: `${String(y).padStart(4, '0')}${String(m).padStart(2, '0')}`,
        label: `${String(y).padStart(4, '0')}年 ${m}月`,
      });
    }
  }
  return ret;
}

/**
 * 開始年月列挙
 * @param {*} startYM YYYYMM で起算年月を指定 無指定は現在年月
 * @returns
 */
const itemsYmSt = () => {
  const ret = [{
    value: DEFAULT_YM,
    label: '決定次第',
  }];
  const initY = NOW_Y;
  const initM = NOW_M;
  ret.push(...makeYM(initY, initM));
  return ret;
};

/**
 * 終了年月列挙
 * @param {*} startYM YYYYMM で起算年月を指定 無指定は現在年月 termStを指定
 * @returns
 */
const itemsYmEd = (startYM) => {
  const ret = [{
    value: DEFAULT_YM,
    label: '未定',
  }];
  const initY = !startYM || startYM === DEFAULT_YM ? NOW_Y : String(startYM).substring(0, 4);
  const initM = !startYM || startYM === DEFAULT_YM ? NOW_M : String(startYM).substring(4, 6);
  ret.push(...makeYM(initY, initM));
  return ret;
};

const calcRewardPerHour = (inputReward, inputHours) => {
  if (!inputReward || !inputHours) return null;
  try {
    const _hours = Number(inputHours);
    const _rewardMin = Number(inputReward);
    return Math.floor(_rewardMin * 10000 / _hours).toString();
  } catch (e) {
    ;
  }
  return null;
};

/**
 * 案件登録の１エントリ部品
 * @returns
 */
export default function EditRecruitItem(routeParam) {
  const params = routeParam?.route?.params;
  const { recruitNo } = params;
  const thisUuid = useMemo(() => uuid(), []);
  const { isSp, userInfo, viewDialog, Link } = useContext(AppContext);

  const [isPreview, setIsPreview] = useState(isSp ? false : true);

  const [recruitType, setRecruitType] = useState(RecruitType.work.value);
  const [title, setTitle] = useState();
  const [termSt, setTermSt] = useState('000000');
  const [termEd, setTermEd] = useState('000000');

  const [fields, setFields] = useState([]);
  const [engineerCategories, setEngineerCategories] = useState([]);
  const [processes, setProcesses] = useState([]);
  const [skills, setSkills] = useState([]);
  const [examinations, setExaminations] = useState([]);
  const [requests, setRequests] = useState([]);
  const [endCompany, setEndCompany] = useState();
  const [endCompanyAddress, setEndCompanyAddress] = useState();
  const [endStation, setEndStation] = useState();
  const [agent, setAgent] = useState();
  const [numPersonOfTeam, setNumPersonOfTeam] = useState();
  const [contract, setContract] = useState();
  const [rewardMonthMin, setRewardMonthMin] = useState();
  const [rewardMonthMax, setRewardMonthMax] = useState();
  const [hoursForReward, setHoursForReward] = useState();
  const [rewardHourMin, setRewardHourMin] = useState();
  const [rewardHourMax, setRewardHourMax] = useState();
  const [description, setDescription] = useState();

  const [error, setError] = useState();

  const [initialized, setInitialized] = useState(false);

  const defItems = useDefinition(...RECRUIT_DEFS_CATEGORIES);

  useEffect(() => {
    setInitialized(false);
    (async () => {
      // 修正ならばDBから、エラーによるローカル情報からの復元ならローカルからデータが入る
      let data;
      if (!!recruitNo) {
        const serverData = await Server.getRecruitByNo(recruitNo);
        if (!serverData) {
          await viewDialog(
            '案件情報の読み込みに失敗しました',
            `対象情報がありません`,
            ['OK'],
            400, 300);
          setInitialized(true);
          return;
        }
        data = serverData;
      } else {
        // ローカルから復元してみる
        const localDataStr = await getData(FAIL_SAVE_KEY);
        if (!!localDataStr) {
          data = JSON.parse(localDataStr)?.json;
        }
      }
      // なければ終了・新規登録
      if (!data) {
        setInitialized(true);
        return;
      }
      setRecruitType(data.recruitType);
      setTitle(data.title);
      setTermSt(data.termSt);
      setTermEd(data.termEd);
      setFields(data.fields);
      setEngineerCategories(data.engineerCategories);
      setProcesses(data.processes);
      setSkills(data.skills);
      setExaminations(data.examinations);
      setRequests(data.requests);
      setEndCompany(data.endCompany);
      setEndCompanyAddress({
        prefCd: data.endPrefCd,
        prefName: data.endPrefName,
        cityCd: data.endCityCd,
        cityName: data.endCityName,
        town: data.endTown,
        build: data.endBuild,
      });
      setEndStation({
        stationGCd: data.endStationGCd,
        lineName: data.endStationLineName,
        stationName: data.endStationName,
      });
      setAgent(data.agentCompany);
      setNumPersonOfTeam(data.numPersonOfTeam);
      setRewardMonthMin(data.rewardMonthMin);
      setRewardMonthMax(data.rewardMonthMax);
      setHoursForReward(data.hoursForReward);
      setRewardHourMin(data.rewardHourMin);
      setRewardHourMax(data.rewardHourMax);
      setDescription(data.description);

      setContract(data.secretInfo?.contract);
      setInitialized(true);
    })();
  }, [recruitNo])


  if (!defItems || !initialized) {
    return (<Waiting/>);
  }

  const validate = () => {
    let errorMessage;
    if (!title) {
      errorMessage = '案件名を入力してください';
    }
    if (!!errorMessage) {
      setError(errorMessage);
      return false;
    }

    setError();
    return true;
  };

  const makeRecruit = () => {
    const ret = {
      no: !!recruitNo ? parseInt(recruitNo, 10) : null,
      uuid: thisUuid,
      recruitType,
      title,
      description,
      numPersonOfTeam: !!numPersonOfTeam ? parseInt(numPersonOfTeam, 10) : null,
      termSt,
      termEd,
      endCompany,
      endName: endCompany?.object?.name,
      endPrefCd: endCompanyAddress?.prefCd,
      endPrefName: endCompanyAddress?.prefName,
      endCityCd: endCompanyAddress?.cityCd,
      endCityName: endCompanyAddress?.cityName,
      endTown: endCompanyAddress?.town,
      endBuild: endCompanyAddress?.build,
      endStationGCd: endStation?.stationGCd,
      endStationLineName: endStation?.lineName,
      endStationName: endStation?.stationName,
      contract,
      rewardMonthMin: Number(rewardMonthMin),
      rewardMonthMax: Number(rewardMonthMax),
      hoursForReward: Number(hoursForReward),
      rewardHourMin: Number(rewardHourMin),
      rewardHourMax: Number(rewardHourMax),
      fields,
      engineerCategories,
      processes,
      skills,
      examinations,
      requests,
    };
    return ret;
  };

  const register = async () => {
    if (!validate()) {
      return;
    }
    const registerData = makeRecruit();
    registerData.csrfKey = await Server.requestCsrfKey();
    try {
      const confirm = await viewDialog(
        '案件は、運営により監視しています',
        `案件登録後、運営にて内容を確認させていただきます。
場合によっては、掲載後に運営による削除を行うことがあります。
内容について、メールでお問い合わせすることがあります。
ご担当者のメールはモニタリングしていただきますよう、お願いいたします。
`,
        ['登録する', '見直す']);
      if (confirm.selected != '登録する') {
          return;
      }
      // ローカル保存していたものはここで消去する
      FAIL_SAVE_KEY
      await authSendWhenFailSave(
        SERVER_PATH,
        registerData,
      );
      await viewDialog(
        '登録ありがとうございます',
        `後ほど運営から連絡することもあります。
どうぞよろしくお願いいたします。`,
        ['OK']);
      LocationLink(LinkUrl.UNIQUE_CLIENT(userInfo.account.accountNo));
    }catch(err) {
      await viewDialog(
        '登録に失敗しました',
        `${err?.response?.data?.errorMessage || `${!!err ? err?.message + "\n" + err?.stack : ''}`}
登録内容をブラウザに保存しました。
ブラウザをリロードすると、登録内容を復元した状態になります。
リロードして、もう一度登録をお試しください。`,
        ['OK'],
        400, 300);
      toastSorry(err?.response?.data?.errorMessage);
    }
  };

  const editComponent = (
    <View
      style={{
        flex: 1,
        alignItems: 'stretch',
        justifyContent: 'flex-start',
      }}
    >
      <TitledInput
        placeholder="案件名"
        onChangeText={setTitle}
        value={title}
        maxLength={64}
        validator={new Validator(
          (t) => (!t) ? '必須入力です' : null,
          (t) => (t.length > 64) ? '64文字以内' : null,
        )}
        subIcons={[
          TITLE_ICON.required,
          TITLE_ICON.public,
        ]}
      />
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'end',
        }}
      >
        <TitledComponent
          componentStyle={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            paddingTop: 8,
            alignItems: 'baseline',
          }}
          help="案件の稼働期間"
          subIcons={[
            TITLE_ICON.required,
            TITLE_ICON.public,
          ]}
        >
          <TitledSelect
            placeholder="稼働開始時期"
            onChange={setTermSt}
            defaultValue={termSt}
            items={itemsYmSt()}
            fieldStyle={{ width: 120 }}
          />
          <Text>～</Text>
          <TitledSelect
            placeholder="終了時期・納期"
            onChange={setTermEd}
            defaultValue={termEd}
            items={itemsYmEd(termSt)}
            fieldStyle={isSp ? { width: 120 } : { marginLeft: 0, width: 120 }}
          />
        </TitledComponent>
        <TitledSelect
          placeholder="案件の種別"
          help="この案件の種別"
          defaultValue={recruitType.toString()}
          onChange={(v) => {
            let setValue = RecruitType.work.value;
            if (!!v) {
              setValue = parseInt(v, 10);
            }
            setRecruitType(setValue);
          }}
          items={Object.values(RecruitType)}
          fieldStyle={{
            width: 150,
            fontSize: 12,
          }}
          subIcons={[
            TITLE_ICON.required,
            TITLE_ICON.public,
          ]}
        />
        <TitledInput
          placeholder="チーム人数"
          keyboardType="numeric"
          help="この案件でエンジニアが属する最小単位のチームの人数"
          onChangeText={(v) => {
            if (!v || v == parseInt(v, 10)) {
              setNumPersonOfTeam(v);
            }
          }}
          value={numPersonOfTeam}
          maxLength={5}
          width={100}
          // height={40}
          subIcons={[
            TITLE_ICON.noRequired,
            TITLE_ICON.public,
          ]}
        />
      </View>
      <TitledInputSuggest
        multiple
        componentKey="fieldsSelect"
        label="業界・分野"
        help="案件がどんな業界や分野の製作であるか"
        onChange={setFields}
        value={fields}
        items={defItems.fields}
        isEnableCustomValue
        subIcons={[
          TITLE_ICON.noRequired,
          TITLE_ICON.public,
        ]}
      />
      <TitledInputSuggest
        multiple
        componentKey="engineerCategorySelect"
        label="担当カテゴリ"
        help="エンジニアがどんな担当であるか"
        onChange={setEngineerCategories}
        value={engineerCategories}
        items={defItems.engineerCategories}
        isEnableCustomValue
        subIcons={[
          TITLE_ICON.noRequired,
          TITLE_ICON.public,
        ]}
      />
      <TitledInputSuggest
        multiple
        componentKey="processesSelect"
        label="担当工程"
        help="エンジニアが担当するプロジェクト内の工程"
        onChange={setProcesses}
        value={processes}
        items={defItems.processes}
        isEnableCustomValue
        subIcons={[
          TITLE_ICON.noRequired,
          TITLE_ICON.public,
        ]}
      />
      <TitledInputSuggest
        multiple
        componentKey="skillsSelect"
        label="必須スキル"
        help="この案件で必須に求められるスキル"
        onChange={setSkills}
        value={skills}
        items={defItems.skills}
        isEnableCustomValue
        subIcons={[
          TITLE_ICON.noRequired,
          TITLE_ICON.public,
        ]}
      />
      <TitledInputSuggest
        multiple
        componentKey="examinationsSelect"
        label="要求する資格"
        help="この案件で求められる資格"
        onChange={setExaminations}
        value={examinations}
        items={defItems.examinations}
        isEnableCustomValue
        subIcons={[
          TITLE_ICON.noRequired,
          TITLE_ICON.public,
        ]}
      />
      <TitledInputSuggest
        multiple
        componentKey="requestsSelect"
        label="要求・条件"
        help="この案件の要求事項や稼働条件など"
        onChange={setRequests}
        value={requests}
        items={defItems.requests}
        isEnableCustomValue
        subIcons={[
          TITLE_ICON.noRequired,
          TITLE_ICON.public,
        ]}
      />
      <TitledComponent
        componentStyle={{
          justifyContent: 'flex-start',
          alignContent: 'flex-start',
          flexDirection: 'row',
          flexWrap: 'wrap',
          flex: 1,
        }}
        help="この案件の参画先の企業情報(公開できる範囲で登録ください)"
        subIcons={[
          TITLE_ICON.noRequired,
          TITLE_ICON.public,
        ]}
      >
        <TitledInputSuggestCompany
          componentKey="endCompany"
          label="参画先企業"
          onChange={(v) => {
            setEndCompany(v);
            if (!!v?.object?.prefCd) {
              setEndCompanyAddress({
                prefCd: v.object.prefCd,
                cityCd: `${v.object.prefCd}${v.object.cityCd}`,
                town: v.object.town,
              });
            }
          }}
          value={endCompany}
        />
      </TitledComponent>
      <TitledAddressInput
        componentKey="endCompanyAddressSelect"
        placeholder="参画先住所"
        help="出勤する場合の参考として、公開できる範囲で入力ください"
        onChange={(v) => setEndCompanyAddress(v)}
        value={endCompanyAddress}
      />
      <TitledStationSelect
        componentKey="endCompanyStation"
        placeholder="参画先企業最寄駅"
        help="出勤する場合の参考情報"
        prefCd={endCompanyAddress?.prefCd}
        cityCd={endCompanyAddress?.cityCd}
        onChange={(v) => setEndStation(v)}
        value={endStation}
      />
      {recruitType == CareerType.ses.value && (
        <TitledInputSuggestCompany
          componentKey="agent"
          label="仲介エージェント企業"
          onChange={(v) => setAgent(v)}
          value={agent}
        />
      )}
      <TitledInput
        multiline
        placeholder="この案件の詳細事項"
        help="スキルの必要年数や、スキル見合いなど、"
        onChangeText={setDescription}
        value={description || ''}
        subIcons={[
          TITLE_ICON.noRequired,
          TITLE_ICON.public,
        ]}
      />
      <TitledComponent
        componentStyle={{
          justifyContent: 'flex-start',
          alignContent: 'flex-start',
          flex: 1,
          marginVertical: 8,
        }}
        help="この経歴の報酬です。公開する場合は時間報酬の概数のみが表示されます。"
        subIcons={[
          TITLE_ICON.noRequired,
          TITLE_ICON.selectablePublish,
        ]}
      >
        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          <TitledInput
            placeholder="月額報酬（万円-税込）-最低"
            keyboardType="numeric"
            onChangeText={(v) => {
              if (!v || v == parseInt(v, 10)) {
                setRewardMonthMin(v);
                ifPresent(calcRewardPerHour(v, hoursForReward), setRewardHourMin);
              }
            }}
            value={rewardMonthMin}
            maxLength={3}
            width={230}
            onBlur={() => ifPresent(calcRewardPerHour(rewardMonthMin, hoursForReward), setRewardHourMin)}
          />
          <T style={{ alignSelf: 'center' }}>～</T>
          <TitledInput
            placeholder="月額報酬（万円-税込）-最高"
            keyboardType="numeric"
            onChangeText={(v) => {
              if (!v || v == parseInt(v, 10)) {
                setRewardMonthMax(v);
                ifPresent(calcRewardPerHour(v, hoursForReward), setRewardHourMax)
              }
            }}
            value={rewardMonthMax}
            maxLength={3}
            width={230}
            onBlur={() => ifPresent(calcRewardPerHour(rewardMonthMax, hoursForReward), setRewardHourMax)}
          />
        </View>
        <TitledInput
          placeholder="月額報酬最低所要時間"
          keyboardType="numeric"
          onChangeText={(v) => {
            if (!v || v == parseInt(v, 10)) {
              setHoursForReward(v);
              ifPresent(calcRewardPerHour(rewardMonthMin, v), setRewardHourMin);
              ifPresent(calcRewardPerHour(rewardMonthMax, v), setRewardHourMax);
            }
          }}
          value={hoursForReward}
          onBlur={() => {
            ifPresent(calcRewardPerHour(rewardMonthMin, hoursForReward), setRewardHourMin);
            ifPresent(calcRewardPerHour(rewardMonthMax, hoursForReward), setRewardHourMax);
          }}
        />
        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          <TitledInput
            placeholder="時間報酬（円-税込）-最低"
            keyboardType="numeric"
            onChangeText={setRewardHourMin}
            value={rewardHourMin}
            width={220}
          />
          <T style={{ alignSelf: 'center' }}>～</T>
          <TitledInput
            placeholder="時間報酬（円-税込）-最高"
            keyboardType="numeric"
            onChangeText={setRewardHourMax}
            value={rewardHourMax}
            width={220}
          />
        </View>
      </TitledComponent>
      <TitledInput
        multiline
        help={`本サービスでは、案件の表示順位や表示機会は、運営判断にて決定いたします。
本項の記載内容は調整の参考とするほか、掲載可否の判断や、弊社と御社間の契約を進める参考にいたします。
未記載でも結構ですが、健全なサービス維持のため、ご協力をよろしくお願いいたします。`}
        placeholder="本サービスで成約時の運営への報酬・条件・契約方法など"
        onChangeText={setContract}
        value={contract || ''}
        subIcons={[
          TITLE_ICON.noRequired,
          TITLE_ICON.private,
        ]}
      />
    </View>
  );

  const editBox = (
    <Box
      title={!!recruitNo ? "案件修正" : "案件登録"}
      style={{
        margin: 16,
      }}
    >
      {editComponent}
    </Box>
  );

  const previewBox = (
    <View
      style={{
        flexDirection: 'column',
        flex: 1,
      }}
    >
      {!!userInfo?.engineer && (
        <PreviewAccountEngineer engineer={userInfo.engineer} />
      )}
      {!!userInfo?.client && (
        <Box
          title="Preview"
          style={{
            margin: 16,
          }}
        >
          <ViewClient
            client={
              (() => {
                // userInfo.client を複製して、最初に現在登録中の案件を
                // 配列最初にセットして渡す
                const value = JSON.parse(JSON.stringify(userInfo.client));
                // if (!value?.recruitList) {
                  value.recruitList = [makeRecruit()];
                // } else {
                //   value.recruitList = [
                //     makeRecruit(),
                //     ...value.recruitList,
                //   ];
                // }
                return value;
              })()
            }
            noButton
          />
        </Box>
      )}
    </View>
  );

  const buttonBox = (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginHorizontal: 16,
      }}
    >
      {isSp
      ?
        isPreview
        ? (
          <>
            <Button
              label="キャンセル"
              onPress={() => Link(LinkUrl.TOP)}
            />
            <Button
              label="編集に戻る"
              onPress={() => setIsPreview(false)}
            />
            <Button
              label="登録"
              onPress={() => register()}
            />
          </>
        ) : (
          <>
            <Button
              label="キャンセル"
              onPress={() => Link(LinkUrl.TOP)}
            />
            <Button
              label="プレビュー"
              onPress={() => setIsPreview(true)}
            />
          </>
        )
      : (
        <>
          <Button
            label="キャンセル"
            onPress={() => Link(LinkUrl.TOP)}
          />
          <Button
            label={isPreview ? 'プレビューを消す' : 'プレビュー'}
            onPress={() => setIsPreview(!isPreview)}
          />
          <Button
            label="登録"
            onPress={() => register()}
          />
        </>
      )}
    </View>
  );

  return (
    <View
      style={[
        Styles.MAIN_VIEW,
        {
          flexDirection: 'row',
          alignContent: 'space-between',
        }
      ]}
    >
      <View
        style={{
          flexDirection: 'column',
          flex: 1,
        }}
      >
        {isSp
          ? !isPreview
            ? editBox
            : previewBox
          : editBox
        }
        {!!error && (
          <T
            style={{
              color: Styles.COLOR_MAIN_TEXT_IMPORTANT,
              fontWeight: 'bold',
              marginLeft: 16,
            }}
          >
            {error}
          </T>
        )}
        {buttonBox}
      </View>
      {!isSp && isPreview && previewBox}
    </View>
  );

}

EditRecruitItem.propTypes = {
  recruitNo: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

EditRecruitItem.defaultProps = {
  recruitNo: null,
};

const styles = StyleSheet.create({
  buttonContainer: {
    paddingHorizontal: 56,
  },
  imageContainer: {
    width: '100%',
    alignItems: 'center',
  },
  image: {
    width: 100,
    height: 100,
  },
  buttonStyle: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'flex-start',
    marginVertical: 2,
    backgroundColor: '#0288e6',
    borderRadius: 50,
    paddingHorizontal: 8,
    paddingVertical: 2,
    height: 40,
    shadowColor: '#000',
    shadowOffset: {
      width: 4,
      height: 8,
    },
    shadowOpacity: 0.4,
    elevation: 16,
  },
  buttonDisabledStyle: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'flex-start',
    marginVertical: 2,
    backgroundColor: '#0288e6',
    borderRadius: 50,
    paddingHorizontal: 8,
    paddingVertical: 2,
    height: 40,
    shadowColor: '#000',
    shadowOffset: {
      width: 4,
      height: 8,
    },
    shadowOpacity: 0.4,
    elevation: 16,
  },
});
