import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import {
  View,
  StyleSheet,
  TextInput,
  ActivityIndicator,
  FlatList,
  TouchableOpacity,
} from 'react-native';
import PropTypes from 'prop-types';
import Modal from 'react-native-modalbox';
import SuggestItem from './SuggestItem';
import Button from './Button';
import { DIR } from './IconText';
import * as TypeUtil from '../../common/util/TypeUtil';
import { AppContext } from '../../src/context/AppContext';
import Styles from '../../src/Styles';
import T from '../../src/components/T';

// 手入力された値の no は０未満となる
// 値は、複数同時使用に耐えるため、componentKey をキーとするJSONとして持つ
const customNum = {};

// フィルタの時差実行タイマー
// 複数同時使用に耐えるため、componentKey をキーとするJSONとして持つ
const itemFilterTimer = {};

export default function InputSuggest(props) {
  const {
    multiple, label, value, isEnableCustomValue, disabled, help,
    items, onChange, componentKey, itemBgColor, itemTextColor, itemStyle,
    itemsFunc, customValueMaxLength, fieldBgColor,
  } = props;

  const { dimensions, isSp } = useContext(AppContext);
  const { width, height } = dimensions.window;

  const [searchInputText, setSearchInputText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [suggestItems, setSuggestItems] = useState(items);
  const [isFilteringNow, setIsFilteringNow] = useState(false);

  const [review, setReview] = useState(false);

  // modal open focus 用
  const searchInputRef = useRef();

  // items を可変にする itemsFunc が設定されていた場合
  useEffect(() => {
    if (!!itemsFunc) {
      if (!!itemFilterTimer[componentKey]) {
        clearTimeout(itemFilterTimer[componentKey]);
      }
      itemFilterTimer[componentKey] = setTimeout(() => {
        setIsFilteringNow(true);
        (async () => {
          const resFunc = await itemsFunc(searchInputText);
          // isSelected を付けていく
          const newItems = resFunc?.map((e) => ({
            no: e.no,
            label: e.label,
            object: e.object,
            cnt: e.cnt,
            isSelected: Array.isArray(value)
              ? !!value?.find((r) => r?.no === e?.no)
              : value?.no === e?.no,
            isCustom: false,
          }));
          setSuggestItems(newItems);
          setIsFilteringNow(false);
        })();
      }, 500);
    }
  }, [searchInputText]);

  // 呼び出しもとで items を変更した場合
  useEffect(() => setSuggestItems(items), [items]);

  // 手入力の値が指定されたときに発番
  const customNumber = useCallback(() => {
    if (customNum[componentKey] === undefined) {
      customNum[componentKey] = -1;
    } else {
      customNum[componentKey] -= 1;
    }
    return customNum[componentKey];
  }, [customNum, componentKey]);

  const isEmpty = useCallback((v) => {
    if (multiple) {
      return TypeUtil.isEmpty(v);
    }
    return TypeUtil.isEmpty(v) || !v?.no;
  }, [multiple]);

  // 配列はpushしてもstateChangeを検知しないので、このメソッドで
  // 検知用stateを調整して review を発生させる
  // また、 item がすでに result にあったら、削除し、なければ追加する
  const changeResult = (item) => {
    const newItem = item;
    let newResults;
    if (multiple) {
      if (!item.isSelected) {
        // 今回選択ならば追加
        newItem.isSelected = true;
        newResults = value.concat();
        newResults.push(newItem);
        setSearchInputText('');
        if (!isSp) {
          searchInputRef.current.focus();
        }
      } else {
        // 今回選択解除なら除去
        newResults = value.filter((e) => e.no !== item.no);
      }
    } else {
      if (!item.isSelected) {
        newItem.isSelected = true;
        newResults = newItem;
        setIsModalOpen(false);
        setSearchInputText('');
        if (!isSp) {
          searchInputRef.current.focus();
        }
      } else {
        newItem.isSelected = false;
        newResults = null;
        setIsModalOpen(true);
      }
    }
    onChange(newResults);
    setReview(!review);
  };

  /** 選択全解除 */
  const allSelectRelease = () => {
    onChange([]);
    setReview(!review);
  };

  // 選択肢を表示
  const viewItemArray = () => {
    let regExp;
    try {
      regExp = new RegExp(searchInputText, 'ig');
    } catch (err) {
      // searchInputText が想定外なので、例外回避
      ;
    }
    const itemArray = suggestItems?.filter((e) => {
      // 検索ワードがないか、itemsFunc があればフィルタしない
      if (!regExp || !!itemsFunc) {
        return true;
      }
      try {
        return e.label?.match(regExp);
      } catch (err) {
        // match で例外ならば、該当ありとする
        return true;
      }
    }).map((e) => ({
      no: e.no,
      label: e.label,
      object: e.object,
      cnt: e.cnt,
      isSelected: Array.isArray(value) ? !!value?.find((r) => r.no === e.no) : value?.no === e.no,
    })) || [];

    if (isEnableCustomValue && searchInputText && !suggestItems?.find((e) => e.label === searchInputText)) {
      // 入力値と同じlabelのitemがなければcustomの部品を追加
      itemArray.push({
        no: customNumber(),
        label: searchInputText,
        object: searchInputText,
        isCustom: true,
      });
    }
    if (!itemArray || itemArray.length < 1) {
      if (isFilteringNow) {
        return (
          <ActivityIndicator
            animating
            color="#333"
            size="large"
          />
        );
      }
      return (
        <T>{!!searchInputText ? '該当なし' : '検索キーワードを入力してください'}</T>
      );
    }

    // custom 項目も表示させる
    const viewItems = [];
    if (TypeUtil.isArray(value)) {
      viewItems.push(...value.filter(e => e.isCustom));
    }
    viewItems.push(...itemArray);
    return (
      <FlatList
        data={viewItems}//itemArray}
        keyExtractor={(item) => `select_item_extractor_${componentKey}_${item.no}`}
        renderItem={({ item }) => (
          <SuggestItem
            onPress={() => changeResult(item)}
            item={item}
            bgColor={itemBgColor}
            textColor={itemTextColor}
            style={[
              itemStyle,
              {
                marginVertical: 4,
                marginLeft: item.isSelected ? 0 : 16,
              }
            ]}
            isViewCount
          />
        )}
      />
    );
  };

  // if (TypeUtil.isEmpty(suggestItems) && !itemsFunc) {
  //   return (<></>);
  // }

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flex: 1,
          flexWrap: 'wrap',
          marginTop: 8,
        }}
      >
        <TouchableOpacity
          style={[
            styles.normalViewArea,
            !!fieldBgColor ? {backgroundColor: fieldBgColor} : {},
          ]}
          onPress={() => setIsModalOpen(true)}
        >
          { isEmpty(value)
          ? (
            <T style={{ lineHeight: 40, marginHorizontal: 8 }}>{label}</T>
          ) : (
            <>
              <T
                style={{
                  position: 'absolute',
                  top: -8,
                  backgroundColor: '#fff',
                  borderRadius: 8,
                  fontSize: 12,
                }}
              >
                {label}
              </T>
              {multiple
                ? value.map((e) => (
                    <SuggestItem
                      key={`temp_result_${componentKey}_${e.no}`}
                      onPress={() => setIsModalOpen(true)}
                      bgColor={itemBgColor}
                      textColor={itemTextColor}
                      style={itemStyle}
                      item={e}
                    />
                  ))
                : (
                  <SuggestItem
                    key={`temp_result_${componentKey}_${value.no}`}
                    onPress={() => setIsModalOpen(true)}
                    bgColor={itemBgColor}
                    textColor={itemTextColor}
                    style={itemStyle}
                    item={value}
                  />
                )
              }
              </>
          )}
        </TouchableOpacity>
      </View>
      <Modal
        key={`inputSuggestModal_${componentKey}`}
        style={{
          maxWidth: 800,
          width: width * 0.9,
          height: height * 0.9,
          alignContet: 'center',
          borderRadius: 10,
          padding: 12,
          shadowColor: '#000',
          shadowOffset: {
            width: 2,
            height: 4,
          },
          shadowOpacity: 0.4,
          elevation: 16,
        }}
        coverScreen
        isOpen={isModalOpen}
        backdropPressToClose
        backdrop
        backdropColor="#000"
        backdropOpacity={0.5}
        position="center"
        entry="bottom"
        onClosed={() => setIsModalOpen(false)}
        onOpened={() => {
          if (!isSp) {
            searchInputRef?.current?.focus();
          }
        }}
        useNativeDriver={false}
      >
        <View
          style={{
            alignContent: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          { !isEmpty(value) && (
            <>
            {/* <View
               style={{
                 flex: 1,
                 flexDirection: 'row',
                 flexWrap: 'wrap',
               }}
            > */}
              { multiple
                ? value.map((e) => (
                    <SuggestItem
                      key={`temp_result_${componentKey}_${e.no}`}
                      onPress={(v) => changeResult(v)}
                      bgColor={itemBgColor}
                      textColor={itemTextColor}
                      style={itemStyle}
                      item={e}
                    />
                ))
                : (
                    <SuggestItem
                      key={`temp_result_${componentKey}_${value.no}`}
                      onPress={(v) => changeResult(v)}
                      bgColor={itemBgColor}
                      textColor={itemTextColor}
                      style={itemStyle}
                      item={value}
                    />
                )
              }
            </>
            // </View>
          )}
        </View>
        <View
          style={{
            alignContent: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            marginVertical: 4,
          }}
        >
          <Button
            onPress={() => setIsModalOpen(false)}
            dir={DIR.ANTDESIGN}
            icon="checkcircle"
            iconColor={Styles.COLOR_SAFETY}
            iconSize={32}
            linkStyle
          />
          <TextInput
            key={`InputSuggest_TextInput_Search_${componentKey}`}
            ref={searchInputRef}
            style={[
              {
                minWidth: 120,
                height: 18,
                marginBottom: 0,
                marginHorizontal: 8,
                fontSize: 12,
                borderWidth: 1,
                paddingHorizontal: 8,
              },
              Styles.FONT,
              styles.textInput,
              disabled ? styles.disabledColorTextInput : styles.normalColorTextInput,
            ]}
            placeholder={`${isEnableCustomValue ? '自由入力 or ' : ''}検索`}
            onChangeText={(e) => setSearchInputText(e)}
            value={searchInputText}
            editable={!disabled}
            maxLength={customValueMaxLength}
          />
          <Button
            onPress={() => allSelectRelease()}
            label="選択全解除"
          />
        </View>
        { isEnableCustomValue && (
          <View style={{flexDirection: 'column'}}>
            <T
              style={{
                fontSize: 12,
                marginLeft: 16,
              }}
              multiple
              shortText="ご注意！！！"
              isFirstOpen
            >
              {`※　自由入力したcustom項目は、登録後は他の人からも選択可能な公開情報になります。(作成者は記録されません)
              不適切な用語や類似語は、運営により削除・統廃合されることがあります。`}
            </T>
          </View>
        ) }
        { !!help && (
          <T
            style={{
              fontSize: 12,
              fontWeight: 'bold',
              color: '#33f',
              marginTop: 8,
              marginLeft: 16,
            }}
          >
            {help}
          </T>
        )}
        <View style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'stretch',
          borderWidth: 1,
          borderColor: '#888',
          borderRadius: 5,
          padding: 16,
        }}
        >
          {viewItemArray()}
        </View>
      </Modal>
    </>
  );
}

export const oneValueType = PropTypes.shape({
  no: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
});

export const multipleValueType = PropTypes.arrayOf(
  oneValueType,
);

export const valueType = PropTypes.oneOfType([
  oneValueType,
  multipleValueType,
]);

export const itemsType = PropTypes.arrayOf(
  PropTypes.shape({
    no: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
);

InputSuggest.propTypes = {
  multiple: PropTypes.bool,
  label: PropTypes.string,
  value: valueType,
  items: itemsType,
  disabled: PropTypes.bool,
  help: PropTypes.string,
  isEnableCustomValue: PropTypes.bool,
  onChange: PropTypes.func,
  componentKey: PropTypes.string.isRequired,
  itemBgColor: SuggestItem.propTypes.bgColor,
  itemTextColor: SuggestItem.propTypes.textColor,
  itemStyle: SuggestItem.propTypes.style,
  itemsFunc: PropTypes.func,
  customValueMaxLength: PropTypes.number,
  fieldBgColor: PropTypes.string,
};

InputSuggest.defaultProps = {
  multiple: false,
  label: '選択',
  value: [],
  disabled: false,
  help: null,
  isEnableCustomValue: false,
  onChange: SuggestItem.defaultProps.onChange,
  itemBgColor: SuggestItem.defaultProps.bgColor,
  itemTextColor: SuggestItem.defaultProps.textColor,
  itemStyle: {},
  itemsFunc: null,
  customValueMaxLength: 32,
  fieldBgColor: null,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    marginRight: 8,
  },
  titleContainer: {
    marginTop: 16,
    marginLeft: 16,
    borderTopColor: '#000',
    borderTopWidth: 1,
  },
  titleText: {
    fontSize: 16,
    marginRight: 32,
  },
  textInput: {
    marginLeft: 8,
    marginRight: 8,
    height: 32,
    fontSize: 12,
    borderWidth: 1,
    paddingHorizontal: 8,
  },
  normalColorTextInput: {
    backgroundColor: '#fff',
    borderColor: '#333',
    borderWidth: 2,
  },
  disabledColorTextInput: {
    backgroundColor: '#ccc',
    color: '#111',
    borderColor: '#ddd',
  },
  error: {
    fontSize: 16,
    marginLeft: 56,
    color: '#f00',
    fontWeight: 'bold',
  },
  normalViewArea: {
    flex: 1,
    borderColor: '#333',
    borderWidth: 1,
    borderRadius: 4,
    backgroundColor: '#fff',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    paddingHorizontal: 16,
    minHeight: 40,
    paddingVertical: 8,
  },
});
