import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TitledSelect from './TitledSelect';
import * as Server from '../functions/data/Server';
import uuid from '../../common/util/Uuid';
import TitledComponent from './TitledComponent';
import TitledInput from './TitledInput';
import Styles from '../Styles';

const choosePrefEntity = (prefCd, prefCityMst) => prefCityMst?.find((e) => e.prefCd === prefCd);
const chooseCityEntity = (prefCd, cityCd, prefCityMst) => {
  const prefEntity = choosePrefEntity(prefCd, prefCityMst);
  return prefEntity?.cityList?.find((e) => e.cityCd === cityCd);
};

export default function TitledAddressInput(props) {
  const {
    dir, icon, iconColor, title, placeholder, value, onChange, disabled, componentKey,
    help, subIcons,
    error, errorBgColor,
  } = props;

  const [prefCityMst, setPrefCityMst] = useState();

  useEffect(() => {
    (async () => {
      const serverResult = await Server.getPrefList();
      if (serverResult) {
        setPrefCityMst(serverResult);
      }
    })();
    return () => {};
  }, []);

  if (!prefCityMst) {
    return (
      <></>
    );
  }

  return (
    <TitledComponent
      dir={dir}
      icon={icon}
      iconColor={iconColor}
      componentKey={componentKey}
      style={{display: 'contents'}}
      componentStyle={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: 0,
        marginLeft: 0,
        marginTop: 8,
      }}
      title={title}
      help={help}
      subIcons={subIcons}
      error={error}
      errorBgColor={errorBgColor}
    >
      <TitledSelect
        placeholder={`${placeholder ? placeholder : ''}-都道府県`}
        items={prefCityMst?.map((e) => ({
          value: e.prefCd,
          label: e.prefName,
        }))}
        defaultValue={value?.prefCd}
        disabled={disabled}
        onChange={(v) =>
          onChange({
            prefCd: v,
            prefName: choosePrefEntity(v, prefCityMst)?.prefName,
            cityCd: '',
            cityName: '',
            town: value?.town,
            build: value?.build,
          })
        }
        fieldStyle={[
          { minWidth: 150, },
          !!disabled && {backgroundColor: Styles.COLOR_BG_DISABLED}
        ]}
        isNullable
      />
      <TitledSelect
        placeholder={`${placeholder ? placeholder : ''}-市区町村`}
        items={prefCityMst?.find((p) => p?.prefCd === value?.prefCd)?.cityList?.map((e) => ({
          value: e.cityCd,
          label: e.cityName,
        })) || []}
        defaultValue={value?.cityCd}
        disabled={!!value?.prefCd ? disabled : true}
        onChange={(v) =>
          onChange({
            prefCd: value?.prefCd,
            prefName: value?.prefName,
            cityCd: v,
            cityName: chooseCityEntity(value?.prefCd, v, prefCityMst)?.cityName,
            town: value?.town,
            build: value?.build,
          })
        }
        fieldStyle={[
          { minWidth: 150, },
          !!disabled && {backgroundColor: Styles.COLOR_BG_DISABLED}
        ]}
        isNullable
      />
      <TitledInput
        placeholder={`${placeholder ? placeholder : ''}-町丁目番地`}
        onChangeText={(v) =>
          onChange({
            prefCd: value?.prefCd,
            prefName: value?.prefName,
            cityCd: value?.cityCd,
            cityName: value?.cityName,
            town: v,
            build: value?.build,
          })
        }
        value={value?.town}
        maxLength={32}
        disabled={disabled}
      />
      <TitledInput
        placeholder={`${placeholder ? placeholder : ''}-建物名`}
        onChangeText={(v) =>
          onChange({
            prefCd: value?.prefCd,
            prefName: value?.prefName,
            cityCd: value?.cityCd,
            cityName: value?.cityName,
            town: value?.town,
            build: v,
          })
        }
        value={value?.build}
        maxLength={32}
        disabled={disabled}
      />
    </TitledComponent>
  );
}

TitledAddressInput.propTypes = {
  dir: PropTypes.any,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    prefCd: PropTypes.string,
    prefName: PropTypes.string,
    cityCd: PropTypes.string,
    cityName: PropTypes.string,
    town: PropTypes.string,
    build: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  componentKey: PropTypes.string,
  help: TitledComponent.propTypes.help,
  subIcons: TitledComponent.propTypes.subIcons,
  error: TitledComponent.propTypes.error,
  errorBgColor: TitledComponent.propTypes.errorBgColor,
};

TitledAddressInput.defaultProps = {
  dir: null,
  icon: null,
  title: null,
  placeholder: null,
  value: {
    prefCd: '',
    cityCd: '',
    town: '',
    build: '',
  },
  disabled: false,
  onBlur: () => {},
  componentKey: uuid(),
  iconColor: '#000',
  help: null,
  subIcons: null,
  error: TitledComponent.defaultProps.error,
  errorBgColor: TitledComponent.defaultProps.errorBgColor,
};
