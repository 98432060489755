import React, { useContext, useCallback, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { AppContext } from '../context/AppContext';
import * as Server from '../functions/data/Server';
import { download } from '../../common/functions/Download';
import { toastSorry } from '../functions/Toast';
import T from '../components/T';
import ButtonGoogle from '../../common/components/sns/ButtonGoogle';
import { googleAnalyticsTracking } from '../../common/functions/google/GoogleAnalytics';
import Button from '../../common/components/Button';

const FAILURE_DOWNLOAD = `キーが存在しない、アクセス権がない、期限が切れている、
またはダウンロード回数上限に達したため、
ダウンロードできませんでした`;

/**
 * 経歴書
 * @returns
 */
export default function CareerSheetDownloadTarget(routeParam) {
  const { route } = routeParam;
  const { params } = route;
  const { key } = !!params ? params : {};
  const { userInfo, dimensions, googleLogin, Link } = useContext(AppContext);

  const [ downloadResult, setDownloadResult ] = useState('ダウンロードしています');

  const [ isViewToDashboard, setIsViewToDashboard] = useState(false);

  // ダウンロード処理
  const downloadCareerSheet = useCallback(async () => {
    try {
      const serverResult = await Server.get(userInfo?.token, 'engineer/careerSheetTarget', {key});
      if (!serverResult?.data?.excel) {
        setDownloadResult(FAILURE_DOWNLOAD);
        return;
      }
      download(serverResult?.data?.excel, '経歴書.xlsx');
      setDownloadResult('ダウンロード完了しました');
    }catch(err) {
      toastSorry(`ダウンロードに失敗しました。`, 3000);
      setDownloadResult(FAILURE_DOWNLOAD);
    }
    setIsViewToDashboard(true);
  }, []);

  useEffect(() => {
    if (!!userInfo?.token) {
      downloadCareerSheet();
    }
  }, [userInfo]);

  return (
    <View
      style={{
        flex: 1,
        height: dimensions?.height,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {!!userInfo?.token
        ? (
          <T>{downloadResult}</T>
        ) : (
          <T>経歴書をダウンロードするにはログインが必要です</T>
          // <ButtonGoogle
          //   label="ログイン"
          //   onPress={() => {
          //     googleAnalyticsTracking('click', 'careerSheetDownloadTarget', 'googleLogin');
          //     googleLogin(downloadCareerSheet); // DASHBOARD への遷移を抑止してダウンロード
          //   }}
          // />
        )}
      {isViewToDashboard && (
        <Button
          onPress={() => Link(Screen.DASHBOARD)}
          label="ダッシュボードへ"
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  h1: {
    marginTop: 16,
    fontSize: 24,
    fontWeight: 'bold',
    alignSelf: 'center',
  },
  h2: {
    marginTop: 16,
    marginLeft: 8,
    fontSize: 20,
    fontWeight: 'bold',
    alignSelf: 'flex-start',
  },
  h3: {
    marginTop: 16,
    marginLeft: 8,
    fontSize: 16,
    alignSelf: 'flex-start',
  },
  fullWidth: {
    width: '100%',
  },
  header: {
    alignSelf: 'flex-end',
    marginRight: 8,
  },
  description: {
    alignSelf: 'flex-start',
    flexWrap: 'wrap',
    marginLeft: 24,
  }
});
