import React, { useState, useEffect, useContext, } from 'react';
import { View, Text } from 'react-native';
import ViewEngineer from './component/ViewEngineer';
import * as Server from '../functions/data/Server';
import Waiting from '../components/Waiting';
import Box from '../../common/components/Box';
import T from '../components/T';
import { AppContext } from '../context/AppContext';

/**
 * エンジニア情報
 * @returns
 */
export default function Engineer(routeParam) {
  const { route } = routeParam;
  const { params } = route;
  const { uniqueName } = params;
  const [engineer, setEngineer] = useState();
  const [error, setError] = useState();

  // reload
  const [review, setReview] = useState(0);

  const reloadEngineer = () => {
    setReview((i) => {
      i++;
      return i;
    });
  };

  const { userInfo, isSp } = useContext(AppContext);

  useEffect(() => {
    (async () => {
      try {
        const serverResult = await Server.getEngineerByUniqueName(uniqueName);
        setEngineer(serverResult);
      } catch (e) {
        setError(e?.response?.data?.errorMessage || e);
      }
    })();
  }, [uniqueName, userInfo, review]);

  if (!!error) {
    return (
      <View
        style={{
          flex: 1,
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <T>{error}</T>
      </View>
    );
  }

  if (!engineer) {
    return (
      <Waiting/>
    )
  };

  return (
    <Box
      style={{
        maxWidth: '90%',
        minWidth: '60%',
        margin: 8,
        flexDirection: 'column',
        alignSelf: 'center',
      }}
    >
      <ViewEngineer
        engineer={engineer}
        reloadEngineer={reloadEngineer}
      />
    </Box>
  );

}
