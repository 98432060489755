import { isNumber } from './TypeUtil';
/**
 * テキストが半角のみであるか
 * @param {string} text
 */
export function isHalfChar(text) {
  if (!text) {
    return false;
  }
  for (let i = 0; i < text.length; i++) {
    if (escape(text.charAt(i)).length >= 4) {
      return false;
    }
  }
  return true;
}

/**
 * テキストから全角文字を除却する
 * @param {string} text
 */
export function removeFullChar(text) {
  let result = '';
  for (let i = 0; i < text.length; i++) {
    const c = text.charAt(i);
    if (escape(c).length < 4) {
      result += c;
    }
  }
  return result;
}

/**
 * 文字列が空
 * @param {string} text
 */
export function isEmpty(text) {
  return !isNotEmpty(text);
}

/**
 * 文字列が空でない
 * @param {string} text
 */
export function isNotEmpty(text) {
  return (text && text?.length > 0);
}

// 半角英数を全角に変換
export function exStringHalfToFull(half) {
  return half.replace(/[A-Za-z0-9]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
  });
}

// 全角英数を全角に変換
export function exStringFullToHalf(full) {
  return full.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
});
}

export function isNumeric(value) {
  if (isNumber(value)) return true;
  return /[0-9]+/.test(value);
}

export function isTel(value) {
  return isNumeric(value) && value.length >= 10 && 11 >= value.length;
}
