import { isString } from "./TypeUtil";

/**
 * 日付フォーマッタ.
 * @param {Date|String} date 日付オブジェクト or 数値 or yyyymmddhhmmss
 * @param {String} format
 *      フォーマット文字
 *      yyyy, MM, dd, HH, mm, ss, SSS
 */
export function formatDate(date, format) {
  if (!date) {
    return null;
  }
  if (isString(date)) {
    date = new Date(date);
  }
  const result = format;
  let target;
  if (typeof date === 'number') {
    target = new Date(date);
  } else if (typeof date === 'string') {
    let year;
    let month;
    let day;
    let hour;
    let min;
    let sec;
    if (date.length >= 8) {
      try {
        year = parseInt(date.substring(0, 4), 10) || undefined;
        month = (parseInt(date.substring(4, 6), 10) - 1) || undefined;
        day = parseInt(date.substring(6, 8), 10) || undefined;
        hour = parseInt(date.substring(8, 10), 10) || undefined;
        min = parseInt(date.substring(10, 12), 10) || undefined;
        sec = parseInt(date.substring(12, 14), 10) || undefined;
      } catch (err) {
        // 無視
      }
      const now = new Date();
      if (year === undefined) {
        year = now.getFullYear();
      }
      if (month === undefined) {
        month = now.getMonth();
      }
      if (day === undefined) {
        day = now.getDate();
      }
      if (hour === undefined) {
        hour = now.getHours();
      }
      if (min === undefined) {
        min = now.getMinutes();
      }
      if (sec === undefined) {
        sec = now.getSeconds();
      }
    }
    target = new Date(year, month, day, hour, min, sec);
  } else {
    try {
      target = new Date(date.getTime());
    } catch (err) {
      return null;
    }
  }
  return result.replace(/yyyy/g, target.getFullYear())
    .replace(/MM/g, (`0${target.getMonth() + 1}`).slice(-2))
    .replace(/M/g, (target.getMonth() + 1))
    .replace(/dd/g, (`0${target.getDate()}`).slice(-2))
    .replace(/d/g, target.getDate())
    .replace(/HH/g, (`0${target.getHours()}`).slice(-2))
    .replace(/H/g, target.getHours())
    .replace(/mm/g, (`0${target.getMinutes()}`).slice(-2))
    .replace(/m/g, target.getMinutes())
    .replace(/ss/g, (`0${target.getSeconds()}`).slice(-2))
    .replace(/s/g, target.getSeconds())
    .replace(/SSS/g, (`00${target.getMilliseconds()}`).slice(-3));
}

/**
 * 日付が正しいか
 * 全空は許容
 * @param {string | number} year
 * @param {string | number} month
 * @param {string | number} day
 * @param {string | number} hour
 * @param {string | number} minute
 */
export function checkDate(year, month, day, hour, minute) {
  if (!year && !month && !day && !hour && !minute) {
    return true;
  }
  try {
    const y = parseInt(year, 10);
    const m = parseInt(month, 10);
    const d = parseInt(day, 10);
    const h = parseInt(hour, 10);
    const min = parseInt(minute, 10);
    const date = new Date(y, m, d, h, min);
    return date.getFullYear() === y
                && date.getMonth() === m
                && date.getDate() === d
                && date.getHours() === h
                && date.getMinutes() === min;
  } catch (err) {
    return false;
  }
}

/**
 * 日付を生成
 * 全空は許容
 * @param {string | number} year
 * @param {string | number} month
 * @param {string | number} day
 * @param {string | number} hour
 * @param {string | number} minute
 */
export function createDate(year, month, day, hour, minute) {
  if (!year && !month && !day && !hour && !minute) {
    return null;
  }
  const y = parseInt(year, 10);
  const m = parseInt(month, 10);
  const d = parseInt(day, 10);
  const h = parseInt(hour, 10);
  const min = parseInt(minute, 10);
  return new Date(y, m, d, h, min);
}

/**
 * yyyymmdd を Date に変換する
 * @param {*} yyyymmdd
 */
export function exchangeDateStr(yyyymmdd) {
  if (!yyyymmdd?.length === 8) {
    return null;
  }
  try {
    const yyyy = parseInt(yyyymmdd.slice(0, 4), 10);
    const mm = parseInt(yyyymmdd.slice(4, 6), 10) - 1;
    const dd = parseInt(yyyymmdd.slice(6, 8), 10);
    return new Date(yyyy, mm, dd);
  } catch(e) {
    return null;
  }
}

/**
 * yyyymmdd形式の日付になっているか
 * @param {string} yyyymmdd
 */
export function checkDateStr(yyyymmdd) {
  if (!yyyymmdd?.length === 8) {
    return null;
  }
  try {
    const yyyy = parseInt(yyyymmdd.slice(0, 4), 10);
    const mm = parseInt(yyyymmdd.slice(4, 6), 10) - 1;
    const dd = parseInt(yyyymmdd.slice(6, 8), 10);
    const date = new Date(yyyy, mm, dd);
    return yyyy === date.getFullYear() && mm === date.getMonth() && dd === date.getDate();
  } catch(e) {
    return null;
  }
}

/**
 * 年齢算出
 * @param {*} birthday
 * @returns
 */
export function getAge(birthday){
  //今日
  const today = new Date();
  //今年の誕生日
  const thisYearsBirthday = new Date(today.getFullYear(), birthday.month-1, birthday.date);
  //年齢
  let age = today.getFullYear() - birthday.year;
  if(today < thisYearsBirthday){
      //今年まだ誕生日が来ていない
      age--;
  }
  return age;
}
