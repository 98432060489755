import React, { useRef, useState } from 'react';
import {
  TouchableOpacity, StyleSheet,
} from 'react-native';
import PropTypes from 'prop-types';
import IconText from './IconText';

const PRIMARY_BUTTON_COLOR = '#000';
const PRIMARY_LABEL_COLOR = '#fff';
const SECONDARY_BUTTON_COLOR = '#fff';
const SECONDARY_LABEL_COLOR = '#000';
const DISABLED_BUTTON_COLOR = '#888';
const DISABLED_LABEL_COLOR = '#ccc';

/**
 * ボタン
 * @param {*} props
 * @returns ボタン
 */
export default function Button(props) {
  const {
    label, onPress, buttonStyle, labelStyle, buttonDisabledStyle, labelDisabledStyle,
    icon, iconSize, dir, disabled, iconColor, buttonWidth,
    buttonColor, buttonColorDisabled, labelColor, labelColorDisabled,
    secondary, linkStyle, help,
  } = props;

  // 2度押し対策
  const isPressing = useRef(false);

  const adjustedStyle = [disabled ? buttonDisabledStyle : buttonStyle];
  if (buttonWidth) {
    adjustedStyle.push({
      width: buttonWidth,
    });
  }
  if (disabled && buttonColorDisabled) {
    adjustedStyle.push({
      backgroundColor: buttonColorDisabled,
    });
  }
  if (!disabled) {
    if (buttonColor) {
      adjustedStyle.push({
        backgroundColor: buttonColor,
      });
    } else if(secondary) {
      adjustedStyle.push({
        backgroundColor: SECONDARY_BUTTON_COLOR,
      });
    } else {
      adjustedStyle.push({
        backgroundColor: PRIMARY_BUTTON_COLOR,
      });
    }
  }

  let adjustedLabelStyle = [];
  if (label) {
    if (disabled) {
      if (labelDisabledStyle) {
        adjustedLabelStyle.push(labelDisabledStyle);
      }
      if (labelColorDisabled) {
        adjustedLabelStyle.push({
          color: labelColorDisabled,
        });
      }
    } else {
      if (labelStyle) {
        adjustedLabelStyle.push(labelStyle);
      }
      if (labelColor) {
        adjustedLabelStyle.push({
          color: labelColor,
        });
      } else if(secondary) {
        adjustedLabelStyle.push({
          color: SECONDARY_LABEL_COLOR,
        });
      } else {
        adjustedLabelStyle.push({
          color: PRIMARY_LABEL_COLOR,
        });
      }
    }
  } else {
    adjustedLabelStyle = {
      width: 0,
      height: 0,
    };
  }

  // linkStyle はウェブサイトアンカースタイル
  // 上記の設定をすべて打ち消す

  return (
    <TouchableOpacity
      key="Button_Touchable"
      style={linkStyle ? styles.linkStyleButton : adjustedStyle}
      onPress={(e) => {
        e.preventDefault();

        // 押してから処理終了してない
        if (isPressing.current) return;

        // 押されてない状態でここに到達 FlgOn
        isPressing.current = true;

        // 2度押し対策
        setTimeout(() => {
          (async () => {
            await onPress(e);
            isPressing.current = false;
          })();
        }, 10);
      }}
      disabled={disabled}
    >
      <IconText
        containerStyle={{
          flexDirection: 'row',
          alignContent: 'center',
          // alignItems: 'baseline',
          justifyContent: 'center',
        }}
        textStyle={linkStyle ? styles.linkStyleText : adjustedLabelStyle}
        icon={icon}
        iconSize={iconSize}
        iconColor={iconColor}
        dir={dir}
        help={help}
      >
        {label}
      </IconText>
    </TouchableOpacity>
  );
}

Button.propTypes = {
  label: PropTypes.string,
  onPress: PropTypes.func.isRequired,
  buttonStyle: PropTypes.any,
  labelStyle: PropTypes.any,
  buttonDisabledStyle: PropTypes.any,
  labelDisabledStyle: PropTypes.any,
  icon: PropTypes.string,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
  dir: PropTypes.any,
  disabled: PropTypes.bool,
  buttonWidth: PropTypes.number,
  buttonColor: PropTypes.string,
  buttonColorDisabled: PropTypes.string,
  labelColor: PropTypes.string,
  labelColorDisabled: PropTypes.string,
  secondary: PropTypes.bool,
  linkStyle: PropTypes.bool,
  help: IconText.propTypes.help,
};

const styles = StyleSheet.create({
  defaultButton: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    marginVertical: 2,
    backgroundColor: PRIMARY_BUTTON_COLOR,
    borderRadius: 16,
    paddingHorizontal: 8,
    paddingVertical: 2,
    shadowColor: '#000',
    shadowOffset: {
      width: 2,
      height: 2,
    },
    shadowOpacity: 0.4,
    elevation: 16,
  },
  defaultButtonDisabled: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    marginVertical: 2,
    backgroundColor: DISABLED_BUTTON_COLOR,
    borderRadius: 16,
    paddingHorizontal: 8,
    paddingVertical: 2,
    height: 32,
    shadowColor: '#000',
    shadowOffset: {
      width: 2,
      height: 4,
    },
    shadowOpacity: 0.4,
    elevation: 16,
  },
  defaultLabel: {
    color: PRIMARY_LABEL_COLOR,
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 24,
    marginHorizontal: 8,
  },
  defaultLabelDisabled: {
    color: DISABLED_LABEL_COLOR,
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 24,
  },
  linkStyleButton: {
    backgroundColor: 'transparent',
    width: 'fit-content',
  },
  linkStyleText: {
    color: '#00f',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 24,
    textDecorationLine: 'underline',
  },
});

Button.defaultProps = {
  label: '',
  buttonStyle: styles.defaultButton,
  labelStyle: styles.defaultLabel,
  buttonDisabledStyle: styles.defaultButtonDisabled,
  labelDisabledStyle: styles.defaultLabelDisabled,
  icon: null,
  iconSize: 24,
  iconColor: '#eee',
  dir: null,
  disabled: false,
  buttonWidth: null,
  linkStyle: false,
  buttonColor: PRIMARY_BUTTON_COLOR,
  buttonColorDisabled: DISABLED_BUTTON_COLOR,
  labelColor: PRIMARY_LABEL_COLOR,
  labelColorDisabled: DISABLED_LABEL_COLOR,
  help: IconText.defaultProps.help,
};

export const STYLE_BUTTON = styles.defaultButton;
export const STYLE_LABEL = styles.defaultLabel;
