function isString(v) {
  return typeof v === 'string';
}

function isNumber(v) {
  return typeof v === 'number';
}

function isBoolean(v) {
  return typeof v === 'boolean';
}

function isUndefined(v) {
  return typeof v === 'undefined';
}

function isJson(v) {
  return v?.constructor === Object;
}

function isEmptyJson(v) {
  return !v || (Object.keys(v).length === 0 && isJson(v));
}

function isEmpty(v) {
  return v === ''
    || v === null
    || (isJson(v) && isEmptyJson(v))
    || isUndefined(v)
    || (Array.isArray(v) && v.length === 0)
    || (Array.isArray(v) && v.length === 1 && isEmptyJson(v[0]));
}

/**
 * 配列であるか
 * @param {*} v 値
 * @param {boolean} isNotEmptyFlg 空のときはfalseを返したいとき true
 */
function isArray(v, isNotEmptyFlg) {
  return Array.isArray(v)
    && (!isNotEmptyFlg || v.length > 0);
}

/**
 * 空、null でなければ値を返す
 */
function optional(v) {
  return isEmpty(v) ? null : v;
}

function ifPresent(v, func) {
  return isEmpty(v) ? null : func(v);
}

module.exports = {
  isString,
  isNumber,
  isBoolean,
  isUndefined,
  isEmpty,
  isArray,
  optional,
  ifPresent,
};
