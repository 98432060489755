import React from 'react';
import {
  TouchableOpacity,
  View,
} from 'react-native';
import PropTypes from 'prop-types';
import T from '../../src/components/T';

export default function SuggestItem (props) {
  const { onPress, item, bgColor, textColor, style, label, isViewCount } = props;

  const main = (
    <T style={[
      {
        lineHeight: 20,
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: item.isCustom
          ? bgColor.custom
          : item.isSelected
            ? bgColor.selected
            : bgColor.normal,
        // eslint-disable-next-line no-nested-ternary
        color: item.isCustom
          ? textColor.custom
          : item.isSelected
            ? textColor.selected
            : textColor.normal,
        margin: 2,
        paddingVertical: 2,
        paddingHorizontal: 8,
        borderRadius: 16,
        flexWrap: 'wrap',
      },
      style,
    ]}
    >
      {label || `${item.label}${item.isCustom ? ' (custom)' : ''}${isViewCount ? `　(${item.cnt || '0'})` : ''}`}
    </T>
  );

  if (onPress) {
    return (
      <TouchableOpacity
        onPress={() => onPress(item)}
      >
        {main}
      </TouchableOpacity>
    );
  } else {
    return (
      <View>
        {main}
      </View>
    );
  }
}

SuggestItem.propTypes = {
  onPress: PropTypes.func,
  item: PropTypes.shape({
    no: PropTypes.number.isRequired,
    isCustom: PropTypes.bool,
    isSelected: PropTypes.bool,
    label: PropTypes.string.isRequired,
  }).isRequired,
  bgColor: PropTypes.shape({
    custom: PropTypes.string.isRequired,
    selected: PropTypes.string.isRequired,
    normal: PropTypes.string.isRequired,
  }),
  textColor: PropTypes.shape({
    custom: PropTypes.string.isRequired,
    selected: PropTypes.string.isRequired,
    normal: PropTypes.string.isRequired,
  }),
  style: PropTypes.any,
  label: PropTypes.string,
  isViewCount: PropTypes.bool,
};

SuggestItem.defaultProps = {
  onPress: null,
  bgColor: {
    normal: '#fee',
    selected: '#E87729',
    custom: '#8BDF4C',
  },
  textColor: {
    normal: '#000',
    selected: '#fff',
    custom: '#333',
  },
  style: null,
  label: null,
  isViewCount: false,
};
