import React, { useState, useEffect, useContext, } from 'react';
import { View } from 'react-native';
import * as Server from '../functions/data/Server';
import Waiting from '../components/Waiting';
import Box from '../../common/components/Box';
import T from '../components/T';
import { AppContext } from '../context/AppContext';
import GitInfo from './component/GitInfo';
import Button from '../../common/components/Button';
import Title from '../components/Title';
import { Screen } from '../Screen';
import { LinkUrl } from '../LinkUrl';

/**
 * エンジニア情報
 * @returns
 */
export default function PreviewSelfGitInfo(routeParam) {
  const { route } = routeParam;
  const { params } = route;
  const gitInfoNonce = params?.gitInfoNonce;

  const [gitInfo, setGitInfo] = useState();
  const [error, setError] = useState();

  const { userInfo, isSp, viewDialog, Link } = useContext(AppContext);

  useEffect(() => {
    (async () => {
      try {
        const serverResult = await Server.getGitInfo(gitInfoNonce);
        setGitInfo(serverResult);
      } catch (e) {
        setError(e?.response?.data?.errorMessage || e);
      }
    })();
  }, []);

  const registerGitInfo = async () => {
    try {
      const serverRes = await Server.saveGitInfo();
      await viewDialog(
        'Github 情報連携-完了',
        '登録しました。ダッシュボードで確認してください。',
        ['OK'],
        300, 100);
      Link(LinkUrl.SCREEN(Screen.DASHBOARD, false));
    }catch(err) {
      viewDialog(
        'エラーが発生しました',
        err?.response?.data?.errorMessage,
        ['OK'],
        300, 100);
      return false;
    }
  };

  const cancel = () => {
    Link(LinkUrl.SCREEN(Screen.DASHBOARD), false);
  };

  if (!!error) {
    return (
      <View
        style={{
          flex: 1,
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <T>{error}</T>
      </View>
    );
  }

  if (!userInfo?.engineer || !gitInfo) {
    return (
      <Waiting/>
    )
  };

  const viewEngineer = JSON.parse(JSON.stringify(userInfo.engineer));
  viewEngineer.gitInfo = gitInfo;

  return (
    <Box
      style={{
        maxWidth: '90%',
        margin: 8,
        flexDirection: 'column',
        alignSelf: 'center',
      }}
      title="Github 情報掲載確認"
    >
      <T
        style={{
          fontSize: 16,
          marginLeft: 32,
          width: '100%',
        }}
      >
        {`以下の情報をエンジニア表示に加えます。
この情報はあなたのエンジニア情報に90日間表示されます。
90日経過後、自動的に消去します。
継続表示する場合や、再度Githubから情報を取得更新するときは、
再度Github連携をして、更新してください。`}
      </T>
      <View
        style={{
          marginVertical: 16,
          borderColor: '#888',
          borderBottomWidth: 2,
        }}
      >
        <Title
          label="Github" fontSize={16} marginHorizontal={0}
          help={`エンジニアのGithubリポジトリ情報。
自身で作成したリポジトリから、言語利用状況を集計します。`}
        />
        <GitInfo
          data={gitInfo}
        />
      </View>
      <View
        style={{
          flexDirection: 'row',
          flex: 1,
          alignContent: 'space-between',
          justifyContent: 'space-evenly',
        }}
      >
        <Button
          label="OK"
          onPress={() => registerGitInfo()}
        />
        <Button
          label="CANCEL"
          onPress={() => cancel()}
        />
      </View>
    </Box>
  );

}
