import React, { useState, useEffect, useContext, useCallback, } from 'react';
import { View } from 'react-native';
import * as Server from '../functions/data/Server';
import Waiting from '../components/Waiting';
import Box from '../../common/components/Box';
import T from '../components/T';
import { AppContext } from '../context/AppContext';
import ViewClient from './component/ViewClient';

/**
 * クライアント情報
 * @returns
 */
export default function Client(routeParam) {
  const { route } = routeParam;
  const { params } = route;
  const { clientAccountNo, client: paramClient } = params;
  const [client, setClient] = useState();
  const [error, setError] = useState();

  const { userInfo, isSp } = useContext(AppContext);

  const getClient = useCallback(async () => {
    try {
      const serverResult = await Server.getClientByAccountNo(clientAccountNo);
      setClient(serverResult);
    } catch (e) {
      setError(e?.response?.data?.errorMessage || e);
    }
  });

  useEffect(() => {
    if (!client && !!clientAccountNo) {
      // engineer 側あるいは urlダイレクト
      getClient();
    } else {
      // client Dashboard から
      setClient(paramClient);
    }
  }, [clientAccountNo, paramClient]);

  // client が自分であるとき、更新されたら更新する
  useEffect(() => {
    if (client?.accountNo === userInfo?.account?.accountNo) {
      setClient(userInfo.client);
    }
  }, [userInfo]);

  if (!!error) {
    return (
      <View
        style={{
          flex: 1,
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <T>{error}</T>
      </View>
    );
  }

  if (!client) {
    return (
      <Waiting/>
    )
  };

  return (
    <Box
      style={{
        maxWidth: '90%',
        minWidth: '60%',
        margin: 8,
        flexDirection: 'column',
        alignSelf: 'center',
      }}
    >
      <ViewClient
        client={client}
        isEditable
        reloadClientFunc={getClient}
      />
    </Box>
  );

}
