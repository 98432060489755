import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { AppContext } from '../../context/AppContext';
import Styles from '../../Styles';
import { Client } from '../../types/Types';
import SuggestItem from '../../../common/components/SuggestItem';
import Title from '../../components/Title';
import UserIcon from './UserIcon';
import {  isEmpty, optional } from '../../../common/util/TypeUtil';
import T from '../../components/T';
import IconText, { DIR } from '../../../common/components/IconText';
import Button from '../../../common/components/Button';
import ClipCopyText from '../../../common/functions/ClipCopyText';
import { toastSorry, toastShort } from '../../functions/Toast';
import Waiting from '../../components/Waiting';
import LocationLink from '../../../common/functions/LocationLink';
import ViewRecruitList from './ViewRecruitList';
import { Screen } from '../../Screen';
import uuid from '../../../common/util/Uuid';
import QrCodeImage from './QrCodeImage';
import { createClientUrl } from '../../functions/data/createUrl';
import { ICON } from '../../Definition';
import ShareCareerSheetButton from './ShareCareerSheetButton';
import { LinkUrl } from '../../LinkUrl';

/**
 * クライアント情報表示
 * @param client
 * @param isEditable 強制的に案件登録ボタン,案件修正ボタンを表示しない
 * @returns
 */
export default function ViewClient(props) {
  const { client, isEditable, reloadClientFunc } = props;

  const { userInfo, isSp, Link, } = useContext(AppContext);

  const [recruitList, setRecruitList] = useState();

  // 自分
  const isMe = client?.accountNo === userInfo?.account?.accountNo;

  useEffect(() => {
    // uuidを付加
    // 自分なら userInfo から、他社なら props から得る
    const srcRecruitList = /*isMe ? userInfo?.client?.recruitList :*/ client?.recruitList;
    if (!isEmpty(srcRecruitList)) {
      const _recruitList = srcRecruitList?.map((e) => {
        if (!e.uuid) {
          e.uuid = uuid();
        }
        return e;
      });
      setRecruitList(_recruitList);
    }
  }, [client, userInfo]);

  if (!client) {
    return <Waiting/>
  }

  // 会社アイコン
  const compIcon = (
    <UserIcon
      size={isSp ? Styles.USER_ICON_SIZE_SP : Styles.USER_ICON_SIZE}
      image={client.compIconUrl}
      nickName={client.compName}
    />
  );

  // 会社名
  const compName = !!client.companySiteUrl
    ? (
        <IconText
          dir={DIR.FONTAWESOME5}
          icon="external-link-alt"
          isIconLast
          iconSize={16}
          textStyle={{
            marginTop: 8,
            fontSize: isSp ? 16 : 24,
            fontWeight: 'bold',
            flexWrap: 'wrap',
            color: '#00f',
            textDecorationLine: 'underline',
            cursor: 'pointer',
          }}
          onPress={() => LocationLink(client.companySiteUrl, true)}
        >
          {`${client?.compName}`}
        </IconText>
    ) : (
      <T
        style={{
          marginTop: 8,
          fontSize: isSp ? 16 : 24,
          fontWeight: 'bold',
          flexWrap: 'wrap',
        }}
      >
        {`${client?.compName}` || ' '}
      </T>
    );

  // 会社概要部
  const compAdditionItems = (
    <>
      {/* 住所 */
      !!client.compPrefName && !!client.compCityName && !!client.compTown && (
        <View>
          <Button
            linkStyle
            dir={DIR.MATERIAL_COMMUNITY}
            iconColor="#f00"
            iconSize={16}
            icon="google-maps"
            label={`${client.compPrefName}${client.compCityName}${client.compTown || ''} ${client.compBuild || ''}`}
            onPress={() =>
              LocationLink(LinkUrl.MAP(client.compPrefName, client.compCityName, client.compTown), true)
            }
          />
        </View>
      )}
      <View
        style={{
          flexDirection: isSp ? 'column' : 'row',
        }}
      >
        <View
          style={{
            flexDirection: 'column',
          }}
        >
          {/* URL コピー */}
          <Button
            linkStyle
            help="クライアント情報に直リンクします。クリックするとクリップボードにコピーします。"
            dir={DIR.FONTAWESOME5}
            iconColor="#aaa"
            iconSize={12}
            icon="link"
            label="共有URLをコピー"
            onPress={() => {
              if (!!client.accountNo) {
                const shareUrl = createClientUrl(client.accountNo);
                ClipCopyText(shareUrl);
                toastShort(
                  'クリップボードにコピーしました',
                  shareUrl,
                  3000);
              } else {
                toastSorry('登録後に動作します', 3000);
              }
            }}
          />
          {/* クライアントタイプ */}
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            {optional(client.defItems?.clientCategories?.filter((e) => !!e.no))?.map((e) => (
              <SuggestItem
                key={`preview_clientCategories_${e.no}`}
                style={styles.clientCategories}
                bgColor={Styles.ITEM_BG_COLOR_CATEGORIES}
                textColor={Styles.ITEM_TEXT_COLOR_CATEGORIES}
                item={e}
              />
            ))}
          </View>
          <View
            style={{
              flex: 1,
              // flexDirection: isSp ? 'column' : 'row',
              flexWrap: 'wrap',
            }}
          >
            {/* 法人番号*/
            !!client.govCompanyNo && (
              <View>
                <Button
                  linkStyle
                  dir={DIR.FONTAWESOME5}
                  iconColor="#0a0"
                  iconSize={16}
                  icon="check-square"
                  label="国税庁-法人情報"
                  onPress={() =>
                    LocationLink(LinkUrl.HOUJIN_DB(client.govCompanyNo), true)
                  }
                />
              </View>
            )}
            {/* 自分がエンジニアの場合、自身の経歴書を現在の画面対象向けのみに経歴書共有URLを発行する */}
            <ShareCareerSheetButton targetAccountNo={client?.accountNo}/>
          </View>
        </View>
        {/** QR */}
        <View
          style={{
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            flex: 1,
            height: 'fitContent',
            paddingLeft: 24,
          }}
        >
          <QrCodeImage
            value={createClientUrl(client.accountNo)}
            logo={ICON}
            qrSize={128}
            logoSize={32}
            help="クライアント情報に直リンクします。クリックすると２次元コードをダウンロードします。"
          />
        </View>
      </View>
    </>
  );

  return (
    <View
      style={{
        marginTop: isSp ? 8 : 24,
        marginLeft: isSp ? 8 : 32,
        marginRight: 8,
        justifyContent: 'space-evenly',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        {/** 会社アイコン */}
        {compIcon}
        <View
          style={{
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            flex: 1,
            paddingLeft: isSp ? 8 : 32,
          }}
        >
          {/** 会社名 */}
          {compName}
          {/* 会社概要部 */}
          {compAdditionItems}
        </View>
      </View>
      <Title label="担当者" fontSize={16} marginHorizontal={0} />
      {/* 担当者アイコン 担当者名*/}
      <View
        style={{
          flexDirection: 'row',
          height: 'fit-content',
          marginBottom: 16,
        }}
      >
        <UserIcon
          size={Styles.USER_ICON_SIZE_SMALL}
          image={client.empIconUrl}
          nickName={client.empName}
        />
        <T
          style={{
            fontSize: 18,
            flexWrap: 'wrap',
            marginLeft: 8,
          }}
        >
          {`${client?.empName}` || ''}
        </T>
      </View>
      <Title label="案件" fontSize={16} marginHorizontal={0} />
      {/* 自社なら登録ボタン isEditable も考慮する */
      isEditable && isMe && (
        <Button
          label="案件を追加"
          onPress={() => Link(LinkUrl.SCREEN(Screen.EDIT_RECRUIT_ITEM))}
        />
      )}
      {/* 案件リスト */}
      <ViewRecruitList
        value={recruitList}
        isEditable={isEditable}
        reloadClientFunc={reloadClientFunc}
      />
    </View>
  );
}

ViewClient.propTypes = {
  client: Client.propTypes,
  isEditable: PropTypes.bool,
  reloadClientFunc: PropTypes.func,
};

ViewClient.defaultProps = {
  client: Client.defaultProps,
  isEditable: false,
  reloadClientFunc: () => {},
}

const styles = StyleSheet.create({
  engineerCategoryLabel: {
    fontWeight: 'bold',
    color: Styles.COLOR_MAIN_ACCENT_BG,
    marginLeft: 8,
    marginVertical: 2,
  },
  processesLabel: {
    fontWeight: 'bold',
    color: Styles.COLOR_MAIN_TEXT_STRONG,
    marginLeft: 8,
    marginVertical: 2,
  },
  skillLabel: {
    fontWeight: 'bold',
    color: '#000',
    backgroundColor: '#ccc',
    paddingHorizontal: 8,
    marginRight: 8,
    marginVertical: 2,
    borderRadius: 4,
  },
  fieldLabel: {
    fontSize: 14,
    lineHeight: 14,
    height: 20,
  },
  clientCategories: {
    fontSize: 14,
    lineHeight: 14,
    height: 20,
  },
});
