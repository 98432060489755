import React from 'react';
import {
  StyleSheet, View,
} from 'react-native';
import PropTypes from 'prop-types';
import Styles from '../../Styles';
import IconText, { DIR } from '../../../common/components/IconText';
import { label, Recruit, RecruitType } from '../../types/Types';
import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { separateYM } from '../../Definition';
import T from '../../components/T';
import { ifPresent, isEmpty } from '../../../common/util/TypeUtil';

const labelTermSt = (termSt) => {
  if (termSt === '000000') {
    return '決定次第～';
  }
  const {yyyy, mm} = separateYM(termSt);
  return `${yyyy}年${mm}月～`;
};

const labelTermEd = (termEd) => {
  if (termEd === '000000') {
    return '';
  }
  const {yyyy, mm} = separateYM(termEd);
  return `${yyyy}年${mm}月まで`;
};

const viewDefs = (list, viewSize, keyHeader, style) => {
  return ifPresent(list, (e) => e.map((v, index) => {
    const ret = [];
    if (!viewSize || viewSize > index) {
      ret.push(
        <T key={`${keyHeader}${v.no}`} style={style}>{v.label}</T>
      );
    } else if (index + 1 === e.length) { // viewSize ありの最後のループ
      if(viewSize < e.length) {
        ret.push(<T key={`${keyHeader}etc`}>...</T>);
      }
    }
    ret.push(<T key={`${keyHeader}margin_${index}`}> </T>);
    return (
      <View
        key={`${keyHeader}${v.no}_group`}
        style={{
          flexDirection: 'row',
          marginRight: 4,
        }}
      >
        {ret.map(r => r)}
      </View>
    );
  })) || <></>;
};


/**
 * 案件情報表示
 * @returns
 */
export default function ViewRecruitItem(props) {

  const { item, isExpand } = props;
  const { isSp } = useContext(AppContext);

  return (
    <>
      {/* 案件１行目　種別・名称・期間 */}
      <View
        style={{
          justifyContent: 'flex-start',
          flexDirection: isSp ? 'column' : 'row',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            marginLeft: 0,
          }}
        >
          <IconText
            dir={DIR.ANTDESIGN}
            icon={isExpand ? 'down' : 'right' }
            iconColor={Styles.COLOR_MAIN_ACCENT_BG}
            textStyle={{
              fontWeight: 'bold',
            }}
          >
            {!!item.hideDate && (
              <T
                style={{
                  marginRight: 16,
                  backgroundColor: '#888',
                  color: '#eee',
                  borderRadius: 8,
                  paddingHorizontal: 8,
                }}
              >
                非表示
              </T>
            )}
            <T>
              {label(RecruitType, item.recruitType)}　{item.title}
            </T>
          </IconText>
        </View>
        <T style={{fontWeight: 'bold', color: Styles.COLOR_MAIN_ACCENT_BG, marginLeft: 8}}>
          {`${labelTermSt(item.termSt)} ${labelTermEd(item.termEd)}`}
        </T>
      </View>
      {/* 要求、分野 */}
      <View
        style={{
          flexDirection: 'row',
          alignSelf: 'flex-start',
          flexWrap: 'wrap',
          marginLeft: 24,
        }}
      >
        {viewDefs(item.requests, null, 'keyRequests_', Styles.LABEL_REQUESTS)}
        {viewDefs(item.fields, null, 'keyFields_', Styles.LABEL_FIELDS)}
      </View>
      {/* 参画先情報 都道府県市区町村　最寄り駅*/
      (!!item.endStationName || !!item.endPrefName || !!item.endCompany) && (
        <View
          style={{
            marginTop: 8,
            marginLeft: 40,
            padding: 8,
            width: 'fit-content',
            borderWidth: 1,
            borderRadius: 8,
          }}
        >
          <T
            style={{
              position: 'absolute',
              top: -8,
              left: 8,
              backgroundColor: '#fff',
              borderRadius: 8,
              fontSize: 12,
              zIndex: 999,
            }}
          >
            参画先
          </T>
          <T>
            {!!item.endStationName
              ? `${item.endStationLineName || ''} ${item.endStationName || ''}`
              : !!item.endPrefName || !!item.endCityName
                ? `${item.endPrefName || ''} ${item.endCityName || ''}` : ''
            }
          </T>
          {!!item.endCompany && (
            <T
              style={{
                fontWeight: 'bold',
              }}
            >
              {item.endName}
            </T>
          )}
        </View>
      )}
      {/* 経歴３行目　報酬*/}
      <View
        style={{
          flexDirection: 'row',
          alignSelf: 'flex-start',
          alignItems: 'baseline',
          marginLeft: 24,
        }}
      >
        {!!item.rewardMonthMin && !!item.rewardMonthMax
        ? (
          <>
            <T style={styles.rewardBold}>{item.rewardMonthMin}</T>
            <T>万 ～ </T>
            <T style={styles.rewardBold}>{item.rewardMonthMax}</T>
            <T>万 (税込月額 {!!item.hoursForReward && `最低稼働 ${item.hoursForReward}h`})</T>
          </>
        ): !!item.rewardMonthMin || !!item.rewardMonthMax
        ? (
          <>
            <T style={styles.rewardBold}>{item.rewardMonthMin || item.rewardMonthMax}</T>
            <T>万(税込月額 {!!item.hoursForReward && `最低稼働 ${item.hoursForReward}h`})</T>
          </>
        ): !!item.rewardHourMin && !!item.rewardHourMax
        ? (
          <>
            <T style={styles.rewardBold}>{Number(item.rewardHourMin).toLocaleString()}</T>
            <T>円/h ～ </T>
            <T style={styles.rewardBold}>{Number(item.rewardHourMax).toLocaleString()}</T>
            <T>円/h (税込 {!!item.hoursForReward && `最低稼働 ${item.hoursForReward}h`})</T>
          </>
        ): !!item.rewardHourMin || !!item.rewardHourMax
        ? (
          <>
            <T style={styles.rewardBold}>{Number(item.rewardHourMin || item.rewardHourMax)?.toLocaleString()}</T>
            <T>円/h (税込 {!!item.hoursForReward && `最低稼働 ${item.hoursForReward}h`})</T>
          </>
        ): (
          <T style={styles.rewardBold}>報酬額要確認</T>
        )}
      </View>
      {/* 経歴４行目 wrap カテゴリ、スキル、工程、資格、チーム人数 */}
      <View
        style={{
          flexDirection: 'row',
          alignSelf: 'flex-start',
          flexWrap: 'wrap',
          marginLeft: 24,
        }}
      >
        {viewDefs(item.engineerCategories, null, 'keyEngineerCategories_', Styles.LABEL_ENGINEER_CATEGORIES)}
        {viewDefs(item.skills, null, 'keySkills_', Styles.LABEL_SKILLS)}
        {viewDefs(item.processes, null, 'keyProcesses_', Styles.LABEL_PROCESSES)}
        {viewDefs(item.examinations, null, 'keyExaminations_', Styles.LABEL_EXAMINATIONS)}
        {ifPresent(item.numPersonOfTeam, (v) => <T>{`(${v}人チーム)`}</T>) || <></>}
      </View>
      {isExpand && (
        <View
          style={{
            borderWidth: 1,
            borderRadius: 8,
            borderColor: '#ccc',
            backgroundColor: '#eee',
            padding: 8,
            marginLeft: 16,
          }}
        >
          <T>{item.description}</T>
        </View>
      )}
    </>
  );
}

ViewRecruitItem.propTypes = {
  item: Recruit.propTypes.isRequired,
  isExpand: PropTypes.bool.isRequired,
};

const styles = StyleSheet.create({
  box: {
    borderWidth: 2,
    borderColor: '#aaa',
    borderRadius: 10,
    margin: 8,
    padding: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 4,
      height: 6,
    },
    shadowOpacity: 0.4,
    elevation: 16,
  },
  rewardBold: {
    fontSize: 20,
    fontWeight: 'bold',
    color: Styles.COLOR_MAIN_ACCENT_BG,
  },
});
