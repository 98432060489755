import React from 'react';
import {
  View, ActivityIndicator,
} from 'react-native';
import Styles from '../Styles';

/**
 * 全画面待ちインジケータ
 * @param {*} props 部分のprops
 * @returns
 */
export default function Waiting() {
  return (
    <View style={Styles.MAIN_VIEW_FOR_WAITING}>
      <ActivityIndicator
        animating
        color={Styles.COLOR_WAITING_INDICATOR}
        size="large"
      />
    </View>
  );
}
