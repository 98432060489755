const termForEngineer = `FreelanceEngineer.net 利用規約
2023年1月20日制定

　この利用規約（以下，「本規約」といいます。）は，合同会社TechnoKuRo（以下，「当社」といいます。）がこのウェブサービス上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。

第1条（適用）

　本規約は，ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
本規約の規定が前条の個別規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。

第2条（利用登録）

本サービスにおいては，登録希望者が本規約に同意の上，当社の定める方法によって利用登録を申請し，当社がこの承認を登録希望者に通知することによって，利用登録が完了するものとします。
　当社は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。
・利用登録の申請に際して虚偽の事項を届け出た場合
・本規約に違反したことがある者からの申請である場合
・その他，当社が利用登録を相当でないと判断した場合

第3条（ユーザーIDおよびパスワードの管理）

　ユーザーは，自己の責任において，本サービスの認証情報を適切に管理するものとします。
　ユーザーは，いかなる場合にも，認証情報を第三者に譲渡または貸与し，もしくは第三者と共用することはできません。当社は，認証情報が登録情報と一致してログインされた場合には，登録しているユーザー自身による利用とみなします。
　認証情報が第三者によって使用されたことによって生じた損害は，当社に故意又は重大な過失がある場合を除き，当社は一切の責任を負わないものとします。

第4条（利用料金および支払方法）

　ユーザーは，本サービスの有料部分の対価として，当社が別途定め，本サービスに表示する利用料金を，当社が指定する方法により支払うものとします。
　ユーザーが利用料金の支払を遅滞した場合には，ユーザーは年14．6％の割合による遅延損害金を支払うものとします。

第5条（禁止事項）

　ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。

・法令または公序良俗に違反する行為
・犯罪行為に関連する行為
・当社，本サービスの他のユーザー，または第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
・当社のサービスの運営を妨害するおそれのある行為
・他のユーザーに関する個人情報等を収集または蓄積する行為
・不正アクセスをし，またはこれを試みる行為、及びこれらを目的とした調査行為
・他のユーザーに成りすます行為
・当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
・当社，本サービスの他のユーザーまたは第三者の知的財産権，肖像権，プライバシー，名誉その他の権利または利益を侵害する行為
・以下の表現を含み，または含むと当社が判断する内容を本サービス上に投稿し，または送信する行為
・過度に暴力的な表現
・露骨な性的表現
・人種，国籍，信条，性別，社会的身分，門地等による差別につながる表現
・自殺，自傷行為，薬物乱用を誘引または助長する表現
・その他反社会的な内容を含み他人に不快感を与える表現
・以下を目的とし，または目的とすると当社が判断する行為
・営業，宣伝，広告，勧誘，その他営利を目的とする行為（当社の認めたものを除きます。）
・性行為やわいせつな行為を目的とする行為
・面識のない異性との出会いや交際を目的とする行為
・他のユーザーに対する嫌がらせや誹謗中傷を目的とする行為
・当社，本サービスの他のユーザー，または第三者に不利益，損害または不快感を与えることを目的とする行為
・その他本サービスが予定している利用目的と異なる目的で本サービスを利用する行為
・宗教活動または宗教団体への勧誘行為
・その他，当社が不適切と判断する行為

第6条（本サービスの提供の停止等）

当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
・本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
・地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
・コンピュータまたは通信回線等が事故により停止した場合
・その他，当社が本サービスの提供が困難と判断した場合
当社は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。

第7条（著作権）

ユーザーは，自ら著作権等の必要な知的財産権を有するか，または必要な権利者の許諾を得た文章，画像や映像等の情報に関してのみ，本サービスを利用し，投稿ないしアップロードすることができるものとします。
ユーザーが本サービスを利用して投稿ないしアップロードした文章，画像，映像等の著作権については，当該ユーザーその他既存の権利者に留保されるものとします。ただし，当社は，本サービスを利用して投稿ないしアップロードされた文章，画像，映像等について，本サービスの改良，品質の向上，または不備の是正等ならびに本サービスの周知宣伝等に必要な範囲で利用できるものとし，ユーザーは，この利用に関して，著作者人格権を行使しないものとします。
前項本文の定めるものを除き，本サービスおよび本サービスに関連する一切の情報についての著作権およびその他の知的財産権はすべて当社または当社にその利用を許諾した権利者に帰属し，ユーザーは無断で複製，譲渡，貸与，翻訳，改変，転載，公衆送信（送信可能化を含みます。），伝送，配布，出版，営業使用等をしてはならないものとします。

第8条（利用制限および登録抹消）

当社は，ユーザーが以下のいずれかに該当する場合には，事前の通知なく，投稿データを削除し，ユーザーに対して本サービスの全部もしくは一部の利用を制限しまたはユーザーとしての登録を抹消し、登録抹消した事実及び理由を公開することができるものとします。
・本規約のいずれかの条項に違反した場合
・登録事項に虚偽の事実があることが判明した場合
・決済手段として当該ユーザーが届け出たクレジットカードが利用停止となった場合
・料金等の支払債務の不履行があった場合
・当社からの連絡に対し，一定期間返答がない場合
・本サービスについて，最終の利用から一定期間利用がない場合
・その他，当社が本サービスの利用を適当でないと判断した場合
・前項各号のいずれかに該当した場合，ユーザーは当然に当社に対する一切の債務について期限の利益を失い，その時点において負担する一切の債務を直ちに一括して弁済しなければなりません。
当社は，本条に基づき当社が行った行為によりユーザーに生じた損害について，一切の責任を負いません。

第9条（退会）

ユーザーは，当社の定める退会手続により，本サービスから退会できるものとします。

第10条（保証の否認および免責事項）

当社は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
当社は，本サービスに起因してユーザーに生じたあらゆる損害について、当社の故意又は重過失による場合を除き、一切の責任を負いません。ただし，本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。
前項ただし書に定める場合であっても，当社は，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。また，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は，ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。
当社は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。

第11条（サービス内容の変更等）

当社は，ユーザーへの事前の告知をもって、本サービスの内容を変更、追加または廃止することがあり、ユーザーはこれを承諾するものとします。

第12条（利用規約の変更）

当社は以下の場合には、ユーザーの個別の同意を要せず、本規約を変更することができるものとします。
・本規約の変更がユーザーの一般の利益に適合するとき。
・本規約の変更が本サービス利用契約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性その他の変更に係る事情に照らして合理的なものであるとき。
当社はユーザーに対し、前項による本規約の変更にあたり、事前に、本規約を変更する旨及び変更後の本規約の内容並びにその効力発生時期を通知します。

第13条（個人情報の取扱い）

当社は，本サービスの利用によって取得する個人情報については，当社「プライバシーポリシー」に従い適切に取り扱うものとします。

第14条（通知または連絡）

ユーザーと当社との間の通知または連絡は，当社の定める方法によって行うものとします。当社は,ユーザーから,当社が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。

第15条（権利義務の譲渡の禁止）

ユーザーは，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。

第16条（準拠法・裁判管轄）

本規約の解釈にあたっては，日本法を準拠法とします。
本サービスに関して紛争が生じた場合には，当社の本店所在地を管轄する裁判所を専属的合意管轄とします。
以上
`;

/*****************************************************************************************************************************************************************/

const privacyPolicy = `FreelanceEngineer.net プライバシーポリシー
2023年1月20日制定

第1条（個人情報）
　「個人情報」とは、個人情報の保護に関する法律（平成15年法律第57号、以下「個人情報保護法」といいます。）にいう「個人情報」を指し、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別できるもの又は個人識別符号が含まれるものを指します。

第2条（個人情報の取得と利用）
当社は、以下の目的に必要な範囲で、ご本人の個⼈情報を取得し、取得した情報を利用させていただきます。以下の⽬的の範囲を超えて個⼈情報を利⽤する場合には、事前に適切な⽅法でご本人からの同意を得るものとします。
　（1）当社のサービス（以下「本サービス」といいます。）を継続的に提供・運営及び有料サービスの利用料金の請求をするため
　（2）本サービスの内容を改良・改善し、又は新サービスを開発するため
　（3）本サービスの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスのご案内（電子メール、チラシ、その他のダイレクトメールの送付を含む）のため
　（4）メンテナンス、重要なお知らせなど必要に応じたご連絡のため
　（5）本サービスに関する利用者からのお問い合わせに回答するため（本人確認を行うことを含む）
　（6）本サービスの利用状況を利用者にご報告するため
　（7）本サービスに関するアンケート・取材等のご協力依頼や各種イベントへのご参加をお願いし、又はその結果などをご報告するため
　（8）本サービスの利用履歴等を調査・分析し、その結果を本サービスの改良・開発や広告の配信に利用するため
　（9）利用規約に違反した利用者や、不正・不当な目的で本サービスを利用しようとする利用者の特定をし、ご利用をお断りするため

第3条（個人情報の管理と保護）
個人情報の管理は、厳重に行うこととし、次に掲げる場合を除き、ご本人の同意がない限り、第三者に対しデータを開示・提供することはいたしません。 また、安全性を考慮し、個人情報への不正アクセス、個人情報の紛失、破壊、改ざん及び漏えい等のリスクに対する予防並びに是正に関する対策を講じます。
　（1）本サービスの機能としてご本人の個人情報を含む情報をご本人が指示する提供先に提供する場合であって、当社所定の方法によりご本人の同意を確認した場合
　（2）人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合
　（3）公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難である場合
　（4）国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
　（5）業務を円滑に遂行するため、利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合
　（6）合併その他の事由による事業の承継に伴って個人情報が提供される場合
　（7）その他法令で認められる場合
` +
/*
第4条（共同利用）
当社は、ユーザーの個人データを以下のとおり当社のグループ企業と共同して利用することがあります。
1 共同して利用される個人データの項目
氏名、住所、電話番号、年齢、商品購入履歴
2 共同して利用するもの範囲
○○株式会社及びその子会社・関連会社
3 利用する者の利用目的
当社のグループ企業の商品・サービスをお知らせするため
4 当該個人データの管理について責任を有する者の氏名又は名称
○○株式会社
*/

`第4条（個人情報の取扱いの委託）
　当社は、利用目的の達成に必要な範囲内において、個人情報の取扱いの全部又は一部を委託する場合がございます。この場合、当社は、委託先としての適格性を十分審査するとともに、契約にあたって守秘義務に関する事項等を定め、委託先に対する必要かつ適切な監督を行います。

第5条（個人情報の開示）
　当社は、ユーザーから、個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で、ユーザーに対し、遅滞なく開示を行います（当該個人情報が存在しない時はその旨を通知いたします。）。ただし、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、１件あたり1,100円（税込み）の手数料を頂戴しておりますので、あらかじめご了承ください。

第6条（個人情報の訂正及び利用停止等）
　1 当社は、ユーザーから、個人情報が真実でないという理由によって、個人情報保護法の定めに基づきその内容の訂正を求められた場合には、他の法令の規定により特別の手続が定められている場合を除き、ユーザーかご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正又は利用停止を行い、その旨をユーザーに通知します。
　2 当社は、ユーザーから、個人情報が、予め公表された利用目的の範囲を超えて取り扱われているという理由、又は不正の手段により取得されたものであるという理由により、その利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨ご本人に通知します。ただし、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ご本人の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。

第7条（プライバシーポリシーの変更手続）
　当社は本ポリシーの内容を適宜見直し、その改善に努めます。本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、変更することができるものとします。変更後のプライバシーポリシーは、当社所定の方法により、利用者に通知し、又は当社ウェブサイトに掲載したときから効力を生じるものとします。

第8条（お問い合わせ窓口）
　ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、下記の窓口までお願いいたします。

　住所：埼玉県熊谷市石原112-4
合同会社TechnoKuRo
個人情報取扱責任者：黒岩　高弘
連絡先：hiro@techno-kuro.com
`;

/*****************************************************************************************************************************************************************/

const cookiePolicy = `Cookie ポリシー
2023年1月20日制定

合同会社TechnoKuRo（以下、「当社」といいます。）は、お客様のウェブサイト利用状況を分析し、または個々のお客様に対してカスタマイズされたサービス・広告を提供する等の目的のため、Cookieを使用して一定の情報を収集します。

Cookieについて

　Cookieとはお客様のウェブサイト閲覧情報を、そのお客様のコンピューター（PCやスマートフォン、タブレットなどインターネット接続可能な機器）に記憶させる機能のことです。
　Cookieには、当社によって設定されるもの（ファーストパーティークッキー）と、当社と提携する第三者によって設定されるもの（サードパーティークッキー）があります。

Cookieの利用目的

 (1) 当社では、Cookieを、お客様がウェブサイトを閲覧する際に同じ情報を繰り返し入力することがなくなるなど、お客様の利便性向上のために使用しています。
 (2) 当社では、Cookieを使用して収集した情報を利用して、お客様のウェブサイトの利用状況（アクセス状況、トラフィック、ルーティング等）を分析し、ウェブサイト自体のパフォーマンス改善や、当社からお客様に提供するサービスの向上、改善のために使用することがあります。
 (3) また、この分析にあたっては、主に以下のツールが利用され、ツール提供者に情報提供されることがあります。

　　・Google Analytics

 (4) この他、Cookieは、提携する広告配信サービス提供会社における行動ターゲティング広告の配信に使用される場合があります。

Cookieの拒否方法

　お客様がブラウザの設定を変更することによりCookieを無効にすることが可能です。ただし、Cookieを無効にした場合は、一部のサービスが受けられない場合があります。
Cookieの設定の変更方法については、各ブラウザの製造元へご確認ください。

特定のCookieを拒否する方法
　広告配信サービス提供会社への情報提供を停止するための方法は各社へお問い合わせください。以下に記載する提携先の主な広告配信サービス提供会社各社のURLからでもご確認いただけます。
　　・Google
　　・Meta
　　・Twitter
　　・LINE
以上
`;

const registerCautionEngineer = `
あなた自身の企業・取引先との秘密保持に関する契約等で、口外してはならない機密情報が登録内容に含まれていませんか？
このサービスはあなたの登録内容を、あなた自身の設定に基づいた範囲のユーザーに閲覧できる機能があります。
当社ではあなた自身が取り交わした契約に対して責任を負いません。
あなた自身が取り交わした契約に十分に留意のうえ登録してください。
`;

const registerCautionClient = `
あなたや組織・取引先との秘密保持に関する契約等で、口外してはならない機密情報が登録内容に含まれていませんか？
このサービスはあなたの登録内容を、あなた自身の設定に基づいた範囲のユーザーに閲覧できる機能があります。
当社ではあなた自身が取り交わした契約に対して責任を負いません。
あなたや組織が取り交わした契約に十分に留意のうえ登録してください。
`;

module.exports = {
  termForEngineer,
  privacyPolicy,
  cookiePolicy,
  registerCautionEngineer,
  registerCautionClient,
};
