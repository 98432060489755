import React, { useCallback, useEffect, useState } from 'react';
import { createQrCode } from '../../functions/data/createQrCode';
import ClipCopyText from '../../../common/functions/ClipCopyText';
import Waiting from '../../components/Waiting';
import ImageButton from '../../../common/components/ImageButton';
import { toastSuccess } from '../../functions/Toast';
import { download } from '../../../common/functions/Download';

const OTHERS_THREASHOLD = 0.05;

/**
 * 画像でQRCodeを表示する
 * @param {*} props
 * @returns ボタン
 */
export default function QrCodeImage(props) {
  const {
    value,
    logo,
    qrSize,
    logoSize,
    help,
  } = props;

  const [qrCanvas, setQrVanvas] = useState();

  useEffect(() => {
    createQrCode(value, logo, qrSize, logoSize, setQrVanvas);
  }, []);

  if (!qrCanvas) {
    return (<Waiting/>);
  }

  return (
    <ImageButton
      onPress={() => {
        download(
          qrCanvas.toDataURL(),
          'QR_FreelanceEngineer.png',
        );
      }}
      image={qrCanvas.toDataURL()}
      imageStyle={{
        width: qrSize,
        height: qrSize,
      }}
      buttonStyle={{
        width: qrSize,
        height: qrSize,
      }}
      help={help}
    />
  );
}
