import React, { createContext, useState, useEffect, useMemo, useCallback, useRef } from 'react';
import {
  SafeAreaView, View, KeyboardAvoidingView, Dimensions, ScrollView,
} from 'react-native';
//import { WebView } from 'react-native-webview';
import PropTypes from 'prop-types';
import Drawer from 'react-native-drawer';
import { createMenuPerUser } from './MenuComponent';
import {GOOGLE_OAUTH_CLIENTID_WEB, isDev} from '../../env';
import { Button } from '@react-native-material/core';
import {  googleAuthForWebGsi } from '../../common/functions/auth/GoogleAuthForWeb';
import Styles from '../Styles';
import * as LocalStorage from '../../common/functions/LocalStorage';
import * as Server from '../functions/data/Server';
import LocationLink from '../../common/functions/LocationLink';
import { Screen } from '../Screen';
import T from '../components/T';
import useDialog from '../hooks/useDialog';
import Terms from '../screen/Terms';
import Url from '../../common/functions/Url';
import Waiting from '../components/Waiting';
import Header from './Header';
import { MenuOption } from 'react-native-popup-menu';
import { LinkUrl } from '../LinkUrl';
import { debug, error } from '../../common/util/Log';
import IconText, { DIR } from '../../common/components/IconText';


// 認証前にアクセスしてきたURLとパラメタをローカルセッションに格納
export const SAVE_PARAM_KEY = 'saveParam';

export const AppContext = createContext();

export function AppContextProvider({ children }) {
  const [webViewUrl, setWebViewUrl] = useState();

  /** ダイアログ */
  const {component: componentDialog, view: viewDialog} = useDialog();

  const [dimensions, setDimensions] = useState({
    window: Dimensions.get('window'),
    screen: Dimensions.get('screen'),
  });
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [isInitialized, setIsInitialized] = useState(false);

  const [url, setUrl] = useState();

  const [navigation, setNavigation] = useState();

  const [confirmBeforeLink, setConfirmBeforeLink] = useState();

  const [reloadSwitch, setReloadSwitch] = useState(false);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const reload = useCallback(() => {
    setReloadSwitch(!reloadSwitch);
  });

  // アプリ内のすべての画面遷移を受け付ける
  // navigationが取れていれば、あるいは受け取っていれば navigation する
  // そうでなければ、location で遷移する
  // delConfirm confirmBeforeLink を削除してリンクする
  const Link = useCallback(async(screen, isNewWindow, delConfirm) => {
    // navigation がなければエラー
    if (!navigation) {
      throw `navigation is null`;
    }

    // 移動する前に確認するか
    if (delConfirm) {
      setConfirmBeforeLink();
    } else if (!!confirmBeforeLink) {
      const result = await viewDialog(
        '確認!!', confirmBeforeLink, ['OK', 'キャンセル']
      );
      if (result.selected !== 'OK') {
        return;
      }
      setConfirmBeforeLink(); // 一度使ったら解除する
    }
    // DASHBOARD 宛は、常に LocationLink
    if (!screen || screen === Screen.DASHBOARD) {
      LocationLink(LinkUrl.TOP);
      return;
    }
    // http 指定があれば LocationLink
    if (screen?.startsWith('http') || isNewWindow) {
      LocationLink(LinkUrl.SCREEN(screen), isNewWindow);
      return;
    }

    // navigationで画面を塗り替え
    // screen が　Screen指定のとき
    let nextScreen = Object.values(Screen).find(_s => screen == _s) || null;
    const params = {};

    // nextScreen が Screen指定でないとき
    if (!nextScreen) {
      const trimed = screen.replace('/', '').replace('?', '');
      trimed.split('&')?.forEach((param) => {
        const [k, v] = param.split('=');
        if (!!k) {
          if (k === 's') {
            nextScreen = v;
          } else {
            params[k] = v;
          }
        }
      });
    }
    navigation.reset({
      index: 0,
      routes: [{
        name: nextScreen,
        params,
      }],
    });
  }, [navigation, confirmBeforeLink]);

  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    (async () => {
      try {
        const _userInfo = await Server.getUserInfo(true) || {};
        _userInfo.isDone = true;
        LocalStorage.saveSession(Server.LOCAL_KEY_USERINFO, JSON.stringify(_userInfo));
        setUserInfo(_userInfo);
      } catch(err) {
        error(`err getUserInfo err ${err.stack}`);

        if (err != Server.UNAUTH) {
          viewDialog('ログインが必要です')
          LocationLink(LinkUrl.LP, false);
        }
      }
      const url = await Url();
      setUrl(url);

      setIsInitialized(true);
    })();
    const subscribe = Dimensions.addEventListener(
      'change',
      ({ window, screen }) => setDimensions({ window, screen })
    );
    return () => {
      subscribe?.remove();
    };
  }, []);

  const googleLogin = useCallback((callback) => {
    /** 新しいツールチップでのログイン 失敗したら、ふるいものでログイン */
    // googleAuthForWeb(
    //   GOOGLE_OAUTH_CLIENTID_WEB,
    //   async (response) => {
    //     Log.debug(googleLogin.name, AppContextProvider.name)
    //     const googleOauth = await Server.getUserInfoByGoogleCredentialResponse(response);
    //     /***以下新旧共通 */
    //     // いったんトークンをローカル保存して、getUserInfoを呼ぶ
    //     LocalStorage.saveSession(Server.LOCAL_KEY_USERINFO, JSON.stringify(googleOauth));
    //     const _userInfo = await getUserInfo(true);
    //     // 上記でユーザ情報全件を取得
    //     LocalStorage.saveSession(Server.LOCAL_KEY_USERINFO, JSON.stringify(_userInfo));
    //     if (_userInfo?.engineer?.nickName) {
    //       Link(`?s=${Screen.DASHBOARD}`);
    //     } else {
    //       Link(`?s=${Screen.REGISTER_ACCOUNT}`);
    //     }
    //   },
    // );
    /*********************************/
    //　失敗したら 2023/3 でサポート終了のもの
    googleAuthForWebGsi(
      GOOGLE_OAUTH_CLIENTID_WEB,
      async (response) => {
        const token = response.access_token;
        const googleOauth = await Server.getUserInfoByGoogleTokenGsi(token);
        /***以下新旧共通 */
        // いったんトークンをローカル保存して、getUserInfoを呼ぶ
        const newUserInfo = JSON.stringify(googleOauth);
        LocalStorage.saveSession(Server.LOCAL_KEY_USERINFO, newUserInfo);
        if (callback) {
          setUserInfo(newUserInfo);
          callback();
        } else {
          Link(Screen.DASHBOARD);
        }
      },
    );
  }, [Link]);

  // userInfoが変更されたときだけ contextValue が更新されるようにして、再レンダリングを抑える
  const contextValue = useMemo(() => ({
    userInfo,
    viewDialog,
    url,
    setConfirmBeforeLink,
    reload,
    setIsFullScreen,
    isDev,
    viewDialog,
  }), [userInfo, url]);

  const drawerRef = useRef();

  const openDrawer = () => drawerRef?.current?.open();
  const closeDrawer = () => drawerRef?.current?.close();

  if (!isInitialized) {
    return <Waiting/>;
  }

  const isSp = dimensions.window.width < Styles.SP_WIDTH_THRESHOLD;

  return (
    <AppContext.Provider
      value={{
        userInfo: contextValue.userInfo,
        googleLogin,
        dimensions,
        isSp,
        mainHeight: dimensions.window.height - (isSp ? Styles.HEADER_HEIGHT_SP : Styles.HEADER_HEIGHT),
        reload: contextValue.reload,
        isFullScreen,
        setIsFullScreen: contextValue.setIsFullScreen,
        isDev: contextValue.isDev,
        viewDialog: contextValue.viewDialog,
        url: contextValue.url,
        Link,
        LocationLink,
        setConfirmBeforeLink,
        setNavigation,
        isOpenDrawer,
      }}
    >
      <SafeAreaView style={[
        Styles.TOP_CONTAINER,
        Styles.FONT,
      ]}>
        {componentDialog}
        {isFullScreen
        ?
          children
        : (
          <>
            <Header
              openDrawer={openDrawer}
              closeDrawer={closeDrawer}
            />
            <Drawer
              ref={(ref) => drawerRef.current = ref}
              side="left"
              content={
                <ScrollView>
                  {createMenuPerUser(userInfo, viewDialog, reload)?.map((menu) => (
                    <View key={menu.key}>
                      <View
                        style={{
                          borderRadius: 16,
                          backgroundColor: Styles.COLOR_MAIN_ACCENT_BG,
                          marginVertical: isSp ? 4 : 16,
                        }}
                      >
                        <MenuOption
                          onSelect={() => menu.func()}
                        >
                          <T style={{color: '#fff', fontSize: isSp ? 12 : 14,}}>{menu.label}</T>
                        </MenuOption>
                      </View>
                    </View>
                  ))}
                </ScrollView>
              }
              openDrawerOffset={dimensions.window.width * (isSp ? 0.3 : 0.6) }
              tapToClose
              styles={
                {
                  drawer: {
                    shadowColor: '#000000',
                    shadowOpacity: 0.8,
                    shadowRadius: 3,
                    width: 100,
                  },
                  main: {
                    paddingLeft: 3
                  },
                }
              }
              onOpen={() => setIsOpenDrawer(true)}
              onClose={() => setIsOpenDrawer(false)}
            >
              <KeyboardAvoidingView
                behavior="height"
                style={[
                  Styles.MAIN_VIEW,
                ]}
              >
                {children}
              </KeyboardAvoidingView>
            </Drawer>
            {/* footer */}
            <View
              style={{
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-evenly',
                marginVertical: 0,
                paddingVertical: 0,
                backgroundColor: '#fff',
              }}
            >
              <Button
                title="ご意見/ご要望"
                compact
                variant="text"
                onPress={() => LocationLink('https://forms.gle/QZeZy3JYak2tZR6S6', true)}
                contentContainerStyle={{
                  height: 24,
                }}
              />
              <Button
                title="サービス利用規約・ポリシー"
                compact
                variant="text"
                onPress={() =>
                  viewDialog(
                    '',
                    <Terms />,
                    ['OK'],
                    '90%', '90%')
                  // Link('/?s=terms', true)
                }
                contentContainerStyle={{
                  height: 24,
                }}
              />
            </View>
            <View
              style={{
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-evenly',
                marginVertical: 0,
                paddingVertical: 0,
                backgroundColor: '#fff',
              }}
            >
              <Button
                title="freelance-engineer.net"
                compact
                variant="text"
                onPress={() => Link(LinkUrl.LP, false)}
                contentContainerStyle={{
                  height: 24,
                }}
              />
              <T
                style={{
                  fontSize: 12,
                  textAlign: 'center',
                  alignSelf: 'center',
                }}
              >
                &copy; 2023 TechnoKuRo LLC.
              </T>
            </View>
          </>
        )}
      </SafeAreaView>
    </AppContext.Provider>
  );
}

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
