import { Linking } from 'react-native';

/**
 * URL情報を取得
 * @returns URL情報JSON
 */
export default async function getUrlParam(inputUrl) {
  // URL を得る
  const url = inputUrl || await Linking.getInitialURL();
  if (!url) {
    return null;
  }

  // エンコード置換
  // %2F /
  // %3F ?
  // %3D =
  // %26 &
  const decoded = `${url}`
    .replace(/%2F/g, '/')
    .replace(/%3F/g, '?')
    .replace(/%3D/g, '=')
    .replace(/%26/g, '&');

  const path = decoded.split('//')[1].split('/');

  const splitedUrl = decoded.split('?');
  if (splitedUrl.length < 2) {
    return {
      decoded,
      splitedUrl,
      path,
    };
  }
  const splitedParam = splitedUrl[1].split('=');
  if (splitedParam.length < 2) {
    return {
      url,
      decoded,
      splitedUrl,
      splitedParam,
      path,
    };
  }

  const params = {};
  splitedUrl[1].split('&').forEach((param) => {
    const splitedParam2 = param.split('=');
    if (splitedParam2.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      params[splitedParam2[0]] = splitedParam2.length > 1 ? splitedParam2[1] : true;
    }
  });
  return {
    url,
    decoded,
    splitedUrl,
    splitedParam,
    params,
    path,
  };
}
