import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import {
  View, StyleSheet,
} from 'react-native';
import { TextInput } from '@react-native-material/core';
import PropTypes from 'prop-types';
import IconText from '../../common/components/IconText';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import Validator from './Validator';
import Styles from '../Styles';
import T from './T';

/** inputAccessoryItems:[
 *    { label: 表示文字（左から順番）, onPress: 押したときの処理、なければ文字表示のみ}
 *  ]
 */
export default function TitledInput(props) {
  const {
    dir, icon, title, placeholder, placeholderTextColor, onChangeText, iconSize, style,
    iconColor, onBlur, value, keyboardType, autoCapitalize, multiline, width, height,
    maxLength, inputRef, validator, secureTextEntry, disabled, help, subIcons,
    error, delayFunc,
  } = props;

  const [_error, _setError] = useState(error);

  const validate = (v) => {
    const message = validator?.execute(v);
    _setError(message);
    return !message || message?.length < 1;
  };

  useEffect(() => {
    _setError(error);
  }, [error]);

  useEffect(() => {
    if (!disabled) {
      validate(value);
    }
  }, [value]);

  const textInputRef = useRef();
  useImperativeHandle(inputRef, () => ({
    focus: () => textInputRef?.current?.focus(),
    validate: () => validate(value),
    setError: (message) => _setError(!!error ? [error, message].join("\n") : message),
  }));

  // setTimeout の返却値をセット delayFunc は 入力後 500ミリ秒後に実行する
  // 入力でタイマにセット、実行前に再度入力されたら、タイマを更新して
  // 500ミリ秒の未入力時間があったら実行される
  const timerForDelayFunc = useRef(null);

  return (
    <View
      style={[
        styles.container,
        style,
      ]}
      key="TitledInput_top"
    >
      { (!!title || !!help || !!icon || !!subIcons) && (
        <IconText
          containerStyle={styles.titleContainer}
          textStyle={styles.titleText}
          dir={dir}
          icon={icon}
          iconSize={iconSize}
          iconColor={iconColor}
          help={help}
          subIcons={subIcons}
        >
          {title}
        </IconText>
      ) }
      {/* <TextInput
        key="TitledInput"
        ref={textInputRef}
        style={[
          styles.textInput,
          disabled ? styles.disabledColorTextInput : styles.normalColorTextInput,
          { height: multiline ? 128 : 48 },
          { textAlignVertical: multiline ? 'top' : 'center' },
        ]}
        placeholderTextColor={placeholderTextColor}
        placeholder={placeholder || `${title}を入力`}
        autoCapitalize={autoCapitalize}
        keyboardType={keyboardType}
        onChangeText={(t) => {
          if (!disabled) {
            setResult(t);
            onChangeText(t);
            validate(t);
          }
        }}
        onBlur={() => {
          if (!disabled) {
            validate(result);
            onBlur();
          }
        }}
        value={result}
        multiline={multiline}
        secureTextEntry={secureTextEntry}
        maxLength={maxLength}
        editable={!disabled}
      /> */}
      <TextInput
        key="TitledInput"
        ref={textInputRef}
        style={[
          {
            // marginBottom: 12,
            minHeight: multiline ? 120 : 48,
            textAlignVertical: multiline ? 'top' : 'center',
          },
          Styles.FONT,
          !!width ? {width: width} : {},
        ]}
        inputStyle={[
          !!disabled && { backgroundColor: Styles.COLOR_BG_DISABLED},
          { fontSize: 14 },
          multiline ? { paddingTop: 16 } : null,
          !!width ? {width: width} : {width: '100%'},
          !!(_error?.length > 0)
            ? {
              backgroundColor: 'yellow',
              color: 'red',
              fontWeight: 'bold',
            } : {},
          {
            height: !!height ? height :
              !!multiline
                ? (() => {
                  const lines = value?.split(/\r\n|\r|\n/) || 1;
                  if (lines.length > 6) {
                    const ret = lines.length * 18;
                    if (ret > 500) return 500;
                    return ret;
                  }
                  return 120;
                })()
                : null,
          }
        ]}
        leadingContainerStyle={{ borderColor: '#f00', borderWidth: 1}}
        trailingContainerStyle={{ borderColor: '#f00', borderWidth: 1 }}
        variant="outlined"
        label={placeholder || `${title}を入力`}
        autoCapitalize={autoCapitalize}
        keyboardType={keyboardType}
        onChangeText={(t) => {
          if (!disabled) {
            onChangeText(t);
          }
          if (!!delayFunc) {
            clearTimeout(timerForDelayFunc.current);
            timerForDelayFunc.current = setTimeout(
              () => delayFunc(t)
            , 300);
          }
        }}
        onBlur={(t) => {
          if (!disabled) {
            onBlur(t);
          }
        }}
        value={`${value || ''}`}
        multiline={multiline}
        secureTextEntry={secureTextEntry}
        maxLength={maxLength}
        editable={!disabled}
        numberOfLines={multiline ? 5 : 1}
      />
      { _error?.length > 0 && (
        <View
          style={{
            flex: 1,
            alignItems: 'flex-end',
          }}
        >
          <T style={styles.error} multiline>{_error}</T>
        </View>
      )}
    </View>
  );
}

TitledInput.propTypes = {
  dir: PropTypes.any,
  icon: PropTypes.string,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderTextColor: PropTypes.string,
  onChangeText: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  keyboardType: PropTypes.string,
  autoCapitalize: PropTypes.string,
  multiline: PropTypes.bool,
  width: PropTypes.number,
  inputRef: PropTypes.any,
  validator: PropTypes.instanceOf(Validator),
  secureTextEntry: PropTypes.bool,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  help: IconText.propTypes.help,
  subIcons: IconText.propTypes.subIcons,
  style: PropTypes.any,
  error: PropTypes.string,
  delayFunc: PropTypes.func,
};

TitledInput.defaultProps = {
  dir: null,
  icon: null,
  title: '',
  placeholder: '',
  placeholderTextColor: '#ccc',
  value: '',
  keyboardType: 'default',
  autoCapitalize: 'none',
  multiline: false,
  width: null,
  inputRef: null,
  validator: null,
  secureTextEntry: false,
  onBlur: () => {},
  maxLength: null,
  disabled: false,
  iconSize: 24,
  iconColor: null,
  help: null,
  subIcons: null,
  style: null,
  error: null,
  delayFunc: () => {},
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    marginTop: 8,
    marginRight: 8,
    paddingLeft: 16,
  },
  titleContainer: {
    marginBottom: 8,
  },
  titleText: {
    fontSize: 16,
    marginRight: 32,
  },
  textInput: {
    marginRight: 8,
    height: 48,
    fontSize: 16,
    marginBottom: 8,
    paddingHorizontal: 8,
  },
  normalColorTextInput: {
    backgroundColor: '#fff',
    borderColor: '#333',
    borderWidth: 1,
  },
  disabledColorTextInput: {
    backgroundColor: '#ccc',
    color: '#111',
    borderColor: '#ddd',
  },
  error: {
    fontSize: 16,
    marginLeft: 16,
    color: '#f00',
    fontWeight: 'bold',
  },
});
