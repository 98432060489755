import React, { useContext } from 'react';
import LocationLink from '../../common/functions/LocationLink';
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from 'react-native-popup-menu';
import Styles from '../Styles';
import { Screen } from '../Screen';
import { AppContext } from './AppContext';
import T from '../components/T';
import Waiting from '../components/Waiting';
import { googleAnalyticsTracking } from '../../common/functions/google/GoogleAnalytics';
import UserIcon from '../screen/component/UserIcon';
import * as Server from '../functions/data/Server'
import { LinkUrl } from '../LinkUrl';

export function createMenuPerUser(userInfo, viewDialog, reload) {
  // メニュー定義 AppContext のメソッドが必要なのでここで定義する
  const MENUS = {
    DASHBOARD: {
      key: Screen.DASHBOARD,
      label: "ダッシュボード",
      func: () => {
        googleAnalyticsTracking('click', 'menu', Screen.DASHBOARD);
        LocationLink(LinkUrl.TOP, false);
      },
    },
    EDIT_ACCOUNT: {
      key: Screen.EDIT_ACCOUNT,
      label: "アカウント編集",
      func: () => {
        googleAnalyticsTracking('click', 'menu', Screen.EDIT_ACCOUNT);
        LocationLink(LinkUrl.SCREEN(Screen.EDIT_ACCOUNT), false);
      },
    },
    GIT_INFO_GET: {
      key: 'startGithubInfo',
      label: "Github 情報連携",
      func: () => {
        googleAnalyticsTracking('click', 'menu', 'startGithubInfo');
        Server.gitOauthStart();
      },
    },
    GIT_INFO_UPDATE: {
      key: 'updateGithubInfo',
      label: "Github 情報更新",
      func: () => {
        googleAnalyticsTracking('click', 'menu', 'updateGithubInfo');
        Server.gitOauthStart();
      },
    },
    GIT_INFO_DEL: {
      key: 'startGithubInfo',
      label: "Github 情報連携解除",
      func: async () => {
        googleAnalyticsTracking('click', 'menu', 'deleteGithubInfo');
        const answer = await viewDialog(
          'Github 情報連携解除',
          '掲載されているあなたの Github 情報を削除します。',
          ['削除する', 'CANCEL'],
          300, 200);
        if (answer.selected === '削除する') {
          try {
            googleAnalyticsTracking('click', 'menu', 'deleteGithubInfoSuccess');
            await Server.removeGitInfo();
            reload();
            viewDialog(
              'Github 情報連携解除-完了',
              '削除しました。ダッシュボードで確認してください。',
              ['OK'],
              300, 200);
          } catch(err) {
            googleAnalyticsTracking('click', 'menu', 'deleteGithubInfoFailed');
            viewDialog(
              'エラーが発生しました',
              err?.response?.data?.errorMessage,
              ['OK'],
              300, 100);
            return false;
          }
        }
      },
    },
    CAREER_SHEET: {
      key: 'careerSheet',
      label: "経歴書",
      func: () => {
        googleAnalyticsTracking('click', 'menu', 'careerSheet');
        LocationLink(LinkUrl.SCREEN(Screen.CAREER_SHEET), false);
      },
    },
    CLIENT: {
      key: 'client',
      label: "掲載案件",
      func: () => {
        googleAnalyticsTracking('click', 'menu', 'client');
        LocationLink(LinkUrl.UNIQUE_CLIENT(userInfo.account.accountNo), false);
      },
    },
    LOG_OUT: {
      key: 'logout',
      label: "ログアウト",
      func: async () => {
        googleAnalyticsTracking('click', 'menu', 'logout');
        await Server.logout();
        LocationLink(LinkUrl.TOP, false);
      },
    },
  };

  // ユーザ毎のメニュー
  // クライアント
  if (!!userInfo?.client) {
    return [
      MENUS.DASHBOARD,
      MENUS.EDIT_ACCOUNT,
      MENUS.CLIENT,
      MENUS.LOG_OUT,
    ];
  }
  // エンジニア
  if (!!userInfo?.engineer) {
    // GitInfo 未連携
    if (!userInfo?.engineer?.gitInfo) {
      return [
        MENUS.DASHBOARD,
        MENUS.EDIT_ACCOUNT,
        MENUS.GIT_INFO_GET,
        MENUS.CAREER_SHEET,
        MENUS.LOG_OUT,
      ];
    } else {
      return [
        MENUS.DASHBOARD,
        MENUS.EDIT_ACCOUNT,
        MENUS.GIT_INFO_UPDATE,
        MENUS.GIT_INFO_DEL,
        MENUS.CAREER_SHEET,
        MENUS.LOG_OUT,
      ];
    }
  }
  // 非ログイン・アカウント未作成状態
  return [
    MENUS.LOG_OUT,
  ];
};

/**
 * アイコン・コンテキストメニュー
 */
export function IconContextMenu() {

  const { isSp, userInfo, googleLogin, viewDialog, reload } = useContext(AppContext);

  if (!userInfo?.isDone) {
    return <Waiting/>;
  }

  const menus = createMenuPerUser(userInfo, viewDialog, reload);

  // null になるのは基本 dev環境のみ
  if (!!menus) {
    return (
      <Menu>
        <MenuTrigger>
          <UserIcon
            nickName={userInfo.engineer?.nickName || userInfo.client?.compName}
            size={isSp ? Styles.USER_ICON_SIZE_SP : Styles.USER_ICON_SIZE}
            image={userInfo.engineer?.iconUrl || userInfo.client?.compIconUrl || userInfo.userIcon}
          />
        </MenuTrigger>
        <MenuOptions>
          {menus.map((menu) => (
            <MenuOption
              key={menu.key}
              onSelect={() => menu.func()}
            >
              <T>{menu.label}</T>
            </MenuOption>
          ))}
        </MenuOptions>
      </Menu>
    );
  }
}

/**
 * ヘッダメニュー
 */
export function HeaderMenus() {

  const { isSp, userInfo, googleLogin, viewDialog, reload } = useContext(AppContext);

  if (!userInfo?.isDone) {
    return <Waiting/>;
  }

  const menus = createMenuPerUser(userInfo, viewDialog, reload);
}
