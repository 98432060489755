import React, { useContext } from 'react';
import { StyleSheet,  } from 'react-native';
import Button from '../../../common/components/Button';
import { AppContext } from '../../context/AppContext';
import * as Server from '../../functions/data/Server';
import { Screen } from '../../Screen';
import { toastSuccess } from '../../functions/Toast';

const DEFAULT_ALLOW_COUNT = 3;

/**
 * 経歴書共有ボタン
 * @param {*} props
 * @returns ボタン
 */
export default function ShareCareerSheetButton(props) {
  const {
    targetAccountNo
  } = props;

  const { userInfo, Link, } = useContext(AppContext);

  // 自身がエンジニアでないか、表示対象が自身ならば表示しない
  if (!userInfo?.engineer || userInfo?.engineer?.accountNo == targetAccountNo) {
    return <></>;
  }

  return (
    <Button
      label="このアカウント向けに経歴書を共有"
      onPress={() => {
        (async () => {
          try {
            await Server.send(userInfo?.token, 'engineer/createSharedCareerSheet', {
              targetAccountNo,
              allowedCount: DEFAULT_ALLOW_COUNT,
            });
            toastSuccess('作成完了', '経歴書共有URLを作成しました');
            Link(Screen.CAREER_SHEET);
          }catch(err) {
            toastSorry(err, 3000);
          }
        })();
      }}
    />
  );
}

const styles = StyleSheet.create({
});
