const { Screen } = require("./Screen");

const LinkUrl = {
  LP: '/lp/?openExternalBrowser=1',
  TOP: '/',
  SCREEN: (screen) => `/?s=${screen}`,
  EDIT_ENGINEER: (engineerTabKey) => `/?s=${Screen.EDIT_ACCOUNT}&tab=${engineerTabKey}`,
  EDIT_RECRUIT_ITEM: (itemNo) => `?s=${Screen.EDIT_RECRUIT_ITEM}&recruitNo=${itemNo}`,
  UNIQUE_ENGINEER: (uniqueName) => `?s=${Screen.ENGINEER}&uniqueName=${uniqueName}`,
  UNIQUE_CLIENT: (clientAccountNo) => `?s=${Screen.CLIENT}&clientAccountNo=${clientAccountNo}`,
  GITHUB: (userName) => `https://github.com/${userName}`,
  MAP: (prefName, cityName, townName) => `https://www.google.co.jp/maps/search/${prefName}${cityName}${townName}`,
  HOUJIN_DB: (companyNo) => `https://www.houjin-bangou.nta.go.jp/henkorireki-johoto.html?selHouzinNo=${companyNo}`,
};

module.exports = {
  LinkUrl,
};
