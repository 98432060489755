const SCRIPT_SRC = 'https://accounts.google.com/gsi/client';

const start = (func) => {
  const head = window.document.getElementsByTagName('head')[0];
  // すでに設定済みかを探す
  for(let n of head.childNodes) {
    if (n?.src === SCRIPT_SRC) {
      func(true);
      return;
    }
  }

  const script = document.createElement('script');
  script.defer = true;
  script.async = true;
  script.src = SCRIPT_SRC;
  script.onload = () => {
    func(false);
  },
  head.appendChild(script);
}

/** WEB用 gsi/client の JS をロードして、POPUP形式の Google oAuth を実施する */
export function googleAuthForWebGsi(clientId, callback) {
  start(async () => {
    // eslint-disable-next-line no-undef
    // モーダル表示バージョン
    const tokenClient = await globalThis.google.accounts.oauth2.initTokenClient({
      client_id: clientId,
      // scope: 'email profile openid https://www.googleapis.com/auth/drive.file',
      scope: 'email profile',
      callback: async (response) => {
        if (response.error !== undefined) {
          throw (response);
        }
        callback(response);
      },
    });
    tokenClient.requestAccessToken({ prompt: 'consent' });
  });
}

/** WEB用 gsi/client の JS をロードして、POPUP形式の Google oAuth を実施する
 * 2022年版 新しいツールチップでの認証
*/
export function googleAuthForWeb(clientId, callback, failFunc) {
  start(async (isRetry) => {
    // ツールチップ表示バージョン
    if(!isRetry) {
      globalThis.google.accounts.id.initialize({
        client_id          : clientId,
        prompt_parent_id   : 'google_login',
        style              : 'position:static;',
        auto_prompt        : true,
        auto_select        : false,
        cancel_on_tap_outside : true,
        callback           : (res) => {
          // const json = toJsonFromGoogleResponse(res);
          // callback(json);
          callback(res)
        },
      });
    }
    globalThis.google.accounts.id.prompt((notification) => {
      if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
        // suppresed_by_user になってしまって、2度目以降がウマク動作してくれない
        failFunc();
      }
    });
  });
}

//   const head = window.document.getElementsByTagName('head')[0];
//   const script = document.createElement('script');
//   script.defer = true;
//   script.async = true;
//   script.src = SCRIPT_SRC;
//   script.onload = async () => {
//     // ツールチップ表示バージョン
//     google.accounts.id.initialize({
//       client_id          : clientId,
//       prompt_parent_id   : 'google_login',
//       style              : 'position:static;',
//       auto_prompt        : false,
//       auto_select        : false,
//       cancel_on_tap_outside : true,
//       callback           : (res) => {
//         // const json = toJsonFromGoogleResponse(res);
//         // callback(json);
//         callback(res)
//       },
//     })
//     google.accounts.id.prompt();
//   };
//   head.appendChild(script);
// }

/** google.accounts.id.initialize の コールバック credential を分割する */
const toJsonFromGoogleResponse = (res) => {
  if (!res?.credential) {
    alert('Googleログインに失敗したようです');
  }
  const base64Url = res.credential.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const str1 = atob(base64)
  const str2 = escape(str1)
  const str3 = decodeURIComponent(str2)
  return JSON.parse(str3)
};
