import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TitledComponent from './TitledComponent';
import TitledSelect from './TitledSelect';
import * as Server from '../functions/data/Server';
import { isEmpty } from '../../common/util/TypeUtil';
import log from '../../common/util/Log';

export default function TitledStationSelect(props) {
  const {
    dir, icon, iconColor, title, placeholder, value, onChange, onBlur, componentKey, disabled,
    help, subIcons, prefCd, cityCd,
    error, errorBgColor,
  } = props;

  const [isInitialized, setIsInitialized] = useState(false);
  const [stationList, setStationList] = useState();
  const [line, setLine] = useState();
  const [station, setStation] = useState(value);

  useEffect(() => {
    (async () => {
      setIsInitialized(false);
      if (!!prefCd) {
        const serverResult = await Server.getStationList(prefCd, cityCd);
        setIsInitialized(true);
        setStationList(serverResult);
      } else {
        if (!!stationList) {
          setStationList(null);
          setLine(null);
          setStation(null);
        }
      }
    })();
  }, [prefCd, cityCd]);

  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    if (!isEmpty(stationList)) {
      // 初期状態ならば初期化せず、LINEを取得してセット
      if (!!value && station?.stationGCd == value?.stationGCd) {
        for (let _line of stationList) {
          const firstSt = _line?.stationList?.find((e) => e.stationGCd === value?.stationGCd);
          if (firstSt) {
            setLine(_line);
            return;
          }
        }
      }
      setLine(stationList[0]);
    } else {
      setLine(null);
    }
  }, [stationList]);

  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    // value に該当する駅があればそれをセット、なければ最初の駅をセット
    if (!isEmpty(line)) {
      const selectedStation = line.stationList?.find((e) => e.stationGCd === value?.stationGCd);
      if (!!selectedStation) {
        setStation(selectedStation);
      } else {
        // prefCd cityCd に一致する最初の駅
        const findStation = line.stationList?.find((e) =>
          e.prefCd === prefCd && e.cityCd === cityCd) || line.stationList[0];
        setStation(findStation);
      }
    } else {
      setStation(null);
    }
  }, [line]);

  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    if (!!station) {
      const setValue = JSON.parse(JSON.stringify(station));
      setValue.lineName = line?.lineName;
      onChange(setValue);
    } else {
      onChange(null);
    }
  }, [station]);

  return (
    <TitledComponent
      dir={dir}
      icon={icon}
      iconColor={iconColor}
      componentStyle={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: 0,
        marginLeft: 0,
        marginTop: 8,
      }}
      style={{
        marginBottom: !!error ? 16 : 0, // Picker が marginTop が効かず、bottom で調整するため、error のときだけ margin をなくす以下同じ
      }}
      title={title}
      help={help}
      subIcons={subIcons}
      error={error}
      errorBgColor={errorBgColor}
    >
      <TitledSelect
        placeholder={`${placeholder ? `${placeholder}-` : ''}路線`}
        items={stationList?.map((e) => ({ value: e.lineCd, label: e.lineName })) || []}
        defaultValue={line?.lineCd}
        onChange={(v) => {
          if (!!v) {
            const lineInfo = stationList.find((e) => e.lineCd == v);
            setLine(lineInfo);
          } else {
            setLine(null);
          }
        }}
        disable={isEmpty(stationList)}
        onBlur={onBlur}
        componentKey={`${componentKey}_line`}
        fieldStyle={ !!error
          ? {
            marginBottom: 0, // Picker が marginTop が効かず、bottom で調整するため、error のときだけ margin をなくす以下同じ
          } : null
        }
        isNullable
      />
      { (!!stationList && !!line) && (
        <TitledSelect
          placeholder={ placeholder || "駅"}
          items={line?.stationList.map((e) => ({ value: e.stationGCd, label: e.stationName })) || []}
          defaultValue={station?.stationGCd}
          onChange={(v) => {
            if (!!v) {
              const stationInfo = line.stationList.find((e) => e.stationGCd == v);
              setStation(stationInfo);
            } else {
              setStation(null);
            }
          }}
          onBlur={onBlur}
          componentKey={`${componentKey}_station`}
          fieldStyle={[
            !!error
              ? {
                marginBottom: 0,
              } : null
            ,
            { minWidth: 120, }
          ]}
        />
      )}
    </TitledComponent>
  );
}

TitledStationSelect.propTypes = {
  dir: TitledComponent.propTypes.dir,
  icon: TitledComponent.propTypes.icon,
  iconColor: TitledComponent.propTypes.iconColor,
  title: TitledComponent.propTypes.title,
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    stationGCd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  prefCd: PropTypes.string,
  cityCd: PropTypes.string,
  componentKey: PropTypes.string,
  help: TitledComponent.propTypes.help,
  subIcons: TitledComponent.propTypes.subIcons,
  error: TitledComponent.propTypes.error,
  errorBgColor: TitledComponent.propTypes.errorBgColor,
};

TitledStationSelect.defaultProps = {
  dir: TitledComponent.defaultProps.dir,
  icon: TitledComponent.defaultProps.icon,
  iconColor: TitledComponent.defaultProps.iconColor,
  title: TitledComponent.defaultProps.title,
  placeholder: '',
  value: null,
  disabled: false,
  onBlur: () => {},
  prefCd: null,
  cityCd: null,
  componentKey: 'stationSelectDefaultKey',
  dir: null,
  icon: null,
  iconColor: '#000',
  title: null,
  help: null,
  subIcons: null,
  error: TitledComponent.defaultProps.error,
  errorBgColor: TitledComponent.defaultProps.errorBgColor,
};
