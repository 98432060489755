import React, { useState } from 'react';
import { Text, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import Styles from '../Styles';

/** 基本フォントなど統一させる単純テキスト */
export default function T(props) {
  const shortText = props?.shortText || ''; // 短文表示、クリックで本文表示
  const isFirstOpen = props?.isFirstOpen; // 最初本文でクリックで短文に
  const onPress = props?.onPress; // 文字のクリック

  const [isOpen, setIsOpen] = useState(isFirstOpen);

  const textMain =  (
    <Text
      {...props}
      style={[
        props.style,
        Styles.FONT,
      ]}
      onPress={onPress}
    >
      {props.children}
    </Text>
  );

  if (!!shortText) {
    return (
      <TouchableOpacity
        onPress={() => setIsOpen(!isOpen)}
      >
        {isOpen
        ? textMain
        : (
          <Text
            style={{
              textDecorationLine: 'underline',
              color: '#00f'
            }}
          >
            {shortText}
          </Text>
        )}
      </TouchableOpacity>
    );
  }

  return textMain;
}

T.propTypes = {
  children: PropTypes.node,
  shortText: PropTypes.string,
  isFirstOpen: PropTypes.bool,
  onPress: PropTypes.func,
};

T.defaultProps = {
  children: '',
  shortText: null,
  isFirstOpen: false,
  onPress: null,
};
