import React, { createRef, useEffect, useState } from 'react';
import {
  View, StyleSheet, Platform, Image,
} from 'react-native';
import PropTypes from 'prop-types';
import { Picker } from '@react-native-picker/picker';
import { WToast } from 'react-native-smart-tip';
import { IconComponentProvider, Icon } from "@react-native-material/core";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import IconText from '../../common/components/IconText';
import Button from '../../common/components/Button';
import uuid from '../../common/util/Uuid';
import Styles from '../Styles';
import { ifPresent, isEmpty, isString, optional } from '../../common/util/TypeUtil';
import T from './T';
import { isNotEmpty } from '../../common/util/TextUtil';

const IMAGE_FAIL = require('../../common/assets/sorry.png');

const DISABLED_ALERT = () => {
  WToast.show({
    data: '変更できません',
    textColor: '#fff',
    backgroundColor: Styles.COLOR_TOAST_BG,
    duration: WToast.duration.LONG,
    position: WToast.position.CENTER,
    icon: <Image
      source={IMAGE_FAIL}
      style={{ width: 128, height: 128, resizeMode: 'contain' }}
    />,
  });
};

const itemMapper = (item) => {
  if (isString(item)) {
    return {
      label: item,
      value: item,
    };
  }
  return {
    label: item.label,
    value: item.value,
    icon: ifPresent(item.icon, (icon) => () =>
      <IconComponentProvider IconComponent={MaterialCommunityIcons}>
        <Icon name={icon} size={12} />
      </IconComponentProvider>
    ),
  };
};

export default function TitledSelect(props) {
  const {
    dir, icon, iconColor, title, defaultValue, placeholder,
    onChange, onBlur, items, ref, disabled, componentKey, help, subIcons,
    style, fieldStyle, isNullable,
  } = props;

  // const [result, setResult] = useState(defaultValue);
  const [isVisiblePickerForSp, setVisiblePickerForSp] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  // 初期呼び出し時は true
  const [isFirst, setIsFirst] = useState(true);

  const onValueChange = (v) => {
    onChange(v);
    setVisiblePickerForSp(false);
  };

  useEffect(() => {
    if (!isEmpty(defaultValue)) {
      onValueChange(defaultValue);
      return;
    }
    if (!isNullable && !isEmpty(items)) {
      onValueChange(items[0].value);
      return
    }
    onValueChange(null);
  }, []);

  // 初期状態では、isNullableのとき "" を、それ以外のときは、items[0] を onChange に渡しておく
  // useEffect(() => {
  //   if (isFirst && !!defaultValue) {
  //     onChange(defaultValue);
  //   } else if (isNullable) {
  //     onChange(null);
  //   } else if (isNotEmpty(items)) {
  //     onChange(items[0].value);
  //   } else {
  //     onChange(null);
  //   }
  //   setIsFirst(false);
  // }, [items]);

  return (
    <View style={[
      styles.container,
      style,
    ]} key={`TitledSelect_top_${componentKey}`}>
      { ['ios', 'android'].includes(Platform.OS) && (
        <>
          { (!!title || !!help || !!icon || !!subIcons) && (
            <IconText
              containerStyle={[
                styles.titleContainer,
              ]}
              textStyle={styles.titleText}
              dir={dir}
              icon={icon}
              iconColor={iconColor}
              help={help}
              subIcons={subIcons}
            >
              {title}
            </IconText>
          )}
          <Picker
            key={`TitledSelect_picker_sp_${componentKey}`}
            ref={ref}
            mode="dropdown"
            // selectedValue={result}
            selectedValue={defaultValue}
            onValueChange={onValueChange}
            onBlur={onBlur}
            style={{
              backgroundColor: '#fff',
              display: isVisiblePickerForSp ? 'flex' : 'none',
            }}
          >
            { !!isNullable && (<Picker.Item label={placeholder || "未選択"} value="" />)}
            { items.map(itemMapper).map((e) => <Picker.Item key={e.value} label={e.label} value={e.value} color={e.value === defaultValue ? '#f00' : '#000'} />)}
          </Picker>
          <Button
            key={`TitledSelect_button_sp_${componentKey}`}
            buttonStyle={styles.fieldContainer}
            labelStyle={styles.buttonLabelForSp}
            label={defaultValue ? items.find((e) => e.value === defaultValue)?.label : placeholder || '未選択'}
            onPress={() => {
              if (disabled) {
                DISABLED_ALERT();
              } else {
                setVisiblePickerForSp(!isVisiblePickerForSp);
              }
            }}
          />
        </>
      )}
      { !['ios', 'android'].includes(Platform.OS) && (
        <>
          { (!!title || !!help || !!icon || !!subIcons) && (
            <IconText
              containerStyle={
                styles.titleContainer
              }
              textStyle={styles.titleText}
              dir={dir}
              icon={icon}
              iconColor={iconColor}
              help={help}
              subIcons={subIcons}
            >
              {title}
            </IconText>
          )}
          {/* <DropDownPicker
            key={`TitledSelect_picker_pc_${componentKey}`}
            placeholder={placeholder}
            items={items.map(itemMapper)}
            value={defaultValue}
            open={isOpen}
            setOpen={setIsOpen}
            setValue={onValueChange}
            dropDownDirection="TOP" // TOP にしないと、他の裏に表示される
            closeOnBackPressed={true}
            showBadgeDot={false}
            labelStyle = {{
              fontSize: 12,
              textAlign: 'left',
            }}
            style={{
              borderRadius: 4,
            }}
            containerStyle={[
              {
                marginLeft: 16,
                marginBottom: 16,
                height: 56,
                lineHeight: 48,
                fontSize: 14,
                backgroundColor: null,
                marginRight: 16,
                zIndex: 998,
                width: '90%',
              },
              fieldStyle,
            ]}
            disabled={disabled}
          /> */}
          <View>
            <Picker
              key={`TitledSelect_picker_pc_${componentKey}`}
              ref={ref}
              // selectedValue={result}
              selectedValue={defaultValue || ''}
              onValueChange={onValueChange}
              onBlur={onBlur}
              style={[
                styles.fieldContainer,
                fieldStyle,
              ]}
              disabled={disabled}
            >
              { !!isNullable && (<Picker.Item label={placeholder || "未選択"} value="" />)}
              { items?.map(itemMapper).map((e) =>
                  <Picker.Item key={e.value} label={e.label} value={e.value} />
              )}
            </Picker>
            { placeholder && !!defaultValue && !isOpen && (
              <T
                style={{
                  position: 'absolute',
                  top: -8,
                  left: 32,
                  backgroundColor: '#fff',
                  borderRadius: 8,
                  fontSize: 12,
                  zIndex: 999,
                }}
              >
                {placeholder}
              </T>
            )}
          </View>
        </>
      )}
    </View>
  );
}

TitledSelect.propTypes = {
  dir: PropTypes.any,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  title: PropTypes.string,
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]).isRequired,
      }).isRequired,
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
      PropTypes.bool.isRequired,
    ]).isRequired,
  ),
  ref: PropTypes.any,
  disabled: PropTypes.bool,
  componentKey: PropTypes.string,
  help: IconText.propTypes.help,
  subIcons: IconText.propTypes.subIcons,
  style: PropTypes.any,
  fieldStyle: PropTypes.any,
  isNullable: PropTypes.bool,
};

TitledSelect.defaultProps = {
  dir: null,
  icon: null,
  title: null,
  defaultValue: '',
  placeholder: null,
  items: [],
  ref: createRef(),
  disabled: false,
  onChange: () => {},
  onBlur: () => {},
  componentKey: uuid(),
  iconColor: '#000',
  help: null,
  subIcons: null,
  style: null,
  fieldStyle: null,
  isNullable: false,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  titleContainer: {
    marginLeft: 16,
    marginBottom: 8,
  },
  titleText: {
    fontSize: 16,
  },
  fieldContainer: {
    marginLeft: 16,
    marginRight: 0,
    marginBottom: 16,
    height: 56,
    lineHeight: 48,
    paddingHorizontal: 8,
    fontSize: 14,
    backgroundColor: '#fff',
    borderColor: '#333',
    borderWidth: 1,
    borderRadius: 4,
  },
  buttonLabelForSp: {
    lineHeight: 48,
  },
});
