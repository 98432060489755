import React, { useContext, useCallback, useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Switch, View } from 'react-native';
import { AppContext } from '../context/AppContext';
import Button from '../../common/components/Button';
import * as Server from '../functions/data/Server';
import { download } from '../../common/functions/Download';
import { toastSorry, toastSuccess } from '../functions/Toast';
import { isNumeric } from '../../common/util/TextUtil';
import Waiting from '../components/Waiting';
import TitledComponent from '../components/TitledComponent';
import T from '../components/T';
import IconText from '../../common/components/IconText';
import { formatDate } from '../../common/util/DateUtils';
import DataListTable from '../components/DataListTable';
import ClipCopyText from '../../common/functions/ClipCopyText';
import { createExcelSheetShareUrl } from '../functions/data/createUrl';
import TitledInput from '../components/TitledInput';
import { debug } from '../../common/util/Log';


/**
 * 経歴書
 * @returns
 */
export default function CareerSheet(routeParam) {
  const { route } = routeParam;
  const { params } = route;
  const { targetAccountNo } = !!params ? params : {};
  const { userInfo, viewDialog } = useContext(AppContext);
  const [ sharedCareerSheetList, setSharedCareerSheetList] = useState(null);

  const [ isViewPastList, setIsViewPastList ] = useState(false);

  const [ allowedCount, setAllowedCount ] = useState(10);
  const [ isTargetShare, setIsTargetShare ] = useState(!!targetAccountNo);

  const [ isInitialized, setIsInitialized ] = useState(false);

  // 共有済み経歴書情報取得
  const getSharedList = useCallback(() => {
    (async () => {
      setIsInitialized(false);
      const serverResult = await Server.get(userInfo.token, 'engineer/sharedCareerSheetList');
      setSharedCareerSheetList(serverResult?.data?.sharedList);
      setIsInitialized(true);
    })();
  }, []);

  useEffect(() => {
    getSharedList();
  }, []);

  return (
    <View style={{flex: 1}}>
      <View
        style={{
          flexDirection: 'row'
        }}
      >
        <Button
          onPress={async () => {
            const result = await Server.get(userInfo.token, 'engineer/careerSheetSelf');
            if (!result?.data?.excel) {
              toastSorry('ダウンロードに失敗しました', 3000);
              return;
            }
            download(result?.data?.excel, '経歴書.xlsx');
          }}
          label="自分のExcel経歴書をダウンロード"
        />
      </View>
      <TitledComponent
        title="共有済み経歴書情報"
        help="共有している経歴書の履歴情報です"
        style={{
          margin: 16,
          borderTopWidth: 1,
          borderTopColor: 'gray',
        }}
        appendNode={(
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <T>{`古い情報・削除済み情報${isViewPastList ? 'を非表示' : 'を表示'}`}</T>
            <Switch
              style={{marginHorizontal: 8}}
              value={isViewPastList}
              onValueChange={setIsViewPastList}
            />
          </View>
        )}
      >
        <View
          style={{
            width: '100%',
            height: 350,
          }}
        >
          {!isInitialized
            ? (
              <>
                <Waiting/>
              </>
            ) : (
              <ScrollView
                style={{
                  height: 350,
                }}
              >
                <DataListTable
                  headers={[
                    {title: 'URL', key:'url'},
                    {title: '共有先アカウント', key:'targetAccountName'},
                    {title: 'DL回数/制限', key:'downloadedCount'},
                    {title: '有効期限', key:'expireDate'},
                    {title: '共有開始日', key:'regDate'},
                    {title: '削除', key: 'deleteButton'},
                  ]}
                  list={sharedCareerSheetList?.filter(r=>
                    isViewPastList
                      ? true
                      : (!r.delDate) || (r.expireDate < new Date() - 7)
                  ).map(r=>({
                    url: (
                      <T
                        onPress={() => {
                          ClipCopyText(createExcelSheetShareUrl(r.key, r.targetAccountNo));
                          toastSuccess('経歴書ダウンロード用URL', 'クリップボードにコピーしました', 3000);
                        }}
                        style={{
                          textDecoration: 'underline',
                          color: '#00f',
                        }}
                      >URLをコピー</T>
                    ),
                    dataRowKey: r.key,
                    targetAccountName: !!r.targetAccountName ? r.targetAccountName : '一般公開',
                    downloadedCount: `${r.downloadedCount} / ${r.allowedCount < 1 ? '無制限' : r.allowedCount}`,
                    expireDate: formatDate(r.expireDate, 'yyyy/MM/dd まで'),
                    regDate: formatDate(r.regDate, 'yyyy/MM/dd'),
                    deleteButton: !!r.delDate
                      ? '削除済み'
                      : (
                        <Button
                          onPress={async () => {
                            try {
                              const confirm = await viewDialog(
                                '共有URLを削除します',
                                `すでに共有URLを他者にお知らせしている場合、その方からのアクセスは拒否されるようになります。
削除してよろしいですか？`,
                                ['はい', '見直す']);
                              if (confirm.selected != 'はい') {
                                  return;
                              }
                              await Server.send(userInfo.token, 'engineer/deleteSharedCareerSheet', {key: r.key});
                              getSharedList();
                            } catch (err) {
                              toastSorry(`削除に失敗:${err}`);
                            }
                          }}
                          label="削除"
                          buttonColor="#f00"
                          labelColor="#fff"
                          labelStyle={{
                            fontSize: 14,
                          }}
                        />
                      )
                  }))}
                  dataRowKey="dataRowKey"
                  // height={350}
                  // width="100%"
                  defaultSortKeyOrder={{key:'expireDate', isDesc:true}}
                  rowMinHeight={35}
                />
              </ScrollView>
            )
          }
        </View>
      </TitledComponent>
      <TitledComponent
        title="経歴書を共有するURLを発行する"
        help="最新状態の経歴書情報をExcelでダウンロードできる共有URLを作成します"
        style={{
          margin: 16,
          borderTopWidth: 1,
          borderTopColor: 'gray',
          alignItems: 'flex-start',
        }}
        componentStyle={{
          flexDirection: 'column',
        }}
      >
        {/* ダウンロード回数 */}
        <TitledInput
          placeholder="ダウンロード上限回数"
          value={allowedCount}
          onChangeText={(v) => {
            if (!v) {
              setAllowedCount(0);
              return;
            }
            if (!isNumeric(v)) {
              return;
            }
            setAllowedCount(v);
          }}
          keyboardType={"numeric"}
          maxLength={5}
          width={160}
        />
        <T
          style={{
            marginLeft: 16,
            fontWeight: 'bold',
          }}
        >
          {allowedCount < 1
            ? '無制限にダウンロードを許容します。'
            : `${allowedCount}回のダウンロードを許容します。
(0を指定すると無制限に許容します)`}
        </T>
        {/* ダウンロード可能アカウント */}
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginVertical: 8,
          }}
        >
          <Switch
            style={{marginHorizontal: 8}}
            value={isTargetShare}
            onValueChange={setIsTargetShare}
            disabled={!targetAccountNo}
          />
          <IconText
            help="指定するためには、共有対象のアカウント詳細画面から経歴書URL発行ボタンを押す必要があります"
          >
            <T>{`${isTargetShare ? '指定のアカウントのみ': 'URLを知っている人は誰でも'}ダウンロード可`}</T>
          </IconText>
        </View>
        <Button
          label="共有URL作成"
          onPress={() => {
            (async () => {
              try {
                const confirm = await viewDialog(
                  '共有URLは、誰でもアクセスできます',
                  `作成される共有URLは、誰でもアクセス可能で、あなたの経歴書をダウンロードできます。
URLさえ知っていれば、誰でもダウンロードできる点に注意してください。
あなたが登録した情報を共有できるURLを発行し、あなたの個人情報に該当する可能性のある情報を
このサイトを経由して他者に提供されることに理解し同意しますか？`,
                  ['同意', '見直す']);
                if (confirm.selected != '同意') {
                    return;
                }
                await Server.send(userInfo?.token, 'engineer/createSharedCareerSheet', {
                  targetAccountNo,
                  allowedCount,
                });
                getSharedList();
              }catch(err) {
                toastSorry(err, 3000);
              }
            })();
          }}
        />
      </TitledComponent>
    </View>
  );
}

CareerSheet.propTypes = {
};

CareerSheet.defaultProps = {
};

const styles = StyleSheet.create({
  h1: {
    marginTop: 16,
    fontSize: 24,
    fontWeight: 'bold',
    alignSelf: 'center',
  },
  h2: {
    marginTop: 16,
    marginLeft: 8,
    fontSize: 20,
    fontWeight: 'bold',
    alignSelf: 'flex-start',
  },
  h3: {
    marginTop: 16,
    marginLeft: 8,
    fontSize: 16,
    alignSelf: 'flex-start',
  },
  fullWidth: {
    width: '100%',
  },
  header: {
    alignSelf: 'flex-end',
    marginRight: 8,
  },
  description: {
    alignSelf: 'flex-start',
    flexWrap: 'wrap',
    marginLeft: 24,
  }
});
