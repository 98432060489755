import { isEmpty } from "../../../common/util/TypeUtil";
import { FRONT_PATH_HEADER } from "../../../env";
import { Screen } from "../../Screen";

export function createEngineerUrl(engineerUniqueName) {
  return `${FRONT_PATH_HEADER}?e=${engineerUniqueName}`
}

export function createEngineerUrlLocal(engineerUniqueName) {
  return `${FRONT_PATH_HEADER}?e=${engineerUniqueName}`
}

export function createClientUrl(clientAccountNo) {
  return `${FRONT_PATH_HEADER}?c=${clientAccountNo}`
}

export function createExcelSheetShareUrl(key, target) {
  return !isEmpty(target)
    ? `${FRONT_PATH_HEADER}/?s=${Screen.CAREER_SHEET_DOWNLOAD_TARGET}&key=${key}`
    : `${FRONT_PATH_HEADER}/?s=${Screen.CAREER_SHEET_DOWNLOAD_PUBLIC}&key=${key}`;
}
