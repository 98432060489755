import React from 'react';
import { ScrollView, View } from 'react-native';
import { ViewPropTypes } from 'deprecated-react-native-prop-types';
import PropTypes from 'prop-types';
import Title from '../../src/components/Title';
import { TouchableOpacity } from 'react-native-gesture-handler';

export default function Box(props) {
  const { children, style, componentStyle, title, isNoScroll, onPress} = props;

  const topStyle = [
    {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: '#fff',
      borderRadius: 8,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.2,
      shadowRadius: 4,
      elevation: 1,
    },
    style,
  ];

  if (!!onPress) {
    topStyle.push({
      cursor: 'pointer',
    });
  }

  const main = (
    <>
      { !!title && (
        <Title label={title}/>
      )}
      {isNoScroll
        ? (
          <View
            style={componentStyle}
          >
            {children}
          </View>
        ):(
          <ScrollView
            style={componentStyle}
          >
            {children}
          </ScrollView>
      )}
    </>
  );

  if (!!onPress) {
    return (
      <TouchableOpacity
        style={topStyle}
        onPress={onPress}
      >
        {main}
      </TouchableOpacity>
    )
  };

  return (
    <View
      style={topStyle}
    >
      {main}
    </View>
  );

  // return (
  //   <View
  //     style={[
  //       {
  //         flex: 1,
  //         flexDirection: 'column',
  //         justifyContent: 'center',
  //         backgroundColor: '#fff',
  //         borderRadius: 8,
  //         shadowColor: '#000',
  //         shadowOffset: {
  //           width: 0,
  //           height: 2,
  //         },
  //         shadowOpacity: 0.2,
  //         shadowRadius: 4,
  //         elevation: 1,
  //       },
  //       style,
  //     ]}
  //   >
  //     { !!title && (
  //       <Title label={title}/>
  //     )}
  //     {isNoScroll
  //       ? (
  //         <View
  //           style={componentStyle}
  //         >
  //           {children}
  //         </View>
  //       ):(
  //         <ScrollView
  //           style={componentStyle}
  //         >
  //           {children}
  //         </ScrollView>
  //       )}
  //   </View>
  // )
}

Box.propTypes = {
  style: ViewPropTypes.style,
  componentStyle: ViewPropTypes.style,
  title: PropTypes.string,
  isNoScroll: PropTypes.bool,
  onPress: PropTypes.func,
};

Box.defaultProps = {
  style: null,
  title: null,
  isNoScroll: false,
  onPress: null,
};
