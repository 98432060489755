import React from 'react';
import {
  View, StyleSheet,
} from 'react-native';
import PropTypes from 'prop-types';
import TitledComponent from './TitledComponent';
import IconText from '../../common/components/IconText';
import InputSuggest from '../../common/components/InputSuggest';

export default function TitledInputSuggest(props) {
  const {
    dir, icon, title, onChange, iconSize, iconColor, multiple, label, value,
    isEnableCustomValue, disabled, items, componentKey, help, modalHelp, subIcons,
    itemBgColor, itemTextColor, itemStyle, itemsFunc, style, componentStyle,
    error, errorBgColor, appendNode, customValueMaxLength,
  } = props;

  return (
    <TitledComponent
      componentKey={`TitledInputSuggest_${componentKey}`}
      dir={dir}
      icon={icon}
      iconColor={iconColor}
      iconSize={iconSize}
      componentStyle={[
        {
          flexDirection: 'row',
          flexWrap: 'nowrap',
          marginBottom: !!error ? 0 : 8,
          marginTop: 0,
          paddingLeft: 16,
          alignItems: 'flex-start',
        },
        componentStyle,
      ]}
      style={style}
      title={title}
      help={help}
      subIcons={subIcons}
      error={error}
      appendNode={appendNode}
    >
    {/*
    <View
      style={[
        styles.container,
        style,
      ]}
      key={`TitledInputSuggest_${componentKey}_top`}
    >
      { (!!title || !!help || !!icon || !!subIcons) && (
        <IconText
          containerStyle={styles.titleContainer}
          textStyle={styles.titleText}
          dir={dir}
          icon={icon}
          iconSize={iconSize}
          iconColor={iconColor}
          help={help}
          subIcons={subIcons}
        >
          {title}
        </IconText>
      ) }
      <View>*/}
        <InputSuggest
          componentKey={`InputSuggest_${componentKey}`}
          multiple={multiple}
          help={modalHelp}
          label={label}
          value={value}
          isEnableCustomValue={isEnableCustomValue}
          customValueMaxLength={customValueMaxLength}
          disabled={disabled}
          items={items}
          onChange={onChange}
          itemBgColor={itemBgColor}
          itemTextColor={itemTextColor}
          itemStyle={itemStyle}
          itemsFunc={itemsFunc}
          fieldBgColor={!!error ? errorBgColor : null}
        />
    {/*   </View>
    </View> */}
    </TitledComponent>
  );
}

TitledInputSuggest.propTypes = {
  style: PropTypes.any,
  dir: TitledComponent.propTypes.dir,
  icon: TitledComponent.propTypes.icon,
  iconColor: TitledComponent.propTypes.iconColor,
  iconSize: TitledComponent.propTypes.iconSize,
  title: TitledComponent.propTypes.title,
  onChange: PropTypes.func.isRequired,
  multiple: InputSuggest.propTypes.multiple,
  label: InputSuggest.propTypes.label,
  value: InputSuggest.propTypes.value,
  items: InputSuggest.propTypes.items,
  disabled: PropTypes.bool,
  isEnableCustomValue: InputSuggest.propTypes.isEnableCustomValue,
  customValueMaxLength: InputSuggest.propTypes.customValueMaxLength,
  componentKey: PropTypes.string.isRequired,
  help: IconText.propTypes.help,
  modalHelp: InputSuggest.propTypes.help,
  subIcons: IconText.propTypes.subIcons,
  itemBgColor: InputSuggest.propTypes.itemBgColor,
  itemTextColor: InputSuggest.propTypes.itemTextColor,
  itemStyle: InputSuggest.propTypes.itemStyle,
  itemsFunc: InputSuggest.propTypes.itemsFunc,
  error: TitledComponent.propTypes.error,
  errorBgColor: TitledComponent.propTypes.errorBgColor,
  appendNode: TitledComponent.propTypes.appendNode,
};

TitledInputSuggest.defaultProps = {
  style: null,
  dir: TitledComponent.defaultProps.dir,
  icon: TitledComponent.defaultProps.icon,
  iconColor: TitledComponent.defaultProps.iconColor,
  iconSize: TitledComponent.defaultProps.iconSize,
  multiple: false,
  label: '選択',
  value: [],
  disabled: false,
  isEnableCustomValue: InputSuggest.defaultProps.isEnableCustomValue,
  customValueMaxLength: InputSuggest.defaultProps.customValueMaxLength,
  help: null,
  modalHelp: null,
  subIcons: null,
  itemBgColor: InputSuggest.defaultProps.itemBgColor,
  itemTextColor: InputSuggest.defaultProps.itemTextColor,
  itemStyle: InputSuggest.defaultProps.itemStyle,
  itemsFunc: InputSuggest.defaultProps.itemsFunc,
  error: TitledComponent.defaultProps.error,
  errorBgColor: TitledComponent.defaultProps.errorBgColor,
  appendNode: null,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    marginRight: 8,
    paddingLeft: 16,
  },
  titleContainer: {
    marginTop: 16,
  },
  titleText: {
    fontSize: 16,
    marginRight: 32,
  },
  textInput: {
    marginRight: 8,
    height: 48,
    fontSize: 16,
    borderWidth: 1,
    marginBottom: 16,
    paddingHorizontal: 8,
  },
  normalColorTextInput: {
    backgroundColor: '#fff',
    borderColor: '#333',
    borderWidth: 2,
  },
  disabledColorTextInput: {
    backgroundColor: '#ccc',
    color: '#111',
    borderColor: '#ddd',
  },
  error: {
    fontSize: 16,
    marginLeft: 56,
    color: '#f00',
    fontWeight: 'bold',
  },
});
