import React, { useState, useContext, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { View, TouchableOpacity, ActivityIndicator } from 'react-native';
import { AppContext } from '../../context/AppContext';
import Styles from '../../Styles';
import { CareerType, Engineer, getFriendStatus, label } from '../../types/Types';
import SuggestItem from '../../../common/components/SuggestItem';
import Title from '../../components/Title';
import { ICON, viewCareerMonthsTotal, viewTerm, viewTermShort } from '../../Definition';
import UserIcon from './UserIcon';
import { isEmpty, optional } from '../../../common/util/TypeUtil';
import RegisterFriendButton from './RegisterFriend';
import T from '../../components/T';
import { getMyEvaluationActivity, registerEvaluationToggle } from '../../functions/data/Server';
import GitInfo from './GitInfo';
import WaitRadarChart from '../../../common/components/WaitRadarChart';
import { DIR } from '../../../common/components/IconText';
import Button from '../../../common/components/Button';
import ClipCopyText from '../../../common/functions/ClipCopyText';
import { toastShort } from '../../functions/Toast';
import { createEngineerUrl, createEngineerUrlLocal } from '../../functions/data/createUrl';
import QrCodeImage from './QrCodeImage';
import { Screen } from '../../Screen';
import { isNotEmpty } from '../../../common/util/TextUtil';
import { ENGINEER_TAB_KEY } from './EditAccountEngineer';
import { LinkUrl } from '../../LinkUrl';

const getMaxEvalCount = (evals) => {
  if (isEmpty(evals)) {
    return 0;
  }
  let max = 0;
  evals.forEach((e) => {
    if (e.count > max) {
      max = e.count;
    }
  });
  return max;
};

/**
 * エンジニア情報表示
 * 表示権限
 *  活動エリア、経歴、稼働先企業、報酬
 *  それぞれは、props に与えられた時点で表示しないものはデータがnullになっていることを要求する
 * （表示権限についてこのリソースでは関与しない）
 * @param reloadEngineer 他者が評価してくれた時の表示更新に利用
 * @returns
 */
export default function ViewEngineer(props) {
  const { engineer, reloadEngineer } = props;
  const [isExpandCareers, setIsExpandCareers] = useState(
    engineer?.engineerCareers?.map((e) => ({
      uuid: e.uuid,
      isOpen: false,
    })) || []
  );
  const [myEvaluationActivity, setMyEvaluationActivity] = useState();

  const { userInfo, isSp, viewDialog, reload, Link, LocationLink } = useContext(AppContext);

  // 他者が自分を評価してくれたときの表示更新
  const updateMyEvalActivity = async () => {
    const myActivity = await getMyEvaluationActivity(engineer.accountNo);
    setMyEvaluationActivity(myActivity);
    reloadEngineer();
  };

  useEffect(() => {
    //myEvaluationActivity の取得
    updateMyEvalActivity();
  }, []);

  /* 経歴の開閉 */
  const expandCareer = useCallback((uuid) => {
    if (isEmpty(isExpandCareers)) {
      return;
    }
    const newItem = isExpandCareers.map((e) => ({
      uuid: e.uuid,
      isOpen: uuid === e.uuid ? !e.isOpen : e.isOpen,
    }));
    setIsExpandCareers(newItem);
  }, [isExpandCareers]);

  /* 経歴の開閉確認 */
  const isExpandCareersThis = useCallback((uuid) => {
    return isExpandCareers?.find((e) => e.uuid === uuid)?.isOpen
  }, [isExpandCareers]);

  if (!engineer) {
    return (
      <View
        style={{
          flex: 1,
          alignContent: 'center',
          justifyContent: 'center',
          alignSelf: 'center',
        }}
      >
        <ActivityIndicator
          animating
          color={Styles.COLOR_WAITING_INDICATOR}
          size="large"
        />
      </View>
    );
  }

  // 自分
  const isMe = engineer?.accountNo === userInfo?.account?.accountNo;
  // フレンド状態
  const {isFriendRequested, isFriendReceived, isBothFriend} = isMe
    ? {isFriendRequested: null, isFriendReceived: null, isBothFriend: null}
    : getFriendStatus(
      userInfo?.engineer,
      engineer,
    );

  return (
    <View
      style={{
        marginTop: 16,
        marginLeft: 32,
        marginRight: 8,
      }}
    >
      {/** ニックネーム、アイコン */}
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          alignItems: 'flex-start',
        }}
      >
        <View>
          {/** アイコン */}
          <UserIcon
            size={isSp ? Styles.USER_ICON_SIZE_SP : Styles.USER_ICON_SIZE}
            image={engineer.iconUrl}
            nickName={engineer.nickName}
          />
        </View>
        {/** プロフィールメイン */}
        <View
          style={{
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            paddingLeft: 8,
          }}
        >
          {/** ニックネーム */}
          <T
            style={{
              marginTop: isSp ? 0 : 8,
              fontSize: isSp ? 24 : 32,
              fontWeight: 'bold',
              flexWrap: 'wrap',
            }}
          >
            {engineer.nickName || ' '}
          </T>
          <View
            style={{
              flexDirection: isSp ? 'column' : 'row',
            }}
          >
            <View>
              {!!engineer.nickName && (
                <Button
                  linkStyle
                  help="エンジニア情報に直リンクします。クリックするとクリップボードにコピーします。"
                  dir={DIR.FONTAWESOME5}
                  iconColor="#00f"
                  iconSize={12}
                  icon="link"
                  label="共有URLをコピー"
                  onPress={() => {
                    const shareUrl = createEngineerUrl(engineer.uniqueName);
                    ClipCopyText(shareUrl);
                    toastShort(
                      'クリップボードにコピーしました',
                      shareUrl,
                      3000);
                  }}
                />
              )}
              <T
                style={{
                  fontSize: 16,
                  color: '#888',
                  flexWrap: 'wrap',
                }}
              >
                {!!engineer.uniqueName ? `@${engineer.uniqueName}` : ''}
              </T>
              <T>
                エンジニア歴：{viewCareerMonthsTotal(engineer.engineerCareers)}
              </T>
              <View
                style={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                }}
              >
                {/* 活動エリア */
                optional(engineer.areas?.filter((e) => !!e.no))?.map((e) => (
                  <SuggestItem
                    key={`preview_area_${e.no}`}
                    style={Styles.LABEL_AREAS}
                    item={e}
                  />
                ))}
              </View>
              {/** フレンド */}
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                {engineer.friends?.both?.map((e) => (
                  <UserIcon
                    size={24}
                    image={e.targetIconUrl}
                    key={`friendIcon_${e.targetAccountNo}`}
                    nickName={'test'}
                    onPress={() => LocationLink(createEngineerUrlLocal(e.uniqueNameTarget))}
                  />
                ))}
              </View>
              {!isMe && (
                <RegisterFriendButton
                  targetEngineer={engineer}
                />
              )}
            </View>
            {/** QR */}
            <View
              style={{
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
                flex: 1,
                height: 'fitContent',
                paddingLeft: 24,
              }}
            >
              <QrCodeImage
                value={createEngineerUrl(engineer.uniqueName)}
                logo={ICON}
                qrSize={128}
                logoSize={32}
                help="エンジニア情報に直リンクします。クリックすると２次元コードをダウンロードします。"
              />
              {isMe && (
                <Button
                  linkStyle
                  label="自分の情報を編集"
                  onPress={() => Link(Screen.EDIT_ACCOUNT, false)}
                />
              )}
            </View>
          </View>
        </View>
      </View>
      <Title
        label="みんなの評価" fontSize={16} marginHorizontal={0}
        help={`本人が設定した評価項目から選択します。
3点以上設定されるとレーダーチャート表示`}
      />
      {
        isEmpty(engineer?.evaluationSettings)
        ?
          isMe ? (
            <Button
              linkStyle
              label="自分の情報を編集して、自分の評価項目を設定する"
              onPress={() => LocationLink(LinkUrl.EDIT_ENGINEER(ENGINEER_TAB_KEY.setting), false)}
            />
          ) : (
            <T>未登録</T>
          )
        : (
          <>
            {(engineer?.evaluationSettings.length > 2) && (
              <View
                style={{
                  width: '100%',
                  height: 'fitContent',
                }}
              >
                <WaitRadarChart
                  data={
                    /** preview のときは evaluations がないので振り分け */
                    !!engineer.evaluations
                    ? engineer.evaluations
                    : engineer?.evaluationSettings.map((e) => {
                      /** 評価数 */
                      e.count = engineer?.evaluations?.find((ev) => ev.no === e.no)?.count || 0
                      return e;
                    })
                  }
                  waitSecond={1.5}
                  width="100%"
                  height={200}
                  dataKeyLabel="label"
                  name="評価された数"
                  dataKeyCount="count"
                  colorStroke="#880015"
                  colorFill="#880015"
                />
              </View>
            )}
            <View
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              { /** preview のときは evaluations がないので振り分け */
              (!!engineer.evaluations
                  ? engineer.evaluations
                  : engineer?.evaluationSettings.map((e) => {
                    /** 評価数 */
                    e.count = engineer?.evaluations?.find((ev) => ev.no === e.no)?.count || 0
                    return e;
                  })
              ).map((e) => (
                <SuggestItem
                  key={`preview_evaluationSettings_${e.no}`}
                  item={e}
                  label={`${e.label} (${e.count})`}
                  onPress={isMe ? null : async () => {
                    try {
                      await registerEvaluationToggle(
                        engineer.accountNo,
                        e.no,
                      );
                      updateMyEvalActivity();
                      reload();
                    } catch (err) {
                      viewDialog(
                        'エラーが発生しました',
                        err?.response?.data?.errorMessage,
                        ['OK'],
                        300, 250);
                    }
                  }}
                  bgColor={isMe
                    ? {
                      selected: "#7F7F7F",
                      normal: "#7F7F7F",
                      custom: "#7F7F7F",
                    } : {
                    selected:
                      /** すでに自分が評価したマーク */
                      !!myEvaluationActivity?.find((mea) => mea.defNo === e.no)
                      ? "#7F7F7F" : "#880015",
                    normal:
                      /** すでに自分が評価したマーク */
                      !!myEvaluationActivity?.find((mea) => mea.defNo === e.no)
                      ? "#7F7F7F" : "#880015",
                    custom: "#7F7F7F",
                  }}
                  textColor={{
                    selected: "#fff",
                    normal: "#fff",
                    custom: "#fff",
                  }}
                />
              ))}
            </View>
          </>
        )
      }
      <Title label="基本スキル" fontSize={16} marginHorizontal={0} />
      {/* エンジニア種別・保有資格・その他スキルいずれもない場合 */}
      {
        (
          !optional(engineer.engineerCategories) &&
          !optional(engineer.examinations) &&
          !optional(engineer.otherSkills?.filter((e) => !!e.no))
        ) ?
          isMe ? (
            <Button
              linkStyle
              label="自分の情報を編集して、スキルを登録・シェア設定する"
              onPress={() => Link(Screen.EDIT_ACCOUNT, false)}
            />
          ) : (
            <T>未登録</T>
          )
        : (<></>)
      }
      {/* エンジニア種別 */}
      <View
        style={{
          flexWrap: 'wrap',
          flexDirection: 'row',
        }}
      >
        { optional(engineer.engineerCategories)?.map((e) => (
            <SuggestItem
              key={`preview_engineerCategories_${e.no}`}
              item={e}
              bgColor={Styles.ITEM_BG_COLOR_CATEGORIES}
              textColor={Styles.ITEM_TEXT_COLOR_CATEGORIES}
            />
          ))
        }
      </View>
      {/* 保有資格 */}
      <View
        style={{
          flexWrap: 'wrap',
          flexDirection: 'row',
        }}
      >
        { optional(engineer.examinations)?.map((e) => (
            <SuggestItem
              key={`preview_examinsations_${e.no}`}
              item={e}
              bgColor={Styles.ITEM_BG_COLOR_EXAMINATIONS}
              textColor={Styles.ITEM_TEXT_COLOR_EXAMINATIONS}
              style={Styles.ITEM_STYLE_EXAMINATIONS}
            />
          ))
        }
      </View>
      {/* その他スキル・言語対応 */}
      <View
        style={{
          flexWrap: 'wrap',
          flexDirection: 'row',
        }}
      >
        { optional(engineer.otherSkills?.filter((e) => !!e.no))?.map((e) => (
            <SuggestItem
              key={`preview_otherSkills_${e.no}`}
              item={e}
              label={`${e.label}${!!e.addition ? `(${e.addition})` : ''}`}
              bgColor={Styles.ITEM_BG_COLOR_OTHER_SKILLS}
              textColor={Styles.ITEM_TEXT_COLOR_OTHER_SKILLS}
              style={Styles.ITEM_STYLE_OTHER_SKILLS}
            />
          ))
        }
      </View>
      {/* 経歴サマリ情報 年数順、出現順にする */}
      {!isEmpty(engineer.careerDefsMonths) && (
        <>
          <Title label="経験月数" fontSize={16} marginHorizontal={0}/>
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {!isEmpty(engineer.careerDefsMonths.engineerCategories) && (
              <>
                {engineer.careerDefsMonths.engineerCategories.length > 2
                 ? (
                  <WaitRadarChart
                    data={engineer.careerDefsMonths.engineerCategories}
                    waitSecond={1.5}
                    // width={350} warning回避
                    // height={200}
                    dataKeyLabel="label"
                    name="経験月数"
                    dataKeyCount="termMonthsTotal"
                    colorStroke="#880015"
                    colorFill="#880015"
                  />
                 ): (
                  <View>
                    {engineer.careerDefsMonths.engineerCategories.sort(
                      (a, b) => b.termMonthsTotal - a.termMonthsTotal
                    ).map((item) => (
                      <T
                        style={[
                          Styles.LABEL_ENGINEER_CATEGORIES,
                          {
                            marginRight: 8,
                          }
                        ]}
                        key={`engineerCategory_key_${item.no}`}
                      >
                        {`${item.label}(${item.termMonthsTotal}月)`}
                      </T>
                    ))}
                  </View>
                 )
                }
              </>
            )}
            {!isEmpty(engineer.careerDefsMonths.processes) && (
              <>
                {engineer.careerDefsMonths.processes.length > 2
                 ? (
                  <WaitRadarChart
                    data={engineer.careerDefsMonths.processes}
                    waitSecond={1.5}
                    width={350}
                    height={200}
                    dataKeyLabel="label"
                    name="経験月数"
                    dataKeyCount="termMonthsTotal"
                    colorStroke="#880015"
                    colorFill="#880015"
                  />
                 ): (
                  <View>
                    {engineer.careerDefsMonths.processes.sort(
                      (a, b) => b.termMonthsTotal - a.termMonthsTotal
                    ).map((item) => (
                      <T
                        style={[
                          Styles.LABEL_PROCESSES,
                          {
                            marginRight: 8,
                          }
                        ]}
                        key={`process_key_${item.no}`}
                      >
                        {`${item.label}(${item.termMonthsTotal}月)`}
                      </T>
                    ))}
                  </View>
                 )
                }
              </>
            )}
            {!isEmpty(engineer.careerDefsMonths.skills) && (
              <>
                {engineer.careerDefsMonths.skills.length > 2
                 ? (
                  <WaitRadarChart
                    data={engineer.careerDefsMonths.skills}
                    waitSecond={1.5}
                    width={350}
                    height={200}
                    dataKeyLabel="label"
                    name="経験月数"
                    dataKeyCount="termMonthsTotal"
                    colorStroke="#880015"
                    colorFill="#880015"
                  />
                 ): (
                  <View>
                    {engineer.careerDefsMonths.skills.sort(
                      (a, b) => b.termMonthsTotal - a.termMonthsTotal
                    ).map((item) => (
                      <T
                        style={[
                          Styles.LABEL_SKILLS,
                          {
                            marginRight: 8,
                          }
                        ]}
                        key={`process_key_${item.no}`}
                      >
                        {`${item.label}(${item.termMonthsTotal}月)`}
                      </T>
                    ))}
                  </View>
                 )
                }
              </>
            )}
            {!isEmpty(engineer.careerDefsMonths.fields) && (
              <>
                {engineer.careerDefsMonths.fields.length > 2
                 ? (
                  <WaitRadarChart
                    data={engineer.careerDefsMonths.fields}
                    waitSecond={1.5}
                    width={350}
                    height={200}
                    dataKeyLabel="label"
                    name="業務分野"
                    dataKeyCount="termMonthsTotal"
                    colorStroke="#880015"
                    colorFill="#880015"
                  />
                 ): (
                  <View>
                    {engineer.careerDefsMonths.fields.sort(
                      (a, b) => b.termMonthsTotal - a.termMonthsTotal
                    ).map((item) => (
                      <T
                        style={[
                          Styles.LABEL_FIELDS,
                          {
                            marginRight: 8,
                          }
                        ]}
                        key={`fields_key_${item.no}`}
                      >
                        {`${item.label}(${item.termMonthsTotal}月)`}
                      </T>
                    ))}
                  </View>
                 )
                }
              </>
            )}
          </View>
        </>
      )}
      <Title label="経歴" marginHorizontal={0}/>
      {/* 経歴 */
      isEmpty(engineer.engineerCareers)
      ?
        isMe ? (
          <Button
            linkStyle
            label="自分の情報を編集して、経歴を登録する"
            onPress={() => LocationLink(LinkUrl.EDIT_ENGINEER(ENGINEER_TAB_KEY.career, false))}
          />
        ) : (
          <T>未登録</T>
        )
      : (
        <>
          {engineer.engineerCareers.map((item) => (
            <TouchableOpacity
              key={item.uuid}
              style={{
                borderBottomColor: '#888',
                borderBottomWidth: 1,
                paddingVertical: 4,
              }}
              onPress={() => expandCareer(item.uuid)}
            >
              {/* 経歴１行目　種別・名称・期間 */}
              <View
                style={{
                  justifyContent: 'flex-start',
                  flexDirection: 'row',
                }}
              >
                <T
                  style={{
                    fontWeight: 'bold',
                    color: Styles.COLOR_MAIN_ACCENT_BG
                  }}
                >&gt;</T>
                <T
                  style={{
                    fontWeight: 'bold',
                    marginRight: 8,
                  }}
                >
                  {label(CareerType, item.careerType)}
                </T>
                <T style={{fontWeight: 'bold', color: Styles.COLOR_MAIN_ACCENT_BG, marginLeft: 8}}>
                  [{viewTermShort(item)}]
                </T>
              </View>
              {/* 経歴２行目　スキル・分野*/}
              <View
                style={{
                  flexDirection: 'column',
                  alignSelf: 'flex-start',
                  marginLeft: 16,
                }}
              >
                {/* タイトル */}
                {isExpandCareersThis(item.uuid)
                && (
                  <View
                    style={{
                      justifyContent: 'flex-start',
                      alignContent: 'flex-start',
                      flexWrap: 'wrap',
                      flexDirection: isSp ? 'column' : 'row',
                    }}
                  >
                    <T style={{fontWeight: 'bold', color: Styles.COLOR_MAIN_ACCENT_BG}}>{item.projectName}</T>
                    <T style={{fontWeight: 'bold', color: Styles.COLOR_MAIN_ACCENT_BG, marginLeft: 8}}>[{viewTerm(item)}]</T>
                  </View>
                )}
                {/* カテゴリ、プロセス、スキル */}
                {isExpandCareersThis(item.uuid)
                ?
                  (!isEmpty(item.engineerCategories) || !isEmpty(item.processes))
                  && (
                    <View
                      style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                      }}
                    >
                      {!isEmpty(item.engineerCategories) &&
                        item.engineerCategories.map((e) => (
                        <T
                          style={[
                            Styles.LABEL_ENGINEER_CATEGORIES,
                            {
                              marginRight: 8,
                            }
                          ]}
                          key={`engineerCategory_key_${e.no}`}
                        >
                          {e.label}
                        </T>
                      ))}
                      {!isEmpty(item.processes) &&
                        item.processes.map((e) => (
                        <T
                          style={[
                            Styles.LABEL_PROCESSES,
                            {
                              marginRight: 8,
                            }
                          ]}
                          key={`process_key_${e.no}`}
                        >
                          {e.label}
                        </T>
                      ))}
                      {!isEmpty(item.skills) &&
                        item.skills.map((e) => (
                        <T
                          style={[
                            Styles.LABEL_SKILLS,
                            {
                              marginRight: 8,
                            }
                          ]}
                          key={`skill_key_${e.no}`}
                        >
                          {e.label}
                        </T>
                      ))}
                    </View>
                  )
                : (
                  <View
                    style={{
                      flex: 1,
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                    }}
                  >
                    {/* {!isEmpty(item.engineerCategories)
                    && (
                      <Text
                        style={styles.LABEL_ENGINEER_CATEGORIES}
                      >
                        {`${item.engineerCategories[0]?.label}${item.engineerCategories?.length > 1 ? '...' : ''}`}
                      </Text>
                    )}
                    {!isEmpty(item.processes)
                    && (
                      <Text
                        style={styles.LABEL_PROCESSES}
                      >
                        {`${item.processes[0]?.label}${item.processes?.length > 1 ? '...' : ''}`}
                      </Text>
                    )} */}
                    {!isEmpty(item.skills)
                    && (
                      <View
                        style={{
                          flexDirection: 'row',
                          flexWrap: 'nowrap',
                          marginRight: 8
                        }}
                      >
                        <SuggestItem
                          item={item?.skills[0]}
                          style={Styles.LABEL_SKILLS}
                        />
                        <T>{item.skills.length > 1 ? '...' : ''}</T>
                      </View>
                    )}
                    {!isEmpty(item.fields)
                    && (
                      <View
                        style={{
                          flexDirection: 'row',
                          flexWrap: 'nowrap',
                          marginRight: 8
                        }}
                      >
                        <SuggestItem
                          item={item?.fields[0]}
                          style={Styles.LABEL_FIELDS}
                        />
                        <T>{item.fields.length > 1 ? '...' : ''}</T>
                      </View>
                    )}
                  </View>
                )}
                {/* 企業 */}
                <View
                  style={{
                    justifyContent: 'flex-start',
                    alignContent: 'flex-start',
                    flexDirection: 'column',
                    flex: 1,
                    marginVertical: 8,
                    alignItems: 'flex-start',
                  }}
                >
                  { isExpandCareersThis(item.uuid) && !!item?.companyMain?.object && (
                    <T>{item?.companyMain?.object?.name}</T>
                  )}
                  { isExpandCareersThis(item.uuid) && !!item?.agent?.object && isExpandCareersThis(item.uuid) && (
                    <T>{item?.agent?.object?.name}</T>
                  )}
                  { !isEmpty(item?.fields) && (
                    <View
                      style={{
                        flex: 1,
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                      }}
                    >
                      {isExpandCareersThis(item.uuid)
                      && item.fields.map((field) => (
                          <SuggestItem
                            key={`field_${field.no}`}
                            item={field}
                            style={Styles.LABEL_FIELDS}
                          />
                        ))
                      }
                    </View>
                  )}
                  {/* 報酬 */}
                  { !isEmpty(item?.rewardHour) && (
                    <T>({
                      (
                        Math.round(parseInt(item.rewardHour, 10) / 500) * 500
                      ).toLocaleString()
                    }円/時)</T>
                  )}
                </View>
              </View>
            </TouchableOpacity>
          ))}
        </>
      )}
      {!isEmpty(engineer?.gitInfo) && (
        <>
          <Title
            label="Github" fontSize={16} marginHorizontal={0}
            help={`エンジニアのGithubリポジトリ情報。自身で作成したリポジトリから、言語利用状況を集計します。`}
          />
          <GitInfo
            data={engineer.gitInfo}
          />
        </>
      )}
      {/* <>
          <Title
            label="みんなの反応" fontSize={16} marginHorizontal={0}
            help={``}
          />
      </> */}
    </View>
  );
}

ViewEngineer.propTypes = {
  engineer: Engineer.propTypes.isRequired,
  reloadEngineer: PropTypes.func,
};

ViewEngineer.defaultProps = {
  reloadEngineer: () => {},
}
