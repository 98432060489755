import {
  StyleSheet, StatusBar, Platform,
} from 'react-native';

const HEADER_HEIGHT = 72;
const HEADER_HEIGHT_SP = 72;
const SP_WIDTH_THRESHOLD = 1000;
const COLOR_HEADER_BG = '#fff';
const COLOR_HEADER_TEXT = '#333';
const COLOR_MAIN_BG = '#FEF4E8';
const COLOR_MAIN_ACCENT_BG = '#E87729';
const COLOR_MAIN_LOWER_BG = '#F2B16B';
const COLOR_MAIN_LOWEST_BG = '#F5E2BE';

const COLOR_MAIN_TEXT = '#333';
const COLOR_MAIN_TEXT_IMPORTANT = '#f00';
const COLOR_MAIN_TEXT_STRONG = '#0000AA';
const COLOR_MAIN_TEXT_LINK = '#1967d2';
const COLOR_FOOTER_BG = '#FFcccc';
const COLOR_TOAST_BG = '#444444';
const COLOR_WAITING_INDICATOR = '#333';

const COLOR_CAUTION_BG = '#ff7f27';
const COLOR_CAUTION_TEXT = '#fff';

const COLOR_BUTTON_BG = '#cc4444';
const COLOR_BUTTON_BG_IMPORTANT = '#ff6233';
const COLOR_BUTTON_BG_DENGER = '#f00';
const COLOR_BUTTON_BG_DISABLED = '#bb8888';
const COLOR_BUTTON_LABEL = '#fff';
const COLOR_BUTTON_LABEL_DISABLED = '#aaa';

const COLOR_TOUCHABLE_ITEM_BG = '#fff4f4';

const COLOR_OTAYORI_ITEM_ICON = '#0000AA';

const COLOR_TEXT_INPUT_BG = '#fff8f8';
const COLOR_TEXT_INPUT_BG_DISABLE = '#664444';
const COLOR_TEXT_INPUT_TEXT = '#333';
const COLOR_TEXT_INPUT_TEXT_DISABLE = '#eee';

const COLOR_BG_DISABLED = '#ccc';

const COLOR_CARD_DEFAULT = '#16feb3';

const COLOR_ELEVATION_SHADOW = '#333';

// https://qiita.com/kaizen_nagoya/items/cb7eb3199b0b98904a35
const COLOR_SAFETY = '#00B06B';
const COLOR_DANGER = '#FF4B00';
const COLOR_CAUTION = '#F6AA00';
const COLOR_FINISH = '#1971FF';
const COLOR_MOST_DANGER = '#990099';

const QR_SIZE = 45;

const USER_ICON_SIZE = 64;
const USER_ICON_SIZE_SP = 40;
const USER_ICON_SIZE_HISTORIES = 48;
const USER_ICON_SIZE_SMALL = 32;

const ITEM_BG_COLOR_CATEGORIES = {
  normal: '#F2B16B',
  selected: COLOR_MAIN_ACCENT_BG,
  custom: '#8BDF4C',
};

const ITEM_TEXT_COLOR_CATEGORIES = {
  normal: '#fff',
  selected: '#fff',
  custom: '#333',
};

const ITEM_BG_COLOR_EXAMINATIONS = {
  normal: '#EDB692',
  selected: '#92705A',
  custom: '#8BDF4C',
};

const ITEM_TEXT_COLOR_EXAMINATIONS = {
  normal: '#000',
  selected: '#fff',
  custom: '#333',
};

const ITEM_STYLE_EXAMINATIONS = {
  borderRadius: 8,
};

const ITEM_BG_COLOR_OTHER_SKILLS = {
  normal: '#fff',
  selected: '#fff',
  custom: '#8BDF4C',
};

const ITEM_TEXT_COLOR_OTHER_SKILLS = {
  normal: '#BBA699',
  selected: '#BBA699',
  custom: '#333',
};

const ITEM_STYLE_OTHER_SKILLS = {
  borderColor: '#C4B1A6',
  borderWidth: 2,
  borderRadius: 0,
};

const STYLES = StyleSheet.create({
  TOP_CONTAINER: {
    flex: 1,
    top: 0,
    width: '100%',
    flexDirection: 'column',
    backgroundColor: COLOR_HEADER_BG,
    paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0,
  },
  SCROLL_VIEW: {
    flexDirection: 'column',
    backgroundColor: COLOR_MAIN_BG,
  },
  MAIN_VIEW: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: COLOR_MAIN_BG,
    width: '100%',
    marginHorizontal: 0,
    paddingHorizontal: 0,
  },
  MAIN_VIEW_FOR_SCROLL: {
    flexGrow: 1,
    flexDirection: 'column',
    backgroundColor: COLOR_MAIN_BG,
    width: '100%',
    marginHorizontal: 0,
    paddingHorizontal: 0,
    marginVertical: 0,
    paddingVertical: 0,
  },
  SCROLL_BOTTOM_MARGIN: {
    borderTopColor: '#000',
    borderTopWidth: 1,
    height: 50,
    marginTop: 16,
  },
  MAIN_VIEW_FOR_WAITING: {
    flex: 1,
    top: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLOR_MAIN_BG,
  },
  FOOTER: {
    width: '100%',
    padding: 0,
    backgroundColor: COLOR_FOOTER_BG,
  },
  USER_ICON: {
    width: USER_ICON_SIZE,
    height: USER_ICON_SIZE,
    borderRadius: USER_ICON_SIZE,
  },
  HEADER_LOGO: {
    width: 270,
    height: 64,
  },
  HEADER_LOGO_SP: {
    width: 202,
    height: 48,
  },
  FONT: {
    fontFamily: 'NotoSansJP_400Regular',
  },
  BOLD_FONT: {
    fontFamily: 'NotoSansJP_700Bold',
  },
  LABEL_ENGINEER_CATEGORIES: {
    fontWeight: 'bold',
    color: COLOR_MAIN_ACCENT_BG,
    marginVertical: 2,
  },
  LABEL_PROCESSES: {
    fontWeight: 'bold',
    color: COLOR_MAIN_TEXT_STRONG,
    marginVertical: 2,
  },
  LABEL_SKILLS: {
    fontWeight: 'bold',
    color: '#000',
    backgroundColor: '#ccc',
    paddingHorizontal: 8,
    marginVertical: 2,
    borderRadius: 4,
  },
  LABEL_FIELDS: {
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: COLOR_MAIN_ACCENT_BG,
    paddingHorizontal: 8,
    marginVertical: 2,
    borderRadius: 14,
  },
  LABEL_AREAS: {
    fontSize: 14,
  },
  LABEL_EXAMINATIONS: {
    backgroundColor: '#92705A',
    color: '#fff',
    borderRadius: 4,
    paddingHorizontal: 8,
    marginVertical: 2,
    fontSize: 14,
  },
  LABEL_REQUESTS: {
    borderWidth: 1,
    borderColor: '#00f',
    borderRadius: 4,
    marginVertical: 2,
    fontSize: 14,
  },
});

export default {
  HEADER_HEIGHT,
  HEADER_HEIGHT_SP,
  SP_WIDTH_THRESHOLD,
  TOP_CONTAINER: STYLES.TOP_CONTAINER,
  SCROLL_VIEW: STYLES.SCROLL_VIEW,
  MAIN_VIEW: STYLES.MAIN_VIEW,
  MAIN_VIEW_FOR_SCROLL: STYLES.MAIN_VIEW_FOR_SCROLL,
  MAIN_VIEW_FOR_WAITING: STYLES.MAIN_VIEW_FOR_WAITING,
  FOOTER: STYLES.FOOTER,
  SCROLL_BOTTOM_MARGIN: STYLES.SCROLL_BOTTOM_MARGIN,
  USER_ICON: STYLES.USER_ICON,
  HEADER_LOGO: STYLES.HEADER_LOGO,
  HEADER_LOGO_SP: STYLES.HEADER_LOGO_SP,
  QR_SIZE,
  FONT: STYLES.FONT,
  BOLD_FONT: STYLES.BOLD_FONT,
  USER_ICON_SIZE,
  USER_ICON_SIZE_SP,
  USER_ICON_SIZE_HISTORIES,
  USER_ICON_SIZE_SMALL,
  COLOR_CAUTION_BG,
  COLOR_CAUTION_TEXT,
  COLOR_BUTTON_BG,
  COLOR_BUTTON_BG_IMPORTANT,
  COLOR_BUTTON_BG_DENGER,
  COLOR_BUTTON_BG_DISABLED,
  COLOR_BUTTON_LABEL,
  COLOR_BUTTON_LABEL_DISABLED,
  COLOR_TOUCHABLE_ITEM_BG,
  COLOR_OTAYORI_ITEM_ICON,
  COLOR_TEXT_INPUT_BG,
  COLOR_TEXT_INPUT_BG_DISABLE,
  COLOR_TEXT_INPUT_TEXT,
  COLOR_TEXT_INPUT_TEXT_DISABLE,
  COLOR_BG_DISABLED,
  COLOR_ELEVATION_SHADOW,
  COLOR_HEADER_BG,
  COLOR_HEADER_TEXT,
  COLOR_MAIN_BG,
  COLOR_MAIN_ACCENT_BG,
  COLOR_MAIN_LOWER_BG,
  COLOR_MAIN_LOWEST_BG,
  COLOR_MAIN_TEXT,
  COLOR_MAIN_TEXT_IMPORTANT,
  COLOR_MAIN_TEXT_STRONG,
  COLOR_MAIN_TEXT_LINK,
  COLOR_FOOTER_BG,
  COLOR_TOAST_BG,
  COLOR_WAITING_INDICATOR,
  COLOR_CARD_DEFAULT,
  COLOR_SAFETY,
  COLOR_DANGER,
  COLOR_CAUTION,
  COLOR_FINISH,
  COLOR_MOST_DANGER,
  ITEM_BG_COLOR_CATEGORIES,
  ITEM_TEXT_COLOR_CATEGORIES,
  ITEM_BG_COLOR_EXAMINATIONS,
  ITEM_TEXT_COLOR_EXAMINATIONS,
  ITEM_STYLE_EXAMINATIONS,
  ITEM_BG_COLOR_OTHER_SKILLS,
  ITEM_TEXT_COLOR_OTHER_SKILLS,
  ITEM_STYLE_OTHER_SKILLS,
  LABEL_ENGINEER_CATEGORIES: STYLES.LABEL_ENGINEER_CATEGORIES,
  LABEL_PROCESSES: STYLES.LABEL_PROCESSES,
  LABEL_SKILLS: STYLES.LABEL_SKILLS,
  LABEL_FIELDS: STYLES.LABEL_FIELDS,
  LABEL_AREAS: STYLES.LABEL_AREAS,
  LABEL_EXAMINATIONS: STYLES.LABEL_EXAMINATIONS,
  LABEL_REQUESTS: STYLES.LABEL_REQUESTS,
};
