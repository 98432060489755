import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet} from 'react-native';
import LocationLink from '../../common/functions/LocationLink';
import Styles from '../Styles';
import { AppContext } from './AppContext';
import ImageButton from '../../common/components/ImageButton';
import T from '../components/T';
import { IMAGE_LOGO } from '../Definition';
import ButtonGoogle from '../../common/components/sns/ButtonGoogle';
import { googleAnalyticsTracking } from '../../common/functions/google/GoogleAnalytics';
import UserIcon from '../screen/component/UserIcon';
import { LinkUrl } from '../LinkUrl';
import IconText, {DIR} from '../../common/components/IconText';

/**
 * 共通ヘッダ
 * @param {*} props
 */
export default function Header(props) {
  const {
    openDrawer,
    closeDrawer,
  } = props;

  const { isSp, userInfo, googleLogin, isOpenDrawer } = useContext(AppContext);

  const pushOpener = () => {
    if (isOpenDrawer) {
      closeDrawer();
    } else {
      openDrawer();
    }
  };

  return (
    <View
      style={{
        backgroundColor: Styles.COLOR_HEADER_BG,
        height: isSp ? Styles.HEADER_HEIGHT_SP : Styles.HEADER_HEIGHT,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'stretch',
      }}
    >
      <View
        style={{
          borderWidth: 1,
          borderColor: '#e1e1f7',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <IconText
          dir={DIR.ANTDESIGN}
          icon={isOpenDrawer ? "doubleleft" : "doubleright"}
          onPress={pushOpener}
        ></IconText>
      </View>
      <View
        // logo
        style={[
          isSp
          ? {
            alignItems: 'flex-start',
            flexDirection: 'column',
          } : {
            alignItems: 'flex-end',
            flexDirection: 'row',
          },
        ]}
      >
        <ImageButton
          buttonStyle={isSp ? Styles.HEADER_LOGO_SP : Styles.HEADER_LOGO}
          imageStyle={isSp ? Styles.HEADER_LOGO_SP : Styles.HEADER_LOGO}
          image={IMAGE_LOGO}
          onPress={() => LocationLink(LinkUrl.TOP, false)}
        />
        <T
          style={{
            marginLeft: 8,
            marginBottom: isSp ? 0 : 8,
          }}
        >
          エンジニアのための共創型プラットフォーム
        </T>
      </View>
      <View
        // userIcon
        style={{
          alignItems: 'flex-end',
          marginRight: 16,
          flex: 1,
        }}
      >
        {!!userInfo?.account
        ? (
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <UserIcon
              nickName={userInfo.engineer?.nickName || userInfo.client?.compName}
              size={isSp ? Styles.USER_ICON_SIZE_SP : Styles.USER_ICON_SIZE}
              image={userInfo.engineer?.iconUrl || userInfo.client?.compIconUrl || userInfo.userIcon}
              onPress={pushOpener}
            />
          </View>
        ) : (
          <>
            {
              /*******************************
              *  ここは dev のみ
              *****************************/
            }
            <ButtonGoogle
              label="ログイン"
              onPress={() => {
                googleAnalyticsTracking('click', 'menu', 'googleLogin');
                googleLogin();
              }}
            />
          </>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  menuMargin: {
    marginLeft: 16,
  },
});

Header.propTypes = {
  openDrawer: PropTypes.func.isRequired,
  closeDrawer: PropTypes.func.isRequired,
};
