import React from 'react';
import { StyleSheet, View, } from 'react-native';
import PropTypes from 'prop-types';
import Button from '../../../common/components/Button';
import LocationLink from '../../../common/functions/LocationLink';
import LabelPieChart from '../../../common/components/LabelPieChart';
import { DataTable } from 'react-native-paper';
import { LinkUrl } from '../../LinkUrl';

const OTHERS_THREASHOLD = 0.05;

/**
 * Github スキル情報表示
 * @param {*} props
 * @returns ボタン
 */
export default function GitInfo(props) {
  const {
    data
  } = props;

  // ratio が OTHERS_THREASHOLD 以下はその他にまとめる
  const viewData = [];
  const otherRecord = {
    language: 'その他',
    count: 0,
    details: [],
  };

  data.usedLanguages.forEach((e) => {
    if (e.ratio <= OTHERS_THREASHOLD) {
      otherRecord.count += e.count;
      otherRecord.details.push({
        language: e.language,
        count: e.count,
      });
    } else {
      viewData.push({
        language: e.language,
        count: e.count,
      });
    }
  });

  if (otherRecord.count > 0) {
    viewData.push(otherRecord);
  }

  const tableData = data.usedLanguages.sort((a, b) => b.count - a.count).map((e) => (
    {
      language: e.language,
      count: e.count.toLocaleString(),
    }
  ));

  return (
    <>
      <Button
        linkStyle
        label={LinkUrl.GITHUB(data.userName)}
        onPress={() => LocationLink(LinkUrl.GITHUB(data.userName), true)}
      />
      <View
        style={{
          width: '100%',
          height: 'fit-content',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        <View style={{minWidth: 350}}>
          <LabelPieChart
            data={viewData}
            nameKey="language"
            dataKey="count"
            fill="#cd853f"
          />
        </View>
        <View>
          <DataTable>
            {tableData?.map((row) => (
              <DataTable.Row
                key={row.language}
                style={{
                  minHeight: 24,
                  cursor: 'normal',
                }}
          >
                <DataTable.Cell
                  style={{
                    flexWrap: 'nowrap',
                    width: 120,
                    cursor: 'normal',
                  }}
                >
                  {row.language}
                </DataTable.Cell>
                <DataTable.Cell
                  style={{
                    flexWrap: 'nowrap',
                    width: 100,
                    cursor: 'normal',
                  }}
                  numeric
                >
                  {row.count}
                </DataTable.Cell>
              </DataTable.Row>
            ))}
          </DataTable>
        </View>
      </View>
    </>
  );
}

GitInfo.propTypes = {
  data: PropTypes.shape({
    userName: PropTypes.string.isRequired,
    repoLangList: PropTypes.arrayOf(
      PropTypes.shape({
        repoName: PropTypes.string.isRequired,
        languages: PropTypes.arrayOf(
          PropTypes.shape({
            language: PropTypes.string.isRequired,
            count: PropTypes.number.isRequired,
          }).isRequired
        ).isRequired,
      }).isRequired
    ).isRequired,
    usedLanguages: PropTypes.arrayOf(
      PropTypes.shape({
        language: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
        ratio: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
};

const styles = StyleSheet.create({
  rowsTextStyle: {textAlign: 'right'},
});
