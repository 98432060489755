import React, { useState } from 'react';
import {
  StyleSheet, View, ScrollView,
} from 'react-native';
import PropTypes from 'prop-types';
import Tooltip from 'react-native-walkthrough-tooltip';
import Icon from 'react-native-vector-icons/FontAwesome';
import Icon5 from 'react-native-vector-icons/FontAwesome5';
import Foundation from 'react-native-vector-icons/Foundation';
import Ionicons from 'react-native-vector-icons/Ionicons';
import SimpleLineIcons from 'react-native-vector-icons/SimpleLineIcons';
import Entypo from 'react-native-vector-icons/Entypo';
import AntDesign from 'react-native-vector-icons/AntDesign';
import MaterialCommunity from 'react-native-vector-icons/MaterialCommunityIcons';
import { isString } from '../util/TypeUtil';
import T from '../../src/components/T';
import Styles from '../../src/Styles';

export const DIR = {
  FONTAWESOME: 'FONTAWESOME',
  FONTAWESOME5: 'FONTAWESOME5',
  FOUNDATION: 'FOUNDATION',
  IONICONS: 'IONICONS',
  SIMPLE_LINE_ICONS: 'SIMPLE_LINE_ICONS',
  ENTYPO: 'ENTYPO',
  ANTDESIGN: 'ANTDESIGN',
  MATERIAL_COMMUNITY: 'MATERIAL_COMMUNITY',
};

/**
 * FontAwesome類のアイコン付きでテキストを表示
 * アイコンをセットしなければ通常のテキスト同様
 * ヘルプ内容を入れると、？アイコンを表示、タップで説明モーダルでヘルプ内容を表示
 * @param {*} props
 * @returns
 */
export default function IconText(props) {
  const {
    children, containerStyle, textStyle, icon, dir, iconSize, iconColor, isIconLast,
    fontSize, help, subIcons, appendNode, onPress,
  } = props;

  const [isViewTooltip, setIsViewTooltip] = useState(false);

  const viewIcon = () => {
    const iconStyle = {
      margin: 0,
      padding: 0,
    };

    if (iconColor) {
      iconStyle.color = iconColor;
    }

    if (icon) {
      const adjustStyleArray = [iconStyle];
      if (iconColor) {
        adjustStyleArray.push({ color: iconColor });
      }

      switch (dir) {
        case DIR.FONTAWESOME5:
          return (
            <Icon5 name={icon} size={iconSize} style={adjustStyleArray} onPress={onPress} />
          );
        case DIR.FOUNDATION:
          return (
            <Foundation name={icon} size={iconSize} style={adjustStyleArray} onPress={onPress} />
          );
        case DIR.IONICONS:
          return (
            <Ionicons name={icon} size={iconSize} style={adjustStyleArray} onPress={onPress} />
          );
        case DIR.SIMPLE_LINE_ICONS:
          return (
            <SimpleLineIcons name={icon} size={iconSize} style={adjustStyleArray} onPress={onPress} />
          );
        case DIR.ENTYPO:
          return (
            <Entypo name={icon} size={iconSize} style={adjustStyleArray} onPress={onPress} />
          );
        case DIR.ANTDESIGN:
          return (
            <AntDesign name={icon} size={iconSize} style={adjustStyleArray} onPress={onPress} />
          );
        case DIR.MATERIAL_COMMUNITY:
          return (
            <MaterialCommunity name={icon} size={iconSize} style={adjustStyleArray} onPress={onPress} />
          );
        default:
          return (
            <Icon name={icon} size={iconSize} style={adjustStyleArray} onPress={onPress} />
          );
      }
    }
    return <></>;
  };

  /**** 保留
  const viewHelp = () => {
    const { width, height } = Dimensions.get('window');
    WModal.show({
      data: ' ',
      textColor: '#000',
      backgroundColor: 'rgba(33, 33, 33, .95)',
      position: WModal.position.CENTER,
      isEasing: 10,
      onPressOut: () => WModal.hide(),
      icon: (
        <>
          <View
            style={{
              width: width - 100,
              height: height - 100,
              flexDirection: 'column',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <T
              style={{
                fontWeight: 'bold',
                color: '#fff',
                textDecorationLine: 'underline',
                fontSize: 24,
                marginVertical: 8,
              }}
              multiline
            >
              {viewIcon()}
              {children}
            </T>
            <ScrollView
              style={{
                height: height - 150,
                maxHeight: height - 150,
              }}
            >
              { Array.isArray(help)
                ? help.map((caption, index) => (
                  <T
                    key={`helpTest_${index.toString()}`}
                    style={{
                      fontWeight: 'bold',
                      color: '#fff',
                      fontSize: 20,
                      marginVertical: 8,
                    }}
                    multiline
                  >
                    {caption}
                  </T>
                )) : (
                  <T
                    style={{
                      fontWeight: 'bold',
                      color: '#fff',
                      fontSize: 20,
                      marginVertical: 8,
                    }}
                    multiline
                  >
                    {help}
                  </T>
                )}
            </ScrollView>
            <TouchableOpacity
              key="help"
              onPress={() => {
                WModal.hide();
              }}
              style={{
                width: '100%',
                height: 100,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <T
                style={{
                  fontWeight: 'bold',
                  color: '#fff',
                  textDecorationLine: 'underline',
                  fontSize: 22,
                }}
              >
                閉じる
              </T>
            </TouchableOpacity>
          </View>
        </>
      ),
    });
  };
  ***/

  return (
    <View style={[
      styles.mainContainer,
      containerStyle,
      !!onPress && { cursor: 'pointer' },
    ]}
    >
      { !!dir && !!icon && !isIconLast && viewIcon() }
      { !!children && (
        <T
          style={[
            { fontSize },
            textStyle,
          ]}
          onPress={onPress}
        >
          {children}
        </T>
      )}
      { !!subIcons && !!subIcons.length > 0 && subIcons.map((i, index) => (
        <T
          key={`subIcon_${index.toString()}`}
          style={{
            marginLeft: 8,
            backgroundColor: i?.backgroundColor,
            borderRadius: 16,
            fontSize: fontSize * 0.7,
            color: i?.color,
            paddingHorizontal: 16,
            paddingVertical: 1,
          }}
          onPress={onPress}
        >
          {i.text}
        </T>
      ))}
      { !!dir && !!icon && isIconLast && viewIcon() }
      { !!help && (
        <View>
          <Tooltip
            tooltipStyle={{
              minHeight: 80,
            }}
            isVisible={isViewTooltip}
            content={
              (() =>
                isString(help)
                ? (
                  <ScrollView>
                    <T
                      style={[
                        Styles.FONT,
                        {
                          padding: 8,
                          paddingBottom: 16,
                        }
                      ]}
                    >
                      {`${help}　　`}
                    </T>
                  </ScrollView>
                )
                : help
              )()
            }
            placement="top"
            onClose={() => setIsViewTooltip(false)}
          >
            <Ionicons
              name="help-circle-outline"
              onPress={() => setIsViewTooltip(true)} //viewHelp
              onMagicTap={alert}
              color={iconColor}
              size={iconSize * (iconSize === IconText.defaultProps.iconSize ? 1 : 1.4)}
            />
          </Tooltip>
        </View>
      )}
      { appendNode }
    </View>
  );
}

IconText.propTypes = {
  children: PropTypes.node,
  containerStyle: PropTypes.any,
  textStyle: PropTypes.any,
  icon: PropTypes.string,
  iconSize: PropTypes.number,
  isIconLast: PropTypes.bool,
  dir: PropTypes.oneOf(Object.values(DIR)),
  iconColor: PropTypes.string,
  fontSize: PropTypes.number,
  help: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.node,
  ]),
  subIcons: PropTypes.arrayOf(
    PropTypes.exact({
      backgroundColor: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ),
  appendNode: PropTypes.node,
  onPress: T.propTypes.onPress,
};

IconText.defaultProps = {
  children: (<></>),
  containerStyle: null,
  textStyle: null,
  icon: null,
  iconSize: 24,
  isIconLast: false,
  dir: null,
  iconColor: null,
  fontSize: 16,
  help: null,
  subIcons: null,
  appendNode: null,
};

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    // justifyContent: 'center',
  },
});
