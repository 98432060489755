const { createCanvas, loadImage } = require('canvas');
const QRCode = require('qrcode');

/** サイトアイコン付きQRコードを生成して canvas インスタンスを返却する */
export const createQrCode = async (data, logo, qrSize, logoSize, setter) => {
  const _qrSize = qrSize || 128;
  // canvas準備
  const canvas = createCanvas(_qrSize, _qrSize);

  // QRコードの設定準備
  const segment = [
    {
      data,
      mode: 'byte'
    },
  ];

  const options = {};

  // QRコード生成
  QRCode.toCanvas(canvas, segment, options, (error) => {
    if (!!logo) {
      loadImage(logo).then((_logo) => {
        const _logoSize = logoSize || Math.floor(_qrSize * 0.2);
        const logoPos  = Math.floor((canvas.height - _logoSize) / 2);  // y

        // 画像を載せる
        const ctx = canvas.getContext('2d');
        ctx.drawImage(_logo, logoPos, logoPos, _logoSize, _logoSize);

        // setter に canvas をセット
        setter(canvas);
      });
    } else {
      setter(canvas);
    }
  });
}
