import React, { useState, useEffect, useContext } from 'react';
import {
  StyleSheet, View, Text,
} from 'react-native';
import PropTypes from 'prop-types';
import { AppContext } from '../../context/AppContext';
import { TITLE_ICON } from '../../Definition';
import TitledInput from '../../components/TitledInput';
import TitledSelect from '../../components/TitledSelect';
import Validator from '../../components/Validator';
import TitledInputSuggest from '../../components/TitledInputSuggest';
import * as TypeUtil from '../../../common/util/TypeUtil';
import useDefinition from '../../hooks/useDefinition';
import { CareerType, EngineerCareer, Monetize, DEF_CATEGORY } from '../../types/Types';
import TitledComponent from '../../components/TitledComponent';
import uuid from '../../../common/util/Uuid';
import TitledInputSuggestCompany from '../../components/TitledInputSuggestCompany';
import { formatDate } from '../../../common/util/DateUtils';

// EngineerCareer で扱う DEFS
export const ENGINEER_CAREER_DEFS_CATEGORIES = [
  DEF_CATEGORY.FIELDS,
  DEF_CATEGORY.PROCESSES,
  DEF_CATEGORY.SKILLS,
  DEF_CATEGORY.ENGINEER_CATEGORIES,
];

// 現在日時
const NOW = new Date();

// デフォルト年月
const DEFAULT_YM = '000000';

// キー入力がもたつくので、useEffect の onChange を非同期遅延させるタイマ
let onChangeTimer;

/**
 * エンジニア経歴登録の１エントリ部品
 * @param onChange このコンポーネントで修正された値を上位に渡す
 * @returns
 */
export default function EditEngineerCareerItem(props) {
  const { onChange, value } = props;

  const { userInfo, isSp } = useContext(AppContext);

  const [careerType, setCareerType] = useState(value?.careerType || CareerType.ses.value);
  const [termSt, setTermSt] = useState(value?.termSt || formatDate(NOW, 'yyyyMM'));
  const [termEd, setTermEd] = useState(value?.termEd || DEFAULT_YM);
  const [projectName, setProjectName] = useState(value?.projectName); //string
  const [fields, setFields] = useState(value?.fields || []); //defs array
  const [engineerCategories, setEngineerCategories] = useState(value?.engineerCategories || []); //defs array
  const [processes, setProcesses] = useState(value?.processes || []);
  const [skills, setSkills] = useState(value?.skills || []);
  const [companyMain, setCompanyMain] = useState(value?.companyMain);
  const [agent, setAgent] = useState(value?.agent);
  const [monetize, setMonetize] = useState(value?.monetize || '');
  const [numPersonOfTeam, setNumPersonOfTeam] = useState(value?.numPersonOfTeam || '');
  const [rewardMonth, setRewardMonth] = useState(value?.rewardMonth || '');
  const [hoursForReward, setHoursForReward] = useState(value?.hoursForReward || '');
  const [rewardHour, setRewardHour] = useState(value?.rewardHour || '');
  const [productUrl, setProductUrl] = useState(value?.productUrl);
  const [pr, setPr] = useState(value?.pr);

  const defItems = useDefinition(...ENGINEER_CAREER_DEFS_CATEGORIES);

  // 値の変更時に変更トリガーON、タイムアウトで上流へ変更
  useEffect(() => {
    clearTimeout(onChangeTimer);
    onChangeTimer = setTimeout(() => {
      onChange({
        uuid: value?.uuid || uuid(),
        no: value?.no || null,
        termSt,
        termEd,
        careerType,
        projectName,
        fields,
        engineerCategories,
        processes,
        skills,
        companyMain,
        agent,
        monetize: parseInt(monetize, 10) || null,
        numPersonOfTeam: parseInt(numPersonOfTeam, 10) || null,
        productUrl,
        pr,
        rewardMonth: parseInt(rewardMonth, 10) || null,
        hoursForReward: parseInt(hoursForReward, 10) || null,
        rewardHour: parseInt(rewardHour, 10) || null,
        isValid: termSt && termEd && projectName
          && !TypeUtil.isEmpty(careerType)
          ,
      });
    }, 500);
  }, [
    termSt, termEd, careerType, projectName,
    fields, engineerCategories, processes, skills,
    companyMain, agent, monetize, numPersonOfTeam, productUrl, pr,
    rewardMonth, hoursForReward, rewardHour,
  ]);

  // 現在年月
  const NOW_Y = NOW.getFullYear();
  const NOW_M = NOW.getMonth() + 1;

  // 開始時期選択リスト 終了年月（未入力なら現在年月）をMaxとして、1980までを選択かとする
  const itemsYmStart = () => {
    const ret = [];
    const maxY = parseInt(termEd?.slice(0, 4), 10) || NOW_Y;
    const nowM = parseInt(termEd?.slice(4), 10) || NOW_M;
    for (let y = maxY; y >= 1980; y--) {
      const startM = y === maxY ? nowM : 12;
      for (let m = startM; m >= 1; m--) {
        const yyyy = String(y).padStart(4, '0');
        const mm = String(m).padStart(2, '0');
        ret.push({
          value: `${yyyy}${mm}`,
          label: `${yyyy}年 ${m}月`,
        });
      }
    }
    return ret;
  };

  // 終了時期選択リスト 開始年月（未入力なら 1980-01）から来年の１２月まで
  const itemsYmEnd = () => {
    const ret = [{value: DEFAULT_YM, label: '継続中'}];
    const startY = parseInt(termSt?.slice(0, 4), 10) || 1980;
    const startM = parseInt(termSt?.slice(4), 10) || 1;
    for (let y = NOW_Y + 1; y >= startY; y--) {
      const endM = y === startY ? startM : 1;
      for (let m = 12; m >= endM; m--) {
        const yyyy = String(y).padStart(4, '0');
        const mm = String(m).padStart(2, '0');
        ret.push({
          value: `${yyyy}${mm}`,
          label: `${yyyy}年 ${m}月`,
        });
      }
    }
    return ret;
  };

  const calcRewardPerHour = (inputReward, inputHours) => {
    try {
      const _reward = parseInt(inputReward || rewardMonth, 10);
      const _hours = parseInt(inputHours || hoursForReward, 10);
      if (!!_reward && !!_hours) {
        setRewardHour(Math.floor(_reward * 10000 / _hours).toString());
      }
    } catch (e) {
      ;
    }
  };

  if (!defItems) {
    return (<></>);
  }

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'stretch',
        justifyContent: 'flex-start',
      }}
    >
      <TitledInput
        placeholder="プロジェクト名"
        onChangeText={setProjectName}
        value={projectName}
        maxLength={64}
        validator={new Validator(
          (t) => (!t) ? '必須入力です' : null,
          (t) => (t.length > 64) ? '64文字以内' : null,
        )}
        subIcons={[
          TITLE_ICON.required,
          TITLE_ICON.public,
        ]}
      />
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'end',
        }}
      >
        <TitledComponent
          componentStyle={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            paddingTop: 8,
            alignItems: 'baseline',
          }}
          help="この案件に従事していた期間"
          subIcons={[
            TITLE_ICON.required,
            TITLE_ICON.public,
          ]}
        >
          <TitledSelect
            placeholder="開始時期"
            onChange={(v) => !!v ? setTermSt(v) : setTermSt(DEFAULT_YM)}
            defaultValue={termSt}
            items={itemsYmStart()}
            fieldStyle={{width: 120}}
          />
          <Text>～</Text>
          <TitledSelect
            placeholder="終了時期"
            onChange={(v) => !!v ? setTermEd(v) : setTermEd(DEFAULT_YM)}
            defaultValue={termEd}
            items={itemsYmEnd()}
            fieldStyle={isSp ? {width: 120} : {marginLeft: 0, width: 120}}
          />
        </TitledComponent>
        <TitledSelect
          placeholder="経歴の概要"
          help="この経歴の種別"
          defaultValue={careerType.toString()}
          onChange={(v) => {
            let setValue = CareerType.ses.value;
            if (!!v) {
              setValue = parseInt(v, 10);
            }
            setCareerType(setValue);
          }}
          items={Object.values(CareerType)}
          fieldStyle={{
            width: 150,
            fontSize: 12,
          }}
          subIcons={[
            TITLE_ICON.noRequired,
            TITLE_ICON.public,
          ]}
        />
        <TitledInput
          placeholder="チーム人数"
          keyboardType="numeric"
          help="この経歴であなたが属する最小単位のチームの人数"
          onChangeText={(v) => {
            if (!v || v == parseInt(v, 10)) {
              setNumPersonOfTeam(v);
            }
          }}
          value={numPersonOfTeam}
          maxLength={5}
          width={100}
          // height={40}
          subIcons={[
            TITLE_ICON.noRequired,
            TITLE_ICON.public,
          ]}
        />
      </View>
      <TitledInputSuggest
        multiple
        componentKey="fieldsSelect"
        label="業界・分野"
        help="案件がどんな業界や分野の製作であるか"
        onChange={setFields}
        value={fields}
        items={defItems.fields}
        isEnableCustomValue
        subIcons={[
          TITLE_ICON.noRequired,
          TITLE_ICON.public,
        ]}
      />
      <TitledInputSuggest
        multiple
        componentKey="engineerCategorySelect"
        label="担当カテゴリ"
        help="あなたがどんな担当であるか"
        onChange={setEngineerCategories}
        value={engineerCategories}
        items={defItems.engineerCategories}
        isEnableCustomValue
        subIcons={[
          TITLE_ICON.noRequired,
          TITLE_ICON.public,
        ]}
      />
      <TitledInputSuggest
        multiple
        componentKey="processesSelect"
        label="担当工程"
        help="あなたが担当するプロジェクト内の工程"
        onChange={setProcesses}
        value={processes}
        items={defItems.processes}
        isEnableCustomValue
        subIcons={[
          TITLE_ICON.noRequired,
          TITLE_ICON.public,
        ]}
      />
      <TitledInputSuggest
        multiple
        componentKey="skillsSelect"
        label="関連スキル"
        help="この経歴で活用されたあなたのスキル"
        onChange={setSkills}
        value={skills}
        items={defItems.skills}
        isEnableCustomValue
        subIcons={[
          TITLE_ICON.noRequired,
          TITLE_ICON.public,
        ]}
      />
      { (careerType == CareerType.ses.value || careerType == CareerType.work.value)
      && (
        <TitledComponent
          componentStyle={{
            justifyContent: 'flex-start',
            alignContent: 'flex-start',
            flexDirection: 'row',
            flexWrap: 'wrap',
            flex: 1,
          }}
          help={`この経歴の参画先${careerType == CareerType.ses.value ? '、仲介エージェント': ''}の企業名`}
          subIcons={[
            TITLE_ICON.noRequired,
            TITLE_ICON.selectablePublish,
          ]}
        >
          <TitledInputSuggestCompany
            componentKey="companyMain"
            label="参画先企業"
            onChange={(v) => setCompanyMain(v)}
            value={companyMain}
          />
          {careerType == CareerType.ses.value && (
            <TitledInputSuggestCompany
              componentKey="agent"
              label="仲介エージェント企業"
              onChange={(v) => setAgent(v)}
              value={agent}
            />
          )}
        </TitledComponent>
      )}
      { careerType == CareerType.business.value && (
        <>
          <TitledSelect
            placeholder="マネタイズ"
            help="この経歴がマネタイズされているか"
            onChange={setMonetize}
            defaultValue={monetize}
            items={Object.values(Monetize)}
            subIcons={[
              TITLE_ICON.noRequired,
              TITLE_ICON.public,
            ]}
          />
          <TitledInput
            multiline
            placeholder="プロダクトURL・Git-URL・PR等"
            onChangeText={setProductUrl}
            value={productUrl || ''}
            subIcons={[
              TITLE_ICON.noRequired,
              TITLE_ICON.public,
            ]}
          />
        </>
      )}
      <TitledInput
        multiline
        placeholder="この経歴で得たことや自己PR"
        onChangeText={setPr}
        value={pr || ''}
        subIcons={[
          TITLE_ICON.noRequired,
          TITLE_ICON.public,
        ]}
      />
      <TitledComponent
        componentStyle={{
          justifyContent: 'flex-start',
          alignContent: 'flex-start',
          flexDirection: 'row',
          flexWrap: 'wrap',
          flex: 1,
          marginVertical: 8,
        }}
        help="この経歴の報酬です。公開する場合は時間報酬の概数のみが表示されます。"
        subIcons={[
          TITLE_ICON.noRequired,
          TITLE_ICON.selectablePublish,
        ]}
      >
        <TitledInput
          placeholder="月額報酬（万円-税込）"
          keyboardType="numeric"
          onChangeText={(v) => {
            if (!v || v == parseInt(v, 10)) {
              setRewardMonth(v);
              calcRewardPerHour(v, null);
            }
          }}
          value={rewardMonth}
          maxLength={3}
          onBlur={() => calcRewardPerHour()}
        />
        <TitledInput
          placeholder="月額報酬最低所要時間"
          keyboardType="numeric"
          onChangeText={(v) => {
            if (!v || v == parseInt(v, 10)) {
              setHoursForReward(v);
              calcRewardPerHour(null, v);
            }
          }}
          value={hoursForReward}
          onBlur={() => calcRewardPerHour()}
        />
        <TitledInput
          placeholder="時間報酬（円-税込）"
          keyboardType="numeric"
          onChangeText={setRewardHour}
          value={rewardHour}
        />
      </TitledComponent>
    </View>
  );

}

EditEngineerCareerItem.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: EngineerCareer.propTypes,
};

EditEngineerCareerItem.defaultProps = {
  value: EngineerCareer.defaultProps,
};

const styles = StyleSheet.create({
  buttonContainer: {
    paddingHorizontal: 56,
  },
  imageContainer: {
    width: '100%',
    alignItems: 'center',
  },
  image: {
    width: 100,
    height: 100,
  },
  buttonStyle: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'flex-start',
    marginVertical: 2,
    backgroundColor: '#0288e6',
    borderRadius: 50,
    paddingHorizontal: 8,
    paddingVertical: 2,
    height: 40,
    shadowColor: '#000',
    shadowOffset: {
      width: 4,
      height: 8,
    },
    shadowOpacity: 0.4,
    elevation: 16,
  },
  buttonDisabledStyle: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'flex-start',
    marginVertical: 2,
    backgroundColor: '#0288e6',
    borderRadius: 50,
    paddingHorizontal: 8,
    paddingVertical: 2,
    height: 40,
    shadowColor: '#000',
    shadowOffset: {
      width: 4,
      height: 8,
    },
    shadowOpacity: 0.4,
    elevation: 16,
  },
});
