import { useEffect, useState } from 'react';
import { getDefinitions } from '../functions/data/Server';

/**
 * 定義後の取得Hook
 *
 * @param categories 定義後カテゴリ名の文字列配列
 * @returns JSON categories の各要素をキーとして整理して返却
 */
export default function useDefinition(...categories) {
  const [defItems, setDefItems] = useState({});

  useEffect(() => {
    (async () => {
      const serverResult = await getDefinitions(
        categories.join(','));
      const result = {};
      categories.forEach((c) => {
        result[c] = serverResult?.filter((e) => e.category === c);
      });
      setDefItems(result);
    })();
  }, []);

  return defItems;
}
