import React, { useContext, useCallback, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { AppContext } from '../context/AppContext';
import * as Server from '../functions/data/Server';
import { download } from '../../common/functions/Download';
import { toastSorry } from '../functions/Toast';
import T from '../components/T';
import { error } from '../../common/util/Log';

/**
 * 経歴書
 * @returns
 */
export default function CareerSheetDownloadPublic(routeParam) {
  const { route } = routeParam;
  const { params } = route;
  const { key } = !!params ? params : {};
  const { dimensions } = useContext(AppContext);

  const [ downloadResult, setDownloadResult ] = useState('ダウンロードしています');

  // ダウンロード処理
  const downloadCareerSheet = useCallback(async () => {
    try {
      const serverResult = await Server.get(null, 'engineer/careerSheetPublic', {key});
      if (!serverResult?.data?.excel) {
        setDownloadResult(`キーが存在しないか、期限が切れている、
またはダウンロード回数上限に達したため、
ダウンロードできませんでした`);
        return;
      }
      download(serverResult?.data?.excel, '経歴書.xlsx');
      setDownloadResult('ダウンロード完了しました');
    }catch(err) {
      error(JSON.stringify(err))
      toastSorry(err.errorMessage, 3000);
    }
  }, []);

  useEffect(() => {
    downloadCareerSheet();
  }, []);

  return (
    <View
      style={{
        flex: 1,
        height: dimensions?.height,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <T>{downloadResult}</T>
    </View>
  );
}

const styles = StyleSheet.create({
  h1: {
    marginTop: 16,
    fontSize: 24,
    fontWeight: 'bold',
    alignSelf: 'center',
  },
  h2: {
    marginTop: 16,
    marginLeft: 8,
    fontSize: 20,
    fontWeight: 'bold',
    alignSelf: 'flex-start',
  },
  h3: {
    marginTop: 16,
    marginLeft: 8,
    fontSize: 16,
    alignSelf: 'flex-start',
  },
  fullWidth: {
    width: '100%',
  },
  header: {
    alignSelf: 'flex-end',
    marginRight: 8,
  },
  description: {
    alignSelf: 'flex-start',
    flexWrap: 'wrap',
    marginLeft: 24,
  }
});
