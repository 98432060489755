import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../context/AppContext';
import EditAccountEngineer, {ENGINEER_TAB_KEY} from './component/EditAccountEngineer';
import { getSelfSecretInfo } from '../functions/data/Server';
import Waiting from '../components/Waiting';
import EditAccountClient from './component/EditAccountClient';
import log from '../../common/util/Log';

/**
 * アカウント作成
 * @returns
 */
export default function EditAccount(routeParam) {
  const { route } = routeParam;
  const { params } = route;
  const { tab } = params;
  const { userInfo } = useContext(AppContext);

  const accountType = !!userInfo?.engineer ? 'engineer' : 'client';

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    (async () => {
      const secretInfo = await getSelfSecretInfo();
      if (!!secretInfo) {
        Object.keys(secretInfo).map((k) => {
          userInfo[accountType][k] = secretInfo[k];
        });
      }
      setIsInitialized(true);
    })();
  }, []);

  if (!isInitialized) {
    return (<Waiting/>);
  }

  return (
    <>
      { accountType === 'client' && (
        // <RegisterAccountRequester
        // />
        <EditAccountClient
          value={userInfo?.client}
        />
      )}
      { accountType === 'engineer' && (
        <EditAccountEngineer
          key="editAccountEngineer"
          isEditMode
          isPersonalInfo={!!userInfo?.engineer}//{tab === ENGINEER_TAB_KEY.personalData} 個人情報入力に直接誘導する場合はこれ
          value={userInfo?.engineer}
          tab={tab}
        />
      )}
    </>
  );

}
