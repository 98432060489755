import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { View, } from 'react-native';
import PropTypes from 'prop-types';
import * as ImagePicker from 'expo-image-picker';
import { IconButton } from "@react-native-material/core";
import AntDesign from 'react-native-vector-icons/AntDesign';
import { AppContext } from '../../context/AppContext';
import TitledInput from '../../components/TitledInput';
import { toastSorry } from '../../functions/Toast';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import Validator from '../../components/Validator';
import TabBox, { TabBoxItem } from '../../../common/components/TabBox';
import { ICON_MAX_SIZE, TITLE_ICON } from '../../Definition';
import * as Server from '../../functions/data/Server';
import TitledComponent from '../../components/TitledComponent';
import TitledInputSuggest from '../../components/TitledInputSuggest';
import PlusButton from '../../../common/components/PlusButton';
import { checkDateStr } from '../../../common/util/DateUtils';
import TitledAddressInput from '../../components/TitledAddressInput';
import Styles from '../../Styles';
import Button from '../../../common/components/Button';
import EditEngineerCareer from './EditEngineerCareer';
import PreviewAccountEngineer from './PreviewAccountEngineer';
import TitledStationSelect from '../../components/TitledStationSelect';
import { Engineer, PublishLevel, DEF_CATEGORY } from '../../types/Types';
import useDefinition from '../../hooks/useDefinition';
import TitledInputSuggestAddition from '../../components/TitledInputSuggestAddition';
import { isEmpty, optional } from '../../../common/util/TypeUtil';
import TitledSelect from '../../components/TitledSelect';
import TitledToggle from '../../components/TitledToggle';
import { exStringFullToHalf, removeFullChar } from '../../../common/util/TextUtil';
import UserIcon from './UserIcon';
import { saveSession } from '../../../common/functions/LocalStorage';
import T from '../../components/T';
import { registerCautionEngineer } from '../../Term';
import Terms from '../Terms';
import { LinkUrl } from '../../LinkUrl';
import LocationLink from '../../../common/functions/LocationLink';

export const ENGINEER_TAB_KEY = {
  basicInfo: 'basicInfo',
  career: 'career',
  personalData: 'personalData',
  setting: 'setting',
};

/**
 * アカウント作成・修正(エンジニア)
 * ニックネーム
 * アイコン
 *
 * @param onChange このコンポーネントで修正された値を上位に渡す
 * @param isEditMode
 *    修正モードのときtrue 修正モードのときは、既存情報を取得するのと、個人情報入力欄が現れる違いがある
 * @param isPersonalInfo
 *    isEditMode が true のとき、かつこれが true だと、個人情報が入力必須になる
 * @returns
 */
export default function EditAccountEngineer(props) {
  const { isEditMode, isPersonalInfo, value, tab } = props;
  const {
    userInfo,
    isSp,
    dimensions,
    viewDialog,
    setConfirmBeforeLink
  } = useContext(AppContext);

  /** 個人情報設定の活性 */
  const [isViewPersonal, setIsViewPersonal] = useState(isPersonalInfo);

  /** プレビュー表示 */
  const [isPreview, setIsPreview] = useState(!isSp);
  /** タブの切り替え
   * basicInfo career personalData setting
   */
  const [viewTabKey, setViewTabKey] = useState(tab);
  /** isEditMode false or true で表示される */
  const [nickName, setNickName] = useState(value?.nickName || '');
  const [uniqueName, setUniqueName] = useState(value?.uniqueName || '');
  const [iconUrl, setIconUrl] = useState(value?.iconUrl);
  const [engineerCategories, setEngineerCategories] = useState(value?.engineerCategories || []); //defs array
  const [examinations, setExaminations] = useState(value?.examinations || []); //defs array
  const [otherSkills, setOtherSkills] = useState(value?.otherSkills || [{}]); //defs array
  const [areas, setAreas] = useState(value?.areas || []); //defs array
  /* 0:非公開 1:フレンドのみ 2:エンジニアのみ 3:全公開 　経歴書を送った先は個人情報含み全公開 */
  const [settingCareerPublishLevel, setSettingCareerPublishLevel]
   = useState(value?.settingCareerPublishLevel || Engineer.defaultProps.settingCareerPublishLevel); //PublishLevel
  /* 0:非公開 1:フレンドのみ 2:エンジニアのみ 3:全公開 　経歴書を送った先は個人情報含み全公開 */
  const [settingAreaPublishLevel, setSettingAreaPublishLevel]
   = useState(value?.settingAreaPublishLevel || Engineer.defaultProps.settingAreaPublishLevel); //PublishLevel

  /** isEditMode true でのみ表示される */
  // 経歴リスト
  const [engineerCareers, setEngineerCareers] = useState(value?.engineerCareers);
  // 個人情報
  const [name, setName] = useState(value?.name || "");
  const [kana, setKana] = useState(value?.kana || "");
  const [birthday, setBirthday] = useState(value?.birthday || ""); // yyyymmdd
  const [prefCd, setPrefCd] = useState(value?.prefCd || ""); // code
  const [cityCd, setCityCd] = useState(value?.cityCd || ""); // code
  const [town, setTown] = useState(value?.town || "");
  const [build, setBuild] = useState(value?.build || "");
  const [tel, setTel] = useState(value?.tel || "");
  const [stationGCd, setStationGCd] = useState(value?.stationGCd); // code
  const [schools, setSchools] = useState(value?.schools); //defs one

  // 経歴書情報
  const [communication, setCommunication] = useState(value?.communication || "");
  const [management, setManagement] = useState(value?.management || "");
  const [languageSkill, setLanguageSkill] = useState(value?.languageSkill || "");
  const [logicalThinking, setLogicalThinking] = useState(value?.logicalThinking || "");
  const [teamwork, setTeamwork] = useState(value?.teamwork || "");
  const [ownership, setOwnership] = useState(value?.ownership || "");
  const [catchup, setCatchup] = useState(value?.catchup || "");
  const [adaptation, setAdaptation] = useState(value?.adaptation || "");
  const [problemDiscovery, setProblemDiscovery] = useState(value?.problemDiscovery || "");
  const [presentation, setPresentation] = useState(value?.presentation || "");
  const [negotiation, setNegotiation] = useState(value?.negotiation || "");

  const [requests, setRequests] = useState(value?.requests); //defs array
  const [workStatuses, setWorkStatuses] = useState(value?.workStatuses); //defs array
  const [evaluationSettings, setEvaluationSettings] = useState(value?.evaluationSettings); //defs array

  /* TRUE:全公開 FALSE:履歴は何者からも閲覧されない */
  const [settingIsHistoryPublic, setSettingIsHistoryPublic]
   = useState(value?.settingIsHistoryPublic || Engineer.defaultProps.settingIsHistoryPublic); //bool
  /* TRUE:検索される FALSE:企業アカウントから検索されない */
  const [settingIsTargetByCompany, setSettingIsTargetByCompany]
   = useState(value?.settingIsTargetByCompany || false);

  /* 0:非公開 1:フレンドのみ 2:エンジニアのみ 3:全公開 　経歴に登録された企業名 */
  const [settingCareerCompanyPublishLevel, setSettingCareerCompanyPublishLevel] =
    useState(value?.settingCareerCompanyPublishLevel || PublishLevel.friendOnly.value);

  /* 0:非公開 1:フレンドのみ 2:エンジニアのみ 3:全公開 　報酬額 */
  const [settingCareerRewardPublishLevel, setSettingCareerRewardPublishLevel] =
    useState(value?.settingCareerRewardPublishLevel || PublishLevel.friendOnly.value);

  /** 管理項目 */
  /** 値を変えることにより、state の機能を利用して再表示を促す */
  const [review, setReview] = useState(true);

  /** エラー */
  const [error, setError] = useState();
  const [errorNickName, setErrorNickName] = useState();
  const [errorUniqueName, setErrorUniqueName] = useState();

  /** defs Items */
  const defItems = useDefinition(
    DEF_CATEGORY.ENGINEER_CATEGORIES,
    DEF_CATEGORY.EXAMINATIONS,
    DEF_CATEGORY.OTHER_SKILLS,
    DEF_CATEGORY.AREAS,
    DEF_CATEGORY.SCHOOLS,
    DEF_CATEGORY.REQUESTS,
    DEF_CATEGORY.WORK_STATUSES,
    DEF_CATEGORY.EVALUATION_SETTINGS,
  );

  useEffect(() => {
    // icon を userInfo のものに差し替える
    if (!value?.iconUrl && userInfo?.userIcon) {
      setIconUrl(userInfo?.userIcon);
    }
    // 移動前確認 ON
    setConfirmBeforeLink('修正は破棄されます。よろしいですか？');
  }, []);

  const checkRegistableName = useCallback(async (nickName, uniqueName) => {
    let ret = true;
    const {
      isRegistableNickName,
      isRegistableUniqueName,
    } = await Server.getRegistableName(nickName, uniqueName);
    if (!isRegistableNickName) {
      setErrorNickName('すでに使われています');
      ret = false;
    }
    if (!isRegistableUniqueName) {
      setErrorUniqueName('すでに使われています');
      ret = false;
    }
    return ret;
  }, []);

  // 基本情報のバリデーション
  // @param checkRegistableLazy registableチェックを遅延で行って、実行数を少なくする場合true
  // 保存実行時は false、name入力時は true を期待
  const timerNameInput = useRef(null);
  const isValidBasicInfo = async (checkRegistableLazy) => {
    let ret = true;
    if (!nickName) {
      setErrorNickName('必須入力です');
      ret = false;
    }else {
      setErrorNickName();
    }
    if (!uniqueName) {
      setErrorUniqueName('必須入力です');
      ret = false;
    }else {
      setErrorUniqueName();
    }
    if (!ret) {
      return false;
    }
    if (!!nickName && !!uniqueName) {
      if (checkRegistableLazy) {
        clearTimeout(timerNameInput.current);
        timerNameInput.current = setTimeout(
          () => checkRegistableName(nickName, uniqueName),
          500);
      } else {
        ret = await checkRegistableName(nickName, uniqueName);
      }
    }
    if (!ret) {
      return false;
    }
    setError();
    return true;
  };

  // uniqueNameセッタ 想定する文字に合わせる
  const setUniqueNameSanitize = useCallback((v) => {
    if (v) {
      setUniqueName(removeFullChar(v)?.replace(/\s/g, '')?.replace(/[^0-9a-zA-Z]/g, '')?.toLowerCase());
    } else {
      setUniqueName('');
    }
  }, []);

  // バリデーション対象項目（基本情報エリア）のeffect
  useEffect(() => {
    isValidBasicInfo(true);
  }, [nickName, uniqueName]);

  // 経歴情報のバリデーション
  const isValidCareerInfo = () => {
    if (!isEmpty(engineerCareers)) {
      for (let ec of engineerCareers) {
        if (!ec.isValid) {
          return false;
        }
      }
    }
    setError();
    return true;
  };

  const makeEngineerData = () => ({
    accountNo: userInfo?.account?.accountNo,
    nickName,
    uniqueName,
    iconUrl,
    name,
    kana,
    birthday,
    prefCd,
    cityCd,
    town,
    build,
    tel,
    stationGCd,
    /* TRUE:全公開 FALSE:履歴は何者からも閲覧されない */
    settingIsHistoryPublic,
    /* TRUE:検索される FALSE:企業アカウントから検索されない */
    settingIsTargetByCompany,
    /* 0:非公開 1:フレンドのみ 2:エンジニアのみ 3:全公開 　経歴書を送った先は個人情報含み全公開 */
    settingCareerPublishLevel: !!settingCareerPublishLevel
      ? parseInt(settingCareerPublishLevel, 10) : null,
    /* 0:非公開 1:フレンドのみ 2:エンジニアのみ 3:全公開 　経歴書を送った先は個人情報含み全公開 */
    settingAreaPublishLevel: !!settingAreaPublishLevel
      ? parseInt(settingAreaPublishLevel, 10) : null,
    /* 0:非公開 1:フレンドのみ 2:エンジニアのみ 3:全公開 　経歴に登録された企業名 */
    settingCareerCompanyPublishLevel: !!settingCareerCompanyPublishLevel
      ? parseInt(settingCareerCompanyPublishLevel, 10) : null,
    /* 0:非公開 1:フレンドのみ 2:エンジニアのみ 3:全公開 　経歴に登録された報酬額 */
    settingCareerRewardPublishLevel: !!settingCareerRewardPublishLevel
      ? parseInt(settingCareerRewardPublishLevel, 10) : null,
    /* TRUE:マッチング支援パートナー FALSE:通常 ユーザ変更不可 */
    settingIsMatchingPartner: null,

    /* 経歴書情報 */
    communication,
    management,
    languageSkill,
    logicalThinking,
    teamwork,
    ownership,
    catchup,
    adaptation,
    problemDiscovery,
    presentation,
    negotiation,

    /** 付属情報 */
    engineerCategories,
    examinations,
    otherSkills: optional(otherSkills),
    areas,
    schools: !!schools?.label ? schools : null,
    requests,
    workStatuses,
    evaluationSettings,
    engineerCareers,

    /** アピール */
    communication,
    management,
    languageSkill,
    logicalThinking,
    teamwork,
    ownership,
    catchup,
    adaptation,
    problemDiscovery,
    presentation,
    negotiation,
  });

  // バリデートエラーのときの処理共通
  const validateErrorFunc = useCallback((tab, errorMessage) => {
    setViewTabKey(tab);
    if (isSp && isPreview) {
      setIsPreview(false);
    }
    setError(errorMessage);
    toastSorry(errorMessage, 3000);
  }, []);

  const validateBeforeRegister = async () => {
    if (!await isValidBasicInfo()) {
      validateErrorFunc(ENGINEER_TAB_KEY.basicInfo, '基本情報に入力が不十分な項目があります');
      return false;
    }
    if (isEditMode && !isValidCareerInfo()) {
      validateErrorFunc(ENGINEER_TAB_KEY.career, '経歴情報に入力が不十分な項目があります');
      return false;
    }
    return true;
  };

  const register = async () => {
    if (! await validateBeforeRegister()) {
      return;
    }

    const registerJson = makeEngineerData();
    // iconURL は ファイルセットされると{uri: ...} になる
    // サーバから取得すると 文字列URL 形式なので、型合わせする
    if (!!registerJson?.iconUrl?.uri) {
      registerJson.iconUrl = registerJson.iconUrl.uri;
    }

    // csrfキーを追加
    registerJson.csrfKey = await Server.requestCsrfKey();

    /** 始めての登録のときは利用規約を表示 */
    if (!isEditMode) {
      const confirm = await viewDialog(
        'サービス利用規約・ポリシー',
        (<Terms/>),
        ['同意して登録', '同意しない'],
        dimensions.width, dimensions.height);
      if (confirm?.selected !== '同意して登録') {
        return;
      }
    } else {
      /** 登録の都度、機密情報の留意事項を表示 */
      const confirm = await viewDialog(
        '登録内容はあなたの契約上問題ありませんか？',
        registerCautionEngineer,
        ['登録する', '登録しない'],
        dimensions.width, 300);
      if (confirm?.selected !== '登録する') {
        return;
      }
    }

    // もう一度バリデーション
    if (!validateBeforeRegister()) {
      return;
    }

    try {
      // iconUrl が変更されているか
      registerJson.isChangeIcon = value?.iconUrl != iconUrl;
      const result = await Server.authSendWhenFailSave(
        'engineer/register',
        registerJson,
      );
      /** はじめてのときはウェルカムメッセージ */
      if (!isEditMode) {
        await viewDialog(
          '登録ありがとうございます！',
          `ぜひたくさんのエンジニアと交流してください。
          もし必要なくなったら、右上のアイコンからアカウント編集-案件条件＆設定をひらいて、アカウント削除できます。
          気になることがあったら、ぜひフィードバックをお願いしますね！
          `,
          ['OK'],
          400, 300);
      }
      LocationLink(LinkUrl.TOP, false, true);
    } catch(err) {
      // await viewDialog(
      //   '登録に失敗しました',
      //   err?.response?.data?.errorMessage || `${!!err ? err?.message + "\n" + err?.stack : ''}`,
      //   ['OK'],
      //   400, 300);
      toastSorry(err?.response?.data?.errorMessage);
    }
  };

  const changeImage = useCallback(async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      base64: true,
      quality: 0.3,
      exif: false,
      selectionLimit: 1,
    });
    if (!result.canceled && result?.assets?.length > 0) {
      // sizeチェック
      if (result?.assets[0]?.uri?.length > ICON_MAX_SIZE) {
        toastSorry(`画像は ${ICON_MAX_SIZE / 1000000}MB 程度にしてください`, 3000);
        return;
      }
      setIconUrl({
        uri: result?.assets[0]?.uri,
      });
    }
  }, []);

  /** アカウント削除処理 */
  const deleteAccount = useCallback(async () => {
    const answer = await viewDialog(
      'アカウント情報を削除します',
      `この操作は復元できません。
ご自身で登録した情報や、友達からの評価などの情報を削除します。
削除後は新規アカウント登録をしていただくことはできます。

削除を実施してよろしいですか？`,
      ['OK', 'Cancel'],
      350, 300);
    if (answer?.selected !== 'OK') {
      return;
    }
    const reAnswer = await viewDialog(
      'ごめんなさい。もう一度確認',
      `運営はとっても悲しいですが、データを削除します。

削除する場合は、OKを選択してください`,
      ['OK', 'Cancel'],
      350, 300);
    if (reAnswer?.selected !== 'OK') {
      return;
    }
    try {
      const result = await Server.send(
        userInfo?.token,
        'engineer/delete',
        {},
      );
      if (result?.data?.result !== 'OK') {
        throw result;
      }
      await viewDialog(
        '削除しました。',
        `ご利用ありがとうございました。
またきっと、利用しにきてくださいね！`,
        ['OK'],
        300, 250);
      saveSession(Server.LOCAL_KEY_FAILSAVE, null);
      saveSession(Server.LOCAL_KEY_USERINFO, null);
      LocationLink(LinkUrl.LP, false);
    } catch(err) {
      toastSorry(err?.response?.data?.errorMessage || err);
    }
  }, [userInfo]);

  const engineer = makeEngineerData();
  // GitInfo
  engineer.gitInfo = value?.gitInfo;

  return (
    <View
      style={[
        Styles.MAIN_VIEW,
        {
          flexDirection: 'row',
          alignContent: 'space-between',
        }
      ]}
    >
      <View
        style={{
          flexDirection: 'column',
          flex: 1,
        }}
      >

        { /** プレビュー時　sp の時はプレビューだけ、
            *              pc の時は、編集とプレビューが並列
            * 非プレビューなら編集画面のみ
            */
        (isSp && !isPreview) || !isSp || !isPreview ? (
          <TabBox
            title="エンジニア 情報登録"
            help="あなたの情報を登録します"
            tabHeight={isSp ? 80 : 50}
            style={{
              marginTop: 16,
              marginHorizontal: 16,
              marginBottom: 8,
            }}
            containerStyle={{
              paddingRight: 8,
            }}
            viewTabKey={viewTabKey}
            setViewTabKey={setViewTabKey}
          >
            <TabBoxItem
              caption={isSp ? '基本 情報' : '基本情報'}
              key={ENGINEER_TAB_KEY.basicInfo}
            >
              {/** ニックネーム、アイコン */}
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  flexWrap: 'wrap',
                  marginRight: 8,
                }}
              >
                <View
                  style={{
                    flex: 1,
                    flexDirection: isSp || isPreview
                      ? 'column'
                      : 'row',
                    maxWidth: 700,
                  }}
                >
                  {/** ニックネーム */}
                  <TitledInput
                    placeholder="ニックネーム"
                    help="経歴書など本名が必要なもの以外で使われる基本の名前です"
                    onChangeText={(e) => {
                      setNickName(e);
                      if (!(isEditMode || !!value)) {
                        setUniqueNameSanitize(e);
                      }
                    }}
                    value={nickName}
                    maxLength={24}
                    validator={new Validator(
                      (t) => ((!t) ? '必須入力です' : null),
                      (t) => ((t > 24) ? '24文字以内' : null),
                    )}
                    subIcons={[
                      TITLE_ICON.required,
                      TITLE_ICON.public,
                    ]}
                    error={errorNickName}
                    style={[
                      { flex: 1, },
                      Styles.FONT,
                    ]}
                  />
                  {/** ユニークネーム 新規登録時以外は修正不可 */
                  isEditMode || !!value
                  ? (
                    <T style={{marginLeft: 24}}>{`@${uniqueName}`}</T>
                  ) : (
                    <TitledInput
                      placeholder="ユニークネーム(半角:変更不可)"
                      help="URLやキーとして使われる唯一無二の名前です。後から変更はできません。"
                      onChangeText={(e) => setUniqueNameSanitize(e)}
                      value={uniqueName}
                      maxLength={24}
                      validator={new Validator(
                        (t) => ((!t) ? '必須入力です' : null),
                        (t) => ((t > 24) ? '24文字以内' : null),
                      )}
                      subIcons={[
                        TITLE_ICON.required,
                        TITLE_ICON.public,
                      ]}
                      error={errorUniqueName}
                      style={[
                        { flex: 1, },
                        Styles.FONT,
                      ]}
                    />
                  )}
                </View>
                {/** アイコン */}
                <TitledComponent
                  componentStyle={{
                    flexDirection: 'column',
                    marginLeft: Styles.USER_ICON_SIZE / 2,
                    flex: 1,
                  }}
                  help={`画像クリックで選択（${ICON_MAX_SIZE / 1000000}MB以内）`}
                >
                  <UserIcon
                    nickName={nickName}
                    size={Styles.USER_ICON_SIZE}
                    image={iconUrl}
                    onPress={() => changeImage()}
                  />
                  <T style={{color: '#00f'}} onPress={() => setIconUrl(userInfo?.userIcon)}>Default</T>
                </TitledComponent>
              </View>
              {/* エンジニア種別 */}
              <TitledInputSuggest
                multiple
                key="engineerCategoriesSelect"
                componentKey="engineerCategoriesSelect"
                label="エンジニア種別"
                help={`表示領域により省略される場合、先頭より優先的に表示されます。`}
                modalHelp={`表示領域により省略される場合、先頭より優先的に表示されます。`}
                onChange={
                  // （別インスタンスとするため concat）
                  (e) => setEngineerCategories(e.concat())
                }
                items={defItems.engineerCategories}
                value={engineerCategories}
                subIcons={[
                  TITLE_ICON.public,
                ]}
                isEnableCustomValue
                itemBgColor={Styles.ITEM_BG_COLOR_CATEGORIES}
                itemTextColor={Styles.ITEM_TEXT_COLOR_CATEGORIES}
              />
              {/* 保有資格 */}
              <TitledInputSuggest
                multiple
                key="examinationsSelect"
                componentKey="examinationsSelect"
                label="保有資格"
                help={`表示領域により省略される場合、先頭より優先的に表示されます。`}
                modalHelp={`表示領域により省略される場合、先頭より優先的に表示されます。`}
                onChange={
                  // （別インスタンスとするため concat）
                  (e) => setExaminations(e)
                }
                items={defItems.examinations}
                value={examinations}
                subIcons={[
                  TITLE_ICON.public,
                ]}
                isEnableCustomValue
                itemBgColor={Styles.ITEM_BG_COLOR_EXAMINATIONS}
                itemTextColor={Styles.ITEM_TEXT_COLOR_EXAMINATIONS}
                itemStyle={Styles.ITEM_STYLE_EXAMINATIONS}
              />
              {/* その他スキル */}
              <TitledComponent
                key="otherSkills"
                componentKey="otherSkills"
                help={`エンジニア関連の他、様々な活動を登録してみてください。
表示領域により省略される場合、先頭より優先的に表示されます。`}
                componentStyle={{
                  flex: 1,
                  flexDirection: 'column',
                  marginTop: 0,
                  padding: 0,
                }}
                style={{
                  marginTop: 8,
                  marginBottom: 16,
                  flex: 1,
                }}
                subIcons={[
                  TITLE_ICON.public,
                ]}
              >
                { otherSkills?.map((e, index) => (
                  <View
                    key={`engineerOtherSkilView${e?.no || 'none'}_${index.toString()}`}
                    style={{
                      width: '100%',
                      flexDirection: 'row',
                      flexWrap: 'nowrap',
                      alignSelf: 'flex-start',
                      marginBottom: 8,
                    }}
                  >
                    <IconButton
                      key={`engineerOtherSkilDelButton_${e?.no || 'none'}_${index.toString()}`}
                      icon={props => <AntDesign name="delete" {...props} />}
                      onPress={() => {
                        // その他スキル値セッタ
                        let newData = otherSkills?.filter((current, i) => (i !== index));
                        if (isEmpty(newData)) {
                          newData = [{}];
                        }
                        setOtherSkills(newData);
                      }}
                      style={{
                        minWidth: 48,
                        maxWidth: 48,
                        alignSelf: 'center',
                      }}
                    />
                    <TitledInputSuggestAddition
                      key={`engineerOtherSkilItemKey_${e?.no || 'none'}_${index.toString()}`}
                      componentKey={`engineerOtherSkilItem_${e?.no || 'none'}_${index.toString()}`}
                      label="その他スキル 外国語"
                      placeholder="レベル 称号など"
                      modalHelp={`エンジニア関連の他、様々な活動を登録してみてください。
表示領域により省略される場合、先頭より優先的に表示されます。`}
                      items={defItems.otherSkills}
                      onChange={(v) => {
                        // その他スキル値セッタ
                        const newData = otherSkills?.map((current, i) => (i === index) ? v : current);
                        setOtherSkills(newData);
                      }}
                      value={e}
                      isEnableCustomValue
                      itemBgColor={Styles.ITEM_BG_COLOR_OTHER_SKILLS}
                      itemTextColor={Styles.ITEM_TEXT_COLOR_OTHER_SKILLS}
                      style={{
                        flex: 1,
                        flexWrap: 'wrap',
                      }}
                      itemStyle={[
                        Styles.ITEM_STYLE_OTHER_SKILLS,
                      ]}
                      vertical={isSp}
                    />
                  </View>
                ))}
                <PlusButton
                  onPress={() => {
                    // その他スキルの部品追加（別インスタンスとするため concat）
                    const newData = otherSkills.concat();
                    newData.push({});
                    setOtherSkills(newData);
                    setReview(!review);
                  }}
                />
              </TitledComponent>
              { /** エリア */ }
              <TitledInputSuggest
                key="engineerAreaKey"
                componentKey="engineerAreaKey"
                label="活動エリア"
                help={`表示領域により省略される場合、先頭より優先的に表示されます。
後で公開・非公開を選択できますが、非公開にしても、アカウントを特定しない統計、ランク情報としては利用することがあります。`}
                modalHelp={`表示領域により省略される場合、先頭より優先的に表示されます。
後で公開・非公開を選択できますが、非公開にしても、アカウントを特定しない統計、ランク情報としては利用することがあります。`}
                items={defItems.areas}
                multiple
                onChange={(v) => setAreas(v)}
                value={areas}
                isEnableCustomValue
                subIcons={[
                  TITLE_ICON.selectablePublish,
                ]}
                appendNode={(
                  <>
                    <TitledSelect
                      key="areaPublishLevelSelect"
                      componentKey="areaPublishLevelSelect"
                      items={Object.values(PublishLevel)}
                      defaultValue={settingAreaPublishLevel}
                      onChange={(v) => setSettingAreaPublishLevel(v)}
                      style={{
                        height: 20,
                      }}
                      fieldStyle={{
                        height: 20,
                      }}
                    />
                  </>
                )}
              />

              <TitledToggle
                title="活動履歴の公開設定"
                help="あなたのこのサイトでの活動履歴を 公開 / 非公開 に設定します"
                toggleLabelOn="公開"
                toggleLabelOff="非公開"
                value={settingIsHistoryPublic}
                onChange={setSettingIsHistoryPublic}
              />

              <TitledToggle
                title="クライアント企業の検索可否（クライアント企業側機能は準備中）"
                help="企業などクライアントユーザーが、このサイトで検索したとき、あなたが検索対象になるかを設定します"
                toggleLabelOn="検索の対象です"
                toggleLabelOff="検索されません"
                value={settingIsTargetByCompany}
                onChange={setSettingIsTargetByCompany}
              />

            </TabBoxItem>
          { /** 経歴エリア */
            !!isEditMode && (
            <TabBoxItem
              caption={isSp ? '経歴 情報' : '経歴情報'}
              key={ENGINEER_TAB_KEY.career}
            >
              <View
                style={{
                  marginTop: 16,
                  marginRight: 8,
                  flexDirection: isSp ? 'column' : 'row',
                }}
              >
                <TitledSelect
                  key="careerPublishLevelSelect"
                  componentKey="careerPublishLevelSelect"
                  placeholder="経歴-公開設定"
                  help={`あなたの経歴情報を公開・非公開に設定します。
非公開にしても、アカウントを特定しない統計、ランク情報としては利用することがあります。`}
                  onChange={(v) => {
                    if (v < settingCareerCompanyPublishLevel) {
                      setSettingCareerCompanyPublishLevel(v);
                    }
                    if (v < settingCareerRewardPublishLevel) {
                      setSettingCareerRewardPublishLevel(v);
                    }
                    setSettingCareerPublishLevel(v);
                  }}
                  defaultValue={settingCareerPublishLevel}
                  items={Object.values(PublishLevel)}
                  fieldStyle={{height: 40}}
                />
                <TitledSelect
                  key="careerComPublishLevelSelect"
                  componentKey="careerComPublishLevelSelect"
                  placeholder="経歴企業名-公開設定"
                  help={`あなたの経歴情報にある関係企業名称を公開・非公開に設定します。
非公開にしても、アカウントを特定しない統計、ランク情報としては利用することがあります。`}
                  disabled={settingCareerPublishLevel === PublishLevel.private.value}
                  onChange={setSettingCareerCompanyPublishLevel}
                  defaultValue={settingCareerCompanyPublishLevel}
                  items={Object.values(PublishLevel).filter((e) =>
                    e.value <= settingCareerPublishLevel && e.value < PublishLevel.engineerOnly.value)}
                    fieldStyle={{height: 40}}
                />
                <TitledSelect
                  key="careerRewardPublishSelect"
                  componentKey="careerRewardPublishSelect"
                  placeholder="経歴報酬額-公開設定"
                  help={`あなたの経歴情報にある報酬額を公開・非公開に設定します。
非公開にしても、アカウントを特定しない統計、ランク情報としては利用することがあります。`}
                  disabled={settingCareerPublishLevel === PublishLevel.private.value}
                  onChange={setSettingCareerRewardPublishLevel}
                  defaultValue={settingCareerRewardPublishLevel}
                  items={Object.values(PublishLevel).filter((e) =>
                    e.value <= settingCareerPublishLevel && e.value < PublishLevel.public.value)}
                    fieldStyle={{height: 40}}
                />
              </View>
              <TitledComponent
                key="career"
                componentKey="career"
                help="キャリアシートに記載する内容を登録します"
                title="経歴情報"
                componentStyle={{
                  flexDirection: 'column',
                }}
                subIcons={[
                  TITLE_ICON.noRequired,
                  TITLE_ICON.selectablePublish,
                ]}
              >
                <EditEngineerCareer
                  value={engineerCareers}
                  onChange={(v) => setEngineerCareers(v)}
                />
              </TitledComponent>
            </TabBoxItem>
          )
          }
          { /** 個人情報・経歴書情報エリア */
            !!isEditMode && !!isViewPersonal && (
            <TabBoxItem
              caption={isSp ? '個人 経歴書 情報' : '個人情報 経歴書情報'}
              key={ENGINEER_TAB_KEY.personalData}
            >
              <TitledComponent
                componentStyle={{
                  justifyContent: 'space-evenly',
                  marginTop: 8,
                }}
                help="経歴書を提出した宛先のクライアント企業にのみ表示されます（クライアント企業側機能は準備中）"
                subIcons={[
                  TITLE_ICON.forCareerSheet,
                ]}
              >
                <View style={{ flexDirection: 'row', marginTop: 8}}>
                  <View style={{ flex: 1, }} >
                    {/** 氏名 */}
                    <TitledInput
                      key="nameInput"
                      placeholder="氏名"
                      onChangeText={(e) => setName(e)}
                      value={name}
                      maxLength={64}
                      validator={new Validator(
                        (t) => ((t > 64) ? '64文字以内' : null),
                      )}
                    />
                  </View>
                  <View style={{ flex: 1, }} >
                    {/** 氏名かな */}
                    <TitledInput
                      key="kanaInput"
                      placeholder="氏名かな"
                      onChangeText={(e) => setKana(e)}
                      value={kana}
                      maxLength={64}
                      validator={new Validator(
                        (t) => ((t > 64) ? '64文字以内' : null),
                      )}
                    />
                  </View>
                </View>
                <View style={{ flexDirection: 'row', marginTop: 16}}>
                  <View style={{ flex: 1, }} >
                    {/* 生年月日 */}
                    <TitledInput
                      key="birthInput"
                      placeholder="生年月日(yyyymmdd)"
                      onChangeText={(e) => setBirthday(e)}
                      onBlur={(e) => {
                        setBirthday(
                          exStringFullToHalf(e.target.value)
                        );
                      }}
                      value={birthday}
                      maxLength={8}
                      validator={new Validator(
                        (t) => checkDateStr(t) ? null : '正しい年月日が必要です',
                      )}
                    />
                  </View>
                  <View style={{ flex: 1, }} >
                    {/** TEL */}
                    <TitledInput
                      key="telInput"
                      placeholder="TEL(ハイフンなし)"
                      onChangeText={(e) => setTel(e)}
                      onBlur={(e) => {
                        setTel(
                          exStringFullToHalf(e.target.value)
                        );
                      }}
                      value={tel}
                      maxLength={11}
                      validator={new Validator(
                        (t) => ((t?.length > 11) ? '11桁以内' : null),
                      )}
                    />
                  </View>
                </View>
                {/* 住所 */}
                <TitledAddressInput
                  key="addressSelect"
                  componentKey="addressSelect"
                  placeholder="住所"
                  onChange={(e) => {
                    setPrefCd(e.prefCd);
                    setCityCd(e.cityCd);
                    setTown(e.town);
                    setBuild(e.build);
                  }}
                  value={{
                    prefCd: prefCd,
                    cityCd: cityCd,
                    town,
                    build,
                  }}
                />
                {/* 最寄り駅 */}
                <TitledStationSelect
                  key="stationSelect"
                  componentKey="stationSelect"
                  placeholder="最寄駅"
                  prefCd={prefCd}
                  cityCd={cityCd}
                  onChange={(v) => {
                    setStationGCd(v?.stationGCd);
                  }}
                  value={{
                    stationGCd
                  }}
                />
                {/* 最終学歴 */}
                <TitledInputSuggestAddition
                  key="schoolSelectKey"
                  componentKey="schoolSelect"
                  label="最終学歴"
                  onChange={(e) => {
                    setSchools(e);
                  }}
                  items={defItems.schools}
                  additionItems={['', '卒業', '在学中', '院卒', '中退']}
                  value={schools}
                  isEnableCustomValue
                  style={{flex: 1}}
                  placeholder="最終学歴"
                />
                {/* コミュニケーション */}
                <TitledInput
                  multiline
                  placeholder="コミュニケーション　に関する自己アピールポイント"
                  onChangeText={setCommunication}
                  value={communication || ''}
                />
                {/* マネジメント */}
                <TitledInput
                  multiline
                  placeholder="マネジメント　に関する自己アピールポイント"
                  onChangeText={setManagement}
                  value={management || ''}
                />
                {/* 言語化・説明力 */}
                <TitledInput
                  multiline
                  placeholder="言語化・説明力　に関する自己アピールポイント"
                  onChangeText={setLanguageSkill}
                  value={languageSkill || ''}
                />
                {/* 論理的思考 */}
                <TitledInput
                  multiline
                  placeholder="論理的思考　に関する自己アピールポイント"
                  onChangeText={setLogicalThinking}
                  value={logicalThinking || ''}
                />
                {/* チームワーク */}
                <TitledInput
                  multiline
                  placeholder="チームワーク　に関する自己アピールポイント"
                  onChangeText={setTeamwork}
                  value={teamwork || ''}
                />
                {/* オーナーシップ }
                <TitledInput
                  multiline
                  placeholder="オーナーシップ　に関する自己アピールポイント"
                  onChangeText={setOwnership}
                  value={ownership || ''}
                />
                {/* 適応力 }
                <TitledInput
                  multiline
                  placeholder="適応力　に関する自己アピールポイント"
                  onChangeText={setAdaptation}
                  value={adaptation || ''}
                />
                {/* キャッチアップ */}
                <TitledInput
                  multiline
                  placeholder="キャッチアップ　に関する自己アピールポイント"
                  onChangeText={setCatchup}
                  value={catchup || ''}
                />
                {/* 課題発見・提案解決 */}
                <TitledInput
                  multiline
                  placeholder="課題発見・提案解決　に関する自己アピールポイント"
                  onChangeText={setProblemDiscovery}
                  value={problemDiscovery || ''}
                />
                {/* プレゼン力 }
                <TitledInput
                  multiline
                  placeholder="プレゼン力　に関する自己アピールポイント"
                  onChangeText={setPresentation}
                  value={presentation || ''}
                />
                {/* 交渉・折衝 */}
                <TitledInput
                  multiline
                  placeholder="交渉・折衝　に関する自己アピールポイント"
                  onChangeText={setNegotiation}
                  value={negotiation || ''}
                />
              </TitledComponent>
            </TabBoxItem>
          )}
            <TabBoxItem
              caption={isSp ? '設定 希望' : '設定 希望'}
              key={ENGINEER_TAB_KEY.setting}
            >
              {/* 評価項目設定 */}
              <TitledInputSuggest
                key="evaluationSettings"
                componentKey="evaluationSettings"
                label="評価項目設定"
                help={`他のユーザーがあなたを評価するときに選択できる選択肢を設定します。
あなた自身が他の人からどんな指標で評価してほしいかを調整してください。
3つ以上選択すると、評価結果がレーダーチャート表示になります。`}
                modalHelp={`他のユーザーがあなたを評価するときに選択できる選択肢を設定します。
あなた自身が他の人からどんな指標で評価してほしいかを調整してください。
3つ以上選択すると、評価結果がレーダーチャート表示になります。`}
                onChange={(e) => {
                  setEvaluationSettings(e);
                }}
                items={defItems.evaluationSettings}
                value={evaluationSettings}
                subIcons={[
                  TITLE_ICON.noRequired,
                  TITLE_ICON.public,
                ]}
                isEnableCustomValue
                multiple
              />
              {/* 案件受付状態 */}
              <TitledInputSuggest
                key="workStatusSelect"
                componentKey="workStatusSelect"
                label="案件受付状態"
                help={`クライアント企業や他のエンジニアがあなたに興味を示したとき、あなたにどんな温度感で声を掛けたらよいか、
あなたの現在の稼働状態を選択してください。（クライアント企業側機能は準備中）`}
                modalHelp={`クライアント企業や他のエンジニアがあなたに興味を示したとき、あなたにどんな温度感で声を掛けたらよいか、
あなたの現在の稼働状態を選択してください。（クライアント企業側機能は準備中）`}
                onChange={(e) => {
                  setWorkStatuses(e);
                }}
                items={defItems.workStatuses}
                value={workStatuses}
                subIcons={[
                  TITLE_ICON.noRequired,
                  TITLE_ICON.public,
                ]}
                isEnableCustomValue
                multiple
              />
              {/* 希望条件 */}
              <TitledInputSuggest
                multiple
                key="requestsSelect"
                componentKey="requestsSelect"
                label="希望条件"
                help={`クライアント企業や他のエンジニアがあなたに興味を示したとき、あなたにどんな温度感で声を掛けたらよいか、
あなたが案件に求める条件を選択してください。（クライアント企業側機能は準備中）`}
                modalHelp={`クライアント企業や他のエンジニアがあなたに興味を示したとき、あなたにどんな温度感で声を掛けたらよいか、
あなたが案件に求める条件を選択してください。（クライアント企業側機能は準備中）`}
                onChange={(e) => {
                  setRequests(e);
                }}
                items={defItems.requests}
                value={requests}
                subIcons={[
                  TITLE_ICON.noRequired,
                  TITLE_ICON.public,
                ]}
                isEnableCustomValue
              />
              {/** アカウントの削除 */
                !!engineer && (
                <TitledComponent
                  help="アカウント情報を削除します"
                  style={{
                    margin: 16,
                    borderTopWidth: 1,
                    borderTopColor: 'gray',
                  }}
                >
                  <Button
                    onPress={deleteAccount}
                    buttonColor="#ff0000"
                    labelColor="#ffffff"
                    label="アカウント削除"
                    buttonWidth={250}
                  />
                </TitledComponent>
              )}
            </TabBoxItem>
          </TabBox>
        ) : (
          <PreviewAccountEngineer engineer={engineer} />
        )}
        <View
          style={{
            alignSelf: 'center',
            width: '80%',
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            marginBottom: 16,
          }}
        >
          { !!error && (
            <T
              style={{
                color: '#f00',
                fontWeight: 'bold',
              }}
            >
              {error}
            </T>
          )}
          <Button
            label={ !isPreview ? 'プレビュー' : isSp ? '編集に戻る' : 'プレビューを消す' }
            onPress={() => setIsPreview(!isPreview)}
          />
          { !!isPreview && (
            <Button
              label="保存"
              onPress={() => register()}
            />
          )}
        </View>
      </View>
      { !isSp && !!isPreview && (
        <View
          style={{
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <PreviewAccountEngineer engineer={engineer} />
        </View>
      )}
    </View>
  );
}

EditAccountEngineer.propTypes = {
  value: Engineer.propTypes,
  isEditMode: PropTypes.bool,
  isPersonalInfo: PropTypes.bool,
  tab: PropTypes.string,
};

EditAccountEngineer.defaultProps = {
  value: null,
  isEditMode: false,
  isPersonalInfo: false,
  tab: ENGINEER_TAB_KEY.basicInfo,
};
