import React, { useCallback, useContext, useState } from 'react';
import {
  StyleSheet, View,
} from 'react-native';
import Modal from 'react-native-modalbox';
import Styles from '../../Styles';
import { FriendConnection, Engineer, getFriendStatus } from '../../types/Types';
import { AppContext } from '../../context/AppContext';
import Waiting from '../../components/Waiting';
import UserIcon from './UserIcon';
import IconText, { DIR } from '../../../common/components/IconText';
import Button from '../../../common/components/Button';
import { registerFriend, cancelFriend, rejectionFriend } from '../../functions/data/Server';
import TitledSelect from '../../components/TitledSelect';
import T from '../../components/T';

// 冒頭説明文を調整
const description = (isFriendReceived) => {
  // 相手の申請を承認する場合
  if (isFriendReceived) {
    return `相手からのフレンド申請を承認します。
承認すると、あなたの活動エリア、経歴公開設定など、フレンド向けに公開している情報が、相手が閲覧可能になります。
なお、あなた自身の公開設定は後から変更可能で、変更に応じて相手に見える情報は変化します。`;
  }
  // 自分が最初のアクションの場合
  return `フレンド登録すると、相手に通知されます。
あなたの活動エリア、経歴公開設定など、フレンド向けに公開している情報が、相手が閲覧可能になります。
なお、あなた自身の公開設定は後から変更可能で、変更に応じて相手に見える情報は変化します。`;
};

/**
 * フレンド登録
 * @returns
 */
export default function RegisterFriendButton(props) {

  const { targetEngineer } = props;
  const { userInfo, isSp, dimensions, reload, viewDialog } = useContext(AppContext);
  const [isModalOpenFriend, setIsModalOpenFriend] = useState(false);
  const [friendConnection, setFriendConnection] = useState(FriendConnection.net.value);

  if (!userInfo?.engineer) {
    return <></>
  }

  const { width, height } = dimensions.window;

  // 現在のステータスを確認
  // フレンド状態
  const {isFriendRequested, isFriendReceived, isBothFriend, isFriendRejected} = getFriendStatus(
    userInfo?.engineer,
    targetEngineer,
  );

  const errorDialog = useCallback((err) => {
    viewDialog(
      'エラーが発生しました',
      err?.response?.data?.errorMessage,
      ['OK'],
      300, 200);
    return false;
  });

  const register = useCallback(async (_targetAccountNo, _friendConnection) => {
    try {
      await registerFriend(_targetAccountNo, _friendConnection);
      return true;
    }catch(err) {
      errorDialog(err);
    }
  }, []);

  const reject = useCallback(async (_targetAccountNo) => {
    const confirm = await viewDialog('フレンドを拒否します',
      `拒否したことは相手に伝わらず、「承認待ち」のままになります。
相手からのフレンド申請はあなたのメッセージから表示されなくなります。`,
      ['OK', 'CANCEL'],
      300, 300);
    if (confirm?.selected !== 'OK') {
      return;
    }
    try {
      await rejectionFriend(_targetAccountNo);
      return true;
    }catch(err) {
      errorDialog(err);
    }
  }, []);

  const cancelReject = useCallback(async (_targetAccountNo) => {
    const confirm = await viewDialog('フレンド拒否を取り下げます',
      `相手があなたへの申請をまだ待っていたら、あなたのフレンド承認待ちになります
相手があなたへの申請を取り下げていたら、何も行われていない初期状態になります`,
      ['OK', 'CANCEL'],
      300, 350);
    if (confirm?.selected !== 'OK') {
      return;
    }
    try {
      await cancelFriend(_targetAccountNo);
      return true;
    }catch(err) {
      errorDialog(err);
    }
  }, []);

  const cancel = useCallback(async (_targetAccountNo) => {
    const confirm = await viewDialog(
      'フレンドの取り下げ',
      'フレンド要求を取り下げます。よろしいですか？',
      ['OK', 'CANCEL'],
      300, 200);
    if (confirm?.selected !== 'OK') {
      return;
    }
    try {
      await cancelFriend(_targetAccountNo);
      return true;
    }catch(err) {
      errorDialog(err);
    }
  }, []);

  // 複数表出するボタンを変数化
  // フレンド取消
  const cancelFriendButton = (
    <Button
      dir={DIR.FONTAWESOME5}
      icon="star"
      iconColor="#ffff00"
      iconSize={BUTTON_ICON_SIZE}
      labelStyle={styles.buttonLabelStyle}
      buttonStyle={styles.buttonStyle}
      label="フレンドを取消"
      onPress={async () => {
        if (await cancel(targetEngineer?.accountNo)) {
          setIsModalOpenFriend(false);
          reload();
        }
      }}
    />
  );

  // フレンド拒否
  const rejectFriendButton = (
    <Button
      dir={DIR.FONTAWESOME5}
      icon="heart-broken"
      iconColor="#ffff00"
      iconSize={BUTTON_ICON_SIZE}
      labelStyle={styles.buttonLabelStyle}
      buttonStyle={styles.buttonStyle}
      buttonColor="#f00"
      label="フレンド拒否"
      onPress={async () => {
        if (await reject(targetEngineer?.accountNo)) {
          setIsModalOpenFriend(false);
          reload();
        }
      }}
    />
  );

  // 状態に応じたボタン
  // viewRejectButton: 拒否ボタンを表出させる
  const friendButton = (onPress, viewRejectButton) => {
    if (isBothFriend) {
      return (
        <IconText
          dir={DIR.FONTAWESOME}
          icon="handshake-o"
          help={
            <>
              <T>相互にフレンド登録済みです</T>
              {cancelFriendButton}
            </>
          }
          iconSize={BUTTON_ICON_SIZE}
          iconColor="#ff69b4"
        >
          相互フレンド
        </IconText>
      );
    }
    if (isFriendRequested) {
      return (
        <IconText
          dir={DIR.FONTAWESOME5}
          icon="star-half-alt"
          help={
            <>
              <T>相手の承認待ちです</T>
              {cancelFriendButton}
            </>
          }
          iconSize={BUTTON_ICON_SIZE}
          iconColor={Styles.COLOR_MAIN_ACCENT_BG}
        >
          フレンド承認待ち
        </IconText>
      );
    }
    if (isFriendReceived) {
      return (
        <View style={{flexDirection: 'row'}}>
          <Button
            dir={DIR.FONTAWESOME5}
            icon="star"
            help={`相手からフレンド申請を受けています。
承認してフレンド登録を完了します`}
            iconColor="#ffff00"
            iconSize={BUTTON_ICON_SIZE}
            labelStyle={styles.buttonLabelStyle}
            buttonStyle={styles.buttonStyle}
            label="フレンドを承認"
            onPress={onPress}
          />
          {!!viewRejectButton && rejectFriendButton}
        </View>
      );
    }
    if (isFriendRejected) {
      return (
        <IconText
          dir={DIR.FONTAWESOME5}
          icon="heart-broken"
          help={
            <View>
              <T>相手のフレンド申請を拒否しました。相手には拒否したことはわかりません。</T>
              <Button
                dir={DIR.MATERIAL_COMMUNITY}
                icon="hospital-box-outline"
                iconColor="#f00"
                iconSize={BUTTON_ICON_SIZE}
                labelStyle={styles.buttonLabelStyle}
                buttonStyle={styles.buttonStyle}
                label="拒否を取消す"
                onPress={async () => {
                  if (await cancelReject(targetEngineer?.accountNo)) {
                    setIsModalOpenFriend(false);
                    reload();
                  }
                }}
              />
            </View>
          }
          iconSize={BUTTON_ICON_SIZE}
          iconColor={Styles.COLOR_MAIN_ACCENT_BG}
        >
          フレンド拒否済み
        </IconText>
      );
    }
    // 何もアクションなし
    return (
      <View style={{flexDirection: 'row'}}>
        <Button
          dir={DIR.FONTAWESOME5}
          icon="star"
          help={`フレンド申請して、相手が承諾すると、フレンド公開に設定された情報が閲覧できます
  フレンド申請の際に、相手を評価してください`}
          iconColor="#ffff00"
          iconSize={BUTTON_ICON_SIZE}
          labelStyle={styles.buttonLabelStyle}
          buttonStyle={styles.buttonStyle}
          label="フレンド申請"
          onPress={onPress}
        />
        {!!viewRejectButton && rejectFriendButton}
      </View>
    );
  };

  return (
    <>
      {friendButton(() => setIsModalOpenFriend(true), true)}
      <Modal
        key={`enginerFriendModal_${targetEngineer?.no}`}
        style={{
          maxWidth: 800,
          width: width * 0.85,
          height: height * (isSp ? 0.8 : 0.5),
          maxHeight: isSp ? 600 : 400,
          alignContet: 'center',
          borderRadius: 10,
          padding: 16,
          shadowColor: '#000',
          shadowOffset: {
            width: 2,
            height: 4,
          },
          shadowOpacity: 0.4,
          elevation: 16,
        }}
        coverScreen
        isOpen={isModalOpenFriend}
        backdropPressToClose
        backdrop
        backdropColor="#000"
        backdropOpacity={0.5}
        position="center"
        entry="bottom"
        onClosed={() => setIsModalOpenFriend(false)}
        onOpened={() => {}}
        useNativeDriver={false}
      >
        <View style={{
          alignItems: 'flex-end',
          justifyContent: 'flex-start',
        }}
        >
          <Button
            onPress={() => setIsModalOpenFriend(false)}
            dir={DIR.ENTYPO}
            icon="cross"
            iconColor="#888"
            iconSize={32}
            linkStyle
          />
        </View>
        <T
          style={{
            marginTop: 16,
            color: Styles.COLOR_MAIN_TEXT_STRONG,
            fontWeight: 'bold',
            fontSize: 16,
          }}
        >
          {description(isFriendReceived)}
        </T>
        <View
          style={{
            flex: 1,
            marginTop: 32,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <UserIcon
            nickName={userInfo?.engineer?.nickName}
            image={userInfo?.engineer?.iconUrl}
            size={Styles.USER_ICON_SIZE * (!isSp ? 1.5 : 1)}
          />
          { /* 相手の申請を承認する場合 or 自分が最初のアクションの場合 */
            isFriendReceived
            ? (
              <IconText
                dir={DIR.FONTAWESOME}
                icon="handshake-o"
                iconSize={32}
                iconColor="#ff69b4"
              />
            ):(
              <IconText
                dir={DIR.ENTYPO}
                icon="triangle-right"
                iconSize={32}
              />
            )
          }
          <UserIcon
            nickName={targetEngineer?.nickName}
            image={targetEngineer?.iconUrl}
            size={Styles.USER_ICON_SIZE * (!isSp ? 1.5 : 1)}
          />
        </View>
        <View
          style={{
            flex: 1,
            marginTop: 24,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TitledSelect
            key="friendConnectionSelect"
            componentKey="friendConnectionSelect"
            items={Object.values(FriendConnection)}
            defaultValue={friendConnection}
            placeholder="つながり"
            onChange={(v) => setFriendConnection(v)}
            style={{
              height: 40,
            }}
            fieldStyle={{
              height: 40,
            }}
          />
        </View>
        <View
          style={{
            flex: 1,
            marginTop: 24,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {!!friendConnection &&
            friendButton(async () => {
              if (await register(targetEngineer?.accountNo, friendConnection)) {
                setIsModalOpenFriend(false);
                await viewDialog(
                  'フレンド申請完了！',
                  '相手からの承認をお待ちください',
                  ['OK'],
                  250, 200);
                reload();
              }
            }, false)
          }
        </View>
      </Modal>
    </>
  );
}

RegisterFriendButton.propTypes = {
  targetEngineer: Engineer.propTypes.isRequired,
};

RegisterFriendButton.defaultProps = {
};

const BUTTON_ICON_SIZE = 14;

const styles = StyleSheet.create({
  box: {
    borderWidth: 2,
    borderColor: '#aaa',
    borderRadius: 10,
    margin: 8,
    padding: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 4,
      height: 6,
    },
    shadowOpacity: 0.4,
    elevation: 16,
  },
  buttonLabelStyle: {
    fontSize: BUTTON_ICON_SIZE,
    lineHeight: BUTTON_ICON_SIZE + 2,
    wordWrap: 'nowrap',
    marginVertical: 4,
  },
  buttonStyle: {
    borderRadius: BUTTON_ICON_SIZE,
    padding: 8,
    // width: 140,
    height: BUTTON_ICON_SIZE + 8,
    justifyContent: 'center',
  },
});
