export default class Validator {
  _isValid = false;
  constructor(...funcs) {
    this.funcs = funcs;
  }

  get isValid() {
    return this._isValid;
  }

  execute(v) {
    let ret = '';
    if (this.funcs?.length > 0) {
      ret = this.funcs.map((f) => f(v)).filter((r) => !!r).join("\n");
    }
    this._isValid = ret.length < 1;
    return ret.length < 1 ? null : ret;
  }
}
