import React, { useState, useContext } from 'react';
import SearchAccountEngineer from './component/SearchAccountEngineer';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import T from '../components/T';
import Box from '../../common/components/Box';
import Styles from '../Styles';
import { isEmpty, optional } from '../../common/util/TypeUtil';
import UserIcon from './component/UserIcon';
import SuggestItem from '../../common/components/SuggestItem';
import { viewCareerMonthsTotal } from '../Definition';
import { AppContext } from '../context/AppContext';
import TitledAccordion from '../components/TitledAccordion';
import Title from '../components/Title';
import TabBox, { TabBoxItem } from '../../common/components/TabBox';


// 数量限定でDefItemを連続表示
const viewDefLimitedItem = (src, count, ifEmpty, viewItemFunc) => {
  if (isEmpty(src)) {
    return ifEmpty;
  }
  const ret = src.filter((e, index) => index < count).map(e => viewItemFunc(e));

  if (src.length > count) {
    ret.push(
      <T>...(全{src.length}件)</T>
    )
  }
  return ret;
};

const TAB_KEY = {
  condition: 'searchConditionTab',
  result: 'searchResultTab',
};

/**
 * アカウント検索
 * @returns
 */
export default function SearchAccount(routeParam) {
  const { route } = routeParam;
  const { params } = route;
  const { isSp } = useContext(AppContext);

  const [result, setResult] = useState();
  const [viewTabKey, setViewTabKey] = useState(TAB_KEY.condition);
  const [searchCondition, setSearchCondition] = useState(null);

  const item = (i) => {
    return (
      <Box
        key={`searchResultItem_${i.accountNo}`}
        style={{
          margin: 16,
        }}
        componentStyle={{
          flexDirection: 'column',
          flex: 1,
          padding: 8,
        }}
        isNoScroll
        onPress={() => alert(i.accountNo)}
      >
        {/* ヘッダ部 */}
        <View
          style={{
            flexDirection : 'row',
          }}
        >
          {/** アイコン */}
          <UserIcon
            size={Styles.USER_ICON_SIZE}
            image={i.iconUrl}
            nickName={i.nickName}
          />
          {/** ニックネーム */}
          <View
            style={{
              flexDirection: 'row',
              borderBottomWidth: 3,
              borderColor: Styles.COLOR_MAIN_ACCENT_BG,
              flex: 1,
            }}
          >
            <T
              style={{
                marginTop: 8,
                fontSize: isSp? 20 : 28,
                fontWeight: 'bold',
                flexWrap: 'wrap',
              }}
            >
              {i.nickName || ' '}
            </T>
            {/* エンジニア歴 */}
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'flex-end',
                marginLeft: 16,
              }}
            >
              <T>エンジニア歴:<T style={{fontWeight: 'bold', color: Styles.COLOR_MAIN_ACCENT_BG}}>{viewCareerMonthsTotal(i.engineerCareers)}</T></T>
              <T>　</T>
              {!!i.engineerCareers?.length
                ? (
                  <T>実績:<T style={{fontWeight: 'bold', color: Styles.COLOR_MAIN_ACCENT_BG}}>{i.engineerCareers?.length}</T>件</T>
                ) : (
                  <T>実績:<T style={{fontWeight: 'bold', color: Styles.COLOR_MAIN_ACCENT_BG}}>未入力</T></T>
                )
              }
            </View>
          </View>
        </View>
        {/* 情報部 */}
        <View
          style={{
            flexDirection: 'row',
            flex: 1,
          }}
        >
          {/** 左ペイン */}
          <View
            style={{
              flexDirection: 'column',
            }}
          >
            {/* エンジニア種別 */}
            <View>
              {viewDefLimitedItem(
                i.engineerCategories,
                5,
                '',
                (e) => (
                  <SuggestItem
                    key={`preview_engineerCategories_${e.no}`}
                    item={e}
                    bgColor={Styles.ITEM_BG_COLOR_CATEGORIES}
                    textColor={Styles.ITEM_TEXT_COLOR_CATEGORIES}
                  />
                )
              )}
            </View>
          </View>
          {/** 右ペイン */}
          <View
            style={{
              flexDirection: 'column',
            }}
          >
            {/* 保有資格 */}
            {!isEmpty(i.examinations) && (
              <View
                style={{
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  flex: 1,
                }}
              >
                {viewDefLimitedItem(
                  i.examinations,
                  3,
                  '保有資格登録なし',
                  (e) => (
                    <SuggestItem
                      key={`preview_examinsations_${e.no}`}
                      item={e}
                      bgColor={Styles.ITEM_BG_COLOR_EXAMINATIONS}
                      textColor={Styles.ITEM_TEXT_COLOR_EXAMINATIONS}
                      style={Styles.ITEM_STYLE_EXAMINATIONS}
                    />
                  )
                )}
              </View>
            )}
            {/* その他スキル・言語対応 */}
            {!isEmpty(i.otherSkills) && (
              <View
                style={{
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  flex: 1,
                }}
              >
                {viewDefLimitedItem(
                  i.otherSkills?.filter((e) => !!e.no),
                  3,
                  '',
                  (e) => (
                    <SuggestItem
                      key={`preview_requests_${e.no}`}
                      item={e}
                      label={`${e.label}${!!e.addition ? `(${e.addition})` : ''}`}
                      bgColor={Styles.ITEM_BG_COLOR_OTHER_SKILLS}
                      textColor={Styles.ITEM_TEXT_COLOR_OTHER_SKILLS}
                      style={Styles.ITEM_STYLE_OTHER_SKILLS}
                    />
                  )
                )}
              </View>
            )}
            {/* 案件受付状態 */}
            {!isEmpty(i.requests) && (
              <View
                style={{
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  flex: 1,
                }}
              >
                {viewDefLimitedItem(
                  i.requests?.filter((e) => !!e.no),
                  3,
                  '',
                  (e) => (
                    <SuggestItem
                      key={`preview_otherSkills_${e.no}`}
                      item={e}
                      label={`${e.label}${!!e.addition ? `(${e.addition})` : ''}`}
                      bgColor={Styles.ITEM_BG_COLOR_OTHER_SKILLS}
                      textColor={Styles.ITEM_TEXT_COLOR_OTHER_SKILLS}
                      style={Styles.ITEM_STYLE_OTHER_SKILLS}
                    />
                  )
                )}
              </View>
            )}
            {/* <T style={{flexWrap: 'wrap', borderColor: 'red', borderWidth: 1}}>{JSON.stringify(i, null, "\t")}</T> */}
          </View>
        </View>
      </Box>
    );
  };

  const viewResult = (r) => {
    if (isEmpty(r)) {
      return (
        <View
          style={{
            margin: 16,
          }}
        >
          <T>該当なし</T>
        </View>
      );
    }
    return (
      <>
        {r.map(i => item(i))}
      </>
    );
  };

  return isSp
  ? (
    <>
      <TabBox
        title="エンジニア 検索"
        tabHeight={isSp ? 80 : 50}
        viewTabKey={viewTabKey}
        setViewTabKey={setViewTabKey}
        style={{
          margin: 4,
        }}
      >
        <TabBoxItem
          caption="条件"
          key={TAB_KEY.condition}
        >
          <SearchAccountEngineer
            key="searchAccountEngineer"
            setResult={setResult}
            condition={searchCondition}
            setCondition={setSearchCondition}
          />
        </TabBoxItem>
        {!!searchCondition && (
          <TabBoxItem
            caption={`検索結果 ${result?.length || 0}件`}
            key={TAB_KEY.result}
          >
            {viewResult(result)}
          </TabBoxItem>
        )}
      </TabBox>
      {/*
      <Box
        title="エンジニア検索"
        style={{
          margin: 4,
          flex: 1,
        }}
        componentStyle={{
          padding: 4,
        }}
      >
        <TitledAccordion
          open
          touchableNode={
            <Title label="エンジニア検索"/>
          }
        >
          <ScrollView style={{height: '100%'}}>
            <SearchAccountEngineer
              key="searchAccountEngineer"
              setResult={setResult}
            />
          </ScrollView>
        </TitledAccordion>
        <TitledAccordion
          open
          touchableNode={
            <Title label="検索結果"/>
          }
        >
            {viewResult(result)}
        </TitledAccordion>

        <Box
          title="test"
          style={{
            margin: 4,
            flex: 1,
          }}
          componentStyle={{
            padding: 4,
          }}
        >
          <T>abc</T>
        </Box>
      </Box>
        */}
    </>
  ) : (
    <View
      style={[
        Styles.MAIN_VIEW,
        {
          flexDirection: 'row',
          flex: 1,
        }
      ]}
    >
      <Box
        title="エンジニア検索"
        expandable
        open
        style={{
          margin: 8,
        }}
        componentStyle={{
          padding: 16,
        }}
      >
        <SearchAccountEngineer
          key="searchAccountEngineer"
          setResult={setResult}
        />
      </Box>
      <Box
        title="検索結果"
        expandable
        style={{
          margin: 8,
        }}
      >
        {viewResult(result)}
      </Box>
    </View>
  );
}
