import React from 'react';
import {
  TouchableOpacity, View,
} from 'react-native';
import AntDesign from 'react-native-vector-icons/AntDesign';
import PropTypes from 'prop-types';

/**
 * ボタン
 * @param {*} props
 * @returns ボタン
 */
export default function PlusButton(props) {
  const { onPress } = props;

  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        marginHorizontal: 16,
      }}
    >
      <View style={{ flex: 1, flexDirection: 'column' }}>
        <View style={{ flex: 1, borderBottomColor: '#333', borderBottomWidth: 1 }} />
        <View style={{ flex: 1 }} />
      </View>
      <TouchableOpacity
        onPress={onPress}
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          marginHorizontal: 16,
          padding: 8,
        }}
      >
        <AntDesign name="pluscircle" size={20} style={{ color: '#888' }} />
      </TouchableOpacity>
      <View style={{ flex: 1, flexDirection: 'column' }}>
        <View style={{ flex: 1, borderBottomColor: '#333', borderBottomWidth: 1 }} />
        <View style={{ flex: 1 }} />
      </View>
    </View>
  );
}

PlusButton.propTypes = {
  onPress: PropTypes.func.isRequired,
};
