import React, { useState } from 'react';
import {
  View, StyleSheet, Text, Switch,
} from 'react-native';
import PropTypes from 'prop-types';
import IconText from '../../common/components/IconText';

export default function TitledToggle(props) {
  const {
    dir, icon, title, help,
    toggleLabelStyle, toggleLabelOn, toggleLabelOff, value,
    onChange, disabled, disabledLabel,
  } = props;

  const viewLabel = () => {
    if (disabled) {
      return disabledLabel;
    }
    return !!value ? toggleLabelOn : toggleLabelOff;
  };

  return (
    <View style={styles.container} key="TitledToggle_top">
      <IconText
        containerStyle={styles.titleContainer}
        textStyle={styles.titleText}
        dir={dir}
        icon={icon}
        help={help}
      >
        {title}
      </IconText>
      <View
        style={{
          marginLeft: 16,
          flexDirection: 'row',
        }}
      >
        <Switch
          key="TitledToggle_switch"
          onValueChange={(v) => onChange(v)}
          value={!!value}
          disabled={disabled}
        />
        <Text style={toggleLabelStyle}>{viewLabel()}</Text>
      </View>
    </View>
  );
}

TitledToggle.propTypes = {
  dir: IconText.propTypes.dir,
  icon: IconText.propTypes.icon,
  help: IconText.propTypes.help,
  subIcons: IconText.propTypes.subIcons,
  title: PropTypes.string.isRequired,
  toggleLabelOn: PropTypes.string.isRequired,
  toggleLabelOff: PropTypes.string.isRequired,
  toggleLabelStyle: PropTypes.any,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

TitledToggle.defaultProps = {
  dir: IconText.defaultProps.dir,
  icon: IconText.defaultProps.icon,
  help: IconText.defaultProps.help,
  subIcons: IconText.defaultProps.subIcons,
  toggleLabelStyle: {
    fontSize: 18,
    lineHeight: 24,
  },
  value: false,
  disabled: false,
  disabledLabel: '選択不可',
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  titleContainer: {
    marginTop: 16,
    marginLeft: 16,
    borderTopColor: '#000',
    borderTopWidth: 1,
  },
  titleText: {
    fontSize: 18,
  },
  label: {
    marginLeft: 56,
    height: 32,
    lineHeight: 32,
    width: 250,
    fontSize: 18,
    fontWeight: 'bold',
  },
});
