export function prepareGoogleAnalyticsForWeb(id) {
  const head = window.document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push(['js', new Date()]);
    dataLayer.push(['config', id]);
  };
  head.prepend(script);
}

export function googleAnalyticsTracking(event, eventCategory, eventLabel) {
  try {
    window.dataLayer.push([
      'event',
      event,
      {
        'event_category': eventCategory,
        'event_label': eventLabel,
      }
    ]);
  }catch (err) {
    console.error(err);
  }
}
