import React, { useEffect, useCallback, useState } from 'react';
import {
  FlatList, StyleSheet, View, TouchableOpacity,
} from 'react-native';
import PropTypes from 'prop-types';
import { Recruit } from '../../types/Types';
import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import T from '../../components/T';
import { isEmpty } from '../../../common/util/TypeUtil';
import Button from '../../../common/components/Button';
import ViewRecruitItem from './ViewRecruitItem';
import { authSend } from '../../functions/data/Server';
import Styles from '../../Styles';
import { LinkUrl } from '../../LinkUrl';
import * as Server from '../../functions/data/Server';

/**
 * 案件リスト
 * @param value 案件リスト(array)
 * @param isEditable 案件修正ボタンを表示する(自分のデータかは判断のうえ)
 * @returns
 */
export default function ViewRecruitList(props) {
  const { value, isEditable, reloadClientFunc } = props;
  const { userInfo, isSp, viewDialog, Link } = useContext(AppContext);
  const [expandRecruitItems, setExpandRecruitItems] = useState();

  useEffect(() => {
    setExpandRecruitItems(value?.map((e) => ({
      uuid: e.uuid,
      isOpen: false,
    })));
  }, [value]);

  const changeExpandRecruitItem = useCallback((uuid) => {
    if (isEmpty(expandRecruitItems)) {
      return;
    }
    const newData = expandRecruitItems.map((e) => ({
      uuid: e.uuid,
      isOpen: uuid === e.uuid ? !e.isOpen : e.isOpen,
    }));
    setExpandRecruitItems(newData);
  }, [expandRecruitItems]);

  /* 経歴の開閉確認 */
  const isExpandRecruitThis = useCallback((uuid) => {
    return expandRecruitItems?.find((e) => e.uuid === uuid)?.isOpen || false;
  }, [expandRecruitItems]);

  /* 修正とかのボタン */
  const EditButton = (props) =>
    isSp
    ? (
      <Button
        labelStyle={{
          fontSize: 14,
        }}
        label={props.label}
        onPress={props.onPress}
      />
    ) : (
      <Button
        buttonWidth={130}
        labelStyle={{
          fontSize: 14,
        }}
        label={props.label}
        onPress={props.onPress}
      />
  );

  return (
    <>
      {isEmpty(value)
        ? (
          <T
            style={{
              lineHeight: 250,
              textAlign: 'center',
            }}
          >
            案件情報がありません
          </T>
        ) : (
          <FlatList
            data={value}
            keyExtractor={item => `recruitItem_${item.uuid}`}
            renderItem={({item, index}) => (
              <View
                style={[
                  {
                    flexDirection: isSp ? 'column' : 'row', // 修正ボタン用
                    marginBottom: 8,
                    paddingBottom: 8,
                    borderBottomColor: '#ccc',
                    borderBottomWidth: 2,
                    paddingRight: 8,
                  },
                  !!item.hideDate && {
                    backgroundColor: Styles.COLOR_BG_DISABLED,
                    borderRadius: 8,
                    marginVertical: 2,
                  },
                ]}
              >
                <TouchableOpacity
                  onPress={() => changeExpandRecruitItem(item.uuid)}
                  style={{
                    padding: 8,
                    margin: 0,
                    cursor: 'pointer',
                    flex: 1,
                  }}
                >
                  <ViewRecruitItem
                    item={item}
                    isExpand={isExpandRecruitThis(item.uuid)}
                  />
                </TouchableOpacity>
                {isEditable && item.accountNo === userInfo?.client?.accountNo && (
                  <View
                    style={[
                      {
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        flexDirection: isSp ? 'row' : 'column',
                      }
                    ]}
                  >
                    <EditButton
                      label="修正"
                      onPress={() => Link(LinkUrl.EDIT_RECRUIT_ITEM(item.no)) }
                    />
                    <EditButton
                      label="削除"
                      onPress={() => {
                        (async () => {
                          const {selected, answer} = await viewDialog(
                            '案件を削除します', 'よろしいですか？', ['OK', 'キャンセル'], 250, 150
                          );
                          if (selected !== 'OK') {
                            return;
                          }
                          await authSend(
                            'recruit/delete',
                            {
                              no: item.no,
                              csrfKey: await Server.requestCsrfKey(),
                            }
                          );
                          reloadClientFunc();
                        })();
                      }}
                    />
                    <EditButton
                      label={!!item.hideDate ? '非表示解除' : '非表示して隠す'}
                      onPress={() => {
                        (async () => {
                          await authSend(
                            'recruit/changeHide',
                            {
                              no: item.no,
                              csrfKey: await Server.requestCsrfKey(),
                            }
                          );
                          reloadClientFunc();
                        })();
                      }}
                    />
                  </View>
                )}
              </View>
            )}
          />
        )
      }
    </>
  );
}

ViewRecruitList.propTypes = {
  value : PropTypes.arrayOf(Recruit.propTypes),
  isEditable: PropTypes.bool,
  reloadClientFunc: PropTypes.func,
};

ViewRecruitList.defaultProps = {
  value: [],
  isEditable: false,
  reloadClientFunc: () => {},
};
