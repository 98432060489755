import React from 'react';
import { WToast } from 'react-native-smart-tip';
import Toast from 'react-native-toast-message';
import { ok } from '../../common/functions/Alert';
import ImageButton from '../../common/components/ImageButton';
import Styles from '../Styles';

const IMAGE_SUCCESS = require('../../common/assets/success.png');
const IMAGE_SORRY = require('../../common/assets/sorry.png');

export const toastSuccess = (title, message, ms) => {
  Toast.show({
    type: 'success',
    text1: title,
    text2: message,
    visibilityTime: ms || 8000,
    onPress: () => Toast.hide(),
  });
  // WToast.show({
  //   data: message,
  //   textColor: '#fff',
  //   backgroundColor: Styles.COLOR_TOAST_BG,
  //   duration: ms || 8000,
  //   position: WToast.position.TOP,
  //   icon: <ImageButton
  //     image={IMAGE_SUCCESS}
  //     imageStyle={{ width: 32, height: 32, resizeMode: 'contain' }}
  //     buttonStyle={{ width: 32, height: 32 }}
  //     onPress={() => WToast.hide()}
  //   />,
  // });
};

export const toastShort = (title, message, ms) => {
  Toast.show({
    type: 'success',
    text1: title,
    text2: message,
    visibilityTime: ms || 3000,
  });
  // WToast.show({
  //   data: message,
  //   textColor: '#fff',
  //   backgroundColor: Styles.COLOR_TOAST_BG,
  //   duration: ms || 3000,
  //   position: WToast.position.TOP,
  // });
};

export const toastSorry = (message, ms) => {
  Toast.show({
    type: 'error',
    text1: 'エラー',
    text2: message,
    visibilityTime: ms || 3000,
  });
  // WToast.show({
  //   data: message,
  //   textColor: '#fff',
  //   backgroundColor: Styles.COLOR_TOAST_BG,
  //   duration: ms || 8000,
  //   position: WToast.position.TOP,
  //   icon: <ImageButton
  //     image={IMAGE_SORRY}
  //     imageStyle={{ width: 32, height: 32, resizeMode: 'contain' }}
  //     buttonStyle={{ width: 32, height: 32 }}
  //     onPress={() => WToast.hide()}
  //   />,
  // });
};

export const toastFail = (message, title) => {
  ok(
    title || 'エラーが発生しました',
    message,
  );
  // WToast.show({
  //   data: message,
  //   textColor: '#fff',
  //   backgroundColor: Styles.TOAST_BG_COLOR,
  //   duration: WToast.duration.LONG,
  //   position: WToast.position.CENTER,
  //   icon: <Image
  //     source={IMAGE_FAIL}
  //     style={{ width: 128, height: 128, resizeMode: 'contain' }}
  //   />,
  // });
};
