import React, { useCallback, useState } from 'react';
import {
  StyleSheet, View,
} from 'react-native';
import PropTypes from 'prop-types';
import Styles from '../../Styles';
import PlusButton from '../../../common/components/PlusButton';
import IconText, { DIR } from '../../../common/components/IconText';
import EditEngineerCareerItem from './EngineerCareerItem';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { EngineerCareer } from '../../types/Types';
import uuid from '../../../common/util/Uuid';
import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { viewTerm, viewTermShort } from '../../Definition';
import TitledAccordion from '../../components/TitledAccordion';

const createItem = () => {
  const ret = Object.assign({}, JSON.parse(JSON.stringify(EngineerCareer.defaultProps)));
  ret.uuid = uuid();
  return ret;
};

/**
 * エンジニア経歴登録
 * @returns
 */
export default function EditEngineerCareer(props) {
  const { value, onChange } = props;
  const [careerItems, setCareerItems] = useState(value);
  const [expandCareerItems, setExpandCareerItems] = useState(value?.map((e, i) => i === 0) || [true]);
  const [review, setReview] = useState(false);

  const { isSp, viewDialog } = useContext(AppContext);

  const addCareerItem = () => {
    for (let index = 0; index < expandCareerItems.length; index += 1) {
      expandCareerItems[index] = false;
    }
    expandCareerItems.push(true);
    setCareerItems(careerItems.concat([createItem()]));
    onChange(careerItems.concat([createItem()]));
    setReview(!review);
  };

  const changeCareerItem = (data, index) => {
    const newData = careerItems.map((e, i) => {
      if (i === index) {
        return data;
      }
      return e;
    });
    setCareerItems(newData);
    onChange(newData);
  };

  const changeExpandCareerItem = (index) => {
    const newData = expandCareerItems.map((e, i) => ((i === index) ? !e : e));
    setExpandCareerItems(newData);
  };

  const accordionText = useCallback((item, index) => {
    if (!item?.isValid) {
      return '入力が不十分です！';
    }
    let s;
    if (item?.projectName) {
      s = item.projectName;
      if (s?.length > 6) {
        s = `${s.slice(0, 6)}...`;
      }
    }
    if (item?.termSt && item?.termEd) {
      s = `${s}(${isSp ? viewTermShort(item) : viewTerm(item)})`;
    }
    return s;
  }, [isSp]);

  /** amount +1 or -1 のみ */
  const changeSort = (index, amount) => {
    if (index + amount < 0
       || index + amount >= careerItems?.length
       || !(amount === -1 || amount === 1)) {
      return; // 範囲外なので何もしない
    }
    const newData = [];
    for (let i = 0; i < careerItems?.length; i++) {
      if (
        // １つ順位上げのとき
        (amount === -1 && i === index - 1) ||
        // １つ順位下げのとき
        (amount === 1 && i === index)
      ){
        careerItems[i + 1].sortNo = i;
        newData.push(careerItems[i + 1]);
        careerItems[i].sortNo = i + 1;
        newData.push(careerItems[i]);
        i++;
      } else {
        newData.push(careerItems[i]);
      }
    }
    setCareerItems(newData);
    onChange(newData);
  };

  const delItem = async (uuid) => {
    const targetData = careerItems?.find((e) => e.uuid === uuid);
    const {selected, answer} = await viewDialog(
      '削除の確認',
      `選択した経歴${targetData?.projectName ? ` [${targetData.projectName}] ` : ''}を１つ削除します。
よろしいですか？`,
      ['OK', 'キャンセル'],
      300, 250
    );
    if (selected === 'OK') {
      const newData = careerItems?.filter((e) => e.uuid !== uuid);
      setCareerItems(newData);
      onChange(newData);
    }
  };

  return (
    <>
      { careerItems.map((item, index) => (
        <View
          key={`careerItemAccordion_${item.uuid}`}
          style={styles.box}
        >
          <View
            style={[
              {
                backgroundColor: Styles.COLOR_MAIN_ACCENT_BG,
                flexDirection: 'row',
                alignContent: 'stretch',
              },
              expandCareerItems[index]
                ? {
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                } : {
                  borderRadius: 8,
                },
            ]}
          >
            <View
              style={{
                alignSelf: 'flex-start',
              }}
            >
              <TouchableOpacity
                onPress={() => changeExpandCareerItem(index)}
                style={{
                  padding: 8,
                  margin: 0,
                  cursor: 'pointer',
                }}
              >
                <IconText
                  dir={DIR.ANTDESIGN}
                  icon={expandCareerItems[index] ? 'downcircle' : 'upcircle'}
                  iconColor="#fff"
                  containerStyle={{ margin: 0 }}
                  textStyle={{ color: '#fff', marginLeft: 8 }}
                >
                  { accordionText(item, index) }
                </IconText>
              </TouchableOpacity>
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                flex: 1,
                paddingHorizontal: 16,
              }}
            >
              <TouchableOpacity
                onPress={() => changeSort(index, -1)}
                disabled={index < 1}
                style={{cursor: 'pointer'}}
              >
                <IconText
                  icon="triangle-up"
                  iconColor={index < 1 ? "transparent" : "#fff"}
                  iconSize={32}
                  dir={DIR.ENTYPO}
                />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => changeSort(index, 1)}
                disabled={index + 1 >= careerItems?.length}
                style={{cursor: 'pointer'}}
              >
                <IconText
                  icon="triangle-down"
                  iconColor={index + 1 >= careerItems?.length ? "transparent" : "#fff"}
                  iconSize={32}
                  dir={DIR.ENTYPO}
                />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => delItem(item.uuid)}
                style={{cursor: 'pointer'}}
              >
                <IconText
                  icon="delete"
                  iconColor="#fff"
                  iconSize={24}
                  dir={DIR.ANTDESIGN}
                />
              </TouchableOpacity>
            </View>
          </View>
          <View
            style={[
              {
                padding: 8,
              },
              expandCareerItems[index]
                ? { flex: 1 }
                : { display: 'none' },
            ]}
          >
            <EditEngineerCareerItem
              key={`careerItem_${item.uuid}`}
              onChange={(data) => changeCareerItem(data, index)}
              value={item}
            />
          </View>
        </View>
      ))}
      <PlusButton
        onPress={() => addCareerItem()}
      />
    </>
  );
}

EditEngineerCareer.propTypes = {
  onChange: PropTypes.func.isRequired,
  value : PropTypes.arrayOf(EngineerCareer.propTypes),
};

EditEngineerCareer.defaultProps = {
  value: [EngineerCareer.defaultProps],
};

const styles = StyleSheet.create({
  box: {
    borderWidth: 2,
    borderColor: '#aaa',
    borderRadius: 10,
    margin: 8,
    padding: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 4,
      height: 6,
    },
    shadowOpacity: 0.4,
    elevation: 16,
  },
});
