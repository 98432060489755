import { Platform } from 'react-native';

/**
 * base64 をダウンロードさせる
 * @param {*} base64
 * @param {*} fileName
 */
export const download = (base64, fileName) => {
  if (Platform.OS === 'web') {
    const a = document.createElement('a');
    a.href = base64;
    a.download = fileName;
    a.click();
  } else {
    throw new Error('unsupported');
  }
};
