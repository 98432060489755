import React from 'react';
import { View, StyleSheet, Text as T } from 'react-native';
import PropTypes from 'prop-types';
import IconText from '../../common/components/IconText';
import Styles from '../Styles';

export default function Title(props) {
  const {
    dir, icon, label, fontSize, marginHorizontal, help,
  } = props;

  return (
    <View style={styles.container}>
      { !!dir && !!icon || !!help ? (
        <IconText
          containerStyle={[
            styles.titleContainer,
            {marginHorizontal}
          ]}
          textStyle={{
            fontWeight: 'bold',
            fontSize,
          }}
          dir={dir}
          icon={icon}
          help={help}
        >
          {label}
        </IconText>
      ) : (
        <T style={[
          styles.titleContainer,
          {
            fontWeight: 'bold',
            fontSize,
            marginHorizontal,
          }
        ]}>
          {label}
        </T>
      )}
    </View>
  );
}

Title.propTypes = {
  dir: PropTypes.any,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
  marginHorizontal: PropTypes.number,
  help: IconText.propTypes.help,
};

Title.defaultProps = {
  dir: null,
  icon: null,
  fontSize: 20,
  marginHorizontal: 16,
  help: null,
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  titleContainer: {
    borderBottomColor: Styles.COLOR_MAIN_ACCENT_BG,
    borderBottomWidth: 2,
    marginVertical: 8,
  },
});
