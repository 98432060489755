import React from 'react';
import {
  TouchableOpacity, View, Text, Image,
} from 'react-native';
import PropTypes from 'prop-types';

const icon = require('../../assets/sns/google_icon.png');

/**
 * Google ガイドラインボタン
 * @param {*} props
 * @returns ボタン
 */
export default function ButtonGoogle(props) {
  const {
    label, onPress,
  } = props;

  return (
    <TouchableOpacity
      style={{
        backgroundColor: '#0288e6',
        borderRadius: 4,
        flex: 1,
        maxHeight: 42,
        minHeight: 42,
        flexDirection: 'row',
        margin: 2,
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.2,
        shadowRadius: 4,
        elevation: 1,
      }}
      onPress={() => onPress()}
    >
      <View
        style={{
          width: 42,
          minHeight: 42,
          borderTopLeftRadius: 4,
          borderBottomLeftRadius: 4,
          backgroundColor: '#fff',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Image
          source={icon}
          style={{
            width: 18,
            height: 18,
          }}
        />
      </View>
      <View
        style={{
          flex: 1,
          minHeight: 42,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={{
          color: '#fff',
          fontWeight: 'bold',
          fontSize: 14,
          marginHorizontal: 16,
        }}
        >
          {label}
        </Text>
      </View>
    </TouchableOpacity>
  );
}

ButtonGoogle.propTypes = {
  label: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
};
