import React, { useCallback, useState, useEffect, useContext } from 'react';
import {
  StyleSheet, View,
} from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import Styles from '../../Styles';
import { AppContext } from '../../context/AppContext';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import Validator from '../../components/Validator';
import TitledInput from '../../components/TitledInput';
import TitledStationSelect from '../../components/TitledStationSelect';
import TitledComponent from '../../components/TitledComponent';
import TitledAddressInput from '../../components/TitledAddressInput';
import TitledInputSuggestCompany from '../../components/TitledInputSuggestCompany';
import Box from '../../../common/components/Box';
import { exStringFullToHalf, isTel } from '../../../common/util/TextUtil';
import { Client, DEF_CATEGORY } from '../../types/Types';
import { registerCautionClient } from '../../Term';
import * as Server from '../../functions/data/Server';
import Button from '../../../common/components/Button';
import Terms from '../Terms';
import UserIcon from './UserIcon';
import T from '../../components/T';
import ViewClient from './ViewClient';
import useDefinition from '../../hooks/useDefinition';
import TitledInputSuggest from '../../components/TitledInputSuggest';
import { ICON_MAX_SIZE, TITLE_ICON } from '../../Definition';
import { isArray, isEmpty } from '../../../common/util/TypeUtil';
import { getSession, saveSession } from '../../../common/functions/LocalStorage';
import { toastSorry } from '../../functions/Toast';
import { Screen } from '../../Screen';
import log from '../../../common/util/Log';
import { isNotTel } from '../../Message';
import { LinkUrl } from '../../LinkUrl';

/**
 * アカウント作成(クライアント)
 * @returns
 */
export default function EditAccountClient(props) {
  const { value } = props;
  const { userInfo, Link, LocationLink, isSp, viewDialog, dimensions  } = useContext(AppContext);

  // セッションストレージにある googleUserInfo から、氏名を取る
  const defaultEmpName = (() => {
    const localUserInfoStr = getSession('userInfo');
    const googleUserINfo = JSON.parse(localUserInfoStr)?.googleUserInfo;
    if (!!googleUserINfo) {
      return `${googleUserINfo['family_name'] || ''} ${googleUserINfo['given_name'] || ''}`;
    }
    return '';
  });

  /** プレビュー表示 */
  const [isPreview, setIsPreview] = useState(!isSp);

  const [empName, setEmpName] = useState(value?.empName || defaultEmpName);
  const [empKana, setEmpKana] = useState(value?.empKana || '');
  const [clientCategories, setClientCategories] = useState(value?.defItems?.clientCategories);
  const [empIconUrl, setEmpIconUrl] = useState(value?.empIconUrl);
  const [empTel, setEmpTel] = useState(value?.empTel || '');
  const [compName, setCompName] = useState(value?.compName || '');
  const [compKana, setCompKana] = useState(value?.compKana || '');
  const [compIconUrl, setCompIconUrl] = useState(value?.compIconUrl);
  const [compTel, setCompTel] = useState(value?.compTel || '');
  const [compAddress, setCompAddress] = useState({
    prefCd: value?.compPrefCd,
    cityCd: value?.compCityCd,
    prefName: value?.compPrefName,
    cityName: value?.compCityName,
    town: value?.compTown,
    build: value?.compBuild,
  });
  const [compStation, setCompStation] = useState({
    stationGCd: value?.compStationGCd,
    lineName: value?.compLineName,
    stationName: value?.compStationName,
  });
  const [company, setCompany] = useState(value?.company);
  const [companySiteUrl, setCompanySiteUrl] = useState(value?.companySiteUrl);

  const [errorEmpName, setErrorEmpName] = useState('');
  const [errorEmpKana, setErrorEmpKana] = useState('');
  const [errorEmpTel, setErrorEmpTel] = useState('');
  const [errorCompName, setErrorCompName] = useState('');
  const [errorCompKana, setErrorCompKana] = useState('');
  const [errorCompTel, setErrorCompTel] = useState('');
  const [errorClientCategories, setErrorClientCategories] = useState('');
  const [errorCompAddress, setErrorCompAddress] = useState('');
  const [errorCompStation, setErrorCompStation] = useState('');
  const [error, setError] = useState('');

  /** defs Items */
  const defItems = useDefinition(
    DEF_CATEGORY.CLIENT_CATEGORIES,
  );

  useEffect(() => {
    // icon を userInfo のものに差し替える
    if (!value?.empIconUrl && userInfo?.userIcon) {
      setEmpIconUrl(userInfo?.userIcon);
    }
    if (!value?.compIconUrl && userInfo?.userIcon) {
      setCompIconUrl(userInfo?.userIcon);
    }
  }, []);

  const changeImage = useCallback(async (setMethod) => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      base64: true,
      quality: 0.3,
      exif: false,
      selectionLimit: 1,
    });
    if (!result.canceled && result?.assets?.length > 0) {
      // sizeチェック
      if (result?.assets[0]?.uri?.length > ICON_MAX_SIZE) {
        toastSorry(`画像は ${ICON_MAX_SIZE / 1000000}MB 程度にしてください`, 3000);
        return;
      }
      setMethod({
        uri: result?.assets[0]?.uri,
      });
    }
  }, []);

  const makeClientData = () => ({
    accountNo: userInfo?.account?.accountNo,
    empIconUrl: empIconUrl?.uri || empIconUrl, // 選択されると uri の形になる、未選択、未変更ならそのままセット
    empName,
    empKana,
    empTel,
    compName: compName || '',
    compKana,
    compIconUrl: compIconUrl?.uri || compIconUrl, // 選択されると uri の形になる、未選択、未変更ならそのままセット
    compTel,
    compPrefCd: compAddress?.prefCd,
    compCityCd: compAddress?.cityCd,
    compPrefName: compAddress?.prefName,
    compCityName: compAddress?.cityName,
    compTown: compAddress?.town,
    compBuild: compAddress?.build,
    compStationGCd: compStation?.stationGCd,
    govCompanyNo: company?.no,
    companySiteUrl,
    defItems: {
      clientCategories,
    },
    recruitList: value?.recruitList || [],
  });

  const validate = () => {
    let ret = true;
    if (!empName) {
      setErrorEmpName('必須入力です');
      ret = false;
    }else{
      setErrorEmpName();
    }
    if (!empKana) {
      setErrorEmpKana('必須入力です');
      ret = false;
    }else{
      setErrorEmpKana();
    }
    if (!compName) {
      setErrorCompName('必須入力です');
      ret = false;
    }else {
      setErrorCompName()
    }
    if (!compKana) {
      setErrorCompKana('必須入力です');
      ret = false;
    }else {
      setErrorCompKana();
    }
    if (isEmpty(clientCategories)) {
      setErrorClientCategories('１つ以上の入力が必要です');
    }else {
      setErrorClientCategories();
    }
    if (!(compAddress?.prefCd && compAddress?.cityCd && compAddress?.town)) {
      setErrorCompAddress('住所-県・市・番地は必須です');
      ret = false;
    }else {
      setErrorCompAddress();
    }
    if (!compStation) {
      setErrorCompStation('必須です');
      ret = false;
    }else {
      setErrorCompStation();
    }
    if (!!empTel && isTel(empTel)) {
      setErrorEmpTel();
    } else {
      setErrorEmpTel('必須です');
    }
    if (!!compTel && isTel(compTel)) {
      setErrorCompTel();
    } else {
      setErrorCompTel('必須です');
    }
    return ret;
  }

  // バリデーション対象項目 のeffect
  useEffect(() => {
    validate();
  }, [empName, empKana, clientCategories, compName, compKana, compAddress]);

  const register = async () => {
    // バリデート
    if (!validate()) {
      setError('入力に不備があります');
      return;
    }
    const registerJson = makeClientData();
    // iconURL は ファイルセットされると{uri: ...} になる
    // サーバから取得すると 文字列URL 形式なので、型合わせする
    if (!!registerJson?.iconUrl?.uri) {
      registerJson.iconUrl = registerJson.iconUrl.uri;
    }

    // csrfキーを追加
    registerJson.csrfKey = await Server.requestCsrfKey();
    /** 始めての登録のときは利用規約を表示 */
    if (!value) {
      const confirm = await viewDialog(
        'サービス利用規約・ポリシー',
        (<Terms/>),
        ['同意して登録', '同意しない'],
        dimensions.width, dimensions.height);
      if (confirm?.selected !== '同意して登録') {
        return;
      }
    } else {
      const confirm = await viewDialog(
        '登録情報は公開されます',
        registerCautionClient,
        ['登録する', '見直す'],
        dimensions.width, dimensions.height);
      if (confirm?.selected !== '登録する') {
        return;
      }
    }

    try {
      // iconUrl が変更されているか
      registerJson.isChangeEmpIcon = value?.empIconUrl != empIconUrl;
      registerJson.isChangeCompIcon = value?.compIconUrl != compIconUrl;
      const result = await Server.authSendWhenFailSave(
        'client/register',
        registerJson,
      );
      /** はじめてのときはウェルカムメッセージ */
      if (!value) {
        await viewDialog(
          '登録ありがとうございます！',
          `ぜひ多くの案件でフリーランスエンジニアが活用され、
貴社のDX促進となりますことを願っております。
もし必要なくなったら、右上のアイコンからアカウント編集-条件＆設定をひらいて、アカウント削除できます。
気になることがあったら、ぜひ画面フッタにあります「ご意見/ご要望」をお寄せください！`,
          ['OK'],
          400, 300);
      }
      LocationLink(LinkUrl.SCREEN(Screen.DASHBOARD), false, true);
    } catch(err) {
      if (isArray(err?.response?.data)) {
        let setter;
        err?.response?.data.forEach((e) => {
          switch(e.param) {
            case 'empName':
              setter = setErrorEmpName;
              break;
            case 'empKana':
              setter = setErrorEmpKana;
              break;
            case 'empTel':
              setter = setErrorEmpTel;
              break;
            case 'compName':
              setter = setErrorCompName;
              break;
            case 'compKana':
              setter = setErrorCompKana;
              break;
            case 'compTel':
              setter = setErrorCompTel;
              break;
            case 'compPrefCd':
              setter = setErrorCompAddress;
              break;
            case 'compCityCd':
              setter = setErrorCompAddress;
              break;
            case 'compTown':
              setter = setErrorCompAddress;
              break;
          }
          if (!!setter) {
            setter(e.message);
          }
        });
        if (!!setter) {
          return;
        }
      }
      await viewDialog(
        '登録に失敗しました',
        err?.response?.data?.errorMessage || `${!!err ? err?.message + "\n" + err?.stack : ''}`,
        ['OK'],
        400, 300);
      toastSorry(err?.response?.data?.errorMessage);
    }
  };

  /** アカウント削除処理 */
  const deleteAccount = useCallback(async () => {
    const answer = await viewDialog(
      'アカウント情報を削除します',
      `この操作は復元できません。
ご自身で登録した情報や、他者からの評価などの情報を削除します。
削除後は新規アカウント登録をしていただくことはできます。

削除を実施してよろしいですか？`,
      ['OK', 'Cancel'],
      350, 300);
    if (answer?.selected !== 'OK') {
      return;
    }
    const reAnswer = await viewDialog(
      'ごめんなさい。もう一度確認',
      `運営はとっても悲しいですが、データを削除します。

削除する場合は、OKを選択してください`,
      ['OK', 'Cancel'],
      350, 300);
    if (reAnswer?.selected !== 'OK') {
      return;
    }
    try {
      const result = await Server.send(
        userInfo?.token,
        'client/delete',
        {},
      );
      if (result?.data?.result !== 'OK') {
        throw result;
      }
      await viewDialog(
        '削除しました。',
        `ご利用ありがとうございました。
またきっと、利用しにきてくださいね！`,
        ['OK'],
        300, 250);
      saveSession(Server.LOCAL_KEY_FAILSAVE, null);
      saveSession(Server.LOCAL_KEY_USERINFO, null);
      LocationLink(LinkUrl.LP, false);
    } catch(err) {
      toastSorry(err?.response?.data?.errorMessage || err);
    }
  }, [userInfo]);
  const client = makeClientData();

  return (
    <View
      style={[
        Styles.MAIN_VIEW,
        {
          flexDirection: 'row',
          alignContent: 'space-between',
        }
      ]}
    >
      <View
        style={{
          flexDirection: 'column',
          flex: 1,
        }}
      >
        { /** プレビュー時　sp の時はプレビューだけ、
            *              pc の時は、編集とプレビューが並列
            * 非プレビューなら編集画面のみ
            */
        (isSp && !isPreview) || !isSp || !isPreview ? (

          <Box
            title="クライアント登録"
            style={{
              margin: 16,
            }}
            componentStyle={{
              padding:8,
              paddingRight: 16,
            }}
          >
            <TitledInputSuggestCompany
              componentKey="companyNo"
              label="法人名で法人情報を検索して引用"
              onChange={(v) => {
                // 値があれば、disable にしていく
                setCompany(v);
                setCompName(v?.object?.name);
                setCompKana(v?.object?.kana);
                setCompAddress({
                  prefCd: v?.object?.prefCd,
                  prefName: v?.object?.prefName,
                  cityCd: `${v?.object?.prefCd}${v?.object?.cityCd}`,
                  cityName: v?.object?.cityName,
                  town: v?.object?.town,
                  build: '',
                });
              }}
              value={company}
            />
            <View
              style={{
                flexDirection: isSp ? 'column' : 'row',
                marginTop: 16
              }}
            >
              {/** 会社名 */}
              <View style={{width: isSp ? '100%' : '60%'}}>
                <TitledInput
                  key="compNameInput"
                  placeholder="クライアント名"
                  onChangeText={(e) => setCompName(e)}
                  value={compName}
                  maxLength={64}
                  validator={new Validator(
                    (t) => ((t?.length > 64) ? '64文字以内' : null),
                    (t) => (!t ? '必須入力です' : null),
                  )}
                  error={errorCompName}
                  disabled={!!company?.object?.name}
                />
              </View>
              {/** 会社名かな */}
              <View style={{width: isSp ? '100%' : '40%'}}>
                <TitledInput
                  key="compKanaInput"
                  placeholder="クライアント名かな"
                  onChangeText={(e) => setCompKana(e)}
                  value={compKana}
                  maxLength={64}
                  validator={new Validator(
                    (t) => ((t?.length > 64) ? '64文字以内' : null),
                    (t) => (!t ? '必須入力です' : null),
                  )}
                  error={errorCompKana}
                  disabled={!!company?.object?.kana}
                />
              </View>
            </View>
            {/** 会社アイコン */}
            <TitledComponent
              componentStyle={{
                flexDirection: 'column',
                marginLeft: Styles.USER_ICON_SIZE / 2,
                flex: 1,
                alignItems: 'flex-start',
              }}
              title="クライアントアイコン"
              help={`画像クリックで選択（${ICON_MAX_SIZE / 1000000}MB以内）`}
            >
              <UserIcon
                nickName={compName}
                size={Styles.USER_ICON_SIZE}
                image={compIconUrl}
                onPress={() => changeImage(setCompIconUrl)}
              />
              <T style={{color: '#00f'}} onPress={() => setCompIconUrl(userInfo?.userIcon)}>Default</T>
            </TitledComponent>
            {/** サイトURL */}
            <TitledInput
              key="compSiteUrlInput"
              placeholder="企業メインサイト"
              onChangeText={(e) => setCompanySiteUrl(e)}
              value={companySiteUrl}
              maxLength={256}
            />
            {/* クライアント種別 */}
            <TitledInputSuggest
              multiple
              key="clientCategoriesSelect"
              componentKey="clientCategoriesSelect"
              label="クライアント種別"
              help={`表示領域により省略される場合、先頭より優先的に表示されます。`}
              modalHelp={`表示領域により省略される場合、先頭より優先的に表示されます。`}
              onChange={(e) => {
                setClientCategories(e.concat());
              }}
              items={defItems.clientCategories}
              value={clientCategories}
              subIcons={[
                TITLE_ICON.public,
              ]}
              isEnableCustomValue
              itemBgColor={Styles.ITEM_BG_COLOR_CATEGORIES}
              itemTextColor={Styles.ITEM_TEXT_COLOR_CATEGORIES}
              error={errorClientCategories}
              errorBgColor="yellow"
            />
            {/* 会社住所 */}
            <TitledAddressInput
              key="compAddressSelect"
              componentKey="compAddressSelect"
              placeholder="住所"
              onChange={(e) => setCompAddress(e)}
              value={compAddress}
              error={errorCompAddress}
              disabled={!!company?.object?.prefCd && !!company?.object?.cityCd}
            />
            {/* 会社最寄駅 */}
            <TitledStationSelect
              key="compStationSelect"
              componentKey="compStationSelect"
              placeholder="最寄駅"
              prefCd={compAddress?.prefCd}
              cityCd={compAddress?.cityCd}
              onChange={(v) => setCompStation(v)}
              value={compStation}
              error={errorCompStation}
            />
            {/** 会社TEL */}
            <TitledInput
              key="compTelInput"
              placeholder="代表TEL(ハイフンなし)"
              onChangeText={(e) => setCompTel(e)}
              onBlur={(e) => {
                setCompTel(
                  exStringFullToHalf(e.target.value)
                );
              }}
              value={compTel}
              maxLength={11}
              validator={new Validator(
                (t) => (!!t && !isTel(t) ? isNotTel : null),
              )}
              error={errorCompTel}
            />
            <View
              style={{
                flexDirection: isSp ? 'column' : 'row',
                marginTop: 8
              }}
            >
              {/** 担当者氏名 */}
              <View style={{width: isSp ? '100%' : '50%'}}>
                <TitledInput
                  key="empNameInput"
                  placeholder="担当者氏名"
                  onChangeText={(e) => setEmpName(e)}
                  value={empName}
                  maxLength={64}
                  validator={new Validator(
                    (t) => ((t > 64) ? '64文字以内' : null),
                    (t) => (!t ? '必須入力です' : null),
                  )}
                  error={errorEmpName}
                />
              </View>
              {/** 担当者名かな */}
              <View style={{width: isSp ? '100%' : '50%'}}>
                <TitledInput
                  key="empKanaInput"
                  placeholder="担当者名かな"
                  onChangeText={(e) => setEmpKana(e)}
                  value={empKana}
                  maxLength={64}
                  validator={new Validator(
                    (t) => ((t > 64) ? '64文字以内' : null),
                    (t) => (!t ? '必須入力です' : null),
                  )}
                  error={errorEmpKana}
                />
              </View>
            </View>
            {/** 担当者アイコン */}
            <TitledComponent
              componentStyle={{
                flexDirection: 'column',
                marginLeft: Styles.USER_ICON_SIZE / 2,
                flex: 1,
                alignItems: 'flex-start',
              }}
              title="担当者アイコン"
              help={`担当者アイコン 画像クリックで選択（${ICON_MAX_SIZE / 1000000}MB以内）`}
            >
              <UserIcon
                nickName={empName}
                size={Styles.USER_ICON_SIZE}
                image={empIconUrl}
                onPress={() => changeImage(setEmpIconUrl)}
              />
              <T style={{color: '#00f'}} onPress={() => setEmpIconUrl(userInfo?.userIcon)}>Default</T>
            </TitledComponent>
            {/** ご担当TEL */}
            <TitledInput
              key="empTelInput"
              placeholder="ご担当TEL(ハイフンなし)"
              onChangeText={(e) => setEmpTel(e)}
              onBlur={(e) => {
                setEmpTel(
                  exStringFullToHalf(e.target.value)
                );
              }}
              value={empTel}
              maxLength={11}
              validator={new Validator(
                (t) => (!!t && !isTel(t) ? isNotTel : null),
              )}
              error={errorEmpTel}
            />
            {/** アカウント削除 */}
            {!!value && (
              <TitledComponent
                help="アカウント情報を削除します"
                title=""
                style={{
                  margin: 16,
                  borderTopWidth: 1,
                  borderTopColor: 'gray',
                  alignItems: 'flex-start',
                }}
              >
                <Button
                  onPress={deleteAccount}
                  buttonColor="#ff0000"
                  labelColor="#ffffff"
                  label="アカウント削除"
                  buttonWidth={250}
                />
              </TitledComponent>
            )}
          </Box>
        ) : (
          <Box>
            <ViewClient client={client} />
          </Box>
        )}
        <View
          style={{
            alignSelf: 'center',
            width: '80%',
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          { !!error && (
            <T
              style={{
                color: '#f00',
                fontWeight: 'bold',
              }}
            >
              {error}
            </T>
          )}
          <Button
            label="キャンセル"
            onPress={() => Link(LinkUrl.TOP)}
          />
          <Button
            label={ !isPreview ? 'プレビュー' : isSp ? '編集に戻る' : 'プレビューを消す' }
            onPress={() => setIsPreview(!isPreview)}
          />
          { !!isPreview && (
            <Button
              label="保存"
              onPress={() => register()}
            />
          )}
        </View>
      </View>
      { !isSp && !!isPreview && (
        <Box
          title="プレビュー"
          style={{
            margin: 16,
            flex: 1,
            alignSelf: null,
          }}
          componentStyle={{
            padding:8,
            paddingRight: 16,
          }}
        >
          <ViewClient client={client} />
        </Box>
      )}
    </View>
  );
}

EditAccountClient.propTypes = {
  value: Client.propTypes,
};

const styles = StyleSheet.create({
  buttonContainer: {
    paddingHorizontal: 56,
  },
  imageContainer: {
    width: '100%',
    alignItems: 'center',
  },
  image: {
    width: 100,
    height: 100,
  },
  buttonStyle: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'flex-start',
    marginVertical: 2,
    backgroundColor: '#0288e6',
    borderRadius: 50,
    paddingHorizontal: 8,
    paddingVertical: 2,
    height: 40,
    shadowColor: '#000',
    shadowOffset: {
      width: 4,
      height: 8,
    },
    shadowOpacity: 0.4,
    elevation: 16,
  },
  buttonDisabledStyle: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'flex-start',
    marginVertical: 2,
    backgroundColor: '#0288e6',
    borderRadius: 50,
    paddingHorizontal: 8,
    paddingVertical: 2,
    height: 40,
    shadowColor: '#000',
    shadowOffset: {
      width: 4,
      height: 8,
    },
    shadowOpacity: 0.4,
    elevation: 16,
  },
});
