import React, { useState, useContext, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { AppContext } from '../../context/AppContext';
import Styles from '../../Styles';
import { Engineer, PublishLevel } from '../../types/Types';
import { isEmpty, optional } from '../../../common/util/TypeUtil';
import TabBox, { TabBoxItem } from '../../../common/components/TabBox';
import ViewEngineer from './ViewEngineer';
import { ENGINEER_CAREER_DEFS_CATEGORIES } from './EngineerCareerItem';
import { termCount } from '../../Definition';
import CareerSheet from '../CareerSheet';
import { getGitInfo } from '../../functions/data/Server';

const PREVIEW_ENGINEER_TAB_KEY = {
  friendEngineer: 'friendEngineer',
  allEngineer: 'allEngineer',
  allRequester: 'allRequester',
  sentCareerSheet: 'sentCareerSheet',
};

/**
 * アカウント　プレビュー(エンジニア)
 * @returns
 */
export default function PreviewAccountEngineer(props) {
  const { engineer } = props;
  const [viewTabKey, setViewTabKey] = useState(PREVIEW_ENGINEER_TAB_KEY.friendEngineer);
  const { isSp } = useContext(AppContext);

  /* メインの表示 各閲覧元の対象に合わせてバックエンドから来るべき情報に補正して表示する */
  const viewCommon = (publishLevel) => {
    let viewData = JSON.parse(JSON.stringify(engineer)); //簡易DEEPCOPY

    /** engineerCareerDefsMonths 経歴の defs の経験月数を計算
     * 経歴を表示しない対象でもサマリ情報として表示される
     * バックエンドで計算されるが、プレビューではフロントで機能を持つ
     */
    // engineerCareerDefs のカテゴリ = EngineerCareerItem.ENGINEER_CAREER_DEFS_CATEGORIES
    const careerDefsMonths = {};
    let careerMonthsTotal = 0; // 全経歴月数
    optional(viewData.engineerCareers)?.forEach((career) => {
      const careerMonths = termCount(career);
      careerMonthsTotal+= careerMonths;
      ENGINEER_CAREER_DEFS_CATEGORIES.map((category) => {
        const defs = career[category];
        optional(defs)?.forEach((def) => {
          if (!careerDefsMonths[category]) {
            careerDefsMonths[category] = [];
          }
          const target = careerDefsMonths[category]?.find((d) => d.no === def.no);
          if (!target){
            careerDefsMonths[category].push({
              no: def.no,
              label: def.label,
              termMonthsTotal: careerMonths,
            });
          } else {
            // 経験月数 加算
            target.termMonthsTotal += careerMonths;
          }
        });
      })
    });
    viewData.careerDefsMonths = careerDefsMonths;
    viewData.careerMonthsTotal = careerMonthsTotal;

    // 公開設定毎判定、閲覧させないデータはnullにする
    // 活動圏
    const isViewArea = engineer.settingAreaPublishLevel >= publishLevel;
    if (!isViewArea) {
      /* optional(engineer.areas?.filter((e) => !!e.no))?.map((e) => ( */
      viewData.areas = null;
    }
    // 経歴
    const isViewCareer = engineer.settingCareerPublishLevel >= publishLevel;
    if (!isViewCareer) {
      /* {engineer.engineerCareers.map((item) => (*/
      viewData.engineerCareers = null;
    } else if(!isEmpty(viewData.engineerCareers)) {
      // 稼働先企業名
      const isViewCompany = engineer.settingCareerCompanyPublishLevel >= publishLevel;
      if (!isViewCompany) {
        /*
          item = engineer.engineerCareers.map((e))
          {item?.companyMain?.object?.name}
          {item?.agent?.object?.name}
        */
        viewData.engineerCareers.forEach((e) => {
          e.companyMain = null;
          e.agent = null;
        });
      }
      // 報酬額
      const isViewReward = engineer.settingCareerRewardPublishLevel >= publishLevel;
      if (!isViewReward) {
        /* !isEmpty(item?.rewardHour) */
        viewData.engineerCareers.forEach((e) => {
          e.rewardHour = null;
        });
      }
    }

    return (
      <ViewEngineer
        engineer={viewData}
      />
    );
  };

  return (
    <TabBox
      title="Preview"
      help="他の人からの見え方を表示します"
      tabHeight={50}
      style={{
        margin: 16,
      }}
      viewTabKey={viewTabKey}
      setViewTabKey={setViewTabKey}
    >
      <TabBoxItem
        caption={isSp ? 'フレンド 向け' : "フレンド エンジニア向け"}
        key={PREVIEW_ENGINEER_TAB_KEY.friendEngineer}
      >
        {viewCommon(PublishLevel.friendOnly.value)}
      </TabBoxItem>
      <TabBoxItem
        caption={isSp ? '全 エンジニア' : "全エンジニア向け"}
        key={PREVIEW_ENGINEER_TAB_KEY.allEngineer}
      >
        {viewCommon(PublishLevel.engineerOnly.value)}
      </TabBoxItem>
      <TabBoxItem
        caption="企業含む 公開"
        key={PREVIEW_ENGINEER_TAB_KEY.allRequester}
      >
        {viewCommon(PublishLevel.public.value)}
      </TabBoxItem>
      {/* <TabBoxItem
        caption="経歴書 送付先"
        key={PREVIEW_ENGINEER_TAB_KEY.sentCareerSheet}
      >
        <CareerSheet
          engineer={engineer}
        />
      </TabBoxItem> */}
    </TabBox>
  );
}

PreviewAccountEngineer.propTypes = {
  engineer: Engineer.propTypes,
};

const styles = StyleSheet.create({
  engineerCategoryLabel: {
    fontWeight: 'bold',
    color: Styles.COLOR_MAIN_ACCENT_BG,
    marginLeft: 8,
    marginVertical: 2,
  },
  processesLabel: {
    fontWeight: 'bold',
    color: Styles.COLOR_MAIN_TEXT_STRONG,
    marginLeft: 8,
    marginVertical: 2,
  },
  skillLabel: {
    fontWeight: 'bold',
    color: '#000',
    backgroundColor: '#ccc',
    paddingHorizontal: 8,
    marginRight: 8,
    marginVertical: 2,
    borderRadius: 4,
  },
});
