import React from 'react';
import { View } from 'react-native';
import Box from '../../common/components/Box';
import { cookiePolicy, privacyPolicy, termForEngineer } from '../Term';
import ViewString from './component/ViewString';

/**
 * 規約
 * @returns
 */
export default function Terms() {

  const terms = [
    {name: 'termForEngineer', data: termForEngineer},
    {name: 'privacyPolicy', data: privacyPolicy},
    {name: 'cookiePolicy', data: cookiePolicy}
  ];

  return (
    <Box
      style={{
        maxWidth: '90%',
        margin: 8,
        flexDirection: 'column',
        alignSelf: 'center',
        padding: 16,
      }}
    >
      {terms.map(t => (
        <View key={`termView_${t.name}`} style={{marginVertical: 16,}}>
          <ViewString key={`term_${t.name}`} data={t.data} isFirstTitle/>
        </View>
      ))}
    </Box>
  );

}
