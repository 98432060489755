import React from 'react';
import {
  TouchableOpacity, Image, View,
} from 'react-native';
import PropTypes from 'prop-types';
import IconText from './IconText';

/**
 * イメージのボタン
 * @param {*} props
 * @returns ボタン
 */
export default function ImageButton(props) {
  const {
    onPress, image, buttonStyle, imageStyle, help,
  } = props;

  if (!!help) {
    return (
      <View style={{flexDirection: 'row'}}>
        <TouchableOpacity style={buttonStyle} onPress={onPress}>
          <Image style={imageStyle} source={image} />
        </TouchableOpacity>
        <IconText
          help={help}
        ></IconText>
      </View>
    );
  } else {
    return (
      <TouchableOpacity style={buttonStyle} onPress={onPress}>
        <Image style={imageStyle} source={image} />
      </TouchableOpacity>
    );
  }
}

ImageButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  image: PropTypes.any.isRequired,
  buttonStyle: PropTypes.any.isRequired,
  imageStyle: PropTypes.any.isRequired,
  help: IconText.propTypes.help,
};

ImageButton.defaultProps = {
  help: IconText.defaultProps.help,
};
