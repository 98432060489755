import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer, Tooltip } from 'recharts';
import Styles from '../../src/Styles';

/* RadarChart は 表示までの間に横幅サイズの変更で表示位置がずれると
 * 変なアニメーションをするので、ちょっと待ってあげる
 */
export default function WaitRadarChart(props) {
  const {data, width, height, dataKeyLabel, dataKeyCount,
    name, colorStroke, colorFill, waitSecond} = props;

  const [isWait, setIsWait] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsWait(false);
    }, (waitSecond || 0) * 1000);
  }, []);

  if (isWait) {
    return (
      <View
        style={{
          width,
          height,
          justifyContent: 'center',
        }}
      >
        <ActivityIndicator
          animating
          color={Styles.COLOR_WAITING_INDICATOR}
          size="large"
        />
      </View>
    )
  }

  return (
    // <ResponsiveContainer width={width} height={height}>
    <View style={{
      width, height
    }}>
      <ResponsiveContainer>
        <RadarChart
          data={data}
        >
          <PolarGrid />
          <PolarAngleAxis dataKey={dataKeyLabel} />
          <PolarRadiusAxis />
          <Radar
            name={name}
            dataKey={dataKeyCount}
            stroke={colorStroke}
            fill={colorFill}
            fillOpacity={0.6}
          />
          <Tooltip/>
        </RadarChart>
      </ResponsiveContainer>
    </View>
  );
}
