import React, { useState, useContext, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { AppContext } from '../context/AppContext';
import Button from '../../common/components/Button';
import RegisterAccountClient from './component/EditAccountClient';
import EditAccountEngineer from './component/EditAccountEngineer';
import Box from '../../common/components/Box';
import { View } from 'react-native';
import { DIR } from '../../common/components/IconText';
import T from '../components/T';
import Waiting from '../components/Waiting';

/**
 * アカウント作成
 * @param tab 表示するタブキー
 * @returns
 */
export default function RegisterAccount(routeParam) {

  const tab = routeParam?.route?.params?.tab;
  const { userInfo, isSp, } = useContext(AppContext);
  const [accountType, setAccountType] = useState();

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if(!!userInfo?.engineer) {
      setAccountType('engineer');
    }
    if(!!userInfo?.client) {
      setAccountType('client');
    }
    setInitialized(true);
  }, []);

  if (!initialized) {
    return (<Waiting/>);
  }

  return (
    <>
      { !accountType && (
        <Box
          title="アカウント選択"
          style={{
            width: '80%',
            margin: 16,
            justifyContent: 'center',
            alignSelf: 'center',
            paddingBottom: 32,
          }}
          componentStyle={{
            height: '100%',
          }}
          isNoScroll
        >
          <View
            style={{
              height: '100%',
              justifyContent: 'space-evenly',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: isSp ? 'column' : 'row',
              paddingHorizontal: 32,
            }}
          >
            <View>
              <T style={!isSp ? {width: 300}: {}}>
                エンジニア同士で評価し合ったり、クライアントから仕事を受注できます。
              </T>
              <Button
                onPress={() => {
                  setAccountType('engineer');
                }}
                dir={DIR.FONTAWESOME5}
                icon="user-cog"
                label="エンジニア"
                buttonStyle={styles.userTypeSelectButton}
              />
            </View>
            <View>
              <T style={!isSp ? {width: 300}: {}}>
                エンジニアを探す・依頼する、案件を掲載できます。
              </T>
              <Button
                onPress={() => {
                  setAccountType('client');
                }}
                dir={DIR.FONTAWESOME5}
                icon="building"
                label="クライアント"
                buttonStyle={styles.userTypeSelectButton}
              />
            </View>
          </View>
        </Box>
      )}
      { accountType === 'client' && (
        <>
          { !userInfo?.client && !userInfo?.engineer && (
            <Button
              label="エンジニアの登録はこちら"
              dir={DIR.FONTAWESOME5}
              icon="user-cog"
              onPress={() => {
                setAccountType('engineer');
              }}
            />
          )}
          <RegisterAccountClient
            key="editAccountClient"
            value={userInfo?.client}
          />
        </>
      )}
      { accountType === 'engineer' && (
        <>
          { !userInfo?.client && !userInfo?.engineer && (
            <Button
              label="仕事を依頼するクライアント登録はこちら"
              dir={DIR.FONTAWESOME5}
              icon="building"
              onPress={() => {
                setAccountType('client');
              }}
            />
          )}
          <EditAccountEngineer
            key="editAccountEngineer"
            isEditMode={!!userInfo?.engineer}
            isPersonalInfo={!!userInfo?.engineer}
            value={userInfo?.engineer}
            tab={tab}
          />
        </>
      )}
    </>
  );

}

const styles = StyleSheet.create({
  userTypeSelectButton: {
    width: 150,
    height: 100,
    borderRadius: 16,
    justifyContent: 'center',
    paddingLeft: 8,
    alignItems: 'left',
    shadowColor: '#000',
    shadowOffset: {
      width: 2,
      height: 4,
    },
    shadowOpacity: 0.4,
    elevation: 16,
  },
})
