const Screen = {
  DEFAULT_SCREEN: 'defaultScreen',
  LOGIN: 'login',
  REGISTER_ACCOUNT: 'registerAccount',
  EDIT_ACCOUNT: 'editAccount',
  DASHBOARD: 'dashboard',
  ENGINEER: 'engineer',
  PREVIEW_SELF_GIT_INFO: 'previewSelfGitInfo',
  PRIVACY_POLICY: 'privacyPolicy',
  TERMS: 'terms',
  CLIENT: 'client',
  EDIT_RECRUIT_ITEM: 'editRecruitItem',
  RECRUIT_LIST: 'recruitList',
  CAREER_SHEET: 'careerSheet',
  CAREER_SHEET_DOWNLOAD_PUBLIC: 'careerSheetDownloadPublic',
  CAREER_SHEET_DOWNLOAD_TARGET: 'careerSheetDownloadTarget',
  SEARCH_ACCOUNT: 'searchAccount',
};

module.exports = {
  Screen,
};
