// タイトルロゴ
const IMAGE_LOGO = require('../assets/fen_logo.png');

// favicon
const FAVICON = require('../assets/favicon.png');

// icon
const ICON = require('../assets/icon.png');

// バナー
const IMAGE_FEATURE = require('../assets/title_logo.png');
const { optional, isEmpty } = require('../common/util/TypeUtil');

const TITLE_ICON = {
  required: { backgroundColor: '#f00', color: '#fff', text: '必須' },
  public: { backgroundColor: '#fa4', color: '#000', text: '公開' },
  private: { backgroundColor: '#000', color: '#fff', text: '非公開' },
  noRequired: { backgroundColor: '#faf', color: '#000', text: '任意' },
  selectablePublish: { backgroundColor: '#faa', color: '#000', text: '選択公開' },
  forCareerSheet: { backgroundColor: '#000', color: '#fff', text: '経歴書用個人情報' },
};

// 最も直近の予約可能な配布日
const SEND_DATE_MIN = () => {
  const ret = new Date();
  return ret;
};

// 最も未来の予約可能な配布日
const SEND_DATE_MAX = (lengthSendDelay) => {
  const ret = new Date();
  ret.setHours(0, 0, 0, 0);
  ret.setDate(ret.getDate() + (lengthSendDelay || 0) + 1);
  ret.setSeconds(ret.getSeconds() - 1);
  return ret;
};

// デフォルト期限
const DEFAULT_LIMIT = (sendDate, maxLimitDays) => {
  const ret = sendDate ? new Date(sendDate.getTime()) : new Date();
  ret.setDate(ret.getDate() + maxLimitDays);
  ret.setHours(0, 0, 0, 0);
  return ret;
};

// スマホとPCの幅境界
const IS_PC = (windowSize) => windowSize > 1000;

const FACEBOOK_URL = (id) => `https://www.facebook.com/${id}/`;
const TWITTER_URL = (id) => `https://twitter.com/${id}/`;

const separateYM = (yyyymm) => {
  const yyyy = parseInt(yyyymm?.slice(0, 4), 10);
  const mm = parseInt(yyyymm?.slice(4), 10);
  return {
    yyyy,
    mm,
  }
};

/** termSt termEd をもつ JSON から表示期間文字を得る */
const viewTerm = (item) => {
  if (item.termEd === '000000') {
    return `${termCount(item)}か月継続中`;
  }
  const { yyyy, mm } = separateYM(item.termEd);
  return `～${yyyy}年${mm}月 (${termCount(item)}か月)`;
};

/** termSt termEd をもつ JSON から表示期間文字（短文）を得る */
const viewTermShort = (item) => {
  if (item.termEd === '000000') {
    return `${termCount(item)}か月継続中 `;
  }
  return `${termCount(item)}か月`;
};

/** termSt termEd をもつ JSON から何か月かを得る */
const termCount = (item) => {
  let { yyyy: termStY, mm: termStM } = separateYM(item.termSt);
  // 000000 は継続中なので、現在年月
  const now = new Date();
  let { yyyy: termEdY, mm: termEdM } = item.termEd === '000000'
    ? {yyyy: now.getFullYear(), mm: now.getMonth() + 1}
    : separateYM(item.termEd);

  // もし開始・終了が未来ならば、現在年月にする
  if (termStY > now.getFullYear() || (termStY == now.getFullYear() && termStM >= now.getMonth() + 1)) {
    termStY = now.getFullYear();
    termStM = now.getMonth() + 1;
  }
  if (termEdY > now.getFullYear() || (termEdY == now.getFullYear() && termEdM >= now.getMonth() + 1)) {
    termEdY = now.getFullYear();
    termEdM = now.getMonth() + 1;
  }

  let termCount = 0;
  while(termStY * 100 + termStM < termEdY * 100 + termEdM) {
    termCount += 1;
    termStM += 1;
    if (termStM > 12) {
      termStM = 1;
      termStY += 1;
    }
  }
  return termCount + 1; // 開始を月初め　終了を月末として +1
};

// 重複期間を除いて合計の月数を得る
const viewCareerMonthsTotal = (careers) => {
  if (isEmpty(careers)) {
    return '未入力';
  }
  let isValid = false;
  let minYm = 999999;
  let maxYm = 0;
  const now = new Date();
  optional(careers)?.forEach((c) => {
    isValid = true;
    const st = parseInt(c.termSt, 10);
    const ed = c.termEd === '000000'
      ? `${now.getFullYear() * 100 + now.getMonth() + 1}`
      : parseInt(c.termEd, 10);
    if (minYm > st) minYm = st;
    if (maxYm < ed) maxYm = ed;
  });
  const term = termCount({
    termSt: `${minYm}`,
    termEd: `${maxYm}`,
  });
  return `${parseInt(term / 12, 10)}年 ${term % 12 > 0 ? `${parseInt(term % 12, 10)}か月` : ''}`;
};

module.exports = {
  IMAGE_LOGO,
  FAVICON,
  ICON,
  ICON_MAX_SIZE: 500000 * 4, // byte 計算
  IMAGE_FEATURE,
  SEND_DATE_MAX,
  SEND_DATE_MIN,
  DEFAULT_LIMIT,
  IS_PC,
  FACEBOOK_URL,
  TWITTER_URL,
  TITLE_ICON,
  viewTerm,
  viewTermShort,
  termCount,
  viewCareerMonthsTotal,
  separateYM,
};
