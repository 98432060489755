import React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import IconText from '../../common/components/IconText';
import T from './T';

export default function TitledComponent(props) {
  const {
    dir, icon, title, children, iconColor, iconSize, help, subIcons, componentStyle, style, titleStyle, titleContainerStyle,
    error, errorBgColor, appendNode,
  } = props;

  return (
    <View
      style={[
        style,
        !!error ? {backgroundColor: errorBgColor} : null,
      ]}
    >
      {(!!icon || !!help || !!subIcons || !!appendNode || !!title) && (
        <IconText
          containerStyle={titleContainerStyle || styles.titleContainer}
          textStyle={titleStyle || styles.titleText}
          dir={dir}
          icon={icon}
          iconColor={iconColor}
          iconSize={iconSize}
          help={help}
          subIcons={subIcons}
          appendNode={appendNode}
        >
          {title}
        </IconText>
      )}
      <View style={componentStyle || styles.childrenContainer}>
        {children}
      </View>
      { !!error && (
        <>
          <T
            style={{
              color: '#f00',
              fontWeight: 'bold',
              marginBottom: 8,
              marginLeft: 32,
            }}
          >
            {error}
          </T>
        </>
      )}
    </View>
  );
}

TitledComponent.propTypes = {
  dir: IconText.propTypes.dir,
  icon: IconText.propTypes.icon,
  iconColor: IconText.propTypes.iconColor,
  iconSize: IconText.propTypes.iconSize,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  help: IconText.propTypes.help,
  subIcons: IconText.propTypes.subIcons,
  componentStyle: PropTypes.any,
  style: PropTypes.any,
  titleStyle: PropTypes.any,
  titleContainerStyle: PropTypes.any,
  error: PropTypes.string,
  errorBgColor: PropTypes.string,
  appendNode: IconText.propTypes.appendNode,
};

TitledComponent.defaultProps = {
  style: null,
  dir: IconText.defaultProps.dir,
  icon: IconText.defaultProps.icon,
  iconColor: '#000',
  iconSize: IconText.defaultProps.iconSize,
  title: null,
  titleStyle: null,
  titleContainerStyle: null,
  help: null,
  subIcons: null,
  componentStyle: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  error: null,
  errorBgColor: null,
  appendNode: null,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    flex: 1,
  },
  titleContainer: {
    marginLeft: 16,
    paddingTop: 8,
  },
  titleText: {
    fontSize: 16,
  },
  childrenContainer: {
    marginLeft: 16,
    flex: 1,
  },
});
