import React, { useState, useContext } from 'react';
import { View, FlatList } from 'react-native';
import { Avatar, ListItem, } from '@react-native-material/core';
import { AppContext } from '../context/AppContext';
import Box from '../../common/components/Box';
import Styles from '../Styles';
import useHistory from '../hooks/useHistory';
import useMessage from '../hooks/useMessage';
import UserIcon from './component/UserIcon';
import { formatDate } from '../../common/util/DateUtils';
import { isEmpty } from '../../common/util/TypeUtil';
import TabBox, { TabBoxItem } from '../../common/components/TabBox';
import ViewEngineer from './component/ViewEngineer';
import ViewClient from './component/ViewClient';
import T from '../components/T';
import Waiting from '../components/Waiting';
import LocationLink from '../../common/functions/LocationLink';
import { LinkUrl } from '../LinkUrl';

// sp の時はタブビュー
const TAB_KEY = {
  ACCOUNT_INFO: 'accountInfo',
  MESSAGE_INFO: 'messageInfo',
  HISTORY_INFO: 'historyInfo',
};

/**
 * ダッシュボード
 * @returns
 */
export default function Dashboard() {
  const { userInfo, isSp, viewDialog, Link } = useContext(AppContext);

  const [histories, nextHistories] = useHistory();
  const [messages, nextMessages] = useMessage();

  // sp のときのタブビュー管理用
  const [viewTabKey, setViewTabKey] = useState(TAB_KEY.ACCOUNT_INFO);

  const viewAccountInfo = () => {
    if (!!userInfo?.engineer) {
      return (
        <ViewEngineer
          engineer={userInfo?.engineer}
        />
      );
    }
    if (!!userInfo?.client) {
      return (
        <ViewClient
          client={userInfo.client}
          isEditable={false}
        />
      );
    }
    return <Waiting/>;
  }

  const viewMessageInfo = () => {
    return !isEmpty(messages)
    ? (
      <FlatList
        data={messages}
        keyExtractor={(item, index) => `viewMessage_flatList_${item.no || index}`}
        renderItem={({item, index}) => {
          return !!item.fromAccountNo
          ? (
            <ListItem
              leadingMode="avatar"
              leading={
                <Avatar image={{uri: item.iconUrlFrom}}/>
              }
              title={item.description}
              secondaryText={`${item.nickNameFrom} (${formatDate(item.regDate, 'yyyy-MM-dd HH:mm')})`}
              onPress={!!item.url ? () => LocationLink(item.url, false) : () => {
                viewDialog('エンジニア検索機能開発中です。', '今は開発中なので、みんなの活動履歴から選んでエンジニアの情報を参照してみてください。');
              }}
              style={[
                Styles.FONT,
              ]}
            />
          ) : (
            <ListItem
              title={item.description}
              secondaryText={`(${formatDate(item.regDate, 'yyyy-MM-dd HH:mm')})`}
              onPress={!!item.url ? () => LocationLink(item.url, false) : () => {
                viewDialog('エンジニア検索機能開発中です。', '今は開発中なので、みんなの活動履歴から選んでエンジニアの情報を参照してみてください。');
              }}
              style={[
                Styles.FONT,
              ]}
            />
          )
        }}
        onEndReached={nextMessages}
        onEndReachedThreshold={0.1}
        style={{
          maxHeight: 500,
        }}
      />
    ) : (
      <T>メッセージはありません</T>
    )
  };

  const viewHistoryInfo = () => {
    return !isEmpty(histories)
    ? (
      <FlatList
        data={histories}
        keyExtractor={item => `viewHistory_flatList_${item.no}`}
        renderItem={({item, index}) =>
          item?.accountType === 'engineer'
          ? (
            <ListItem
              leadingMode="avatar"
              leading={
                <UserIcon
                  size={Styles.USER_ICON_SIZE_HISTORIES}
                  image={item.iconUrl}
                  nickName={item.nickName}
                  onPress={() => Link(LinkUrl.UNIQUE_ENGINEER(item.uniqueName), false)}
                />
              }
              title={
                <T style={{fontWeight: 'bold'}}>{item.nickName}</T>
              }
              secondaryText={
                <View style={{flexDirection: 'column'}}>
                  <T style={{fontSize: 12}}>{item.description}</T>
                  <T style={{fontSize: 8}}>{`(${formatDate(item.regDate, 'yyyy-MM-dd HH:mm')})`}</T>
                </View>
              }
              onPress={() => LocationLink(item.url, false)}
            />
          ) : (
            <ListItem
              leadingMode="avatar"
              leading={
                <UserIcon
                  size={Styles.USER_ICON_SIZE_HISTORIES}
                  image={item.compIconUrl}
                  nickName={item.compName}
                  onPress={() => Link(LinkUrl.UNIQUE_CLIENT(item.accountNo), false)}
                />
              }
              title={
                <T style={{fontWeight: 'bold'}}>{item.compName}</T>
              }
              secondaryText={
                <View style={{flexDirection: 'column'}}>
                  <T style={{fontSize: 12}}>{item.description}</T>
                  <T style={{fontSize: 8}}>{`(${formatDate(item.regDate, 'yyyy-MM-dd HH:mm')})`}</T>
                </View>
              }
              onPress={() => LocationLink(item.url, false)}
            />
          )
        }
        onEndReached={nextHistories}
        onEndReachedThreshold={0.1}
      />
    ) : (
      <T>履歴がないか、取得に失敗しました</T>
    );
  };

  return (
    <>
      {isSp
      ? (
        <TabBox
          viewTabKey={viewTabKey}
          setViewTabKey={setViewTabKey}
          tabHeight={40}
          // title="DASH BOARD"
          style={{
            flex: 1,
          }}
        >
          <TabBoxItem
            caption="アカウント情報"
            key={TAB_KEY.ACCOUNT_INFO}
          >
            {viewAccountInfo()}
          </TabBoxItem>
          <TabBoxItem
            caption="あなたへの メッセージ"
            key={TAB_KEY.MESSAGE_INFO}
          >
            {viewMessageInfo()}
          </TabBoxItem>
          <TabBoxItem
            caption="みんなの 活動履歴"
            key={TAB_KEY.HISTORY_INFO}
          >
            {viewHistoryInfo()}
          </TabBoxItem>
        </TabBox>
      ) : (
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            padding: 16,
            backgroundColor: Styles.COLOR_MAIN_BG,
          }}
        >
          <Box
            style={{
              alignSelf: 'auto',
              marginHorizontal: 16,
            }}
            title="アカウント情報"
          >
            {viewAccountInfo()}
          </Box>
          <View
            style={{
              flexDirection: 'column',
              marginHorizontal: 16,
            }}
          >
            <Box
              style={{
                alignSelf: 'auto',
                marginBottom: 16,
                // maxHeight: '50%',
                // flex: 1,
              }}
              componentStyle={{
                flex: 1,
              }}
              title="あなたへのメッセージ"
              isNoScroll
            >
              {viewMessageInfo()}
            </Box>
            <Box
              style={{
                alignSelf: 'auto',
                // maxHeight: '50%',
                // flex: 1,
              }}
              componentStyle={{
                flex: 1,
              }}
              title="みんなの活動履歴"
              isNoScroll
            >
              {viewHistoryInfo()}
            </Box>
          </View>
        </View>
      )}
    </>
  )
}
