import React from 'react';
import {
  View
} from 'react-native';
import uuid from '../../../common/util/Uuid';
import T from '../../components/T';
import Title from '../../components/Title';

/**
 * 文字をちょっと成形して表示
 * @returns
 */
export default function ViewString(props) {

  const {data, isFirstTitle} = props;

  const lines = data.replace(/ /g, '').replace("\r", '').split("\n");

  const contents = [];

  let numForKey = 0;

  let isFirst = true;
  for (let s of lines) {
    if(isFirstTitle && isFirst && !!s) {
      isFirst = false;
      contents.push((
        <Title
          key={`title_${uuid()}`}
          label={s}
          fontSize={16}
          style={{fontWeight: 'bold'}}
        />
      ));
      continue;
    }
    if (!s) {
      contents.push((
        <View key={`blank_${uuid()}`} style={{height: 16}}></View>
      ));
      continue;
    }

    if (s.match(/^\d{4}.+\d+.+\d.+制定$/)) {
      contents.push((
        <View
          key={`date_${uuid()}`}
          style={{
            width: '100%',
            alignItems: 'flex-end',
          }}
        >
          <T style={{fontWeight: 'bold'}}>{s}</T>
        </View>
      ));
      continue;
    }
    if (s.match(/^・/)) {
      contents.push((
        <View style={{flexDirection: 'row'}} key={`line_${uuid()}`}>
          <T style={{marginLeft: 16, marginRight: 8}}>・</T>
          <T>{s.replace(/^・/, '')}</T>
        </View>
      ));
      continue;
    }
    if (s.match(/^\(\d\)/)) {
      const group = s.match(/^(\(\d\))(.+)$/);
      contents.push((
        <View style={{flexDirection: 'row'}} key={`line_${uuid()}`}>
          <T key={`left_${uuid()}`} style={{marginLeft: 16, marginRight: 8}}>{group[1]}</T>
          <T key={`right_${uuid()}`}>{group[2]}</T>
        </View>
      ));
      continue;
    }
    if (s.match(/^　（\d）/)) {
      const group = s.match(/^　(（\d）)(.+)$/);
      contents.push((
        <View style={{flexDirection: 'row'}} key={`line_${uuid()}`}>
          <T key={`left_${uuid()}`} style={{marginLeft: 16, marginRight: 8}}>{group[1]}</T>
          <T key={`right_${uuid()}`}>{group[2]}</T>
        </View>
      ));
      continue;
    }
    if (s.match(/^\d/)) {
      const group = s.match(/^(\d)(.+)$/);
      contents.push((
        <View style={{flexDirection: 'row'}} key={`line_${uuid()}`}>
          <T key={`left_${uuid()}`} style={{marginLeft: 16, marginRight: 8}}>{group[1]}</T>
          <T key={`right_${uuid()}`}>{group[2]}</T>
        </View>
      ));
      continue;
    }
    if (s.match(/^　\d/)) {
      const group = s.match(/^　(\d)(.+)$/);
      contents.push((
        <View style={{flexDirection: 'row'}} key={`line_${uuid()}`}>
          <T key={`left_${uuid()}`} style={{marginLeft: 16, marginRight: 8}}>{group[1]}</T>
          <T key={`right_${uuid()}`}>{group[2]}</T>
        </View>
      ));
      continue;
    }

    contents.push((
      <T key={`line_${uuid()}`}>{s}</T>
    ));
  }

  return (
    <View style={{flexDirection: 'column'}}>
      {contents.map(e=>e)}
    </View>
  );
};
