import React, { useEffect, useContext, useCallback } from 'react';
import {
  LogBox,
} from 'react-native';
import { NavigationContainer, useNavigation, DefaultTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Toast, { BaseToast, ErrorToast } from 'react-native-toast-message';
import {
  useFonts,
  NotoSansJP_400Regular,
  NotoSansJP_700Bold,
} from '@expo-google-fonts/noto-sans-jp';
import RegisterAccount from './src/screen/RegisterAccount';
import Dashboard from './src/screen/Dashboard';
// import { toastFail, toastSorry } from './src/functions/Toast';
import { Screen } from './src/Screen';
import Waiting from './src/components/Waiting';
import { AppContextProvider, AppContext, SAVE_PARAM_KEY } from './src/context/AppContext';
import { debug } from './common/util/Log';
import Engineer from './src/screen/Engineer';
import Styles from './src/Styles';
import EditAccount from './src/screen/EditAccount';
import { MenuProvider } from 'react-native-popup-menu';
import PreviewSelfGitInfo from './src/screen/PreviewSelfGitInfo';
import Terms from './src/screen/Terms';
import * as LocalStorage from './common/functions/LocalStorage';
import LocationLink from './common/functions/LocationLink';
import { GOOGLE_ANALYTICS_ID } from './env';
import { prepareGoogleAnalyticsForWeb } from './common/functions/google/GoogleAnalytics';
import Client from './src/screen/Clinet';
import EditRecruitItem from './src/screen/EditRecruitItem';
import RecruitList from './src/screen/RecruitList';
import CareerSheet from './src/screen/CareerSheet';
import CareerSheetDownloadPublic from './src/screen/CareerSheetDownloadPublic';
import CareerSheetDownloadTarget from './src/screen/CareerSheetDownloadTarget';
import SearchAccount from './src/screen/SearchAccount';
import { LinkUrl } from './src/LinkUrl';

LogBox.ignoreLogs([
  'Setting a timer',
  'AsyncStorage has been extracted from react-native core and will be removed in a future release.',
  'Failed prop type: Hyperlink:.*',
  'Invalid prop textStyle of type array supplied to Cell',
]);

// 認証なしでログインできる params.s のもの
const NO_AUTH_SCREENS = [
  Screen.TERMS,
  Screen.CAREER_SHEET_DOWNLOAD_PUBLIC,
  Screen.CAREER_SHEET_DOWNLOAD_TARGET,
];

const Stack = createNativeStackNavigator();

// no-cache をセット
(() => {
  const noCacheMeta = document.createElement('meta');
  noCacheMeta['http-equiv'] = 'Cache-Control';
  noCacheMeta['content'] = 'no-cache';
  const noCacheMeta2 = document.createElement('meta');
  noCacheMeta2['http-equiv'] = 'Pragma';
  noCacheMeta2['content'] = 'no-cache';
  const noCacheMeta3 = document.createElement('meta');
  noCacheMeta3['http-equiv'] = 'Pragma';
  noCacheMeta3['content'] = 'no-cache';
  window.document.getElementsByTagName('head')[0].prepend(noCacheMeta);
  window.document.getElementsByTagName('head')[0].prepend(noCacheMeta2);
})();

// GA をセット
prepareGoogleAnalyticsForWeb(GOOGLE_ANALYTICS_ID);

/** URL による画面振り分けを担当 */
const DefaultScreen = () => {
  const navigation = useNavigation();

  const { userInfo, url, isDev, setNavigation } = useContext(AppContext);

  const navi = useCallback((nextScreenName, params) => {
    navigation.reset({
      index: 0,
      routes: [{
        name: nextScreenName,
        params,
      }],
    });
  }, [navigation]);

  useEffect(() => {
    (async () => {
      debug('App.jsx useEffect', url);
      setNavigation(navigation);

      // この画面が認証なしで許可されているか
      const isNoAuthScreen = NO_AUTH_SCREENS.find((e) => e === url?.params?.s);
      // NO_AUTH ならスルー
      if (!isNoAuthScreen) {
        // ユーザ取得処理終了でもアカウントがなければLPへ
        if (!userInfo?.account) {
          // 認証なし、アクセスしようとしたパラメタを保存する
          LocalStorage.saveSession(SAVE_PARAM_KEY, JSON.stringify(url));
          if (!isDev) {
            LocationLink(LinkUrl.LP); // LINEブラウザ対応
          }
          return;
        }
        // engineer client がなければ、エンジニア登録
        if (!userInfo?.engineer && !userInfo?.client) {
          navi(Screen.REGISTER_ACCOUNT, null);
          return;
        }

        // SAVE_PARAM_KEY に値があれば、前回未認証でアクセスしようとしていたところに誘導
        const saveParam = LocalStorage.getSession(SAVE_PARAM_KEY);
        if (!!saveParam) {
          LocalStorage.saveSession(SAVE_PARAM_KEY, null); //取得したので消す
          try {
            const saveUrl = JSON.parse(saveParam);
            if (!!saveUrl?.s) {
              navi(saveUrl.s, saveUrl.params);
              return;
            }
            LocationLink(saveUrl?.url || '/');
            return;
          } catch(err) {
            ; // 仕方ないので無視する
          }
        }
      }

      // nextScreen の決定
      let nextScreenName; //screen
      let param; //params
      if (!!url?.params?.e) {
        // ?e=xxx は s=engineer&uniqueName=xxx に読み替え
        // CloudFront では domain/@xxx でアクセスされ、s3リダイレクト で domain/?e=/@xxx にアクセスされる
        nextScreenName = Screen.ENGINEER;
        param = {
          uniqueName: url.params.e.replace('/@', '')
        };
      } else if (url?.path?.length > 1 && url.path[1].startsWith('@')) {
        // /@xxxx は s=engineer&uniqueName=xxx に読み替え
        // CloudFront と S3 では、まだ rewrite ができないようなので、これはあくまで希望的として記載
        nextScreenName = Screen.ENGINEER;
        param = {
          uniqueName: url.path[1].replace('@', '')
        };
      } else if (!!url?.params?.c) {
        // ?c=xxx は s=client&clientAccountNo=xxx に読み替え
        nextScreenName = Screen.CLIENT;
        param = {
          clientAccountNo: url.params.c
        };
      } else {
        // ?s=screen名 その他はパラメタに詰める
        // next画面指定がなければ、初期
        if (!url.params?.s) {
          // client があれば、CLIENT ダッシュボードへ
          // if (userInfo?.client) {
          //   // TODO 見直す
          //   nextScreenName = Screen.CLIENT;
          //   param = {client: userInfo.client};
          // }
          // // そうでなければエンジニアへ
          // if (userInfo?.engineer) {
            nextScreenName = Screen.DASHBOARD;
            param = {};
          // }
        } else {
          nextScreenName = url.params?.s;
          param = {};
          if (!!url.params) {
            Object.keys(url.params)?.filter((k) => k !== 's').forEach((k) => {
              param[k] = url.params[k];
            });
          }
        }
      }
      // nextScreenName のバリデーション　該当なければルートへ
      if (!Object.keys(Screen).find((k) => Screen[k] === nextScreenName)){
        LocationLink(LinkUrl.TOP);
      }
      navi(
        nextScreenName,
        param,
      );
      return;
    })();
    return () => {};
  }, []);

  return <Waiting title="読み込み中" />;
};


// スクリーン定義
export default function App() {
  const [fontsLoaded] = useFonts({
    NotoSansJP_400Regular,
    NotoSansJP_700Bold,
  });

  if (!fontsLoaded) {
    return <Waiting/>;
  }

  return (
    <MenuProvider>
      <AppContextProvider>
        {/* <NavigationContainer
          initialRouteName={Screen.DASHBOARD}
          drawerType="slide"
        >
          <Drawer.Navigator>
            <Drawer.Screen
              name={Screen.DEFAULT_SCREEN}
              component={DefaultScreen}
              options={{
                headerShown: true,
                title: '',
              }}
            />
          </Drawer.Navigator>
        </NavigationContainer> */}
        <NavigationContainer
          theme={{
            ...DefaultTheme,
            colors: {
              bachground: Styles.COLOR_BUTTON_BG,
              text: Styles.COLOR_TEXT_INPUT_TEXT,
            }
          }}
        >
          <Stack.Navigator
            initialRouteName={Screen.DEFAULT_SCREEN}
          >
            <Stack.Screen
              name={Screen.DEFAULT_SCREEN}
              component={DefaultScreen}
              options={{
                headerShown: true,
                title: '',
              }}
            />
            <Stack.Screen
              name={Screen.REGISTER_ACCOUNT}
              component={RegisterAccount}
              options={{
                headerShown: false,
                title: 'アカウント登録',
              }}
            />
            <Stack.Screen
              name={Screen.DASHBOARD}
              component={Dashboard}
              options={{
                headerShown: false,
                title: 'ダッシュボード',
              }}
            />
            <Stack.Screen
              name={Screen.ENGINEER}
              component={Engineer}
              options={{
                headerShown: false,
                title: 'エンジニア情報',
              }}
            />
            <Stack.Screen
              name={Screen.EDIT_ACCOUNT}
              component={EditAccount}
              options={{
                headerShown: false,
                title: 'アカウント情報編集',
              }}
            />
            <Stack.Screen
              name={Screen.PREVIEW_SELF_GIT_INFO}
              component={PreviewSelfGitInfo}
              options={{
                headerShown: false,
                title: 'エンジニア-Github情報',
              }}
            />
            <Stack.Screen
              name={Screen.TERMS}
              component={Terms}
              options={{
                headerShown: false,
                title: '規約・ポリシー',
              }}
            />
            <Stack.Screen
              name={Screen.CLIENT}
              component={Client}
              options={{
                headerShown: false,
                title: 'クライアント情報',
              }}
            />
            <Stack.Screen
              name={Screen.EDIT_RECRUIT_ITEM}
              component={EditRecruitItem}
              options={{
                headerShown: false,
                title: '案件情報登録・修正',
              }}
            />
            <Stack.Screen
              name={Screen.RECRUIT_LIST}
              component={RecruitList}
              options={{
                headerShown: false,
                title: '案件情報',
              }}
            />
            <Stack.Screen
              name={Screen.CAREER_SHEET}
              component={CareerSheet}
              options={{
                headerShown: false,
                title: '経歴書',
              }}
            />
            <Stack.Screen
              name={Screen.CAREER_SHEET_DOWNLOAD_PUBLIC}
              component={CareerSheetDownloadPublic}
              options={{
                headerShown: false,
                title: '経歴書ダウンロード',
              }}
            />
            <Stack.Screen
              name={Screen.CAREER_SHEET_DOWNLOAD_TARGET}
              component={CareerSheetDownloadTarget}
              options={{
                headerShown: false,
                title: '経歴書ダウンロード (指定者向け)',
              }}
            />
            <Stack.Screen
              name={Screen.SEARCH_ACCOUNT}
              component={SearchAccount}
              options={{
                headerShown: false,
                title: 'アカウント検索',
              }}
            />
          </Stack.Navigator>
        </NavigationContainer>
        <Toast
          config={{
            success: (props) => (
              <BaseToast
                {...props}
                style={{
                  backgroundColor: Styles.COLOR_TOAST_BG,
                  width: 380,
                  height: 150,
                  padding: 16,
                }}
                text1Style={{
                  fontSize: 18,
                  fontWeight: 'bold',
                  color: '#fff',
                }}
                text2Style={{
                  fontSize: 14,
                  color: '#fff',
                }}
              />
            ),
            error: (props) => (
              <ErrorToast
                {...props}
                style={{
                  backgroundColor: Styles.COLOR_CAUTION_BG,
                  width: 380,
                  height: 150,
                  padding: 16,
                }}
                text1Style={{
                  fontSize: 18,
                  fontWeight: 'bold',
                  color: '#fff',
                }}
                text2Style={{
                  fontSize: 14,
                  color: '#fff',
                }}
              />
            ),
          }}
        />
      </AppContextProvider>
    </MenuProvider>
  );
}
