export const isDev = window?.location?.href?.includes('localhost');

export const ENV = isDev ? 'DEV' : 'PRD';
export const HOST = isDev
  ? 'localhost'
  : 'freelance-engineer.net';
export const API_PATH_HEADER = isDev
  ? `http://${HOST}:3000/app/`
  : `https://api.${HOST}/app/`;
export const FRONT_PATH_HEADER = isDev
  ? `http://localhost:19006`
  : `https://${HOST}`;
export const GOOGLE_OAUTH_CLIENTID_WEB = isDev
  ? '485033429790-f6tgu0fqpahvrparf7o3satdmr47u24a.apps.googleusercontent.com'
  : '842693075967-jfa3fndannkncaa3hp5n9ipd61fdokcb.apps.googleusercontent.com';
export const GOOGLE_ANALYTICS_ID = 'G-XGVXW3W9YF';
export const DEBUG = isDev;
