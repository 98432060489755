import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';

/**
 * ローカルストレージからキーで値を取得
 * @param {string} key
 * @returns 値
 */
export async function getData(key) {
  const ret = await AsyncStorage.getItem(key);
  return ret;
}

/**
 * ローカルストレージにキーを保存
 * @param {string}} key
 * @param {*} value
 */
export async function saveData(key, value) {
  if (value) {
    await AsyncStorage.setItem(key, value);
  } else {
    await AsyncStorage.removeItem(key);
  }
}

export function saveSession(key, value) {
  if (Platform.OS !== 'web'){
    throw `unsupported`;
  }

  if (value) {
    sessionStorage.setItem(key, value);
  } else {
    sessionStorage.removeItem(key);
  }
}

export function getSession(key) {
  if (Platform.OS !== 'web'){
    throw `unsupported`;
  }

  return sessionStorage.getItem(key);
}
