import PropTypes from 'prop-types';
import React from 'react';
import {
  View,
  StyleSheet,
  Text,
} from 'react-native';
import { Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

const offsetLabel = (name, x, y) => {
  let offsetX
  let offsetY
  if (name === '100%') return [22, 5]
  if (x <= 145) {
    offsetX = 14
  } else {
    offsetX = 22
  }
  if (y <= 105) {
    offsetY = 11
  } else {
    offsetY = 5
  }
  return [offsetX, offsetY]
};

/** outside のラベル */
const outsideLabel = p => {
  const { name, x, y, midAngle, percent } = p;
  const [offsetX, offsetY] = offsetLabel(name, x, y);
  return (
    <g transform={`translate(${x}, ${y})`} textAnchor={(midAngle < -90 || midAngle >= 90) ? 'end' : 'start'}>
      <text x={offsetX} y={offsetY}>{`${name} ${Math.floor(percent * 1000) / 10}%`}</text>
    </g>
  );
};

/** inside のラベル */
const RADIAN = (Math.PI / 180);
const insideLabel = p => {
  const radius = p.innerRadius + (p.outerRadius - p.innerRadius) * 0.5;
  const x = p.cx + radius * Math.cos(-p.midAngle * RADIAN);
  const y = p.cy + radius * Math.sin(-p.midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="#333" textAnchor={x > p.cx ? 'start' : 'end'} dominantBaseline="central">
      {`${p.name} ${p.percent * 100}%`}
    </text>
  );
};

export default function LabelPieChart(props) {
  const {data, inside, nameKey, dataKey, fill} = props;

  return (
    <ResponsiveContainer width="100%" height={200}>
      <PieChart>
        <Pie
          data={data}
          label={(p) => inside ? insideLabel(p) : outsideLabel(p)}
          nameKey={nameKey}
          dataKey={dataKey}
          fill={fill}
        >
        </Pie>
        <Tooltip/>
      </PieChart>
    </ResponsiveContainer>
  );
}

LabelPieChart.propTypes = {
  data: PropTypes.any.isRequired,
  inside: PropTypes.bool,
  nameKey: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
};

LabelPieChart.defaultProps = {
  inside: false,
};
