// eslint-disable-next-line import/no-unresolved

import { isDev } from "../../env";

export default function log(...v) {
  if (!isDev) return;
  if (isExcludes(v)) {
    return ;
  }
  const logJson = exchange(v);
  console.log(`${logJson.currentTime.toLocaleString()} ${logJson.log.join(' / ')}`);
}

export function stop(...v) {
  if (!isDev) return;
  const logJson = exchange(v);
  alert(logJson.log.join(' / '));
}

export function debug(...v) {
  if (!isDev) return;
  log(v);
}

export function info(...v) {
  const logJson = exchange(v);
  console.log(`${logJson.currentTime.toLocaleString()} ${logJson.log.join(' / ')}`);
}

export function error(...v) {
  const logJson = exchange(v);
  const log = `${logJson.currentTime.toLocaleString()} ${logJson.log.join(' / ')}`;
  console.error(log);
  if (isDev) {
    alert(log);
  }
}

function exchange(...v) {
  const log = v.map((e) => {
    if (e instanceof Object) {
      return JSON.stringify(e, null, "\t");
    }
    return e;
  });
  const currentTime = new Date();
  return {
    currentTime: new Date(),
    log,
  };
}

// 以下条件に該当したら、ログ出力しない
const EXCLUDES_LOG_PREFIX = [
  'getUserInfo called isCheck:',
  'getUserInfo release synchro',
  'call getUserInfo in getToken',
  'login by longToken when expired',
  'call self get with longToken when expired',
];
function isExcludes(v) {
  const judge = v.find(s => {
    if (!!EXCLUDES_LOG_PREFIX.find(ex => s?.toString().startsWith(ex))) return true;

    return false;
  });
  return !!judge;
}
