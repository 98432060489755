import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { View, } from 'react-native';
import { IconButton } from "@react-native-material/core";
import AntDesign from 'react-native-vector-icons/AntDesign';
import { AppContext } from '../../context/AppContext';
import TitledInput from '../../components/TitledInput';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import * as Server from '../../functions/data/Server';
import TitledComponent from '../../components/TitledComponent';
import TitledInputSuggest from '../../components/TitledInputSuggest';
import PlusButton from '../../../common/components/PlusButton';
import Styles from '../../Styles';
import Button from '../../../common/components/Button';
import { DEF_CATEGORY } from '../../types/Types';
import useDefinition from '../../hooks/useDefinition';
import TitledInputSuggestAddition from '../../components/TitledInputSuggestAddition';
import { isEmpty, } from '../../../common/util/TypeUtil';
import InputSuggest from '../../../common/components/InputSuggest';
import T from '../../components/T';
import Box from '../../../common/components/Box';
import Waiting from '../../components/Waiting';

export const CAREER_RANGE_ITEMS = [
  {label: '期間問わず', value: 0},
  {label: '半年以上', value: 6},
  {label: '１年以上', value: 12},
  {label: '２年以上', value: 24},
  {label: '３年以上', value: 36},
  {label: '４年以上', value: 48},
  {label: '５年以上', value: 60},
];

/** 値の変更によりタイマ起動、待ち中に値が変更になったらキャンセル */
let doSearchTimer = null;

/**
 * アカウント検索(エンジニア)
 */
export default function SearchAccountEngineer(props) {
  const { condition, setResult, setCondition, setNowSearching } = props;

  const [nickName, setNickName] = useState(condition?.nickName || '');
  const [examinations, setExaminations] = useState(condition?.examinations || []); //defs array
  const [otherSkills, setOtherSkills] = useState(condition?.otherSkills || []); //defs array
  const [areas, setAreas] = useState(condition?.areas || []); //defs array
  const [fieldsRanges, setFieldsRanges] = useState(condition?.fieldsRanges || [{}]); //defs array
  const [engineerCategoriesRanges, setEngineerCategoriesRanges] = useState(condition?.engineerCategoriesRanges || [{}]); //defs array
  const [processesRanges, setProcessesRanges] = useState(condition?.processesRanges || [{}]);
  const [skillRanges, setSkillRanges] = useState(condition?.skillRanges || [{}]); //defs array

  /** defs Items */
  const defItems = useDefinition(
    DEF_CATEGORY.ENGINEER_CATEGORIES,
    DEF_CATEGORY.EXAMINATIONS,
    DEF_CATEGORY.OTHER_SKILLS,
    DEF_CATEGORY.AREAS,
    DEF_CATEGORY.SCHOOLS,
    DEF_CATEGORY.REQUESTS,
    DEF_CATEGORY.WORK_STATUSES,
    DEF_CATEGORY.EVALUATION_SETTINGS,
    DEF_CATEGORY.FIELDS,
    DEF_CATEGORY.PROCESSES,
    DEF_CATEGORY.SKILLS,
  );

  const isParams = !isEmpty(nickName) || !isEmpty(examinations) || !isEmpty(otherSkills) || !isEmpty(areas) || !isEmpty(skillRanges) || !isEmpty(fieldsRanges) || !isEmpty(engineerCategoriesRanges) || !isEmpty(processesRanges);

  /** 検索動作 */
  const doSearch = async () => {
    if (!!setNowSearching) {
      setNowSearching(false);
    }

    // いずれかのパラメタは必要
    if (!isParams) return;
    if (!!setNowSearching) {
      setNowSearching(true);
    }

    const searchCondition = {
      nickName: !!nickName ? nickName : null,
      examinations,
      otherSkills,
      areas,
      skillRanges: isEmpty(skillRanges) ? null : skillRanges,
      fieldsRanges: isEmpty(fieldsRanges) ? null : fieldsRanges,
      engineerCategoriesRanges: isEmpty(engineerCategoriesRanges) ? null : engineerCategoriesRanges,
      processesRanges: isEmpty(processesRanges) ? null : processesRanges,
    };
    if (!!setCondition) {
      setCondition(searchCondition);
    }
    const { result } = await Server.searchEngineer(searchCondition);
    if (!isEmpty(result) && !!setResult) {
      setResult(result);
    }
    if (!!setNowSearching) {
      setNowSearching(false);
    }
  };

  /** 値変更 */
  useEffect(() => {
    if (!!setNowSearching){
      setNowSearching(true);
    }
    clearTimeout(doSearchTimer);
    doSearchTimer = setTimeout(doSearch, 1500);
  }, [
    nickName,
    examinations,
    otherSkills,
    areas,
    skillRanges,
    fieldsRanges,
    engineerCategoriesRanges,
    processesRanges,
  ]);

  return (
    <View>
      {/** ニックネーム */}
      <TitledInput
        placeholder="ニックネーム"
        help="部分一致で検索します"
        onChangeText={setNickName}
        value={nickName}
        maxLength={24}
      />
      {/* 保有資格 */}
      <TitledInputSuggest
        multiple
        key="examinationsSelect"
        componentKey="examinationsSelect"
        label="保有資格"
        help={`選択したものすべてを含むエンジニアを検索します`}
        modalHelp={`選択したものすべてを含むエンジニアを検索します。カッコ内は登録人数。`}
        onChange={(e) => {
          setExaminations(e.concat());
        }}
        items={defItems.examinations}
        value={examinations}
        itemBgColor={Styles.ITEM_BG_COLOR_EXAMINATIONS}
        itemTextColor={Styles.ITEM_TEXT_COLOR_EXAMINATIONS}
        itemStyle={Styles.ITEM_STYLE_EXAMINATIONS}
      />
      {/* その他スキル */}
      <TitledInputSuggest
        multiple
        key="otherSkillsSelect"
        componentKey="otherSkillsSelect"
        label="その他スキル"
        help={`選択したものすべてを含むエンジニアを検索します`}
        modalHelp={`選択したものすべてを含むエンジニアを検索します。カッコ内は登録人数。`}
        onChange={(e) => {
          setOtherSkills(e.concat());
        }}
        items={defItems.otherSkills}
        value={otherSkills}
        itemBgColor={Styles.ITEM_BG_COLOR_EXAMINATIONS}
        itemTextColor={Styles.ITEM_TEXT_COLOR_EXAMINATIONS}
        itemStyle={Styles.ITEM_STYLE_EXAMINATIONS}
      />
      { /** エリア */ }
      <TitledInputSuggest
        multiple
        key="engineerAreaKey"
        componentKey="engineerAreaKey"
        label="活動エリア"
        help={`選択したものすべてを含むエンジニアを検索します`}
        modalHelp={`選択したものすべてを含むエンジニアを検索します。カッコ内は登録人数。`}
        items={defItems.areas}
        onChange={(v) => setAreas(v.concat())}
        value={areas}
      />
      {/* スキル-期間検索 */}
      <RangeSelectMultipleField
        stateValue={skillRanges}
        setter={setSkillRanges}
        items={defItems.skills}
        keyHead="skillRanges"
        helpHead="指定したスキルと、そのスキルの経歴期間の条件すべてを含むエンジニアを検索します。"
        label="関連スキル"
        placeholder="期間"
      />
      {/* 業界・分野-期間検索 */}
      <RangeSelectMultipleField
        stateValue={fieldsRanges}
        setter={setFieldsRanges}
        items={defItems.fields}
        keyHead="fieldRanges"
        helpHead="指定した業界・分野と、その業界・分野の経歴期間の条件すべてを含むエンジニアを検索します。"
        label="業界・分野"
        placeholder="期間"
      />
      {/* 担当カテゴリ-期間検索 */}
      <RangeSelectMultipleField
        stateValue={engineerCategoriesRanges}
        setter={setEngineerCategoriesRanges}
        items={defItems.engineerCategories}
        keyHead="engineerCategoryRanges"
        helpHead="指定した担当カテゴリと、その担当カテゴリの経歴期間の条件すべてを含むエンジニアを検索します。"
        label="担当カテゴリ"
        placeholder="期間"
      />
      {/* 担当工程-期間検索 */}
      <RangeSelectMultipleField
        stateValue={processesRanges}
        setter={setProcessesRanges}
        items={defItems.processes}
        keyHead="processRanges"
        helpHead="指定した担当工程と、その担当工程の経歴期間の条件すべてを含むエンジニアを検索します。"
        label="担当工程"
        placeholder="期間"
      />
    </View>
  );
}

SearchAccountEngineer.propTypes = {
  condition: PropTypes.shape({
    nickName: PropTypes.string,
    examinations: InputSuggest.propTypes.value,
    otherSkills: InputSuggest.propTypes.value,
    areas: InputSuggest.propTypes.value,
    fieldsRanges: InputSuggest.propTypes.value,
    engineerCategoriesRanges: InputSuggest.propTypes.value,
    processesRanges: InputSuggest.propTypes.value,
    skillRanges: InputSuggest.propTypes.value,
  }),
  setResult: PropTypes.func,
  setCondition: PropTypes.func,
  setNowSearching: PropTypes.func,
};

SearchAccountEngineer.defaultProps = {
  condition: null,
  setResult: null,
  setCondition: null,
  setNowSearching: null,
};

// xxxRanges のフィールド
const RangeSelectMultipleField = (props) => {
  const {
    stateValue, setter, items,
    keyHead, helpHead, label, placeholder,
    itemBgColor, itemTextColor, itemStyle,
  } = props;

  const {
    isSp,
  } = useContext(AppContext);

  return (
    <TitledComponent
      key={keyHead}
      componentKey={keyHead}
      help={helpHead}
    >
      { stateValue?.map((e, index) => (
        <View
          key={`${keyHead}View_${e?.no || 'none'}_${index.toString()}`}
          style={{
            width: '100%',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            alignSelf: 'flex-start',
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          <IconButton
            key={`${keyHead}DelButton_${e?.no || 'none'}_${index.toString()}`}
            icon={props => <AntDesign name="delete" {...props} />}
            onPress={() => {
              // 削除結果セッタ
              let newData = stateValue?.filter((current, i) => (i !== index)).concat();
              if (isEmpty(newData)) {
                newData = [{}];
              }
              setter(newData);
            }}
            style={{
              minWidth: 48,
              maxWidth: 48,
              alignSelf: 'center',
            }}
          />
          <TitledInputSuggestAddition
            key={`${keyHead}_${e?.no || 'none'}_${index.toString()}`}
            componentKey={`${keyHead}_${e?.no || 'none'}_${index.toString()}`}
            label={label}
            placeholder={placeholder}
            additionPlaceholder="経験期間"
            modalHelp={`${helpHead}。カッコ内は登録人数。`}
            items={items}
            onChange={(v) => {
              // 値セッタ
              const newData = stateValue?.map((current, i) => (i === index) ? v : current).concat();
              setter(newData);
            }}
            value={e}
            itemBgColor={itemBgColor}
            itemTextColor={itemTextColor}
            style={{
              flex: 1,
              flexWrap: 'wrap',
            }}
            itemStyle={itemStyle}
            vertical={isSp}
            additionItems={CAREER_RANGE_ITEMS}
          />
        </View>
      ))}
      <PlusButton
        onPress={() => {
          // 部品追加（別インスタンスとするため concat）
          const newData = stateValue.concat();
          newData.push({});
          setter(newData);
        }}
      />
    </TitledComponent>
  );
};
