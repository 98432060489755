import { Button } from '@react-native-material/core';
import { useState } from 'react';
import {
  View,
  TextInput,
  ScrollView,
} from 'react-native';
import Modal from 'react-native-modalbox';
import { isEmpty, isString } from '../../common/util/TypeUtil';
import T from '../components/T';
import Styles from '../Styles';
import uuid from '../../common/util/Uuid';
import IButton from '../../common/components/Button';
import { DIR } from '../../common/components/IconText';

/**
 * ダイアログを material で作成するヘルパ
 * @returns
 */
export default function useDialog() {
  const [component, setComponent] = useState(<></>);
  const [answer, setAnswer] = useState();

  const view = async (
    title, message, buttons, width, height, question, defaultAnswer, isBordered,
  ) => {
    const selected = await new Promise((resolve) => {
      setComponent(
        <>
          <Modal
            key={`useDialog_modal`}
            style={[
              {
                maxWidth: '90%',
                maxHeight: '90%',
              },
              !!width ? {width} : {},
              !!height ? {height} : {height: 'fit-content'},
              {
                alignContent: 'center',
                borderRadius: 10,
                padding: 16,
                shadowColor: '#888',
                shadowOffset: {
                  width: 2,
                  height: 4,
                },
                shadowOpacity: 0.4,
                elevation: 16,
              },
            ]}
            coverScreen
            isOpen={true}
            backdropPressToClose
            backdrop
            backdropColor="#888"
            backdropOpacity={0.5}
            position="center"
            entry="bottom"
            onClosed={() => {
              resolve();
            }}
            onOpened={() => {}}
            useNativeDriver={false}
          >
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <T
                style={{
                  fontSize: 20,
                  fontWeight: 'bold',
                  marginBottom: 12,
                  flex: 1,
                }}
              >
                {title}
              </T>
              <IButton
                dir={DIR.ENTYPO}
                icon="cross"
                iconColor="#888"
                iconSize={32}
                linkStyle
                onPress={() => {
                  resolve();
                }}
              />
            </View>
            <ScrollView
              style={[
                {
                  flex: 1,
                },
                (isBordered)
                  ? {
                    borderWidth: 1,
                    borderColor: '#ccc',
                    borderRadius: 8,
                  } : {}
              ]}
            >
              {isString(message)
              ? (
                <T
                  style={{
                    fontSize: 16,
                    flexWrap: 'wrap',
                  }}
                >
                  {message}
                </T>
              ) : (
                <>
                  {message}
                </>
              )}
              {!!question && (
                <TextInput
                  style={[
                    {
                      height: 24,
                      marginBottom: 0,
                      marginHorizontal: 8,
                      fontSize: 16,
                      borderWidth: 1,
                      paddingHorizontal: 8,
                    },
                    Styles.FONT,
                  ]}
                  placeholder={question}
                  onChangeText={(e) => setAnswer(e)}
                  value={answer || defaultAnswer}
                />
              )}
            </ScrollView>
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              {!isEmpty(buttons) && (
                <>
                {buttons.map((b) => (
                  <Button
                    key={uuid()}
                    title={b}
                    compact
                    variant="text"
                    onPress={() => {
                      resolve(b);
                    }}
                  />
                ))}
                </>
              )}
            </View>
          </Modal>
        </>
      );
    });
    setComponent(<></>);
    return {
      selected,
      answer,
    };
  };

  return {
    component, view,
  };
}
