import React from 'react';
import {
  TouchableOpacity, Image, View,
} from 'react-native';
import PropTypes from 'prop-types';
import { Avatar } from '@react-native-material/core';

const createStyle = (size) => ({
  width: size,
  height: size,
  borderRadius: size,
});

/**
 * ユーザアイコン
 * @param {*} props
 * @returns ボタン
 */
export default function UserIcon(props) {
  const {
    onPress, image, nickName, size,
  } = props;

  const imageContainer = !image
    ? (
      <Avatar
        style={createStyle(size)}
        label={nickName || '?'}
        labelStyle={{
          fontSize: nickName?.length > 3 ? 8 : 12,
        }}
        autoColor
        initials={nickName?.length > 4}
      />
    ) : (
      <Image
        style={createStyle(size)}
        source={image}
      />
    );

  if (!!onPress) {
    return (
      <TouchableOpacity
        style={createStyle(size)}
        onPress={onPress}
      >
        {imageContainer}
      </TouchableOpacity>
    );
  }

  return (
    <View
      style={createStyle(size)}
    >
      {imageContainer}
    </View>
  );


}

UserIcon.propTypes = {
  onPress: PropTypes.func,
  image: PropTypes.any,
  nickName: PropTypes.string,
  size: PropTypes.number.isRequired,
};

UserIcon.defaultProps = {
  onPress: null,
  image: null,
  nickName: '',
};
