import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import Styles from '../Styles';
import IconText, { DIR } from '../../common/components/IconText';
import { TouchableOpacity } from 'react-native-gesture-handler';

export default function TitledAccordion(props) {
  const { open, headerColor, touchableNode, subNode, children, style  } = props;

  const [isOpen, setIsOpen] = useState(open || false);

  return (
    <View
      style={[
        styles.box,
        style,
      ]}
    >
      <View
        style={[
          {
            backgroundColor: headerColor,
            flexDirection: 'row',
            alignContent: 'stretch',
          },
          isOpen
            ? {
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            } : {
              borderRadius: 8,
            },
        ]}
      >
        <View
          style={{
            alignSelf: 'flex-start',
          }}
        >
          <TouchableOpacity
            onPress={() => setIsOpen(!isOpen)}
            style={{
              padding: 8,
              margin: 0,
              cursor: 'pointer',
            }}
          >
            <IconText
              dir={DIR.ANTDESIGN}
              icon={isOpen ? 'downcircle' : 'upcircle'}
              iconColor="#fff"
              containerStyle={{ margin: 0 }}
              textStyle={{ color: '#fff', marginLeft: 8 }}
            >
              { touchableNode }
            </IconText>
          </TouchableOpacity>
        </View>
        { !!subNode && (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              flex: 1,
              paddingHorizontal: 16,
            }}
          >
            { subNode }
          </View>
        )}
      </View>
      <View
        style={[
          {
            padding: 8,
          },
          isOpen
            ? { flex: 1 }
            : { display: 'none' },
        ]}
      >
        { children }
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  box: {
    borderWidth: 1,
    borderColor: '#aaa',
    borderRadius: 10,
    margin: 8,
    padding: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 4,
      height: 6,
    },
    shadowOpacity: 0.4,
    elevation: 16,
  },
});

TitledAccordion.propTypes = {
  open: PropTypes.bool,
  headerColor: PropTypes.string,
  touchableNode: PropTypes.node.isRequired,
  subNode: PropTypes.node,
  style: PropTypes.any,
};

TitledAccordion.defaultProps = {
  open: false,
  headerColor: Styles.COLOR_MAIN_ACCENT_BG,
  subNode: null,
  style: null,
};
