import React, { useState } from 'react';
import { ScrollView } from 'react-native';
import { DataTable } from 'react-native-paper';
import PropTypes from 'prop-types';
import Waiting from './Waiting';
import uuid from '../../common/util/Uuid';
import { isEmpty } from '../../common/util/TypeUtil';
import T from './T';


/**
 * データリストテーブル
 * @returns
 */
export default function DataListTable(props) {
  const { headers, list, dataRowKey, height, width, defaultSortKeyOrder, rowMinHeight } = props;

  const [sortKeyOrder, setSortKeyOrder] = useState(defaultSortKeyOrder);

  // このリソースだけで利用する Table.Title 型
  const ThisTitle = (props) => {
    const { title, valueKey } = props;

    return (
      <DataTable.Title
        key={`title_cell_${valueKey || uuid()}`}
        sortDirection={
          !valueKey
            ? null
            : sortKeyOrder?.key === valueKey
                ? sortKeyOrder?.isDesc ? 'descending' : 'ascending'
                : null
        }
      >
        <T
          onPress={() => {
            if (!valueKey) return;
            setSortKeyOrder((prev) => ({
              key: valueKey,
              isDesc: prev?.key === valueKey ? !prev.isDesc : false
            }));
          }}
        >
          {`${title}`}
        </T>
      </DataTable.Title>
    );
  };

  return (
    <ScrollView
      style={{
        width,
        height,
      }}
    >
      {isEmpty(list)
      ? (
        <T>データがありません</T>
      ) : (
        <DataTable>
          <DataTable.Header>
            {headers.map(header => (
              <ThisTitle
                key={`title_${header.key}`}
                title={header.title}
                valueKey={header.key}
              />
            ))}
          </DataTable.Header>
          {list.sort((a, b) => {
            if (!sortKeyOrder?.key) return 1;
            let av = a[sortKeyOrder.key];
            let bv = b[sortKeyOrder.key];
            if (sortKeyOrder.isDesc) {
              return av == bv
                  ? 0
                  : av > bv
                    ? -1 : 1;
            }
            return av == bv
                ? 0
                : av > bv
                  ? 1 : -1;
          }).map(r => (
            <DataTable.Row
              key={`row_${!!dataRowKey ? r[dataRowKey] : uuid()}`}
              style={{
                minHeight: rowMinHeight,
              }}
            >
              {headers.map(header=>(
                <DataTable.Cell
                  key={`cell_${header.key}`}
                  style={{
                    flexWrap: 'nowrap',
                  }}
                >
                  <T>{r[header.key]}</T>
                </DataTable.Cell>
              ))}
            </DataTable.Row>
          ))}
        </DataTable>
      )}
    </ScrollView>
  );
};

DataListTable.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      key: PropTypes.string,
    }).isRequired,
  ).isRequired,
  list: PropTypes.array,
  dataRowKey: PropTypes.string,
  height: PropTypes.any,
  width: PropTypes.any,
  defaultSortKeyOrder: PropTypes.exact({
    key: PropTypes.string,
    isDesc: PropTypes.bool,
  }),
  rowMinHeight: PropTypes.number,
};

DataListTable.defaultProps = {
  dataRowKey: null,
  height: 350,
  width: '100%',
  defaultSortKeyOrder: null,
  rowMinHeight: 35,
  list: [],
};
