import { Platform, Linking } from 'react-native';
import { debug, error } from '../util/Log';

/**
 * URL へ遷移
 * @param {string} url
 * @param {boolean} isNewWindow
 */
export default function LocationLink(url, isNewWindow) {
  debug(`called link ${url}`);
  if (Platform.OS === 'web') {
    if (isNewWindow) {
      window.open(url);
    } else {
      window.location = url;
    }
  } else {
    Linking.canOpenURL(url).then((supported) => {
      if (!supported) {
        error('no support url');
        return null;
      }
      return Linking.openURL(url);
    }).catch((err) => console.error('cannot open url', err));
  }
}
